import { Directive, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[skip]',
})
export class SkipPageDirective {
  constructor(private _el: ElementRef, private renderer: Renderer2) {}
  @Output() emitNext = new EventEmitter();
  ngOnInit(): void {
    const button = this.renderer.createElement('button') as HTMLButtonElement;
    button.innerHTML = 'skip';
    button.classList.add('btn', 'btn-rounded', 'btn-small', 'rs-btn-info');
    button.addEventListener('click', () => {
      alert(
        'This form can be skipped for now but this information is required in order to assist your child better.'
      );
      this.emitNext.emit();
    });
    (this._el.nativeElement as HTMLElement).appendChild(button);
  }

}
