import {FundingSessionMonths, SessionType} from 'src/app/modules/nursery/models/child.model';
import { Session } from 'src/app/modules/nursery/models/child.session';

export const CommonConfig = {
  titles: [
    'Mr.',
    'Ms.',
    'Mrs.',
    'Miss',
  ],
  ageGroups: [
    '0-2 Years',
    '2-3 Years',
    '3-5 Years',
  ],
  rooms: [
    'Baby',
    'Toddler',
    'Pre-School',
  ],
  mapLatLang: { lat: 51.499133, lng: -0.012677 },
  premiumCost: 60,
  genderData: [
    'Boy',
    'Girl',
  ],
  religionData: [
    'Christian',
    'Islam',
    'Hindu',
    'Chinese',
  ],
  ethnicityData: [
    'British',
    'Indian',
    'Chinese',
    'French',
  ],
  firstLanguageData: [
    'English',
    'French',
    'German',
    'Indian',
  ],
  anyOtherLanguageSelect: [
    { value: 'English', label: 'English' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Italian', label: 'Italian' },
  ],
  relationshipToChildData: [
    'Mother',
    'Father',
    'Uncle',
    'Aunty',
    'Nany',
    'GrandMother',
    'GrandFather',
    'Other',
  ],
  yesNoData: ['Yes', 'No'],
  getSessions: [
    {
      session: SessionType.Full,
      days: [
        { name: 'monday', isChecked: false },
        { name: 'tuesday', isChecked: false },
        { name: 'wednesday', isChecked: false },
        { name: 'thursday', isChecked: false },
        { name: 'friday', isChecked: false }]
    },
    {
      session: SessionType.AM,
      days: [
        { name: 'monday', isChecked: false },
        { name: 'tuesday', isChecked: false },
        { name: 'wednesday', isChecked: false },
        { name: 'thursday', isChecked: false },
        { name: 'friday', isChecked: false }]
    },
    {
      session: SessionType.PM,
      days: [
        { name: 'monday', isChecked: false },
        { name: 'tuesday', isChecked: false },
        { name: 'wednesday', isChecked: false },
        { name: 'thursday', isChecked: false },
        { name: 'friday', isChecked: false }]
    }
  ],
  getFundingDates: [
    {
      session: FundingSessionMonths.Jan,
      days: { startDate: (new Date()).getFullYear() + '-01-01', endDate: (new Date()).getFullYear() + '-03-30' }
    },
    {
      session: FundingSessionMonths.April,
      days: { startDate: (new Date()).getFullYear() + '-04-01', endDate: (new Date()).getFullYear() + '-08-30' }
    },
    {
      session: FundingSessionMonths.September,
      days: { startDate: (new Date()).getFullYear() + '-09-01', endDate: (new Date()).getFullYear() + '-12-30' }
    }
  ],
  RegistrationSteps: [{
    id: 0,
    checked: true,
  },
  {
    id: 1,
    checked: false,
  },
  {
    id: 2,
    checked: false,
  },
  {
    id: 3,
    checked: false,
  },
  {
    id: 4,
    checked: false,
  },
  {
    id: 5,
    checked: false,
  },
  {
    id: 6,
    checked: false,
  },
  {
    id: 7,
    checked: false,
  },
  {
    id: 8,
    checked: false,
  },
  {
    id: 9,
    checked: false,
  }],
  Months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
};
