import { ModalToasterService } from './modal-toaster.sevrice';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-toaster',
  templateUrl: 'modal-toaster.component.html',
})

export class ModalToasterComponent implements OnInit {

  @ViewChild('frame', { static: true }) public frame: ModalDirective;

  constructor(private modalService: ModalToasterService) { }

  ngOnInit(): void {
    this.modalService.toggleModal.subscribe(response => {
      response === true ? this.frame.show() : this.frame.hide();
    });
  }

}
