<router-outlet #outlet="outlet"></router-outlet>


<section *ngIf="outlet.isActivated == false" class="text-center" id="classroom">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown">Classrooms</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">

      <p class="section-text mt-3 wow slideInUp">Rise ‘N’ Shine has three dedicated classrooms – Baby Room, Toddler Room and Pre-School Room. Each classroom is
        tailored to support them in reaching the expected level advancement and accomplishment. Our bright and airy
        rooms and a beautiful outdoor play area is a bonus to the setting. Our spacious and multi-age classrooms promote independence and are
        designed to make kids feel comfortable enough to try exciting things and to carry forward whatever their
        imaginations can perceive..</p>
    </div>
  </div>

  <!-- Grid row -->
  <div class="row mr-0 ml-0">

    <!-- Grid Column -->
    <div class="col-lg-4 pb-4">
        <!--Card-->
        <mdb-card cascade="true" class="wow fadeInRight">

          <!--Card image-->
          <div class="view view-cascade overlay waves-light" mdbWavesEffect>
            <mdb-card-img src="../../../../../assets/img/nursery/classrooms/baby-room/baby-room.jpg" alt="Rise N Shine - Baby Room"></mdb-card-img>
            <a [routerLink]="['/classrooms/baby-room']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
          <!--/Card image-->

          <mdb-card-body cascade="true" class="text-center">
            <!--Card content-->
              <h5 class="pink-text mt-3 mb-3">
                <mdb-icon fas icon="child"></mdb-icon> Baby Room
              </h5>
              <h6 class="card-title font-weight-bold">3 months to 24 months</h6>

            <mdb-card-text class="dark-grey-text">
              Rise 'N' Shine Helping the baby to explore the world around them!.
            </mdb-card-text>

            <!-- Read More Button -->
            <a mdbBtn [routerLink]="['/classrooms/baby-room']" class="rs-btn-pink" rounded="true" mdbWavesEffect>Read more</a>
            <!-- Read More Button -->

          </mdb-card-body>
          <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->
    </div>

    <!-- Grid column -->
    <div class="col-lg-4 pb-4">
      <!--Card-->
      <mdb-card cascade="true" class="wow fadeInRight">

        <!--Card image-->
        <div class="view view-cascade overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="../../../../../assets/img/nursery/classrooms/toddler-room/toddler-room.jpg" alt="Rise N Shine - Toddler Room"></mdb-card-img>
          <a [routerLink]="['/classrooms/toddler-room']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
        <!--/Card image-->

        <mdb-card-body cascade="true" class="text-center">
          <!--Card content-->
            <h5 class="deep-orange-text mt-3 mb-3">
              <mdb-icon fas icon="graduation-cap"></mdb-icon> Toddler
            </h5>
            <h6 class="card-title font-weight-bold">2 Years to 3 Years</h6>

          <mdb-card-text class="dark-grey-text">
            Introduction to the toddler room where your child discovers new challenges.
          </mdb-card-text>

          <!-- Read More Button -->
          <a mdbBtn [routerLink]="['/classrooms/toddler-room']" class="rs-btn-orange" rounded="true" mdbWavesEffect>Read more</a>
          <!-- Read More Button -->

        </mdb-card-body>
        <!--/.Card content-->

      </mdb-card>
      <!--/.Card-->
    </div>

    <!-- Grid column -->
    <div class="col-lg-4 pb-4">
      <!--Card-->
      <mdb-card cascade="true" class="wow fadeInRight">

        <!--Card image-->
        <div class="view view-cascade overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="../../../../../assets/img/nursery/classrooms/pre-school-room/pre-school-room.jpg" alt="Rise N Shine - Pre-School Room"></mdb-card-img>
          <a [routerLink]="['/classrooms/pre-school-room']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
        <!--/Card image-->

        <mdb-card-body cascade="true" class="text-center">
          <!--Card content-->
            <h5 class="blue-text mt-3 mb-3">
              <mdb-icon fas icon="graduation-cap"></mdb-icon> Pre-School
            </h5>
            <h6 class="card-title font-weight-bold">3 years to 5 years</h6>

          <mdb-card-text class="dark-grey-text">
            He next step of your child's education and preparation for school...
          </mdb-card-text>

          <!-- Read More Button -->
          <a mdbBtn [routerLink]="['/classrooms/pre-school-room']" class="rs-btn-info" rounded="true" mdbWavesEffect>Read more</a>
          <!-- Read More Button -->

        </mdb-card-body>
        <!--/.Card content-->

      </mdb-card>
      <!--/.Card-->
    </div>

  </div>
  <!-- Grid row -->

</section>


