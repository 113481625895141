import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {SafePipe} from './pipes/safe.pipe';
import {GrdFilterPipe} from './pipes/grid-pipe';

@NgModule({
  declarations: [SafePipe, GrdFilterPipe],
  imports: [CommonModule],
  providers: [],
  exports: [
    GrdFilterPipe,
  ]
})
export class CoreModule {}
