import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { NannyService } from '../../nanny-service/nanny-service.service';
import { NannyServiceModel } from './../../../../../shared/models/nanny-service.model';
import {
  saveBookingSlot,
  saveBookingSlotError,
  saveBookingSlotSuccess,
  saveChildCountInfo,
  saveChildCountInfoError,
  saveChildCountInfoSuccess,
  saveConfirmPaymentAmount,
  saveConfirmPaymentAmountError,
  saveConfirmPaymentAmountSuccess,
  saveOptionalService,
  saveOptionalServiceError,
  saveOptionalServiceSuccess,
  saveUserCredentials,
  saveUserCredentialsError,
  saveUserCredentialsSuccess,
  saveUserDetails,
  saveUserDetailsError,
  saveUserDetailsSuccess,
} from './../actions/nanny-service.action';


@Injectable()
export class NannyServiceEffects {

  constructor(private actions$: Actions, private nannyService: NannyService) { }


  @Effect({ dispatch: true })
  public saveChildCountInfo$ = this.actions$.pipe(
    ofType(saveChildCountInfo),
    map((action) => action.nannyService),
    mergeMap((payload) => this.nannyService.saveChildCountInfo(payload)
      .pipe(
        map((nannyData) => {
          const jsonObj: any = JSON.parse(nannyData);
          const nanny: NannyServiceModel = <NannyServiceModel>jsonObj;
          return saveChildCountInfoSuccess({ nannyService: nanny });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          return of(saveChildCountInfoError({ nannyService: payload }));
        })
      )));

  @Effect({ dispatch: true })
  public saveOptionalService$ = this.actions$.pipe(
    ofType(saveOptionalService),
    map((action) => action.nannyService),
    mergeMap((payload) => this.nannyService.saveOptionalService(payload)
      .pipe(
        map((nannyData) => {
          const jsonObj: any = JSON.parse(nannyData);
          const nanny: NannyServiceModel = <NannyServiceModel>jsonObj;
          return saveOptionalServiceSuccess({ nannyService: nanny });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          return of(saveOptionalServiceError({ nannyService: payload }));
        })
      )));

  @Effect({ dispatch: true })
  public saveUserDetails$ = this.actions$.pipe(
    ofType(saveUserDetails),
    map((action) => action.nannyService),
    mergeMap((payload) => this.nannyService.saveUserDetails(payload)
      .pipe(
        map((nannyData) => {
          const jsonObj: any = JSON.parse(nannyData);
          const nanny: NannyServiceModel = <NannyServiceModel>jsonObj;
          return saveUserDetailsSuccess({ nannyService: nanny });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          return of(saveUserDetailsError({ nannyService: payload }));
        })
      )));

  @Effect({ dispatch: true })
  public saveUserCredentials$ = this.actions$.pipe(
    ofType(saveUserCredentials),
    map((action) => action.nannyService),
    mergeMap((payload) => this.nannyService.saveUserCredentials(payload)
      .pipe(
        map((nannyData) => {
          const jsonObj: any = JSON.parse(nannyData);
          const nanny: NannyServiceModel = <NannyServiceModel>jsonObj;
          return saveUserCredentialsSuccess({ nannyService: nanny });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          return of(saveUserCredentialsError({ nannyService: payload }));
        })
      )));

  @Effect({ dispatch: true })
  public saveBookingSlot$ = this.actions$.pipe(
    ofType(saveBookingSlot),
    map((action) => action.nannyService),
    mergeMap((payload) => this.nannyService.saveBookingSlot(payload)
      .pipe(
        map((nannyData) => {
          const jsonObj: any = JSON.parse(nannyData);
          const nanny: NannyServiceModel = <NannyServiceModel>jsonObj;
          return saveBookingSlotSuccess({ nannyService: nanny });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          return of(saveBookingSlotError({ nannyService: payload }));
        })
      )));


  @Effect({ dispatch: true })
  public saveConfirmPayment$ = this.actions$.pipe(
    ofType(saveConfirmPaymentAmount),
    map((action) => action.nannyService),
    mergeMap((payload) => this.nannyService.confirmPayment(payload)
      .pipe(
        map((nannyData) => {
          const jsonObj: any = JSON.parse(nannyData);
          const nanny: NannyServiceModel = <NannyServiceModel>jsonObj;
          return saveConfirmPaymentAmountSuccess({ nannyService: nanny });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          return of(saveConfirmPaymentAmountError({ nannyService: payload }));
        })
      )));


}
