import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NannyServiceState, nannyStateFeatureKey } from './../reducers/nanny-service.reducer';

export const selectNannyState = createFeatureSelector<NannyServiceState>(nannyStateFeatureKey);

export const selectNannyFromStore = createSelector(selectNannyState,
  (nannyService: NannyServiceState) => {
    return nannyService;
  }
);

export const selectTotalChildrenFromStore = createSelector(selectNannyState,
  (nannyService: NannyServiceState) => {
     let total = 0;
     nannyService?.nannyService?.childrenCountGroup?.forEach(item => total = total + item.count);
     return total;
  }
);