<!-- Childcare Funding Section -->
<div id="childcare-funding">
    <!-- About Funding -->
    <section id="about-funding" class="about-funding">
      <img class="d-block mx-auto mb-4 wow slideInDown" src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle"  />
      <h1 class="section-heading font-weight-bold wow slideInDown text-center">WE ACCEPT 30 HOURS FREE CHILDCARE, THE FUNDED 30 HRS FUNDING AMOUNT IS DEDUCTED FROM OUR MONTHLY FEE</h1>
      <!-- Section description -->
      <div class="row ml-0 mr-0">
        <div class="col-lg-10 col-sm-10 mx-auto">
            <p class="section-text text-center mt-3 wow slideInUp">The Government currently offers ‘free early education’ (FEE) to most 3 year olds and all 4 year olds up to 15 hours per week term time (38 weeks a year), although Rise 'N' Shine 'stretch' this to offer 570 free hours over a year. In addition, eligible working families can claim a further 15 hours per week term time for their 3&4 year olds through the 'Extended Entitlement' (EE), set up through their HMRC government gateway account, which we also 'stretch' to offer a further 570 free hours over a year.</p>
<!--            <p class="section-text text-center mt-3 wow slideInUp">The aim of FEE is to ensure all eligible children have access to good quality early education which is free at the point of delivery. As a provider of good quality early education, Rise 'N' Shine are registered to claim the FEE on your behalf, as well as the EE.</p>-->
          <p class="section-text text-center mt-4 wow slideInUp"><a [routerLink]="['/fee']" title="Registration">Click Here To See After 30 Hrs Discount Fee</a></p>
        </div>
      </div>
    </section>
    <!-- End About Funding -->

    <!-- Seprator Line-->
    <hr class="rs-mt-40 rs-mb-40 clearfix" />
    <!-- End Seprator Line-->

    <!-- Childcare Feature Section -->
    <section id="childcare" class="childcare-feature">
        <div class="feature-wrapper">
          <div class="feature-inner">

            <!-- Row 1 -->
            <div class="row clearfix">
              <div class="col-lg-6 col-md-12 p-0 pr-0">
                  <div class="featured-img img-block tm-animation-floating mt-2">
                      <img class="img-fluid" src="../../../../../assets/img/nursery/funding/30-hours/img-01.jpg" alt="Featured Image One">
                  </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="text-wrapper wow slideInRight">
                    <div class="icon-wrapper">
                        <img src="../../../../../assets/img/icons/round-circle-blue.png" width="40" height="40" alt="Rounder Circle" />
                    </div>
                    <h2>30 Hours Of Eearly Years Funding</h2>
                    <div class="line-1 mb-3">
                        The government's 30-hours funded childcare places for working parents of three- and four-year-olds differs from the 15 hours childcare offer.
                    </div>
                    <div class="line-1 mb-3">
                        You may be able to get 30 hours free childcare if:
                        <ul>
                            <li>you live in England</li>
                            <li>your child is 3 to 4 years old</li>
                        </ul>
                    </div>
                    <div class="button-wrap mt-4">
                      <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-blue" mdbWavesEffect title="Learn More">Contact Us</a>
                    </div>
                  </div>
              </div>
            </div>
            <!-- End Row 1 -->

            <!-- Seprator Line-->
            <hr class="rs-mt-40 rs-mb-40 clearfix" />
            <!-- End Seprator Line-->

            <!-- Row 2 -->
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 order-md-2 order-2">
                <div class="text-wrapper wow slideInLeft">
                    <div class="icon-wrapper">
                        <img src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle" />
                    </div>
                    <h2>HOW TO <br> USE HOURS</h2>
                    <div class="line-1 mb-3">
                        You can get 30 hours of free childcare per week for 38 weeks of the year (during school term time).
                    </div>
                    <div class="line-1 mb-3">
                        You may be able to get free childcare for 52 weeks if you use fewer than 30 hours per week. Check with your childcare provider to find out if this is something they offer.
                    </div>
                    <div class="button-wrap mt-4">
                    <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded btn-unique" mdbWavesEffect title="Learn More">Contact Us</a>
                    </div>
                </div>
                </div>
                <div class="col-lg-6 col-md-12 order-lg-2">
                <div class="featured-img img-block tm-animation-floating mt-3">
                    <img class="img-fluid mr-img-left" src="../../../../../assets/img/nursery/funding/30-hours/img-02.jpg" alt="Featured Image One">
                </div>
                </div>
            </div>
            <!-- End Row 2 -->

            <!-- Seprator Line-->
            <hr class="rs-mt-40 rs-mb-40 clearfix" />
            <!-- End Seprator Line-->

            <!-- Row 3 -->
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 p-0 pr-0">
                    <div class="featured-img img-block tm-animation-floating mt-3">
                        <img class="img-fluid" src="../../../../../assets/img/nursery/funding/30-hours/img-01.jpg" alt="Featured Image Three">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                <div class="text-wrapper wow slideInRight">
                    <div class="icon-wrapper">
                        <img src="../../../../../assets/img/icons/round-circle-blue.png" width="40" height="40" alt="Rounder Circle" />
                    </div>
                    <h2>ELIGIBILITY</h2>
                    <div class="line-1 mb-3">
                        You can get 30 hours of free childcare per week for 38 weeks of the year (during school term time). Your eligibility depends on:
                    </div>
                    <!-- Accordion Wrapper -->
                    <div class="accordion md-accordion accordion-1" id="eligibilityTracking" role="tablist">
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="workingHeading">
                                <h5 class="mb-0 py-1">
                                <a class="collapsed font-weight-bold" data-toggle="collapse" href="#workingCollapse" aria-expanded="false"
                                    aria-controls="workingCollapse">
                                    1. If You Are Working <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                </a>
                                </h5>
                            </div>
                            <div id="workingCollapse" class="collapse" role="tabpanel" aria-labelledby="workingHeading"
                                data-parent="#eligibilityTracking">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">

                                        <!-- Box Text -->
                                        <div class="box-text">
                                            <h6 class="mt-3 mb-4 black-text">(A) <strong>If you are working</strong></h6>
                                            <p class="black-text">You can usually get 30 hours free childcare if you (and your partner, if you have one) are:</p>
                                            <div class="ordered-list mb-4">
                                                <ul>
                                                <li class="black-text"><i class="fas fa-arrow-right"></i>In work</li>
                                                <li class="black-text"><i class="fas fa-arrow-right"></i>On Sick Leave or Annual Leave</li>
                                                <li class="black-text"><i class="fas fa-arrow-right"></i>On Shared Parental, Maternity, Paternity or Adoption Leave</li>
                                                </ul>
                                            </div>
                                            <p class="black-text">If you’re on adoption leave for a child aged 3 to 4 years old, you must return to work within 31 days of the date you first apply for 30 hours free childcare for that child.</p>
                                        </div>
                                        <!-- End Box Text -->

                                        <!-- Box Text -->
                                        <div class="box-text">
                                            <h6 class="mt-3 mb-4 black-text">(B) <strong>If your working pattern has changed because of coronavirus (COVID-19)</strong></h6>
                                            <p class="black-text">You may still be eligible for 30 hours free childcare if you’re temporarily working less and either:</p>
                                            <div class="ordered-list mb-4">
                                                <ul>
                                                    <li class="black-text"><i class="fas fa-arrow-right"></i>you’re on furlough through the Coronavirus Job Retention Scheme</li>
                                                    <li class="black-text"><i class="fas fa-arrow-right"></i>you’re claiming a Self Employment Income Support Scheme grant</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End Box Text -->

                                        <!-- Box Text -->
                                        <div class="box-text">
                                            <h6 class="mt-3 mb-4 black-text">(C) <strong>If you are not currently working</strong></h6>
                                            <p class="black-text">You may still be eligible if your partner is working, and you get Incapacity Benefit, Severe Disablement Allowance, Carer’s Allowance or contribution-based Employment and Support Allowance.</p>
                                            <p class="black-text">You can apply if you’re starting or re-starting work within the next 31 days.</p>
                                        </div>
                                        <!-- End Box Text -->

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                        <div class="card-header z-depth-1" role="tab" id="incomeHeading">
                            <h5 class="mb-0 py-1">
                            <a class="collapsed font-weight-bold" data-toggle="collapse" href="#incomeCollapse"
                                aria-expanded="false" aria-controls="incomeCollapse">
                                2. Your Income <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                            </a>
                            </h5>
                        </div>
                        <div id="incomeCollapse" class="collapse" role="tabpanel" aria-labelledby="incomeHeading"
                            data-parent="#eligibilityTracking">
                            <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                <!-- Box Text -->
                                <div class="box-text">
                                        <p class="black-text">You’ll need to expect to earn a certain amount over the next 3 months. This is at least the National Minimum Wage or Living Wage for 16 hours a week on average.</p>
                                        <p class="black-text">For example, over the next 3 months you expect to earn at least £1,813.76 - the National Living Wage for people over 25.</p>
                                        <p class="black-text">If you have a partner, they’ll need to expect to earn at least this much too.</p>
                                        <p class="black-text">If you’re self-employed and do not expect to make enough profit in the next 3 months, you can use an average of how much you expect to make over the current tax year.</p>
                                        <p class="black-text">This earnings limit does not apply if you’re self-employed and started your business less than 12 months ago.</p>
                                        <p class="black-text">If you or your partner have an expected ‘adjusted net income’ over £100,000 in the current tax year you will not be eligible. This includes any bonuses you expect to get.</p>
                                        <p class="black-text">Your adjusted net income is your total taxable income before any personal allowances and minus things like Gift Aid.</p>
                                    </div>
                                    <!-- End Box Text -->
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="childHeading">
                            <h5 class="mb-0 py-1">
                                <a class="collapsed font-weight-bold" data-toggle="collapse" href="#childCollapse"
                                aria-expanded="false" aria-controls="childCollapse">
                                3. Your Child <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                </a>
                            </h5>
                            </div>
                            <div id="childCollapse" class="collapse" role="tabpanel" aria-labelledby="childHeading"
                            data-parent="#eligibilityTracking">
                            <div class="card-body">
                                <div class="row">
                                <div class="col-md-12">
                                    <!-- Box Text -->
                                    <div class="box-text">
                                        <p class="black-text">You will not be eligible if your child does not usually live with you.</p>
                                        <p class="black-text">If you foster your child, you cannot use the online application to get 30 hours free childcare. Speak to your social worker and your local authority if you want to apply.</p>
                                    </div>
                                    <!-- End Box Text -->
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="immigrationHeading">
                            <h5 class="mb-0 py-1">
                                <a class="collapsed font-weight-bold" data-toggle="collapse" href="#immigrationCollapse"
                                aria-expanded="false" aria-controls="immigrationCollapse">
                                4. Your Immigration Status <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                </a>
                            </h5>
                            </div>
                            <div id="immigrationCollapse" class="collapse" role="tabpanel" aria-labelledby="immigrationHeading"
                            data-parent="#eligibilityTracking">
                            <div class="card-body">
                                <div class="row">
                                <div class="col-md-12">
                                    <!-- Box Text -->
                                    <div class="box-text">
                                        <p class="black-text">You will not be eligible if you’re from outside the EEA and your UK residence card says you cannot access public funds.</p>
                                        <p class="black-text">Your partner can apply instead if they’re from:</p>
                                        <div class="ordered-list mb-4">
                                            <ul>
                                                <li class="black-text"><i class="fas fa-arrow-right"></i>the UK or EEA</li>
                                                <li class="black-text"><i class="fas fa-arrow-right"></i>outside the EEA and their UK residence card says they can access public funds</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- End Box Text -->
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="checkMyEligibilityHeading">
                            <h5 class="mb-0 py-1">
                                <a class="collapsed font-weight-bold" data-toggle="collapse" href="#checkMyEligibilityCollapse"
                                aria-expanded="false" aria-controls="checkMyEligibilityCollapse">
                                5. Check Eligibility <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                </a>
                            </h5>
                            </div>
                            <div id="checkMyEligibilityCollapse" class="collapse" role="tabpanel" aria-labelledby="checkMyEligibilityHeading"
                            data-parent="#eligibilityTracking">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>It should take around 5 minutes to check your eligibility. To check if you are eligible for 15-30 free hours a week free childcare fill the form below:</p>
                                        <!-- Default form contact -->

                                        <form class="border border-light p-3 mt-5" [formGroup]="nestedForm" (ngSubmit) ="onSubmit()">
                                            <!-- Form Heading -->
                                            <h2 class="pb-4 mb-5 text-center border-bottom">Eligibility Check</h2>

                                            <mdb-stepper #stepper [linear]="true" [vertical]="isVertical">

                                                <!-- Step 1 -->
                                                <mdb-step name="Step 1">
                                                  <app-eligibility-step-one formControlName="eligibilityFormOne" [stage]="'Step 1'" [step]="stepper" ></app-eligibility-step-one>
                                                </mdb-step>

                                                <!-- Step 2 -->
                                                <mdb-step name="Step 2">
                                                  <app-eligibility-step-two formControlName="eligibilityFormTwo" [stage]="'Step 2'" [step]="stepper"></app-eligibility-step-two>
                                                </mdb-step>

                                                <!-- Step 3 -->
                                                <mdb-step name="Step 3">
                                                  <app-eligibility-step-three formControlName="eligibilityFormThree" [stage]="'Step 3'" [step]="stepper"></app-eligibility-step-three>
                                                </mdb-step>

                                                <!-- Step 4 -->
                                                <mdb-step name="Step 4">
                                                  <app-eligibility-step-four formControlName="eligibilityFormFour" [stage]="'Step 4'" [step]="stepper"></app-eligibility-step-four>
                                                </mdb-step>

                                            </mdb-stepper>
                                        </form>
                                        <!-- Default form contact -->
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!--Accordion wrapper-->
                    <div class="button-wrap mt-4">
                        <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-blue" mdbWavesEffect title="Learn More">Contact Us</a>
                    </div>
                    </div>
                </div>
            </div>
            <!-- End Row 3 -->

            <!-- Seprator Line-->
            <hr class="rs-mt-40 rs-mb-40 clearfix" />
            <!-- End Seprator Line-->

            <!-- Row 4 -->
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 order-md-2 order-2">
                    <div class="text-wrapper wow slideInLeft">
                    <div class="icon-wrapper">
                        <img src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle" />
                    </div>
                    <h2>WHEN TO <br> APPLY</h2>
                    <div class="line-1 mb-3">
                        You can apply from when your child is 2 years and 36 weeks old.
                    </div>
                    <div class="table-wrapper">
                        <table mdbTable bordered="true">
                        <thead class="blue white-text">
                          <tr>
                            <th>If your child turns three between</th>
                            <th>They can take up their Free place from</th>
                          </tr>
                        </thead>
                        <tbody class="grey lighten-3 black-text">
                          <tr>
                            <td>1 April to 31 August</td>
                            <td>1 September (Autumn Term)</td>
                          </tr>
                          <tr>
                            <td>1 September to 31 December</td>
                            <td>1 January (Spring Term)</td>
                          </tr>
                          <tr>
                            <td>1 January to 31 March</td>
                            <td>1 April (Summer Term)</td>
                          </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="button-wrap mt-4">
                    <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded btn-unique" mdbWavesEffect title="Learn More">Contact Us</a>
                    </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-lg-2">
                    <div class="featured-img img-block tm-animation-floating mt-3">
                    <img class="img-fluid mr-img-left" src="../../../../../assets/img/nursery/funding/30-hours/img-02.jpg" alt="Featured Image One">
                    </div>
                </div>
            </div>
            <!-- End Row 4 -->

            <!-- Seprator Line-->
            <hr class="rs-mt-40 rs-mb-40 clearfix" />
            <!-- End Seprator Line-->

             <!-- Row 5 -->
             <div class="row clearfix">
                <div class="col-lg-6 col-md-12 p-0 pr-0">
                    <div class="featured-img img-block tm-animation-floating mt-3">
                        <img class="img-fluid" src="../../../../../assets/img/nursery/funding/30-hours/img-01.jpg" alt="Featured Image Three">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                <div class="text-wrapper wow slideInRight">
                    <div class="icon-wrapper">
                        <img src="../../../../../assets/img/icons/round-circle-blue.png" width="40" height="40" alt="Rounder Circle" />
                    </div>
                    <h2>HOW TO <br/>APPLY</h2>
                    <div class="line-1 mb-3">
                        You can get 30 hours of free childcare per week for 38 weeks of the year (during school term time). To Apply for free childcare follow below steps:
                    </div>
                    <!-- Accordion Wrapper -->
                    <div class="accordion md-accordion accordion-1" id="applyFreeChildCare" role="tablist">
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="checkEligibility">
                                <h5 class="mb-0 py-1">
                                <a class="collapsed font-weight-bold" data-toggle="collapse" href="#checkEligibilityCollapse" aria-expanded="false"
                                    aria-controls="checkEligibilityCollapse">
                                    1. Check Your Eligibility <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                </a>
                                </h5>
                            </div>
                            <div id="checkEligibilityCollapse" class="collapse" role="tabpanel" aria-labelledby="checkEligibility"
                                data-parent="#applyFreeChildCare">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p>To check if you are eligible for 30 free hours a week, or Tax-Free Childcare, you’ll need to know the working hours and income for yourself (and your partner if you have one).</p>
                                        <p>It should take around 5 minutes to check your eligibility. To check your eligibility <a href="https://www.tax.service.gov.uk/childcare-calc/location" class="red-text">CLICK HERE</a></p>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <mdb-image-modal [modalImages]="checkEligibilityModal" type="no-margin"></mdb-image-modal>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="gatewayAccountHeading">
                                <h5 class="mb-0 py-1">
                                <a class="collapsed font-weight-bold" data-toggle="collapse" href="#gatewayCollapse"
                                    aria-expanded="false" aria-controls="gatewayCollapse">
                                    2. Create Government Gateway Account<i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                </a>
                                </h5>
                            </div>
                            <div id="gatewayCollapse" class="collapse" role="tabpanel" aria-labelledby="gatewayAccountHeading"
                                data-parent="#applyFreeChildCare">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="black-text">Create a government gateway account to get your 11 digit eligibility code.</p>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <mdb-image-modal [modalImages]="createAccountModal" type="no-margin"></mdb-image-modal>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-header z-depth-1" role="tab" id="codeHeading">
                                <h5 class="mb-0 py-1">
                                    <a class="collapsed font-weight-bold" data-toggle="collapse" href="#codeCollapse"
                                    aria-expanded="false" aria-controls="codeCollapse">
                                    3. Take Your Code To Childcare Provider<i class="fas fa-angle-down rotate-icon fa-1x"></i>
                                    </a>
                                </h5>
                            </div>
                            <div id="codeCollapse" class="collapse" role="tabpanel" aria-labelledby="codeHeading"
                            data-parent="#applyFreeChildCare">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="black-text">Take your code to your childcare provider/childminder</p>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <mdb-image-modal [modalImages]="codeModal" type="no-margin"></mdb-image-modal>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!--Accordion wrapper-->
                    <div class="button-wrap mt-4">
                        <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-blue" mdbWavesEffect title="Learn More">Contact Us</a>
                    </div>
                    </div>
                </div>
            </div>
            <!-- End Row 5 -->

          </div>
        </div>
    </section>
    <!-- End Childcare Feature Section -->

  </div>
  <!-- Childcare Funding Section -->
