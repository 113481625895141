<section class="no-gutter" *ngIf="customFormGroup" [formGroup]="customFormGroup">
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div class="md-form">
        <input mdbInput maxlength="50" type="text" id="serviceName" formControlName="serviceName" class="form-control" mdbValidate>
        <label for="serviceName">Service Name</label>
        <mdb-error *ngIf="customFormGroup.get('serviceName').touched && customFormGroup.get('serviceName').errors">
          Please enter Service Name
        </mdb-error>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="md-form">
        <input mdbInput type="text" maxlength="50" id="nname" formControlName="name" class="form-control" mdbValidate>
        <label for="nname">Name</label>
        <mdb-error *ngIf="customFormGroup.get('name').touched && customFormGroup.get('name').errors">
          Please enter Name
        </mdb-error>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="md-form">
        <mdb-date-picker name="date" id="date" [options]="'{}'" formControlName="date" mdbValidate>
        </mdb-date-picker>
        <label for="date" class="active text-left"> Date Involvement commenced</label>
        <mdb-error *ngIf="customFormGroup.get('date').touched && customFormGroup.get('date').errors">
          Please Enter Date
        </mdb-error>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="md-form">
        <input mdbInput type="text" maxlength="25" id="contactInfo" formControlName="contactInfo" class="form-control" mdbValidate>
        <label for="contactInfo">
          Contact Information and Telephone Number
        </label>
        <mdb-error *ngIf="customFormGroup.get('contactInfo').touched && customFormGroup.get('contactInfo').errors">
          Please Enter Contact Information
        </mdb-error>
      </div>
    </div>
  </div>
</section>
