{{parentFormGroup.value | json}}
<form [formGroup]="parentFormGroup" *ngIf="parentFormGroup">
  <div id="parent-info">
    <!-- Row 1 -->
    <div class="form-row clearfix">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'title'+uniqueIndex" class="active">Title</label>
          <input autocomplete="new-title" name="title" (focusout)="verifyChanges($event, autoCompleteKeys.Title)" appendToBody="false" mdbValidate formControlName="title" [id]="'title'+uniqueIndex" type="text"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="auto" />

          <mdb-auto-completer mdbValidate #auto="mdbAutoCompleter" textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of titleResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <mdb-error *ngIf="formGetterSetter.title.touched && formGetterSetter.title.errors">
            Please enter title
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'firstName'+uniqueIndex">First Name</label>
          <input autocomplete="new-firstName" maxlength="30"  name="firstName"  mdbInput mdbValidate type="text" formControlName="firstName" [id]="'firstName'+uniqueIndex" class="form-control">
          <mdb-error *ngIf="formGetterSetter.firstName.touched && formGetterSetter.firstName.errors">
            Please enter first name
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'lastName'+uniqueIndex">Last Name</label>
          <input autocomplete="new-lastName" maxlength="30" name="lastName" mdbInput mdbValidate type="text" formControlName="lastName" [id]="'lastName'+uniqueIndex" class="form-control">
          <mdb-error *ngIf="formGetterSetter.lastName.touched && formGetterSetter.lastName.errors">
            Please enter last name
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- Row 1 End -->

    <!-- Row 2 -->
    <div class="form-row clearfix">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'email'+uniqueIndex">Email</label>
          <input  autocomplete="new-email" maxlength="50" name="email"  mdbInput mdbValidate type="text" [id]="'email'+uniqueIndex" formControlName="email" class="form-control">
          <mdb-error *ngIf="formGetterSetter.email.touched && formGetterSetter.email.errors">
            Please enter email
          </mdb-error>

        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-isd [inputValue]="parentData?.mobileNumber" controlName="Mobile Number" (numberValue)="setNumberValue($event, 'mobileNumber')"></app-isd>
      </div>
      <div class="col-md-4 col-sm-12">
          <app-isd [inputValue]="parentData?.landlineNumber" controlName="LandLine Number" (numberValue)="setNumberValue($event, 'landlineNumber')"></app-isd>
      </div>
    </div>
    <!-- Row 2 End -->

    <!-- Row 3 -->
    <div class="form-row clearfix">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'relationshipToChild'+uniqueIndex" class="active">Relationship to the child</label>
          <input  autocomplete="new-relationshipToChild" (focusout)="verifyChanges($event, autoCompleteKeys.RelationShip)" name="relationshipToChild" mdbValidate formControlName="relationshipToChild" [id]="'relationshipToChild'+uniqueIndex" type="text"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="relationshipToChildAuto" />

          <mdb-auto-completer mdbValidate appendToBody="false" #relationshipToChildAuto="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of relationshipToChildResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <mdb-error *ngIf="formGetterSetter.relationshipToChild.touched && formGetterSetter.relationshipToChild.errors">
            Please select relationship to the child
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'parentalResponsibility'+uniqueIndex" class="active">Parental Responsibility</label>
          <input autocomplete="new-parentalResponsibilityAuto" name="parentalResponsibilityAuto" mdbValidate formControlName="parentalResponsibility" [id]="'parentalResponsibility'+uniqueIndex" type="text"
            class="completer-input form-control mdb-autocomplete"  (focusout)="verifyChanges($event, autoCompleteKeys.Responsibility)"  [mdbAutoCompleter]="parentalResponsibilityAuto" />

          <mdb-auto-completer mdbValidate appendToBody="false" #parentalResponsibilityAuto="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of parentalResponsibilityResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <mdb-error *ngIf="formGetterSetter.parentalResponsibility.touched && formGetterSetter.parentalResponsibility.errors">
            Please select parental responsibility
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'ableToCollectChild'+uniqueIndex" class="active">Able To Collect Child</label>
          <input  (focusout)="verifyChanges($event, autoCompleteKeys.Able_To_Collect)"  autocomplete="new-ableToCollectChildAuto" name="ableToCollectChildAuto" mdbValidate formControlName="ableToCollectChild" [id]="'ableToCollectChild'+uniqueIndex" type="text"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="ableToCollectChildAuto" />

          <mdb-auto-completer mdbValidate appendToBody="false" #ableToCollectChildAuto="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of ableToCollectChildResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <mdb-error *ngIf="formGetterSetter.ableToCollectChild.touched && formGetterSetter.ableToCollectChild.errors">
            Please select value for able to collect child
          </mdb-error>


        </div>
      </div>
    </div>
    <!-- Row 3 End -->

    <!-- Row 4 -->
    <div class="form-row clearfix">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'niNumber'+uniqueIndex">National Insurance Number</label>
          <input maxlength="15" autocomplete="new-niNumber" name="niNumber" mdbInput mdbValidate type="text" formControlName="niNumber" [id]="'niNumber'+uniqueIndex" class="form-control">
          <mdb-error *ngIf="formGetterSetter.niNumber.touched && formGetterSetter.niNumber.errors">
            Please enter national insurance number
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- Row 4 End -->

  </div>

  <div id="office-details">

    <h3 class="font-weight-bold mt-4">Office Details</h3>
    <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">

    <!-- Row 1 -->
    <div class="form-row clearfix">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">

          <input  maxlength="30" autocomplete="new-jobTitle" name="jobTitle"  mdbInput mdbValidate type="text" [id]="'jobTitle'+uniqueIndex" formControlName="jobTitle" class="form-control">
          <label [attr.for]="'jobTitle'+uniqueIndex">Job Title</label>
          <mdb-error *ngIf="formGetterSetter.jobTitle.touched && formGetterSetter.jobTitle.errors">
            Please enter job title
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">

          <input maxlength="30" autocomplete="new-department" name="department" mdbInput mdbValidate type="text" [id]="'department'+uniqueIndex" formControlName="department" class="form-control">
          <label [attr.for]="'department'+uniqueIndex">Department</label>
          <mdb-error *ngIf="formGetterSetter.department.touched && formGetterSetter.department.errors">
            Please enter department
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <input  autocomplete="new-placeOfWork" name="placeOfWork" mdbInput mdbValidate type="text" [id]="'placeOfWork'+uniqueIndex" formControlName="placeOfWork"
            class="form-control">
          <label [attr.for]="'placeOfWork'+uniqueIndex">Place of Work</label>
          <mdb-error *ngIf="formGetterSetter.placeOfWork.touched && formGetterSetter.placeOfWork.errors">
            Please enter place of work
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- Row 1 End -->

    <!-- Row 2 -->
    <div class="form-row clearfix">
      <div class="col-md-4 col-sm-12">
        <app-isd [inputValue]="parentData?.officePhoneNumber" controlName="Office Phone Number" (numberValue)="setNumberValue($event, 'officePhoneNumber')"></app-isd>
      </div>
    </div>
    <!-- Row 2 End -->
  </div>
</form>
