import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faculty-and-staff',
  templateUrl: './faculty-and-staff.component.html',
  //styleUrls: ['./faculty-and-staff.component.scss']
})
export class FacultyAndStaffComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
