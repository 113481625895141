import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from "../../../services/header-hide-show.service";

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  //styleUrls: ['./social-share.component.scss'],
  host: {'class': 'w-100'}
})
export class SocialShareComponent implements OnInit {

  @Input() showHeader : boolean;

  constructor(private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
