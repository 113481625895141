<!-- Nursery Software Section -->
<div id="nursery-software">

  <!-- Top Image Section -->
  <!-- <div class="image-bar-wrap">
    <img class="d-block w-100 wow slideInLeft" src="../../../../../assets/img/nursery/nursery-software/nursery-header-img.jpg" alt="Nursery Software" />
    <div class="text-wrapper">
      <div class="line-1 wow slideInDown">
        <h3><span class="light">Your new </span>Early Years sidekick</h3>
      </div>
      <div class="line-2 wow slideInDown">
        <p>Software that brings you closer to parents and makes it easier to run your setting smoothly.</p>
      </div>
      <div class="line-3">
        <a mdbBtn href="#" class="btn btn-rounded rs-btn-info wow fadeInUp" mdbWavesEffect>Learn More</a>
      </div>
    </div>
    <div class="layer-mask-bg"></div>
  </div> -->
  <!-- End Top Image Section -->

  <!-- About Nursery App -->
  <section id="about-app" class="about-nursery-app">
    <img class="d-block mx-auto mb-4 wow slideInDown" src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle"  />
    <h1 class="section-heading font-weight-bold wow slideInDown text-center">ABOUT NURSERY APP (LEARNING SOFTWARE)</h1>
    <!-- Section description -->
    <div class="row ml-0 mr-0">
      <div class="col-lg-10 col-sm-10 mx-auto">
          <p class="section-text text-center mt-3 wow slideInUp">In today’s generation, technology plays a very vital role and for us this is equally essential. Moreover, during these current Pandemic times no nurseries allow parents to enter. So the big question is how will the parents know what their kids are learning?</p>
          <p class="section-text text-center mt-3 wow slideInUp">Hence, to save you from this nightmare, we bring you an App that will make communication between staff and the parents easy and convenient. Along with customized tracking, streaming photos and videos observations to the next step overview - all these can be done with just a click! <br/><a id="about-app-link" class="read-more d-block mt-2">Read More..</a></p>
          <div id="about-app-read-more">
            <p class="section-text text-center mt-3">For us the relationship with parents is vital, therefore, this simple digital daily diary lets the parents stay up to date with messages, photos and videos throughout the day, in real time. All those minute incidents and accidents, if any, will be delivered straight to your pocket. Our App will give parents a much better and clear view  on what's going on at the nursery. This innovative personalized news feed makes it easy to stay in touch and work together.</p>
            <p class="section-text text-center mt-3 mb-0">With the help of an exclusive timeline view you can view the dairy features of all the activities that you want to see. Effective learning journey and tracking kid’s development and learning are one of the best features of the App. Daily activity feed, sleep time, toilet, nappy and what they ate, this App helps the parents stay updated no matter where they are.</p>
          </div>
        </div>
    </div>
  </section>
  <!-- End About Nursery App -->

  <!-- Curve Image -->
  <!-- <div class="curve-wrapper">
    <div class="curve-inner"></div>
  </div> -->
  <!-- End Curve Image -->

  <!-- Feature Section -->
  <section id="app-feature" class="feature">
      <div class="feature-wrapper">
        <div class="feature-inner">


          <!-- Row 5 -->
          <div class="row clearfix">
            <div class="col-lg-6 col-md-12 p-0 pr-0">
              <div class="featured-img img-block tm-animation-floating mt-3">
                <img class="img-fluid" src="../../../../../assets/img/nursery/nursery-software/featured-img/featured-img-03.png" alt="Featured Image Three">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="text-wrapper wow slideInRight">
                <div class="icon-wrapper">
                  <img src="../../../../../assets/img/icons/round-circle-blue.png" width="40" height="40" alt="Rounder Circle" />
                </div>
                <h2>EYFS TRACKING AND <br> LEARNING</h2>
                <div class="line-1">
                  Our App comes with an EYFS tracker that shows you the developmental progress of your child.
                </div>
                <!-- Accordion Wrapper -->
                <div class="accordion md-accordion accordion-1" id="eyfcTracking" role="tablist">
                  <div class="card mb-2">
                    <div class="card-header z-depth-1" role="tab" id="eyfsHeading1">
                      <h5 class="mb-0 py-1">
                        <a class="collapsed font-weight-bold" data-toggle="collapse" href="#eyfsCollapse1" aria-expanded="false"
                           aria-controls="eyfsCollapse1">
                          1. Write Observation <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                        </a>
                      </h5>
                    </div>
                    <div id="eyfsCollapse1" class="collapse" role="tabpanel" aria-labelledby="eyfsHeading1"
                         data-parent="#eyfcTracking">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-8">
                            <!-- <h2 class="font-weight-bold mb-3 black-text">Hi! I am the first one.</h2> -->
                            <p>Creating observations and linking to the EYFS Framework</p>
                            <div class="ordered-list mb-2">
                              <ul>
                                <li><i class="fas fa-baby"></i>Makes single or group observation</li>
                                <li><i class="fas fa-baby"></i>Upload photos or videos to an observation</li>
                                <li><i class="fas fa-baby"></i>Send to the manager for approval</li>
                                <li><i class="fas fa-baby"></i>Share golden moments with the parents</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-4 mt-3">
                            <mdb-image-modal [modalImages]="writeObservation" type="no-margin"></mdb-image-modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-2">
                    <div class="card-header z-depth-1" role="tab" id="eyfsHeading2">
                      <h5 class="mb-0 py-1">
                        <a class="collapsed font-weight-bold" data-toggle="collapse" href="#eyfsCollapse2"
                           aria-expanded="false" aria-controls="eyfsCollapse2">
                          2. Link EYFS Framework <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                        </a>
                      </h5>
                    </div>
                    <div id="eyfsCollapse2" class="collapse" role="tabpanel" aria-labelledby="eyfsHeading2"
                         data-parent="#eyfcTracking">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="ordered-list mb-2">
                              <ul>
                                <li><i class="fas fa-baby"></i>Helps to track and monitor the progress of each child.</li>
                                <li><i class="fas fa-baby"></i>Links to the EYFS next steps</li>
                                <li><i class="fas fa-baby"></i>Track individual and group observations made on children</li>
                                <li><i class="fas fa-baby"></i>Helps to spot coping kids</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-4 mt-3">
                            <mdb-image-modal [modalImages]="eyfsFramework" type="no-margin"></mdb-image-modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Accordion wrapper-->
                <div class="button-wrap mt-5">
                  <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-pink" mdbWavesEffect title="Learn More">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
          <!-- End Row 5 -->

          <!-- Seprator Line-->
          <hr class="rs-mt-40 rs-mb-40 clearfix" />
          <!-- End Seprator Line-->


          <!-- Row 1 -->
          <div class="row clearfix">
            <div class="col-lg-6 col-md-12 order-md-2 order-2">
              <div class="text-wrapper wow slideInLeft">
                <div class="icon-wrapper">
                  <img src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle" />
                </div>
                <h2>COHORT <br> TRACKING</h2>
                <div class="line-1 mb-2">
                  Cohort tracking feature helps to track child’s development within nursery.
                </div>
                <div class="line-1 mb-3">
                  Responds to parent’s queries instantly regarding their child’s progress, health, account and other valuable information.
                </div>
                <div class="ordered-list">
                  <ul>
                    <li><i class="fas fa-baby"></i>Refresing to get such a personal touch.</li>
                    <li><i class="fas fa-baby"></i>Duis aute irure dolor in reprehenderit in voluptate</li>
                    <li><i class="fas fa-baby"></i>Velit esse cillum dolore eu fugiat nulla pariatur</li>
                  </ul>
                </div>
                <div class="button-wrap mt-5">
                  <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-pink" mdbWavesEffect title="Contact Us">Contact Us</a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-2">
              <div class="featured-img tm-animation-floating">
                <img class="img-fluid" src="../../../../../assets/img/nursery/nursery-software/featured-img/featured-img-04.png" alt="Featured Image Four">
              </div>
            </div>
          </div>
          <!-- End Row 1 -->

          <!-- Seprator Line-->
          <hr class="rs-mt-40 rs-mb-40 clearfix" />
          <!-- End Seprator Line-->

          <!-- Row 2-->
          <div class="row clearfix">
            <div class="col-lg-6 col-md-12 p-0 pr-0">
              <div class="featured-img img-block tm-animation-floating mt-3">
                <img class="img-fluid" src="../../../../../assets/img/nursery/nursery-software/featured-img/featured-img-05.png" alt="Featured Image One">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="text-wrapper wow slideInRight">
                <div class="icon-wrapper">
                  <img src="../../../../../assets/img/icons/round-circle-blue.png" width="40" height="40" alt="Rounder Circle" />
                </div>
                <h2>SAFE <br> GUARDING</h2>
                <div class="line-1">
                  It might happen that you child meets with an accident at the Nursery and you might not be aware of it and then turn to the nursery to find out about it. But with this App we keep you updated with all accidents and incidents. Here you can view all the safeguarding information.
                </div>
                <div class="ordered-list">
                  <ul>
                    <li><i class="fas fa-baby"></i>It contains the child’s name, kind, reported by which staff, date and location and the detailed note of the accident that occurred with your child at the nursery.</li>
                    <li><i class="fas fa-baby"></i>It contains the witness person's name as well and also has the option to show the evidence with the help of photographs.</li>
                  </ul>
                </div>
                <div class="button-wrap mt-5">
                  <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-pink" mdbWavesEffect title="Learn More">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
          <!-- End Row 2 -->

          <!-- Seprator Line-->
          <hr class="rs-mt-40 rs-mb-40 clearfix" />
          <!-- End Seprator Line-->

          <!-- Row 3 -->
          <div class="row clearfix">
            <div class="col-lg-6 col-md-12 p-0 pr-0">
                <div class="featured-img img-block tm-animation-floating mt-2">
                    <img class="img-fluid" src="../../../../../assets/img/nursery/nursery-software/featured-img/featured-img-01.png" alt="Featured Image One">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="text-wrapper wow slideInRight">
                  <div class="icon-wrapper">
                      <img src="../../../../../assets/img/icons/round-circle-blue.png" width="40" height="40" alt="Rounder Circle" />
                  </div>
                  <h2>CHILDREN’S <br> DIGITAL DIARY</h2>
                  <div class="line-1">
                    We give parents a view into their child’s day at the nursery by sharing instant and regular updates about meals, bottle feed, nappy change, progress report and sleep all while your kid is at the nursery.
                  </div>
                  <div class="button-wrap mt-5">
                    <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-pink" mdbWavesEffect title="Learn More">Contact Us</a>
                  </div>
                </div>
            </div>
          </div>
          <!-- End Row 3 -->

          <!-- Seprator Line-->
          <hr class="rs-mt-40 rs-mb-40 clearfix" />
          <!-- End Seprator Line-->

          <!-- Row 4 -->
          <div class="row clearfix">
            <div class="col-lg-6 col-md-12 order-md-2 order-2">
              <div class="text-wrapper wow slideInLeft">
                <div class="icon-wrapper">
                    <img src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle" />
                </div>
                <h2>PARENTAL <br> COMMUNICATION</h2>
                <div class="line-1">
                  We engage the parents and keep them updated with instant photos, videos, observations about their child’s day at the nursery.
                </div>
                <!-- Accordion Wrapper -->
                <div class="accordion md-accordion accordion-2" id="parentalCommunication" role="tablist">
                  <div class="card mb-2">
                    <div class="card-header z-depth-1" role="tab" id="parentalHeading1">
                      <h5 class="mb-0 py-1">
                        <a class="collapsed font-weight-bold" data-toggle="collapse" href="#parentalCollapse1" aria-expanded="false"
                          aria-controls="parentalCollapse1">
                          1. Send Diary Updates <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                        </a>
                      </h5>
                    </div>
                    <div id="parentalCollapse1" class="collapse" role="tabpanel" aria-labelledby="parentalHeading1"
                      data-parent="#parentalCommunication">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-8">
                            <p>We bring to the parents all those delightful moments of their kids at the nursery by regularly sharing with them instant updates.</p>
                            <div class="ordered-list mb-2">
                              <ul>
                                <li><i class="fas fa-baby"></i>Accurate timeline feed of each child’s day</li>
                                <li><i class="fas fa-baby"></i>Share activities and observations with instant photos</li>
                                <li><i class="fas fa-baby"></i>Meals, snacks and sleep time update</li>
                                <li><i class="fas fa-baby"></i>We welcome parents response and reaction</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-4 mt-3">
                            <mdb-image-modal [modalImages]="diaryUpdates" type="no-margin"></mdb-image-modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-2">
                    <div class="card-header z-depth-1" role="tab" id="parentalHeading2">
                      <h5 class="mb-0 py-1">
                        <a class="collapsed font-weight-bold" data-toggle="collapse" href="#parentalCollapse2"
                          aria-expanded="false" aria-controls="parentalCollapse2">
                          2. Sharing Learning Journey With Parents: <i class="fas fa-angle-down rotate-icon fa-1x"></i>
                        </a>
                      </h5>
                    </div>
                    <div id="parentalCollapse2" class="collapse" role="tabpanel" aria-labelledby="parentalHeading2"
                      data-parent="#parentalCommunication">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="ordered-list mb-2">
                              <ul>
                                <li><i class="fas fa-baby"></i>Sharing golden journey with parents</li>
                                <li><i class="fas fa-baby"></i>Sharing formative reports and assessments</li>
                                <li><i class="fas fa-baby"></i>Providing attendance records</li>
                                <li><i class="fas fa-baby"></i>Regular observations with attached media</li>
                                <li><i class="fas fa-baby"></i>Development with next steps</li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-4 mt-3">
                              <mdb-image-modal [modalImages]="learningJourney" type="no-margin"></mdb-image-modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Accordion wrapper-->
                <div class="button-wrap mt-5">
                  <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-pink" mdbWavesEffect title="Learn More">Contact Us</a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-2">
              <div class="featured-img img-block tm-animation-floating mt-3">
                  <img class="img-fluid mobile-img" src="../../../../../assets/img/nursery/nursery-software/featured-img/featured-img-02.png" alt="Featured Image One">
              </div>
            </div>
          </div>
          <!-- End Row 4 -->

          <!-- Seprator Line-->
          <hr class="rs-mt-40 rs-mb-40 clearfix" />
          <!-- End Seprator Line-->


            <!-- Seprator Line-->
          <!-- <hr class="rs-mt-40 rs-mb-40 clearfix" /> -->
          <!-- End Seprator Line-->

          <!-- Row 6 -->
          <!-- <div class="row clearfix">
            <div class="col-lg-6 col-md-12 order-md-2 order-2">
              <div class="text-wrapper wow slideInLeft">
                <div class="icon-wrapper">
                    <img src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle" />
                </div>
                <h2>COHORT <br> TRACKING</h2>
                <div class="line-1">
                  Cohort tracking feature helps to track child’s development within nursery.
                </div>
                <div class="ordered-list">
                  <ul>
                    <li><i class="fas fa-baby"></i>Refresing to get such a personal touch.</li>
                    <li><i class="fas fa-baby"></i>Duis aute irure dolor in reprehenderit in voluptate</li>
                    <li><i class="fas fa-baby"></i>Velit esse cillum dolore eu fugiat nulla pariatur</li>
                  </ul>
                </div>
                <div class="button-wrap mt-5">
                  <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-pink" mdbWavesEffect title="Learn More">Contact Us</a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-2">
              <div class="featured-img tm-animation-floating">
                  <img class="img-fluid" src="../../../../../assets/img/nursery/nursery-software/featured-img/featured-img-02.png" alt="Featured Image One">
              </div>
            </div>
          </div> -->
          <!-- End Row 6 -->

        </div>
      </div>
  </section>
  <!-- End Feature Section -->

</div>
<!-- Nursery Software Section -->
