import { BaseServiceService } from './../../services/base-service.service';
import { saveChildCountInfoSuccess } from './../store/actions/nanny-service.action';
import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';

import { ChildrenCountGroup, NannyServiceModel } from '../../../../shared/models/nanny-service.model';
import { OptionalService } from './../../../../shared/models/nanny-service.model';
import { ChildrenGroupComponent } from './question-components/children-group-question/children-group-question.component';
import { ChooseServiceComponent } from './question-components/choose-service.component.ts/choose-service.component';
import { OptionalServiceComponent } from './question-components/optional-services/optional-services.component';
import { UserCredentialsComponent } from './question-components/user-credentials/user-credentials.component';
import { UserInfoComponent } from './question-components/user-info/user-info.component';
import { LoaderEnabled } from 'src/app/shared/components/loader/loader.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class NannyComponents {
  constructor(public component: Type<any>, public data: any) { }
}

@Injectable()
export class NannyService extends BaseServiceService {

  constructor(private readonly _http: HttpClient) {
    super();
  }

  @LoaderEnabled()
  saveChildCountInfo(payLoad: NannyServiceModel): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-reg', payLoad, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', payLoad))
      );
  }

  @LoaderEnabled()
  saveOptionalService(payLoad: NannyServiceModel): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-reg', payLoad, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', payLoad))
      );
  }

  @LoaderEnabled()
  saveUserDetails(payLoad: NannyServiceModel): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-reg', payLoad, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', payLoad))
      );
  }

  @LoaderEnabled()
  saveUserCredentials(payLoad: NannyServiceModel): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-reg', payLoad, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', payLoad))
      );
  }

  @LoaderEnabled()
  saveBookingSlot(payLoad: NannyServiceModel): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-reg', payLoad, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', payLoad))
      );
  }

  @LoaderEnabled()
  confirmPayment(payLoad: NannyServiceModel): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-reg', payLoad, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', payLoad))
      );
  }


  getSteps() {
    return [
      new NannyComponents(ChooseServiceComponent, null),
      new NannyComponents(ChildrenGroupComponent, null),
      new NannyComponents(OptionalServiceComponent, null),
      new NannyComponents(UserCredentialsComponent, null),
      new NannyComponents(UserInfoComponent, null),
    ];
  }

  getOptionalServices(): OptionalService[] {
    return [{
      serviceName: 'Cooking / Meal preparation',
      isChecked: false
    },
    {
      serviceName: 'Pick up / Drop off',
      isChecked: false
    },
    {
      serviceName: 'Light housekeeping',
      isChecked: false
    },
    {
      serviceName: 'Activities (e.g. swimming)',
      isChecked: false
    },
    {
      serviceName: 'Putting kids to bed',
      isChecked: false
    },
    {
      serviceName: 'Homework help',
      isChecked: false
    },
    {
      serviceName: 'Bathing',
      isChecked: false
    },
    {
      serviceName: 'Virtual Care',
      isChecked: false
    }];
  }

  getChildrenGroups(): ChildrenCountGroup[] {
    return [{
      count: 0,
      label: 'New Born'
    },
    {
      count: 0,
      label: 'Toddler'
    },
    {
      count: 0,
      label: 'Pre-School'
    },
    {
      count: 0,
      label: 'Primary'
    }];
  }
}
