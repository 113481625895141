// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  // apiUrl: 'https://risenshinenursery.co.uk/api',
  // apiUrl: 'http://risenshinestg-env.eba-sev3x6wv.ap-south-1.elasticbeanstalk.com/v1/api',
  // apiUrl: 'http://localhost:8080/v1/api',
  // apiUrl2: 'http://localhost:8080/api',
  // apiUrl2: 'http://risenshinestg-env.eba-sev3x6wv.ap-south-1.elasticbeanstalk.com/api',
  apiUrl: 'https://ec2-13-40-160-124.eu-west-2.compute.amazonaws.com:8080/v1/api',
  apiUrl2: 'https://ec2-13-40-160-124.eu-west-2.compute.amazonaws.com:8080/api',
  //   apiUrl: 'http://ec2-3-111-237-120.ap-south-1.compute.amazonaws.com:8080/rise/v1/api',
  // apiUrl2: 'http://ec2-3-111-237-120.ap-south-1.compute.amazonaws.com:8080/rise/api',
  
  baseChallengerUrl: ''
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
