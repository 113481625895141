import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-isd',
  templateUrl: './isd.component.html',
  styleUrls: ['./isd.component.scss'],
})
export class IsdComponent implements OnInit, OnDestroy {
  optionsSelect = [];
  form: FormGroup;
  @Input() inputValue: string;
  @Input() controlName: string;
  @Output() numberValue = new EventEmitter();

  destroy$ = new Subject();

  constructor(private http: HttpClient, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.getAllIsd();
  }

  initForm() {
    this.form = this.fb.group({
      isd: new FormControl(),
      phone: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(10)])
      ),
    });
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (this.form.valid) {
          const { isd, phone } = this.form.value;
          this.numberValue.emit(`${isd}-${phone}`);
        }
      });
  }

  ngOnChanges(change: SimpleChange) {
    if (!this.form) {
      this.initForm();
    }
    this.setControlValue();
  }

  setControlValue() {
    this.form.patchValue({
      isd: parseInt(this.inputValue.substring(0, this.inputValue.indexOf('-'))),
      phone: this.inputValue.substring(
        this.inputValue.indexOf('-') + 1,
        this.inputValue.length
      ),
    });
  }

  getAllIsd(): void {
    this.http
      .get('https://restcountries.com/v2/all')
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any[]) => {
        response.forEach((element) => {
          if (
            !this.optionsSelect.some(
              (item) => item === parseInt(element.callingCodes[0])
            )
          ) {
            this.optionsSelect.push(parseInt(element.callingCodes[0]));
          }
        });
        this.optionsSelect.sort(function (a, b) {
          return a - b;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
