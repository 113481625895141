import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CommonConfig } from 'src/app/core/constants/common.constants';

import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { TokenStorageService } from '../../../auth/_services/token-storage.service';
import { ContactUs } from '../../models/contact-us.model';
import { HeaderHideShowService } from '../../services/header-hide-show.service';
import { InstallationService } from '../../services/installation.service';
import { AppointmentModel } from '../appointment-calendar/models/appointment.model';
import {
  initUserContactInfoStore,
  saveUserContactInfoError404,
  saveUserContactInfoSuccess,
  saveUserContactUs,
} from './store/actions/user-contact-us.action';
import { UserContactUsState } from './store/reducers/user-contact-us.reducer';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit, AfterViewInit {

  // Parent Configs
  @Input() showMap = true;
  @Input() showFullWidth = false;
  @Input() headerShow: boolean;

  // General Declarations
  public contactUsSubmitted: boolean;
  public appointments: AppointmentModel[];
  public appointmentsCost: number;
  public contactUsForm: FormGroup;
  public bookingDate: string;
  public submitted: boolean;
  public retval: Observable<ContactUs>;
  public chooseManual: boolean;
  // Config Variables
  public premiumAppointmentCost = CommonConfig.premiumCost;
  public titleData: string[] = CommonConfig.titles;
  public ageGroupData: string[] = CommonConfig.ageGroups;
  public map = CommonConfig.mapLatLang;
  public estimatedStartDatePickerOptions: IMyOptions = {
    dateFormat: 'mm-dd-yyyy',
    showClearDateBtn: false,
    disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }, // Disable dates backward starting from the given date.
  };
  isModalShown: boolean;
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;

  constructor(
    private store: Store<UserContactUsState>,
    private formBuilder: FormBuilder,
    private updates$: Actions,
    private headerHideShowService: HeaderHideShowService,
    private installationService: InstallationService,
    private token: TokenStorageService,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService) {
  }

  get f() { return this.contactUsForm.controls; }

  ngOnInit(): void {
    this.appointments = this.installationService.getAppointmentCalendar();
    this.initForm();
    this.headerHideShowService.isHeaderShown(this.headerShow);
    const contactUsObj: ContactUs = this.localStorageService.getContactUs();
    if (contactUsObj) {
      contactUsObj.fullName = contactUsObj.firstName + ' ' + contactUsObj.lastName;
      contactUsObj.phoneNumber = contactUsObj.phoneNumber.split(' ')[1];
      this.contactUsForm.patchValue(contactUsObj);
      this.bookingDate = contactUsObj.showaroundDateTime;
      this.store.dispatch(initUserContactInfoStore({ contactInfo: contactUsObj }));
    }

    this.updates$.pipe(ofType(initUserContactInfoStore))
      .subscribe(data => {
        this.bookingDate = data.contactInfo.showaroundDateTime;
      });

    this.updates$.pipe(ofType(saveUserContactInfoSuccess))
      .subscribe(data => {
        this.updateContactSuccess(data.contactInfo);
      });

    this.updates$.pipe(ofType(saveUserContactInfoError404))
      .subscribe(d => {
        this.postContactUsError(d.contactInfo);
      });
  }

  ngAfterViewInit(): void {
    this.contactUsSubmitted = !!this.localStorageService.getContactUs();
  }

  initForm(): void {
    this.contactUsForm = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      fullName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      isd: ['+44', Validators.required],
      phoneNumber: ['', [Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10), Validators.maxLength(11)]],
      ageGroup: ['', Validators.required],
      estimatedStartDate: ['', Validators.required],
      message: ['', Validators.required],
    });

  }

  keyPress(event: any): void {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }
    const fullName = this.contactUsForm.get('fullName').value;
    const index = fullName.indexOf(' ');
    const result: ContactUs = Object.assign({}, this.contactUsForm.value);
    result.firstName = fullName.slice(0, index);
    result.lastName = fullName.slice(index + 1);
    result.nursery = 'RNS';
    result.phoneNumber = this.contactUsForm.get('isd').value + ' ' + this.contactUsForm.get('phoneNumber').value;
    result.status = 'New';
    result.user = this.token.getUser();
    this.store.dispatch(saveUserContactUs({ contactInfo: result }));
  }

  updateContactSuccess(data: ContactUs) {
    this.contactUsSubmitted = true;
    this.localStorageService.saveContactUs(data);
    this.contactUsForm.patchValue({
      id: data.id
    });
    this.showModal();
    this.bookingDate = data.showaroundDateTime;
  }

  postContactUsError(data: ContactUs) {
    this.toastrService.info(data.errorMessage);
    delete data.errorMessage;
    this.localStorageService.saveContactUs(data);
    const contactUsObj: ContactUs = this.localStorageService.getContactUs();
    if (contactUsObj) {
      contactUsObj.fullName = contactUsObj.firstName + ' ' + contactUsObj.lastName;
      contactUsObj.phoneNumber = contactUsObj.phoneNumber.split(' ')[1];
      this.contactUsForm.patchValue(contactUsObj);
      this.bookingDate = contactUsObj.showaroundDateTime;
      this.store.dispatch(initUserContactInfoStore({ contactInfo: contactUsObj }));
    }
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal?.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  setInView(template) {
    setTimeout(() => template.elem.nativeElement.children[5].scrollIntoView());
  }

  onReset() {
    this.submitted = false;
    this.contactUsForm.reset();
  }

}
