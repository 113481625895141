import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-and-hours',
  templateUrl: './location-hours.component.html',
  //styleUrls: ['./location-hours.component.scss']
})
export class LocationAndHoursComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
