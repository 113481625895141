import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from '../../../../../services/header-hide-show.service';

@Component({
  selector: 'app-toddler-outdoor-activities',
  templateUrl: './toddler-outdoor-activities.component.html',
  // styleUrls: ['./toddler-outdoor-activities.component.scss'],
  host: {'class': 'w-100'}
})
export class ToddlerOutdoorActivitiesComponent implements OnInit {

  toddlerOutdoorGallery = [
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-01.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-01.jpg', description: 'Image 1', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-02.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-02.jpg', description: 'Image 2', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-03.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-03.jpg', description: 'Image 3', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-04.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-04.jpg', description: 'Image 4', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-05.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-05.jpg', description: 'Image 5', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-06.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/outdoor-activity/toddler-activity-06.jpg', description: 'Image 6', caption: ''
    }
  ];

  @Input() showHeader: boolean;

  constructor(private headerHideShowService: HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
