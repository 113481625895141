import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ContactUs} from "../models/contact-us.model";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class HeaderHideShowService {

  @Output()
  change: EventEmitter<boolean> = new EventEmitter();

  isHeaderShown(headerShown:boolean) {
    this.change.emit(headerShown);
  }


}
