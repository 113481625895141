<section id="location-hours" class="text-center">
  <!--Grid row-->
  <div class="row ml-0 mr-0">

    <!--Grid column-->
    <div class="col-md-12">

      <mdb-card class="testimonial-card">
        <!--Bacground color-->
        <div class="card-up indigo">
        </div>

        <!--Avatar-->
        <div class="avatar mx-auto">
          <img src="../../../../../../assets/img/nursery/about-us/location-avatar.jpg" class="rounded-circle" alt="Location & Hours">
        </div>

        <mdb-card-body>
          <!--Name-->
          <mdb-card-title>
            <h4>Location And Hours
            </h4>
          </mdb-card-title>
          <hr>
          <!--Quotation-->
          <div class="pt-4 pb-4">
          <p>
            <mdb-icon fas icon="quote-left"></mdb-icon>  To provide a warm, secure and caring environment in which each individual child feels safe, comfortable and happy - the welfare of the children placed in our care is of paramount importance.
          </p>

          <p>
            <mdb-icon fas icon="quote-left"></mdb-icon>  To provide high quality education and childcare where young children can investigate and explore a well planned, imaginative and challenging environment both indoors and outside, where they are encouraged to grow and develop at their own pace and where every day is varied, stimulating exciting and above all FUN!! We aim to provide carefully planned, fun-filled learning activities which are based on our professional knowledge of how young children learn. Such activities will meet the individual needs of the child to learn, experiment, make progress and develop - emotionally, socially, intellectually and physically.
          </p>

          <p>
            <mdb-icon fas icon="quote-left"></mdb-icon>  To continue the excellent work you have started! We firmly believe that learning is a shared experience and communication between home and Nursery is vital. We aim to provide as many opportunities as possible to enable parents/carers to feel fully involved in their child's learning and development. Family contributions enrich our children's lives!
          </p>

          <p>
            <mdb-icon fas icon="quote-left"></mdb-icon>  To value each child as an individual. A child's early years are the most formative and childhood is a precious, magical time. A young child needs to know that he/she is going to be valued as an individual - to encourage a positive self-image and attitude to learning and a high self-esteem.
          </p>

          <p>
            <mdb-icon fas icon="quote-left"></mdb-icon>  To maintain high standards of education and childcare through a commitment to the professional development and ongoing training of all members of staff. To encourage an awareness of the necessity to keep abreast of current research, changes and new developments in the field of education and childcare.
          </p>
        </div>
        </mdb-card-body>
      </mdb-card>

    </div>
    <!--Grid column-->


  </div>
  <!--Grid row-->

  </section>
