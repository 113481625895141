<section id="nanny-service">
  <div class="section-wrapper">
    <h2 class="text-center mb-0">
      <span class="d-block p-2 bg-warning text-white">Nanny Service</span>
    </h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-lg-6">
        <main>
          <div class="progress-bar-wrapper">
            <mdb-progress [value]="progress" min="0" max="100" aria-valuemin="0" aria-valuemax="100">
            </mdb-progress>
          </div>
          <div class="component-wrapper">
            <ng-template dynamicLoader></ng-template>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
