<div id="child-info">
  <h3 class="font-weight-bold mt-4">Child Details</h3>
  <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">
</div>
<form (ngSubmit)="updateChildForm()" [formGroup]="childrenFormGroup">
  <!-- <div class="p-2 bg-warning text-white mt-3 mb-3">Step 1: Children Details</div> -->
  <div class="m-3">
    <!-- First Row-->
    <div class="row">
      <!-- First Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-firstName" name="firstName" mdbInput type="text" id="firstName"
            formControlName="firstName" class="form-control" mdbValidate>
          <label for="firstName">First Name</label>
          <mdb-error *ngIf="childDetails.firstName?.touched && childDetails.firstName.errors">
            Please provide your first name
          </mdb-error>
        </div>
      </div>
      <!-- Second Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-lastName" name="lastName" mdbInput type="text" id="lastName"
            formControlName="lastName" class="form-control" mdbValidate>
          <label for="lastName">Last Name</label>
          <mdb-error *ngIf="childDetails.lastName?.touched && childDetails.lastName.errors">
            Please provide you last name
          </mdb-error>
        </div>
      </div>
      <!-- Third Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-knownAs" name="knownAs" mdbInput type="text" id="knownAs" formControlName="knownAs"
            class="form-control" mdbValidate>
          <label for="knownAs">Known As</label>
          <mdb-error *ngIf="childDetails.knownAs?.touched && childDetails.knownAs.errors">
            Please provide known as
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- First Row End-->

    <!-- Second Row-->
    <div class="row">
      <!-- Fourth Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <input autocomplete="new-religion" placeholder="Religion" name="religion" formControlName="religion"
            (focusout)="verifyChanges($event, autoCompleteKeys.Religion)" type="text" id="religion"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="religionAutoComplete" />


          <mdb-auto-completer appendToBody="false" #religionAutoComplete="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of religionResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <label for="religion" class="active text-left">Religion</label>
          <mdb-error *ngIf="childDetails.religion?.touched && childDetails.religion.errors">
            Please provide your religion
          </mdb-error>
        </div>
      </div>

      <!-- Fifth Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">

          <input placeholder="Ethinicity" formControlName="ethinicity" autocomplete="new-ethnicity" name="ethnicity"
            (focusout)="verifyChanges($event, autoCompleteKeys.Ethnicity)" type="text" id="ethnicity"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="ethnicityAutoComplete" />

          <mdb-auto-completer appendToBody="false" #ethnicityAutoComplete="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of ethnicityResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <label for="ethnicity" class="active text-left">Ethnicity</label>

          <mdb-error *ngIf="childDetails.ethinicity.touched && childDetails.ethinicity.errors">
            Please provide your ethnicity
          </mdb-error>

        </div>
      </div>

      <!-- Sixth Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <input placeholder="Gender" autocomplete="new-gender" name="gender" formControlName="gender"
            (focusout)="verifyChanges($event, autoCompleteKeys.Gender)" type="text" id="gender"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="genderAutoComplete" />
          <mdb-auto-completer appendToBody="false" #genderAutoComplete="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of genderResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>
          <label for="gender" class="active text-left">Gender</label>
          <mdb-error *ngIf="childDetails.gender?.touched && childDetails.gender.errors">
            Please provide your gender
          </mdb-error>
        </div>
      </div>

    </div>

    <!-- Third Row-->
    <div class="row">

      <!-- Seventh Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <mdb-date-picker name="dob" id="dob" [options]="myDatePickerOptions"
                           formControlName="dob" (inputFieldChanged)="selectRoom()" required>
          </mdb-date-picker>

          <label for="dob" class="active text-left">DOB</label>

          <mdb-error *ngIf="childDetails.dob?.touched && childDetails.dob.errors">
            Please provide your dob
          </mdb-error>

        </div>
      </div>

      <!-- Fifth Col-->
      <div class="col-md-4 col-sm-12" formGroupName="room">
        <div class="md-form">

          <input disabled placeholder="Selected Room" formControlName="roomName" autocomplete="new-room" name="roomName" [disabled]="true"
                 (focusout)="verifyChanges($event, autoCompleteKeys.Room)" type="text" id="roomName" autocomplete="off"
                 class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="roomAutoComplete" />

          <mdb-auto-completer appendToBody="false" #roomAutoComplete="mdbAutoCompleter" [disabled]="true"
                              textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of roomResults | async" [value]="option" [disabled]="true">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <label for="roomName" class="active text-left">Room</label>

          <mdb-error *ngIf="childDetails.room.touched && childDetails.room.errors">
            Room
          </mdb-error>

        </div>
      </div>

      <!-- Eighth Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">

          <input autocomplete="new-firstLanguage" name="firstLanguage" formControlName="firstLanguage"
            (focusout)="verifyChanges($event, autoCompleteKeys.FirstLanguage)" type="text"
            [id]="'firstLanguage'+uniqueIndex" class="completer-input form-control mdb-autocomplete"
            [mdbAutoCompleter]="firstLanguageAuto" placeholder="Choose first language" />
          <mdb-auto-completer appendToBody="false" #firstLanguageAuto="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of firstLanguageResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <label [for]="'firstLanguage'+uniqueIndex" class="active text-left">First Language</label>

          <mdb-error *ngIf="childDetails.firstLanguage?.touched && childDetails.firstLanguage.errors">
            Please provide your First Language
          </mdb-error>
        </div>
      </div>

      <!-- Nineth Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <mdb-select placeholder="Any other Language" autocomplete="new-anyOtherLanguageSpoken" name="anyOtherLanguageSpoken"
            formControlName="anyOtherLanguageSpoken" id="anyOtherLanguageSpoken" [options]="anyOtherLanguageSelect"
            [multiple]="true" [highlightFirst]="false" appendToBody="false" class="">
          </mdb-select>
          <label for="anyOtherLanguageSpoken" class="text-left">Any Other Language Spoken</label>
          <mdb-error *ngIf="childDetails.anyOtherLanguageSpoken?.touched && childDetails.anyOtherLanguageSpoken.errors">
            Please provide any other language
          </mdb-error>
        </div>
      </div>

    </div>

    <div id="child-info">
      <h3 class="font-weight-bold mt-4">Other Details</h3>
      <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">
    </div>

    <div class="row">
      <!-- First Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-liveWith" name="liveWith" mdbInput type="text" id="liveWith"
            formControlName="liveWith" class="form-control" mdbValidate>
          <label for="liveWith">Lives with</label>
          <mdb-error *ngIf="childDetails.liveWith?.touched && childDetails.liveWith.errors">
            Please provide lives with value
          </mdb-error>
        </div>
      </div>
      <!-- Second Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <textarea rows="1" name="dietaryRequirements" mdbInput type="text" id="dietaryRequirements"
            formControlName="dietaryRequirements" class="form-control">
        </textarea>
          <label for="dietaryRequirements">Dietary Requirements, If any</label>
        </div>
      </div>
      <!-- Third Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <textarea rows="1" name="dislike" mdbInput type="text" id="dislike" formControlName="dislike" class="form-control">
          </textarea>
          <label for="dislike">Things I don't like</label>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- First Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <textarea rows="1" name="medicalNeeds" mdbInput type="text" id="medicalNeeds" formControlName="medicalNeeds"
          class="form-control">
        </textarea>
        <label for="medicalNeeds">Please mention medical needs, If any</label>
        </div>
      </div>

      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <textarea name="routine" rows="1" mdbInput type="text" id="routine" formControlName="routine" class="form-control">
          </textarea>
          <label for="routine">My Routine is (Milk? Naps? Etc?) </label>
        </div>
      </div>
      <!-- Second Col-->
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <textarea name="needs" rows="1" mdbInput type="text" id="needs" formControlName="needs" class="form-control">
          </textarea>
          <label for="needs">When I'm sad or tired I need (Dummy? Teddy?)</label>
        </div>
      </div>
    </div>
  </div>

  <button mdbBtn type="submit" class="rs-btn-info btn btn-rounded waves-light" mdbWavesEffect> SAVE AND
    NEXT
  </button>
</form>
