<div id="toddler-room-fee" class="row">
  <div class="col-12">
    <mdb-card class="testimonial-card mb-5">
      <!--Bacground color-->
      <div class="card-up">
      </div>

      <!--Avatar-->
      <div class="avatar mx-auto">
        <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Toddler Room Fee">
      </div>

      <mdb-card-body>
        <!--Name-->
        <mdb-card-title>
          <h3>Toddler Room Fee</h3>
        </mdb-card-title>
        <hr>
        <!--Accordion-->
        <mdb-accordion [multiple]="false">

          <mdb-accordion-item [collapsed]="false" [customClass]="'border-0 mb-3'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee Without Any Funding Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-6 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveDaysFee.toggle()" mdbWavesEffect>
                    5 Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Full Days
                        <mdb-badge pill="true" primary="true">£1427.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourDaysFee.toggle()" mdbWavesEffect>
                    4 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolFourDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days
                        <mdb-badge pill="true" primary="true">£1263.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1371.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Fees Row -->
              <div class="row">
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolThreeDaysFee.toggle()" mdbWavesEffect>
                    3 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolThreeDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days
                        <mdb-badge pill="true" primary="true">£1017.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1126.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1339.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolTwoDaysFee.toggle()" mdbWavesEffect>
                    2 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolTwoDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days
                        <mdb-badge pill="true" primary="true">£844.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£952.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1166.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 3 Half Days
                        <mdb-badge pill="true" primary="true">£1393.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item [customClass]="'border-0 mb-3'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee With 15 Hrs Funding Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-6 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveDaysFee15Hrs.toggle()" mdbWavesEffect>
                    5 Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Full Days
                        <mdb-badge pill="true" primary="true">£1118.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourDaysFee15Hrs.toggle()" mdbWavesEffect>
                    4 Days
                  </button>
                  <div class="" mdbCollapse #preSchoolFourDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days
                        <mdb-badge pill="true" primary="true">£954.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1066.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Fees Row -->
              <div class="row">
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyThreeDaysFee.toggle()" mdbWavesEffect>
                    3 Days a week
                  </button>
                  <div class="" mdbCollapse #babyThreeDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days
                        <mdb-badge pill="true" primary="true">£708.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£811.43</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1190.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolTwoDaysFee15Hrs.toggle()" mdbWavesEffect>
                    2 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolTwoDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days
                        <mdb-badge pill="true" primary="true">£535.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£643.35</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£906.521</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 3 Half Days
                        <mdb-badge pill="true" primary="true">£1034.35</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee For Half Days Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyRoomFiveHalfDaysFee.toggle()" mdbWavesEffect>
                    5 Half Days a week
                  </button>

                  <div class="" mdbCollapse #babyRoomFiveHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Half Days
                        <mdb-badge pill="true" primary="true">£964.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyRoomFourHalfDaysFee.toggle()" mdbWavesEffect>
                    4 Half Days a week
                  </button>

                  <div class="" mdbCollapse #babyRoomFourHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Half Days
                        <mdb-badge pill="true" primary="true">£840.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyRoomThreeHalfDaysFee.toggle()" mdbWavesEffect>
                    3 Half Days a week
                  </button>

                  <div class="" mdbCollapse #babyRoomThreeHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Half Days
                        <mdb-badge pill="true" primary="true">£630.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="toddlerRoomFiveHalfDaysFeePlus15Hrs.toggle()" mdbWavesEffect>
                    5 Half Days + 15 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #toddlerRoomFiveHalfDaysFeePlus15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Half Days + 15 Hrs Funding
                        <mdb-badge pill="true" primary="true">£636.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="toddlerRoomFourHalfDaysFeePlus15Hrs.toggle()" mdbWavesEffect>
                    4 Half Days + 15 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #toddlerRoomFourHalfDaysFeePlus15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Half Days + 15 Hrs Funding
                        <mdb-badge pill="true" primary="true">531.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="toddlerRoomThreeHalfDaysFeePlus15Hrs.toggle()" mdbWavesEffect>
                    3 Half Days + 15 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #toddlerRoomThreeHalfDaysFeePlus15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Half Days + 15 Hrs Funding
                        <mdb-badge pill="true" primary="true">£321.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </mdb-accordion-item-body>
          </mdb-accordion-item>

        </mdb-accordion>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
