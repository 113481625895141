<div id="baby-room-fee" class="row">
  <div class="col-12">
    <mdb-card class="testimonial-card mb-5">
      <!--Bacground color-->
      <div class="card-up">
      </div>

      <!--Avatar-->
      <div class="avatar mx-auto">
        <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Baby Room Fee">
      </div>

      <mdb-card-body>
        <!--Name-->
        <mdb-card-title>
          <h3>Baby Room Fee</h3>
        </mdb-card-title>
        <hr>
        <!--Accordion-->
        <mdb-accordion [multiple]="false">
          <mdb-accordion-item [collapsed]="false" [customClass]="'border-0 mb-3'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-6 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveDaysFee15Hrs.toggle()" mdbWavesEffect>
                    5 Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <mdb-badge pill="true" primary="true">8:00am to 6:00pm</mdb-badge>
                        <mdb-badge pill="true" primary="true">5 Full Days</mdb-badge>
                        <mdb-badge pill="true" primary="true">£1470.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <mdb-badge pill="true" primary="true">7:30am to 6:00pm</mdb-badge>
                        <mdb-badge pill="true" primary="true">5 Full Days</mdb-badge>
                        <mdb-badge pill="true" primary="true">£1575.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <mdb-badge pill="true" primary="true">7:30am to 6:30pm</mdb-badge>
                        <mdb-badge pill="true" primary="true">5 Full Days</mdb-badge>
                        <mdb-badge pill="true" primary="true">£1680.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourDaysFee15Hrs.toggle()" mdbWavesEffect>
                    4 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolFourDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days
                        <mdb-badge pill="true" primary="true">£1280.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1389.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Fees Row -->
              <div class="row">
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyThreeDaysFee.toggle()" mdbWavesEffect>
                    3 Days a week
                  </button>
                  <div class="" mdbCollapse #babyThreeDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days
                        <mdb-badge pill="true" primary="true">£1008.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1116.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1239.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolTwoDaysFee15Hrs.toggle()" mdbWavesEffect>
                    2 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolTwoDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days
                        <mdb-badge pill="true" primary="true">£834.75</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£943.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1065.75</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 3 Half Days
                        <mdb-badge pill="true" primary="true">£1202.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee For Half Days Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyRoomFiveHalfDaysFee.toggle()" mdbWavesEffect>
                    5 Half days a week
                  </button>

                  <div class="" mdbCollapse #babyRoomFiveHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Half Days
                        <mdb-badge pill="true" primary="true">£945.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyRoomFourHalfDaysFee.toggle()" mdbWavesEffect>
                    4 Half Days a week
                  </button>

                  <div class="" mdbCollapse #babyRoomFourHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Half Days
                        <mdb-badge pill="true" primary="true">£832.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="babyRoomThreeHalfDaysFee.toggle()" mdbWavesEffect>
                    3 Half Days
                  </button>

                  <div class="" mdbCollapse #babyRoomThreeHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Half Days
                        <mdb-badge pill="true" primary="true">£630.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </mdb-accordion-item-body>
          </mdb-accordion-item>


        </mdb-accordion>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
