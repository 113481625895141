    <form #medicalForm="ngForm" [formGroup]="medicalFormGroup">
      <div id="address-info">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="font-weight-bold mt-4">Doctor's Information</h3>
          <div skip (emitNext)="moveNext()"></div>
        </div>
        <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">
      </div>

      <app-person-details (updateParentForm)="updateFormData($event, 'doctorDetails')" [medicalFormData]="medicalData?.doctorDetails">
      </app-person-details>
      <div id="address-info">
        <h3 class="font-weight-bold mt-4">Health Visitor's Information</h3>
        <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">
      </div>
      <app-person-details [medicalFormData]="medicalData?.healthVisitorDetails" (updateParentForm)="updateFormData($event, 'healthVisitorDetails')">
      </app-person-details>
      <div class="row">
        <div class="col-12 m-3">
          <div class=" d-flex align-items-center justify-content-between">
            <label>
              Does your child have a Personal Child Health Record book (Red Book)?
            </label>
            <mdb-checkbox class="z-index-99" formControlName="hasRedBook"></mdb-checkbox>
          </div>
        </div>
      </div>
      <div id="address-info">
        <span class="d-flex align-items-center justify-content-between">
          <h3 class="font-weight-bold mt-4">Please Add other services involved with the child or family</h3>
          <span class="mt-4">
            <a (click)="push()">
              <mdb-icon fas icon="plus"></mdb-icon>
              Add Services
            </a>
          </span>
        </span>
        <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">
      </div>
      <ng-container *ngFor="let service of medicalData?.services; let i=index">
        <div>
          <mdb-icon fas icon="trash" class="d-flex justify-content-end cursor-pointer mr-4" (click)="removeForm(i, service)"></mdb-icon>
          <app-basic-info-form [serviceData]="service" (formChange)="updateBasicForm($event, i)" >
          </app-basic-info-form>
        </div>
      </ng-container>
    </form>
