<section id="food-hygiene" class="text-center">
  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center mb-5">Food Hygiene</h2>

  <p>
    We do not just set our high standards into childcare but across the board too. Rise N Shine Day Nursery
    have been graded with a five-star hygiene rating with the use of the kitchen facilities.
    The UK Food Standards Agency (FSA) states that “You must comply with food
    safety and hygiene regulations if you provide food and drink for children or babies including meals,
    snacks, and drinks. Our nursery has been 5 stars rated in food hygiene.
  </p>
  <div class="row clearfix">

     <!--Grid column-->
     <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards1>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image -->
          <div class="food-img-view view overlay rounded z-depth-2 mb-4 food-hygiene">
            <img class="img-fluid food-img-wrap" src="../../../../../assets/img/icons/food-hygine.png" alt="Sample image">
            <a>
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Food Hygiene Rating</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="food-hygiene-wrap">
              <p>We stand out number 1 in terms of food hygiene. we never compromise with food quality</p>
            </div>
             <!--Triggering button-->
             <a class="rotate-btn" data-card="card-1" (click)="cards1.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Food Hygiene Rating</h4>
          <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
          <hr>
          <div class="food-hygiene-content">
            <p>We stand out number 1 in terms of food hygiene. we never compromise with food quality
            </p>
          </div>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-1" (click)="cards1.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

  </div>

</section>
