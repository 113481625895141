<div id="toddler-room-admission" class="row">
  <!-- Grid column -->
  <div class="col-lg-12 pl-0 pr-0">

    <mdb-card class="testimonial-card mb-5">
      <!--Bacground color-->
      <div class="card-up">
      </div>

      <!--Avatar-->
      <div class="avatar mx-auto">
        <img src="../../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Toddler Room Admission">
      </div>

      <mdb-card-body>

        <!--Name-->
        <mdb-card-title>
          <h3>Admission</h3>
        </mdb-card-title>
        <hr>

        <mdb-stepper #stepper1 [vertical]="true" [linear]="true">

          <!-- Show Around Step -->
          <mdb-step name="Show Around">
            <div class="pt-4 pb-3 text-left">
              <p class="text-left">Show Around - After booking an appointment at Rise 'N' Shine nursery, parents would
                be invited for initial viewing where you would be allowed to experience our light and spacious learning
                areas. One of our staff will assist you throughout the visit and answer any questions that you may have.
                Please note: Due to the Covid-19 outbreak, a nursery tour would be conducted after the classes.</p>
            </div>
            <!--        <button mdbBtn size="sm" color="primary" (click)="goForward(stepper1)" class="float-left">CONTINUE</button>-->
          </mdb-step>

          <!-- RegistrationStep -->
          <mdb-step name="Registration">
            <div class="pt-4 pb-3 text-left">
              <p class="text-left">If you wish you can register your kid at Rise ‘N’ Shine, you would be provided with
                a registration, terms & conditions and all about me forms that needs to be duly filled and submitted.</p>
            </div>
            <!--        <button mdbBtn size="sm" color="primary" (click)="goForward(stepper1)" class="mt-3 float-left">CONTINUE</button>-->
            <!--        <button mdbBtn size="sm" color="secondary" (click)="goBack(stepper1)" class="mt-3 float-left">BACK</button>-->
          </mdb-step>

          <!-- Settling In Step -->
          <mdb-step name="Settling In">
            <p class="text-left"> Initially, we allow parents to accompany the child for 4-5 sessions until the kids
              are settled in the new environment. Once the kid is settled the parents can leave after dropping
              their kid to thye nursery. Start your child's learning adventure today.</p>
          </mdb-step>

          <!-- Start Step-->
          <mdb-step name="Start">
            <p class="pl-2 text-left">Welcome to Rise 'N' Shine Nursery. You will receive a confirmation mail with
              login credentials of our nursery app where you can closely monitor and follow your kid's day to day activities at the nursery. </p>
          </mdb-step>

          <!-- Contact Us Step-->
          <mdb-step name="Contact Us" id="contact-us">
            <app-contact-us [showMap]="false" [showFullWidth]="true"></app-contact-us>
            <!-- <button mdbBtn size="sm" color="primary" (click)="goForward(stepper1)" class="float-left">CONTINUE</button>-->
          </mdb-step>

        </mdb-stepper>

      </mdb-card-body>
    </mdb-card>
  </div>
  <!-- Grid column -->
</div>
