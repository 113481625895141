import { Injectable } from '@angular/core';
import { ContactUs } from '../../modules/nursery/models/contact-us.model';

const CONTACT_US_KEY = 'contact-us';
const REGISTRATION_ID_KEY = 'registration-id';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    public setLocalstorage(key: string, value: string, durationInMinutes: number): void {
        const nowTimeStamp = this.getUTCTimeStamp();
        const item = {
            value,
            expiry: nowTimeStamp + (durationInMinutes * 60 * 1000) // epoch minutes
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    public getLocalStorage(key: string): any {
        const item = JSON.parse(localStorage.getItem(key));
        const nowTimeStamp = this.getUTCTimeStamp();

        if (nowTimeStamp > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item;
    }

    private getUTCTimeStamp(): number {
        const now = new Date();
        const utcTimestamp = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(),
            now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

        return utcTimestamp;
    }

  public saveRegistrationId(registrationId: number) {
    localStorage.removeItem(REGISTRATION_ID_KEY);
    localStorage.setItem(REGISTRATION_ID_KEY, JSON.stringify(registrationId));
  }

  public getRegistrationId(): number {
    if (localStorage.getItem(REGISTRATION_ID_KEY) && localStorage.getItem(REGISTRATION_ID_KEY) !== 'undefined') {
      const registrationId = JSON.parse(localStorage.getItem(REGISTRATION_ID_KEY)) ;
      return registrationId;
    }
  }

  public saveContactUs(contactUs: ContactUs) {
      localStorage.removeItem(CONTACT_US_KEY);
      localStorage.setItem(CONTACT_US_KEY, JSON.stringify(contactUs));
  }

  public getContactUs(): ContactUs {
      if (localStorage.getItem(CONTACT_US_KEY) && localStorage.getItem(CONTACT_US_KEY) !== 'undefined') {
          const contactUs = JSON.parse(localStorage.getItem(CONTACT_US_KEY)) as ContactUs;
          return contactUs;
      }
  }
}
