<mdb-icon fas icon="edit" *ngIf="isInReview" (click)="isEditAble = !isEditAble;" class="pencil-icon"></mdb-icon>
<div class="booking-form">
  <div class="main-heading">
    <h3>
      Slots Booked
    </h3>
  </div>
  <div class="date-picker mt-3 mb-3">
    <div class="col-lg-6 col-md-6 col-sm-12 no-gutter">
      <mdb-date-picker [class.disabled]="!isEditAble" (closeButtonClicked)="updateDateSections()" [inline]="true"
        name="mydate" [options]="myDatePickerOptions" [placeholder]="'Select starting date'" [(ngModel)]="startDate"
        required>
      </mdb-date-picker>
    </div>
  </div>
  <div class="select-option" class="col-lg-12 no-gutter">
    <div class="radio-toolbar row">
      <div class="col-lg-4 col-md-12 ">
        <input type="radio" [class.disabled]="!isEditAble" (change)="updateDateSections()" [(ngModel)]="selectedOption"
          checked id="day" name="radioFruit" value="0">
        <label class="btn " [class.disabled]="!isEditAble" for="day">Custom Selection</label>
      </div>

      <div class="col-lg-4 col-md-12">
        <input type="radio" [class.disabled]="!isEditAble" (change)="updateDateSections()" [(ngModel)]="selectedOption"
          id="week" name="radioFruit" value="1">
        <label class="btn " [class.disabled]="!isEditAble" for="week">1 Week</label>
      </div>

      <div class="col-lg-4 col-md-12 ">
        <input type="radio" [class.disabled]="!isEditAble" (change)="updateDateSections()" [(ngModel)]="selectedOption"
          id="Month" name="radioFruit" value="2">
        <label class="btn" [class.disabled]="!isEditAble" for="Month">5 Weeks </label>
      </div>
    </div>
  </div>
  <ng-container>
    <div *ngIf="steppers.length > 1 && startDate" class="mb-3">
      <app-stepper (clickEmit)="selectedWeek = $event.id; checkedStepper()" [isClickAble]="true" [array]="weeks">
      </app-stepper>
    </div>
    <ng-container>
      <div *ngIf="steppers.length > 1 && startDate" class="footer-section m-0 mb-2">
        <button mdbBtn type="button" [class.disabled]="selectedWeek === 0" class="btn-link p-0"
          (click)="selectedWeek = selectedWeek - 1; checkedStepper()" mdbWavesEffect>
          Previous Week
        </button>
        <button mdbBtn type="button" [class.disabled]="selectedWeek === steppers.length - 1"
          (click)="selectedWeek = selectedWeek + 1; checkedStepper()" class="btn-link p-0" mdbWavesEffect>
          Next Week
        </button>
      </div>
      <ng-container *ngTemplateOutlet="oneDay">
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #oneDay>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <ng-container *ngIf="startDate">
        <ng-container *ngFor="let step of steppers;let i = index">
          <div [class.d-none]="selectedWeek !== i" class="table-wrapper">
            <table class="table">
              <thead>
                <th *ngIf="selectedOption !== '0'">
                  <mdb-checkbox [class.disabled]="!isEditAble" [(ngModel)]="selectAll" (change)="toggleAll()">
                  </mdb-checkbox>
                </th>
                <th>Date</th>
                <th class="align-end">Start Time</th>
                <th class="align-end">End Time</th>
              </thead>
              <tbody>
                <tr class="position-relative" *ngFor="let date of step.dates;let i =index">
                  <td *ngIf="selectedOption !== '0'">
                    <mdb-checkbox [class.disabled]="!isEditAble" (change)="setAllCheckedBox()"
                      (change)="updateTime(date)" [(ngModel)]="date.checked">
                    </mdb-checkbox>
                  </td>
                  <td [class.disabled]="!date.checked">
                    <span *ngIf="!date.isEditAble">{{date.date | date: 'mediumDate'}}</span>
                    <div class="mr-3">
                      <mdb-date-picker [class.disabled]="!isEditAble" (dateChanged)="dateCheckValidation($event,date)"
                        *ngIf="date.isEditAble" [inline]="true" name="mydate" [options]="myDatePickerOptions"
                        [placeholder]="'Select date'" [(ngModel)]="date.date" required>
                      </mdb-date-picker>
                    </div>
                  </td>
                  <td class="align-end">
                    <div class="row">
                      <div class="col-lg-11 col-md-11 col-sm-11">
                        <input [class.disabled]="!date.checked || !isEditAble" type="time" (change)="updateTime(date)"
                          id="input" class="form-control" [(ngModel)]="date.startTime" [value]="date.startTime"
                          mdbInput />
                      </div>
                    </div>
                  </td>
                  <td class="align-end">
                    <div class="row">
                      <div class="col-lg-11 col-md-11 col-sm-11">
                        <div class="d-flex align-items-center justify-content-center">
                          <input (change)="updateTime(date)" [class.disabled]="!date.checked || !isEditAble" type="time"
                            id="input" class="form-control" [(ngModel)]="date.endTime" [value]="date.endTime"
                            mdbInput />
                          <span [class.disabled]="!isEditAble" *ngIf="selectedOption !== '0'"
                            class="badge badge-primary ml-2" (click)="syncTime(date)">
                            <mdb-icon fas icon="copy" title="Copy this date's time in all "></mdb-icon>
                          </span>
                          <span [class.disabled]="!isEditAble" *ngIf="selectedOption === '0' && i === 0"
                            class="badge badge-primary ml-2" (click)="pushNextDate(i)">
                            <mdb-icon fas icon="plus" title="Copy this date's time in all "></mdb-icon>
                          </span>
                          <span [class.disabled]="!isEditAble" *ngIf="selectedOption === '0' && i !== 0"
                            class="badge badge-primary ml-2" (click)="removeCurrentDate(i, date)">
                            <mdb-icon fas icon="minus" title="Copy this date's time in all "></mdb-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </ng-container>
      <div class="mt-5">
        <span>Invoice Total: £ {{(totalPrice > 0 ? totalPrice : '0')}} </span>
      </div>
      <div class="footer-section m-4" *ngIf="isEditAble" [ngClass]="isInReview ? 'justify-content-center' : '' ">
        <button mdbBtn *ngIf="!isInReview" type="button" (click)="goBack()" class="btn btn-link waves-light"
          mdbWavesEffect>
          Go Back
        </button>
        <button mdbBtn type="submit" (click)="submit()" class="rs-btn-info btn btn-rounded waves-light" mdbWavesEffect>
          {{isInReview ? 'Update' : 'SAVE AND NEXT'}}
        </button>
      </div>
    </div>
  </div>
</ng-template>
