import { createAction, props } from '@ngrx/store';

export const mainAreaUpdated = createAction(
  '[EYFS] Main Area Updated',
   props<{mainArea: string}>()
);

export const subAreaUpdated = createAction(
  '[EYFS] Sub Area Updated',
  props<{subArea: string}>()
);

