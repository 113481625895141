import { createAction, props } from '@ngrx/store';
import { MedicalDetail } from 'src/app/modules/nursery/models/child.model';


export const LoadMedicalData = createAction(
  '[LoadMedicalData] Load MedicalData',
  props<{ id: string }>()
);

export const LoadMedicalSuccess = createAction(
  '[LoadMedicalData] Load MedicalData Success',
  props<{ medicalDetail: MedicalDetail }>()
);

export const LoadMedicalFailure = createAction(
  '[LoadMedicalData] Load MedicalData Failure',
  props<{ error: any }>()
);

export const DeleteMedicalService = createAction(
  '[Delete Medical Service] Deleting Medical Service',
   props<{childId: string, serviceId: string, index: string}>()
);

export const SuccessDeleteMedicalService = createAction(
  '[Delete Medical Service] Success Deleting Medical Service',
   props<{childId: string, serviceId: string, index: string}>()
);

export const FailDeleteMedicalService = createAction(
  '[Delete Medical Service] Failed Deleting Medical Service',
   props<{childId: string, serviceId: string}>()
);

export const SaveMedical = createAction(
  '[LoadMedicalData] Adding MedicalData to store',
   props<{medicalDetail: MedicalDetail}>()
);

export const SaveSuccessMedical = createAction(
  '[LoadMedicalData] Save Success Medical Data attribute to store',
   props<{medicalDetail: MedicalDetail}>()
);

export const SaveFailedMedical = createAction(
  '[LoadMedicalData] Failed Adding Medical Data attribute to store',
   props<{medicalDetail: MedicalDetail}>()
);

