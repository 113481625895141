<ng-container [formGroup]="checkEligibilityFormFour">
    <!-- Form Field -->
    <div class="form-group pl-4" [hidden]="stage!=='Step 4'">
        <h5 class="mb-3">Are you both earning less than £100,000 pounds per annum?</h5>
        <span class="mb-4 d-block">You will be eligible for free working hours if your earning is less than £100,000 pounds.</span>

        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" value="yes" id="radIncomeBtn1" name="income" formControlName="income">
            <label class="form-check-label font-weight-bold text-uppercase" for="radIncomeBtn1">Yes</label>
        </div>

        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" value="no" id="radIncomeBtn2" name="income" formControlName="income">
            <label class="form-check-label font-weight-bold text-uppercase" for="radIncomeBtn2">No</label>
        </div>

        <!-- <div class="alert-text">
            <mdb-error *ngIf="submitted && form1.income.errors">Please Select Value</mdb-error>
        </div> -->

    </div>

    <!-- Send button -->
    <button mdbBtn type="button" color="danger" rounded="true" class="z-depth-0 my-4 waves-effect" [hidden]="stage=='Step 1'" (click)="goBack(step)" mdbWavesEffect>PREVIOUS</button>
    <button mdbBtn type="button" color="info" rounded="true" class="z-depth-0 my-4 waves-effect float-right" [innerHtml]="stage!=='Step 4' ? 'NEXT': 'SUBMIT' " (click)="goForward();" mdbWavesEffect>NEXT</button>

</ng-container>
