import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss'],
})

export class FlyOutComponent {
  @Input() isVisible: boolean;
  @Input() customWidth = '40rem';
  @Output() emitClose = new EventEmitter();

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if(event.key === 'Escape') {
      this.emitClose.emit();
    }
  }
}
