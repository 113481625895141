import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Registration } from '../../../../models/registration.model';
import { RegistrationService } from '../../../../services/registration.service';
import {
  saveConsentDetails,
  saveConsentDetailsError,
  saveConsentDetailsSuccess,
  saveIndividualDetails,
  saveIndividualDetailsError,
  saveMedicalDetails,
  saveMedicalDetailsError,
  saveMedicalDetailsSuccess,
} from '../actions/registration.actions';
import { RegistrationState } from '../reducers/registration.reducer';
import {
  saveImmuneDetails,
  saveImmuneDetailsError,
  saveImmuneDetailsSuccess,
  saveIndividualDetailsSuccess,
} from './../actions/registration.actions';
import {LocalStorageService} from "../../../../../../core/services/local-storage.service";


@Injectable()
export class RegistrationEffect {

  constructor(
    protected store: Store<RegistrationState>, private actions$: Actions,
    private registrationService: RegistrationService,
    private localStorageService: LocalStorageService) {
  }

  @Effect({ dispatch: true })
  public saveMedicalDetailsReg$ = this.actions$.pipe(
    ofType(saveMedicalDetails),
    map((action) => action.registration),
    mergeMap((payload) => this.registrationService.saveMedicalDetails(payload)
      .pipe(
        map((registration) => {
          const jsonObj: any = JSON.parse(registration);
          const regis: Registration = <Registration>jsonObj;
          return saveMedicalDetailsSuccess({ registration: regis });
        }),
        catchError(error => {
          console.log('Caught error while saving parent two !' + error);
          return of(saveMedicalDetailsError({ registration: payload }));
        })
      )));

  @Effect({ dispatch: true })
  public saveImmuneDetails$ = this.actions$.pipe(
    ofType(saveImmuneDetails),
    map((action) => action.registration),
    mergeMap((payload) => this.registrationService.saveImmuneDetails(payload)
      .pipe(
        map((registration) => {
          const jsonObj: any = JSON.parse(registration);
          const regis: Registration = <Registration>jsonObj;
          return saveImmuneDetailsSuccess({ registration: regis });
        }),
        catchError(error => {
          console.log('Caught error while saving parent two !' + error);
          return of(saveImmuneDetailsError({ registration: payload }));
        })
      )));


  @Effect({ dispatch: true })
  public saveIndividualDetails$ = this.actions$.pipe(
    ofType(saveIndividualDetails),
    map((action) => action.registration),
    mergeMap((payload) => this.registrationService.saveIndividualDetails(payload)
      .pipe(
        map((registration) => {
          const jsonObj: any = JSON.parse(registration);
          const regis: Registration = <Registration>jsonObj;
          return saveIndividualDetailsSuccess({ registration: regis });
        }),
        catchError(error => {
          console.log('Caught error while saving parent two !' + error);
          return of(saveIndividualDetailsError({ registration: payload }));
        })
      )));

      @Effect({ dispatch: true })
      public saveConsentDetails$ = this.actions$.pipe(
        ofType(saveConsentDetails),
        map((action) => action.registration),
        mergeMap((payload) => this.registrationService.saveConsentDetails(payload)
          .pipe(
            map((registration) => {
              const jsonObj: any = JSON.parse(registration);
              const regis: Registration = <Registration>jsonObj;
              return saveConsentDetailsSuccess({ registration: regis });
            }),
            catchError(error => {
              console.log('Caught error while saving parent two !' + error);
              return of(saveConsentDetailsError({ registration: payload }));
            })
          )));

}
