import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from "../../../../../services/header-hide-show.service";

@Component({
  selector: 'app-baby-outdoor-activities',
  templateUrl: './baby-outdoor-activities.component.html',
  styleUrls: ['./baby-outdoor-activities.component.scss'],
  host: {'class': 'w-100'}
})
export class BabyOutdoorActivitiesComponent implements OnInit {

  babyOutdoorGallery = [
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-01.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-01.jpg', description: 'Image 1', 
      caption: 'Children’s imagine works in mysterious ways. When a child imagines that one object represents something else, thats not them being wrong....That’s them using their imagination. Children’s imagine works in mysterious ways. When a child imagines that one object represents something else, thats not them being wrong....That’s them using their imagination. '
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-02.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-02.jpg', description: 'Image 2', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-03.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-03.jpg', description: 'Image 3', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-04.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-04.jpg', description: 'Image 4', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-05.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-05.jpg', description: 'Image 5', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-06.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-06.jpg', description: 'Image 6', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-07.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-07.jpg', description: 'Image 7', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-08.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-08.jpg', description: 'Image 8', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-09.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-09.jpg', description: 'Image 9', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-10.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-10.jpg', description: 'Image 10', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-11.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-11.jpg', description: 'Image 11', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-12.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-12.jpg', description: 'Image 12', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-13.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-13.jpg', description: 'Image 13', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-14.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-14.jpg', description: 'Image 14', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-15.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-15.jpg', description: 'Image 15', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-16.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-16.jpg', description: 'Image 16', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-17.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-17.jpg', description: 'Image 17', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-18.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-18.jpg', description: 'Image 18', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-19.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-19.jpg', description: 'Image 19', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-20.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-20.jpg', description: 'Image 20', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-21.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-21.jpg', description: 'Image 21', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-22.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-22.jpg', description: 'Image 22', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-23.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-23.jpg', description: 'Image 23', caption: ''
    },
    {
      img: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-24.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/baby-room/outdoor-activity/baby-activity-24.jpg', description: 'Image 24', caption: ''
    }
  ];

  @Input() showHeader : boolean;

  constructor(private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
