import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoCompleteKey } from 'src/app/core/enums/autocomplete.enum';
import { ChildRegistrationService } from 'src/app/modules/forms/child-registration/child-registration.service';
import {
  CopyAddress,
  HomeAddress,
  RelationShipObject,
  RelationType,
} from 'src/app/modules/nursery/models/child.model';

import { CommonService } from './../../../core/services/common.service';

@Component({
  selector: 'app-emergency-contact-not-parents',
  templateUrl: './emergency-contact-not-parents.component.html',
})
export class EmergencyContactNotParentsComponent implements OnInit {
  emergencyContactFormGroup: FormGroup;
  @Input() uniqueIndex: string;
  @Input() childId: string;
  @Input() emergencyContactOne: RelationShipObject;
  @Output() parentChange = new EventEmitter();

  destroy$ = new Subject();

  titleResults: Observable<string[]>;
  relationshipToChildResults: Observable<string[]>;
  autoCompleteKeys = AutoCompleteKey;
  allAddresses: CopyAddress[];
  relationType = RelationType;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private service: ChildRegistrationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.relationShipSearch();
    this.titleSearch();
    this.emergencyContactFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.updateParentGroup();
      });
  }

  ngOnChanges(): void {
    if (this.emergencyContactOne) {
      this.emergencyContactFormGroup.patchValue(this.emergencyContactOne);
    }
    this.getAllAddress();
  }

  setNumberValue(value: string, controlName: string): void {
    const obj = {};
    obj[controlName] = value;
    this.emergencyContactFormGroup.patchValue(obj);
  }

  getAllAddress() {
    this.service
      .getAddressByChildId(this.childId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allAddresses = [];
        response.forEach((item) => {
          if (item?.homeAddress) {
            const obj: CopyAddress = item.homeAddress as CopyAddress;
            obj.addressType = 1;
            obj.relationType = item.type as number;
            this.allAddresses.push(obj);
          }
          if (item?.officeAddress?.addressLineOne) {
            const obj: CopyAddress = item.officeAddress as CopyAddress;
            obj.addressType = 2;
            obj.relationType = item.type as number;
            this.allAddresses.push(obj);
          }
        });
      });
  }

  titleSearch(): void {
    this.titleResults = this.commonService
      .titleSearch()
      .pipe(takeUntil(this.destroy$));
  }

  relationShipSearch(): void {
    this.relationshipToChildResults = this.commonService
      .relationShipSearch()
      .pipe(takeUntil(this.destroy$));
  }

  initForm(): void {
    this.emergencyContactFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      relationshipToChild: ['', Validators.required],
      passwordForCollectingChild: [
        '',
        Validators.compose([Validators.required]),
      ],
      mobileNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(15),
          Validators.minLength(10),
        ]),
      ],
      landlineNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(15),
          Validators.minLength(10),
        ]),
      ],
    });
  }

  verifyChanges(event, key: AutoCompleteKey) {
    const isValid = this.commonService.isValidValue(event.target.value, key);
    if (!isValid) {
      key === AutoCompleteKey.Title
        ? this.emergencyContactFormGroup.patchValue({ title: null })
        : '';
      key === AutoCompleteKey.RelationShip
        ? this.emergencyContactFormGroup.patchValue({
            relationshipToChild: null,
          })
        : '';
    }
  }

  updateAddressForm(formGroup: FormGroup): void {
    this.emergencyContactFormGroup.addControl('homeAddress', formGroup);
  }

  patchAddress(item: HomeAddress): void {
    delete item.id;
    this.emergencyContactFormGroup.get('homeAddress').patchValue(item);
  }

  updateParentGroup(): void {
    this.parentChange.emit(this.emergencyContactFormGroup);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
