import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from '../../services/header-hide-show.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  // styleUrls: ['./reviews.component.scss'],
  host: {'class': 'w-100'}
})
export class Reviews implements OnInit {

  @Input() showHeader: boolean;

  constructor(private headerHideShowService: HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
