import { NannyServiceState } from './../../../store/reducers/nanny-service.reducer';
import { ColorPickerModule } from 'ngx-color-picker';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import {
  addOptionalService,
  saveOptionalService,
  saveOptionalServiceError,
} from '../../../store/actions/nanny-service.action';
import { selectNannyFromStore } from '../../../store/selectors/nanny-service.selector';
import { NannyServiceModel, OptionalService } from './../../../../../../shared/models/nanny-service.model';
import { NannyService } from './../../nanny-service.service';

@Component({
  selector: 'app-optional-services',
  templateUrl: './optional-services.component.html',
  styleUrls: ['./optional-services.component.scss']
})
export class OptionalServiceComponent implements OnInit {
  @Input() isEditAble = true;
  @Input() isInReview: boolean;
  @Output() successEmit = new EventEmitter();
  @Output() goBack = new EventEmitter();

  optionalService: OptionalService[]
  destroy$ = new Subject();

  constructor(private nannyService: NannyService,
    private updates$: Actions,
    private store: Store<NannyServiceModel>) { }

  async ngOnInit(): Promise<void> {
    let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
    this.optionalService = data?.nannyService?.optionalService || this.nannyService.getOptionalServices();

    this.updates$.pipe(ofType(saveOptionalServiceError))
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.successEmit.next();
      });
  }

  updateStore(): void {
    this.store.dispatch(addOptionalService({ optionalService: this.optionalService }));
  }

  async submitForm(): Promise<void> {
    this.store.dispatch(addOptionalService({ optionalService: this.optionalService }));
    let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
    this.store.dispatch(saveOptionalService({ nannyService: data.nannyService }));
  }

  back(): void {
    this.goBack.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
