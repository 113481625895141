import { createReducer, MetaReducer, on } from '@ngrx/store';

import { environment } from '../../../../../../environments/environment';
import { NannyServiceModel } from './../../../../../shared/models/nanny-service.model';
import {
  addBookingSlot,
  addChildCountInfo,
  addOptionalService,
  addUserCredentials,
  addUserDetails,
  saveBookingSlot,
  saveBookingSlotError,
  saveBookingSlotSuccess,
  saveChildCountInfo,
  saveChildCountInfoError,
  saveChildCountInfoSuccess,
  saveOptionalService,
  saveOptionalServiceError,
  saveOptionalServiceSuccess,
  saveUserCredentials,
  saveUserCredentialsError,
  saveUserCredentialsSuccess,
  saveUserDetails,
  saveUserDetailsError,
  saveUserDetailsSuccess,
} from './../actions/nanny-service.action';

export const nannyStateFeatureKey = 'nannyState';


export interface NannyServiceState {
  nannyService: NannyServiceModel;
}

export const initialState: NannyServiceState = {
  nannyService: {
    childrenCountGroup: null,
    slots: null,
    optionalService: null,
    userDetails: null,
    userCredentials: null,
  } as NannyServiceModel,
};

export const _nannyReducers = createReducer(initialState,

  on(addChildCountInfo, (state: NannyServiceState = initialState, action) => (
    {
      ...state,
      nannyService: {
        ...state.nannyService,
        childrenCountGroup: action.childCountInfo
      }
    })),

  on(saveChildCountInfo, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveChildCountInfoSuccess, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveChildCountInfoError, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  //

  on(addOptionalService, (state: NannyServiceState = initialState, action) => (
    {
      ...state,
      nannyService: {
        ...state.nannyService,
        optionalService: action.optionalService
      }
    })),

  on(saveOptionalService, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveOptionalServiceSuccess, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveOptionalServiceError, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  //

  on(addUserCredentials, (state: NannyServiceState = initialState, action) => (
    {
      ...state,
      nannyService: {
        ...state.nannyService,
        userCredentials: action.userCredentials
      }
    })),

  on(saveUserCredentials, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveUserCredentialsSuccess, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveUserCredentialsError, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  //

  on(addUserDetails, (state: NannyServiceState = initialState, action) => (
    {
      ...state,
      nannyService: {
        ...state.nannyService,
        userDetails: action.userDetails
      }
    })),

  on(saveUserDetails, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveUserDetailsSuccess, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveUserDetailsError, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  //

  on(addBookingSlot, (state: NannyServiceState = initialState, action) => (
    {
      ...state,
      nannyService: {
        ...state.nannyService,
        slots: action.slot
      }
    })),

  on(saveBookingSlot, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveBookingSlotSuccess, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),

  on(saveBookingSlotError, (state, action) => {
    return {
      ...state,
      nannyService: {
        ...action.nannyService
      }
    };
  }),
);

export function nannyReducers(state, action) {
  return _nannyReducers(state, action);
}


export const nannyMetaReducers: MetaReducer<NannyServiceState>[] = !environment.production ? [] : [];
