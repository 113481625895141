<section id="best-features" class="text-center mt-5">

  <!-- Heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown">Learning</h1>

  <!--Grid row-->
  <div class="d-flex justify-content-center pb-4">

    <!--Grid column-->
    <div class="col-lg-10 col-sm-10">

      <!-- Description -->
      <p class="mt-3 wow section-text slideInUp">Welcome to Rise 'N' Shine Nursery!
        An exceptional Nursery and Pre-School in Isle of dogs, London, providing high quality childcare to
        Isle of dogs and the surrounding area.</p>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->
  <div class="row mr-0 ml-0">

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/icons/learning-icon.png"
              alt="Card image cap">
            <a [routerLink]="['eyfs']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">EYFS</h4>
            <!-- Text -->
            <p class="card-text section-text">The purpose of the Early Years Foundation Stage (EYFS) tracker is to enable parents and practitioners to support children in meeting their potential by ensuring they are making progress in all areas of their development at the right rate according to the child's age..</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['eyfs']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

    <div class="col-lg-4 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/icons/school-checklist.png"
            alt="Card image cap">
          <a  [routerLink]="['school-readiness']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">School Readiness</h4>
          <!-- Text -->
          <p class="card-text section-text">School readiness refers to whether a child is ready to make an easy and successful transition into school. The term ‘preschool readiness’ might be used in the same manner in reference to beginning preschool. School readiness can be actively facilitated with a little..</p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['school-readiness']" mdbWavesEffect>Read More</a>

        </div>

      </div>
      <!-- Card -->
  </div>

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/icons/learning-cycle.png"
              alt="Card image cap">
            <a [routerLink]="['learning']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Children Learning Cycle</h4>
            <!-- Text -->
            <p class="card-text section-text">Early years education forms the essential part in the overall development of the child. It is during this period that a kid learns by engaging its sense of sight, smell, touch and hearing. with the help of sense of touch the child learns about different shapes..</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['learning']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

  </div>

  <!--Grid row-->

</section>
