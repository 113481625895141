import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { TokenStorageService } from './../../auth/_services/token-storage.service';

@Component({
  selector: 'app-admin-main-layout',
  templateUrl: './admin-main-layout.component.html',
  styleUrls: ['./admin-main-layout.component.scss'],
})
export class AdminMainLayoutComponent implements OnInit {

  destroySubject: Subject<any> = new Subject<any>();
  showMenu: boolean;
  menuItems = [{
    label: 'Get All Enquiries',
    router: '/pages/enquiry-list',
    icon: 'enquiry.svg',
  },
  {
    label: 'Get All Career Users',
    router: '/pages/career-list',
    icon: 'job.svg',
  },
  {
    label: 'Create a new users',
    router: '/pages/register',
    icon: 'create-user.svg',
  },
  {
    label: 'Registration',
    router: '/pages/registration-form',
    icon: 'registration.svg',
  },
  {
    label: 'Manager',
    router: '/pages/manager',
    icon: 'checklist.svg',
  },
];

  constructor(private route: Router, private tokenStorage: TokenStorageService) {
  }

  goToRoute(link: string): void {
    this.route.navigateByUrl(link);
    this.showMenu = false;
  }

  logout() {
    this.tokenStorage.signOut();
    this.route.navigate(['/']);
  }

  ngOnInit() {

  }

}
