import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admission-fee-section',
  templateUrl: './admission-fee-section.component.html'
  //styleUrls: ['./admission-fee-section.component.scss']
})
export class AdmissionFeeSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
