import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HeaderHideShowService } from '../../services/header-hide-show.service';
import { DynamicLoaderDirective } from './../../../../shared/directive/dynamic-loader.directive';
import { NannyComponents, NannyService } from './nanny-service.service';

@Component({
  selector: 'app-nanny-service',
  templateUrl: './nanny-service.component.html',
  styleUrls: ['./nanny-service.component.scss']
})
export class NannyServiceComponent implements OnInit {

  progress = 0;
  activeStep = -1;
  questionComponent: NannyComponents[] = [];

  @Input() headerShow: boolean;
  @ViewChild(DynamicLoaderDirective, { static: true }) dynamicLoader!: DynamicLoaderDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private headerHideShowService: HeaderHideShowService,
    private router: Router,
    private route: ActivatedRoute,
    private nannyService: NannyService) { }

  ngOnInit(): void {
    this.headerHideShowService.isHeaderShown(this.headerShow);
    this.questionComponent = this.nannyService.getSteps();
    this.route.params.subscribe(params => {
      this.activeStep = params['id'] || -1;
      this.goForward();
    });
  }

  setProgressCount(): void {
    this.progress = (100 / this.questionComponent.length) * this.activeStep;
  }

  goBack() {
    this.activeStep--;
    this.loadComponent();
  }

  goForward() {
    this.activeStep++;
    this.loadComponent();
  }

  loadComponent(): void {
    this.setProgressCount();
    const adItem = this.questionComponent[this.activeStep];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
    const viewContainerRef = this.dynamicLoader.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(componentFactory);
    componentRef.instance.data = adItem.data;
    const destroy$ = new Subject();
    componentRef.instance.successEmit
      .pipe(takeUntil(destroy$))
      .subscribe(response => {
        destroy$.next();
        destroy$.complete();
        if (this.activeStep + 1 !== this.questionComponent.length) {
          this.goForward();
        } else {
          this.activeStep++;
          this.setProgressCount();
          this.router.navigateByUrl('/booking-nanny')
        }
      });
    componentRef.instance.goBack
      ?.pipe(takeUntil(destroy$))
      .subscribe(response => {
        destroy$.next();
        destroy$.complete();
        this.goBack();
      });
  }

}
