import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Observable, Subject} from "rxjs";
import { startWith, map} from 'rxjs/operators';
import {Router} from "@angular/router";
import {select, Store} from '@ngrx/store';
import {HeaderHideShowService} from '../../services/header-hide-show.service';

@Component({
  selector: 'app-learning-lifecycle',
  templateUrl: './learning-lifecycle.component.html',
  //styleUrls: ['./learning-lifecycle.component.scss']
})
export class LearningLifecycleComponent implements OnInit {

  planningGallery = [
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/planning-img-1.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/planning-img-1.jpg', description: 'Image 1'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/planning-img-2.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/planning-img-2.jpg', description: 'Image 2'
    }
  ];

  resourceGallery = [
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/resource-collection-1.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/resource-collection-1.jpg', description: 'Image 1'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/resource-collection-2.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/resource-collection-2.jpg', description: 'Image 2'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/resource-collection-3.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/resource-collection-3.jpg', description: 'Image 3'
    }
  ];

  activityGallery = [
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/activity-1.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/activity-1.jpg', description: 'Image 1'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/activity-2.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/activity-2.jpg', description: 'Image 2'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/activity-3.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/activity-3.jpg', description: 'Image 3'
    }
  ];

  observationGallery = [
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/observation-1.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/observation-1.jpg', description: 'Image 1'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/observation-2.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/observation-2.jpg', description: 'Image 2'
    }
  ];

  managerApprovalGallery = [
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/manager-approval-1.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/manager-approval-1.jpg', description: 'Image 1'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/manager-approval-2.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/manager-approval-2.jpg', description: 'Image 2'
    }
  ];

  nextActivityGallery = [
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/next-activity-1.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/next-activity-1.jpg', description: 'Image 1'
    },
    {
      img: '../../../../assets/img/nursery/learning-lifecycle/gallery/next-activity-2.jpg',
      thumb: '../../../../assets/img/nursery/learning-lifecycle/gallery/next-activity-2.jpg', description: 'Image 2'
    }
  ];

  constructor(private router: Router,
    private headerHideShowService: HeaderHideShowService) { 
      
      $(document).ready(function() {
        /** Read More - Less Functionality */
        $('#readmore-link-one').click(function() {
          $('#readmore-text-one').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-two').click(function() {
          $('#readmore-text-two').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-three').click(function() {
          $('#readmore-text-three').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-four').click(function() {
          $('#readmore-text-four').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-five').click(function() {
          $('#readmore-text-five').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-six').click(function() {
          $('#readmore-text-six').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-seven').click(function() {
          $('#readmore-text-seven').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-eight').click(function() {
          $('#readmore-text-eight').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-nine').click(function() {
          $('#readmore-text-nine').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-ten').click(function() {
          $('#readmore-text-ten').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-eleven').click(function() {
          $('#readmore-text-eleven').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        $('#readmore-link-twelve').click(function() {
          $('#readmore-text-twelve').slideToggle();
          if ($(this).text() == "read more..") {
            $(this).text("read less -")
          } else {
            $(this).text("read more..")
          }
        });
        
      });
    }

  @Input() headerShow: boolean;

  ngOnInit() {
  
    this.headerHideShowService.isHeaderShown(this.headerShow);

  }

  
  

}
