<mdb-icon fas icon="edit" *ngIf="isInReview" (click)="isEditAble = !isEditAble;" class="pencil-icon"></mdb-icon>
<form class="question-1-wrapper" (ngSubmit)="submitForm()">
  <h3 class="main-heading">
    <span>
      {{isInReview ? 'Selected Children Information': 'Tell us children count and age group?'}}
    </span>
  </h3>
  <div class="badges">
    <div class="label-group" *ngFor="let age of childrenGroup">
      <span class="age-label">
        {{age.label}}
      </span>
      <span class="count-label">
        <span *ngIf="isEditAble" mdbWavesEffect class="icon-box" [class.disabled]="age.count === 0"
          (click)="toggleCount(age, 'back')">
          <mdb-icon fas icon="minus" class="mr-0"></mdb-icon>
        </span>
        <span class="age-count">
          {{age.count}}
        </span>
        <span *ngIf="isEditAble" mdbWavesEffect class="icon-box" [class.disabled]="age.count === 12"
          (click)="toggleCount(age, 'forward')">
          <mdb-icon fas icon="plus" class="mr-0 "></mdb-icon>
        </span>
      </span>
    </div>
  </div>
  <div class="footer-section m-4" *ngIf="isEditAble" [ngClass]="isInReview ? 'justify-content-center' : '' ">
    <button mdbBtn  *ngIf="!isInReview" type="button" (click)="back()" class="btn btn-link waves-light" mdbWavesEffect>
      Go Back
    </button>
    <button mdbBtn type="submit"  [class.disabled]="!isValid" class="rs-btn-info btn btn-rounded waves-light"
      mdbWavesEffect>
      {{isInReview ? 'Update' : 'SAVE AND NEXT'}}
    </button>
  </div>
</form>
