
<!-- Nursery Policies Section -->
<section id="nursery-policies">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Newsletter Archives</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
      <p class="section-text mt-3 wow slideInUp text-center">Here you will find all our weekly newsletter so you can stay up-to-date with news and announcements from Rainbow Splash Nursery.
      </p>
    </div>
  </div>
  <div class="section-wrapper">
    <!--Panel 1-->
    <div class="row">
      <div class="col-12">
        <mdb-accordion [multiple]="false">
          <mdb-accordion-item [collapsed]="false" [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="book-open"></mdb-icon> July 2021 Newsletters </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="row">
                <!-- Grid column -->
                <div class="col-lg-12">
                  <div class="policy-list">
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 16_7_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 1" target="_blank">Week ending 16/07/2021</a>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="book-open"></mdb-icon> June 2021 Newsletters </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="row">
                <!-- Grid column -->
                <div class="col-lg-12">
                  <div class="policy-list">
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 4_6_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 1" target="_blank">Week ending 04/06/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 11_6_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 2" target="_blank">Week ending 11/06/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 18_6_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 3" target="_blank">Week ending 18/06/2021</a>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="book-open"></mdb-icon> May 2021 Newsletters </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="row">
                <!-- Grid column -->
                <div class="col-lg-12">
                  <div class="policy-list">
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 7_5_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 1" target="_blank">Week ending 07/05/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 14_5_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 2" target="_blank">Week ending 14/05/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 21_5_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 3" target="_blank">Week ending 21/05/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/WEBSITE Newsletter 28_5_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 4" target="_blank">Week ending 28/05/2021</a>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="book-open"></mdb-icon> April 2021 Newsletters</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="row">
                <!-- Grid column -->
                <div class="col-lg-12">
                  <div class="policy-list">
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 1_4_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 1" target="_blank">Week ending 02/04/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 9_4_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 2" target="_blank">Week ending 09/04/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 16_4_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 3" target="_blank">Week ending 16/04/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 23_4_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 4" target="_blank">Week ending 23/04/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 30_4_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 5" target="_blank">Week ending 23/04/2021</a>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="book-open"></mdb-icon> March 2021 Newsletters</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="row">
                <!-- Grid column -->
                <div class="col-lg-12">
                  <div class="policy-list">
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 5_3_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 1" target="_blank">Week ending 05/03/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 12_3_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 2" target="_blank">Week ending 12/03/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 19_3_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 3" target="_blank">Week ending 19/03/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/Newsletter 26_3_2021.pdf" title="Rainbow Splash Weekly Newsletter Vol. 4" target="_blank">Week ending 26/03/2021</a>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="book-open"></mdb-icon> Feb 2021 Newsletters</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="row">
                <!-- Grid column -->
                <div class="col-lg-12">
                  <div class="policy-list">
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/risenshine-weekly-nl-vol-1.pdf" title="Rainbow Splash Weekly Newsletter Vol. 1" target="_blank">Week ending 05/02/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/risenshine-weekly-nl-vol-2.pdf" title="Rainbow Splash Weekly Newsletter Vol. 2" target="_blank">Week ending 12/02/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/risenshine-weekly-nl-vol-3.pdf" title="Rainbow Splash Weekly Newsletter Vol. 3" target="_blank">Week ending 19/02/2021</a>
                    </div>
                    <div class="policy-box">
                      <span></span><a href="../../../../../../assets/docs/newsletter-archives/risenshine-weekly-nl-vol-4.pdf" title="Rainbow Splash Weekly Newsletter Vol. 4" target="_blank">Week ending 26/02/2021</a>
                    </div>
                  </div>
                </div>
                <!-- Grid column -->
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
        </mdb-accordion>
      </div>
    </div>
  </div>
</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
