import { Observable, throwError } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalStorageService } from '../services/local-storage.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(public localStorageService: LocalStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO need to give token a appropriate name.
    // const userSession = this.localStorageService.getLocalStorage('userSession');
    //
    // if (userSession) {
    //   request = request.clone({
    //     setHeaders: {
    //       // eslint-disable-next-line @typescript-eslint/naming-convention
    //       Authorization: `Bearer ${userSession.token}`,
    //     },
    //   });
    // }

    return next.handle(request).pipe(
      map(res => {
        return res;
      }),
      catchError(response => {
        // alert('Something went wrong');
        return throwError(response);
      })
    );
  }
}
