import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IMyDate } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonConfig } from 'src/app/core/constants/common.constants';
import { NannyServiceModel } from 'src/app/shared/models/nanny-service.model';
import { NannyServiceState } from '../../../store/reducers/nanny-service.reducer';

import { addBookingSlot, saveBookingSlot, saveBookingSlotError } from './../../../store/actions/nanny-service.action';
import { selectNannyFromStore } from './../../../store/selectors/nanny-service.selector';
import { BookingInfo, Slot, Steppers } from './booking-form.interface';
import { PriceEngine } from './price-calculation.engine';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit, OnDestroy {
  @Input() isInReview: boolean;
  @Input() isEditAble = true;
  @Output() emitSuccess = new EventEmitter();
  myDatePickerOptions = {
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",
    dateFormat: 'mmm dd, yyyy',
    showClearDateBtn: false,
    disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }, // Disable dates backward starting from the given date.
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 2, day: new Date().getDate() }, // Disable dates forward starting from the given date.
  }
  startDate = new Date();
  selectedOption = '0';
  selectedWeek = 0;

  steppers: Steppers[] = [];
  selectAll: boolean;
  weeks = [];
  dateDataCollection: Slot[];
  totalPrice: number;
  bookingInfo: BookingInfo;
  totalChildren = 0;
  destroy$ = new Subject();

  constructor(private toastr: ToastrService,
    private price: PriceEngine,
    private route: Router,
    private updates$: Actions,
    private store: Store<NannyServiceModel>) { }

  async ngOnInit(): Promise<void> {
    this.store.pipe(select(selectNannyFromStore))
    .pipe(takeUntil(this.destroy$))
    .subscribe(response => {
      this.totalChildren = 0;
      const data = response.nannyService;
      data.childrenCountGroup?.forEach(item => this.totalChildren = this.totalChildren + item.count);
      if (data.slots) {
        this.startDate = data.slots.slot[0].date;
        this.patchData(data.slots);
      } else {
        this.startDate = this.getDates(0);
        this.updateDateSections();
      }
    });

    this.updates$.pipe(ofType(saveBookingSlotError))
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.toastr.success('Booking slot updated successfully');
        this.emitSuccess.emit();
      });

  }

  patchData(data: BookingInfo): void {
    this.selectedOption = data.selectedOption;
    this.totalPrice = data.totalCost;
    this.dateDataCollection = data.slot;
    this.patchDateBox();
  }

  patchDateBox(): void {
    this.steppers = [];
    this.weeks = [];
    if (this.selectedOption === '0') {
      this.steppers.push({ dates: this.dateDataCollection, week: 0 });
    } else if (this.selectedOption === '1') {
      this.steppers.push({ dates: this.dateDataCollection, week: 0 });
    } else if (this.selectedOption === '2') {
      for (let j = 0; j < (this.dateDataCollection.length / 7); j++) {
        this.selectedWeek = 0;
        const dates = [];
        this.weeks.push({
          checked: false,
          label: `Week ${j + 1}`,
          id: j
        });
        for (let i = 0; i < 7; i++) {
          const weekAddDays = (j + i + (6 * j));
          dates.push(this.dateDataCollection[weekAddDays]);
        }
        this.steppers.push({ dates: dates, week: j });
      }
      this.checkedStepper();
    }
    this.setAllCheckedBox();
    this.toggleAll();
    this.getMinutes();
  }

  updateDateSections(): void {
    this.steppers = [];
    const dates = [];
    this.dateDataCollection = [];
    this.selectedWeek = 0;
    if (this.selectedOption === '0') {
      const date = this.getDateObject(0);
      date.isEditAble = true;
      dates.push(date);
      this.dateDataCollection.push(this.getDateObject(0));
      this.steppers.push({ dates: dates, week: this.selectedWeek });
    } else if (this.selectedOption === '1') {
      for (let i = 0; i < 7; i++) {
        dates.push(this.getDateObject(i));
        this.dateDataCollection.push(this.getDateObject(i));
      }
      this.steppers.push({ dates: dates, week: this.selectedWeek });
    } else if (this.selectedOption === '2') {
      this.customWeek(5);
    }
    this.setAllCheckedBox();
    this.getMinutes();
  }

  checkTotalHours(): void {
    this.steppers.forEach(item => {
      if (this.dateDataCollection.length) {
        this.dateDataCollection.concat(item.dates);
      } else {
        this.dateDataCollection = item.dates;
      }
    });
  }

  dateCheckValidation(event: any, slot: Slot) {
    for (let i = 0; i < this.steppers.length; i++) {
      const d = this.steppers[i].dates;
      for (let j = 0; j < this.steppers[i].dates.length; j++) {
        if (this.getDateUID(d[j].date) === this.getDateUID(event.jsdate)) {
          setTimeout(() => slot.date = event.previousDateFormatted)
          this.toastr.error('This date has been already selected. Please choose another one');
          break;
        }
      }
    }
  }

  customWeek(weeks: number): void {
    this.steppers = [];
    this.weeks = [];
    for (let j = 0; j <= weeks - 1; j++) {
      const dates = [];
      this.weeks.push({
        checked: false,
        label: `Week ${j + 1}`,
        id: j
      })
      const weekAddDays = 7 * j
      for (let i = 0; i < 7; i++) {
        dates.push(this.getDateObject(i + weekAddDays))
        this.dateDataCollection.push(this.getDateObject(i + weekAddDays));
      }
      this.steppers.push({ dates: dates, week: j });
    }
    this.checkedStepper();
  }

  checkedStepper() {
    this.weeks.forEach(item => {
      if (item.id === this.selectedWeek) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
  }

  getDateObject(daysCount: number): Slot {
    return {
      id: this.getDateUID(this.getDates(daysCount)),
      date: this.getDates(daysCount),
      startTime: '13:00',
      endTime: '14:00',
      checked: true,
      isEditAble: false,
    }
  }

  updateTime(slot: Slot): void {
    this.dateDataCollection = this.dateDataCollection.map(item => {
      if (slot.id === item.id) {
        return slot;
      } else {
        return item;
      }
    });
    this.getMinutes();
  }

  getMinutes() {
    this.totalPrice = 0;
    let minutes = 0;
    this.dateDataCollection.map(item => {
      if (item.checked) {
        const startMinute = this.getMinutesFromHours(item.startTime);
        const endMinute = this.getMinutesFromHours(item.endTime);
        const totalMinutesForCurrentDay = endMinute - startMinute;
        minutes = minutes + totalMinutesForCurrentDay;
      }
    })
    this.totalPrice = this.price.getPrice(this.totalChildren, minutes);
  }

  pushNextDate(index) {
    const date = this.getDateObject(this.steppers[this.selectedWeek].dates.length);
    date.isEditAble = true;
    this.steppers[this.selectedWeek].dates.push(date);
    this.dateDataCollection.push(date);
    this.getMinutes();
  }

  removeCurrentDate(index: number, date: Slot): void {
    this.steppers[this.selectedWeek].dates =
      this.steppers[this.selectedWeek].dates.filter((item, iIndex) => iIndex !== index);
    this.dateDataCollection = this.dateDataCollection.filter(item => item.id !== date.id);
    this.getMinutes();
  }

  getMinutesFromHours(timeInHour: string): number {
    var timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }

  syncTime(slot: Slot): void {
    let selected;
    for (let i = 0; i < this.steppers.length; i++) {
      const d = this.steppers[i].dates;
      for (let j = 0; j < this.steppers[i].dates.length; j++) {
        if (this.getDateUID(d[j].date) === this.getDateUID(slot.date)) {
          selected = d[j];
          break;
        }
      }
    }
    this.steppers.forEach(item => {
      item.dates.forEach(ele => {
        ele.startTime = selected.startTime;
        ele.endTime = selected.endTime;
        this.updateTime(ele);
      });
    });
  }

  getDates(days: number): Date {
    const today = this.startDate;
    const newDate = new Date(today);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  setAllCheckedBox(): void {
    const hasFalse = this.steppers[this.selectedWeek].dates.some(item => !item.checked);
    if (hasFalse) {
      this.selectAll = false;
    } else {
      this.selectAll = true;
    }
  }

  toggleAll(): void {
    this.steppers[this.selectedWeek].dates.forEach(item => {
      item.checked = this.selectAll;
      this.updateTime(item);
    });
  }

  async submit(): Promise<void> {
    let data: Slot[] = [];
    for (let i = 0; i < this.steppers.length; i++) {
      const weeks = this.steppers[i].dates;
      for (let j = 0; j < this.steppers[i].dates.length; j++) {
        const selectedDate = weeks[j];
        if (selectedDate.date && selectedDate.startTime && selectedDate.endTime && this.hasDifference(selectedDate)) {
          data.push(selectedDate);
        } else if (selectedDate.date) {
          data = [];
          const date = `${CommonConfig.Months[selectedDate.date.getMonth()]} ${selectedDate.date.getDate()}, ${selectedDate.date.getFullYear()}`
          this.toastr
            .error(`Please enter the valid time for ${date}`)
          break;
        } else {
          data = [];
          this.toastr.error('Please enter the valid date');
          break;
        }
      }
    }
    if (data.length) {
      this.bookingInfo = {
        selectedOption: this.selectedOption,
        slot: data,
        totalCost: this.totalPrice,
      };
      await this.store.dispatch(addBookingSlot({ slot: this.bookingInfo }));
      let nannyData: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
      this.store.dispatch(saveBookingSlot({ nannyService: nannyData.nannyService }));
    }
  }

  getDateUID(date: Date): string {
    if (date) {
      date = new Date(date);
      return `${date.getDate()}${date.getMonth()}`;
    }
  }

  splitDate(date: Date): IMyDate {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    }
  }

  hasDifference(date: Slot): boolean {
    let end = date.endTime;
    let start = date.startTime;
    let hour1 = parseInt(start.substring(0, start.indexOf(":")))
    let hour2 = parseInt(end.substring(0, end.indexOf(":")))
    let l = hour2 - hour1;
    if (l >= 1 && hour2 > hour1) {
      return true;
    } else {
      return false;
    }
  }

  goBack(): void {
    this.route.navigateByUrl('/nanny-service/3');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
