import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from "../../services/header-hide-show.service";

@Component({
  selector: 'app-our-feature',
  templateUrl: './our-feature.component.html',
  //styleUrls: ['./our-feature.component.scss']
})
export class OurFeatureComponent implements OnInit {

  @Input() showHeader : boolean;

  constructor(private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {
    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
