<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTopToasterOne" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>
                <ng-content></ng-content>
            </strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
             (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
