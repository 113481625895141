<!-- Section: Team v.3 -->
<section class="team-section">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center">Our amazing team</h2>
  <!-- Section description -->
  <p class="grey-text text-center w-responsive mx-auto mb-5">We have an exceptional staff and our fully
    trained staff make sure that each kid is carefully monitored, nurtured and supported throughout their day in the nursery..</p>

  <!-- Grid row-->
  <div class="row text-center text-md-left">

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInLeft">
        <img src="../../../../../../assets/img/nursery/our-team/anubhuti.jpg" class="rounded z-depth-1" alt="Anubhuti Rajpal">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3">Anubhuti Rajpal</h4>
        <h6 class="font-weight-bold grey-text mb-3">Director</h6>
        <p class="grey-text">MPhil. in Education - Jawaharlal Nehru University. Delhi, India</p>
        <p class="grey-text">Master of Arts in Sociology - Delhi School of Economics. Delhi, India</p>
        <p class="grey-text">Sociology BA (Hons) - Lady Shri Ram College For Women. Delhi, India</p>
        <p class="grey-text">Diploma	for	the	Children's Workforce	(Early	Years	Educator)	Level	3. London</p>

        <!--linkedin -->
        <a class="p-2 fa-lg li-ic" href="https://www.linkedin.com/in/anubhuti-rajpal-347b8210a/">
          <mdb-icon fab="" icon="linkedin-in" class="fab"><i class="fa-linkedin-in fab"></i></mdb-icon></a>


      </div>
    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInLeft">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg"
             class="rounded z-depth-1" alt="Shahida jahan">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Deputy Manager</h6>
        <p class="grey-text">Level 6 Diploma in Children's learning and development (Early Years Educator) ,</p>
        <p class="grey-text">Extended Diploma in Health and Social Care Level 3 (DDM)</p>
        <p class="grey-text">Behaviour Management and Positive Interaction in childcare Level 2</p>
        <p class="grey-text">SENCO</p>
      </div>
    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row-->

  <!-- Grid row-->
  <div class="row text-center text-md-left">

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInRight">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Early Years Practitioner</h6>
        <p class="grey-text">CACHE Level 3 Diploma for the Children and Young People's Workforce (QCF),</p>
        <p class="grey-text">Makaton Workshop Certificate of Attendance, Safeguarding Trained, Paediatric First Aid Trained</p>
      </div>
    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInLeft">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1 img-fluid" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Early Years Practitioner</h6>
        <p class="grey-text">Level 3 Qualified and Trained, Level 2 Health and Social Care, </p>
        <p class="grey-text">Children and adults Qualified, Safeguarding Trained</p>
      </div>
    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row-->

  <!-- Grid row-->
  <div class="row text-center text-md-left">

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInRight">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1 img-fluid" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Early Years Practitioner</h6>
        <p class="grey-text">Qualified Teacher Status - Level 6, Safeguarding Trained, Paediatric First Aid Trained</p>
        <p class="grey-text">Level 2 Qualified and Trained, Level 2 Health and Social Care</p>
      </div>
    </div>
    <!-- Grid column -->

     <!-- Grid column -->
     <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInRight">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1 img-fluid" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Apprentice</h6>
        <p class="grey-text">Level 2 Qualified and Trained</p>
        <p class="grey-text">Persuing Childcare Level 3</p>
      </div>
    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row-->

  <!-- Grid row-->
  <div class="row text-center text-md-left">

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInLeft">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Bank Staff</h6>
        <p class="grey-text">CACHE Level 3 Diploma for the Early Years Workforce, Safeguarding Trained, Paediatric First Aid Trained</p>
      </div>
    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInLeft">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Early Years Practitioner</h6>
        <p class="grey-text">CACHE Level 3 Diploma for the Early Years Workforce, Safeguarding Trained, Paediatric First Aid Trained</p>
      </div>
    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row-->

  <!-- Grid row-->
  <div class="row text-center text-md-left">

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInRight">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1 img-fluid" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Bank Staff</h6>
        <p class="grey-text">Level 3 Qualified and Trained</p>
      </div>
    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInRight">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1 img-fluid" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Apprentice</h6>
        <p class="grey-text">Persuing Childcare Level 3, Safeguarding Trained</p>
      </div>
    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row-->

  <!-- Grid row-->
  <div class="row text-center text-md-left">

    <!-- Grid column -->
    <div class="col-xl-6 col-lg-12 mb-5 d-md-flex">
      <div class="avatar mb-3 wow slideInRight">
        <img src="../../../../../../assets/img/nursery/our-team/no_image.jpg" class="rounded z-depth-1 img-fluid" alt="">
      </div>
      <div class="mx-4 wow slideInDown">
        <h4 class="font-weight-bold mb-3"></h4>
        <h6 class="font-weight-bold grey-text mb-3">Apprentice</h6>
        <p class="grey-text">Persuing Childcare Level 3, Safeguarding Trained</p>
      </div>
    </div>
    <!-- Grid column -->

  </div>

</section>
<!-- Section: Team v.3 -->
