export class TimeSlotModel {
  endTime: string;
  startTime: string;
  timeslotCode: string;
  available?: boolean;
  cost?: number;
  timeRange?: string;
  booked?: boolean;

  constructor(endTime?: null,
    startTime?: null,
    timeslotCode?: null,
    available?: null,
    cost?: null,
    timeRange?: null,
    booked?: boolean,
  ) {
    this.endTime = endTime;
    this.startTime = startTime;
    this.timeslotCode = timeslotCode;
    this.available = available;
    this.cost = cost;
    this.timeRange = timeRange;
    this.booked = booked;
  }
}
