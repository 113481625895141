import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionRequiredComponent } from 'src/app/shared/components/session-required/session-required.component';

import { AboutUsComponent } from './home/about-us/about-us.component';
import { DirectorWelcomeComponent } from './home/about-us/director-welcome/director-welcome.component';
import { FacilityComponent } from './home/about-us/facility/facility.component';
import { FacultyAndStaffComponent } from './home/about-us/faculty-and-staff/faculty-and-staff.component';
import { LeadershipTeamComponent } from './home/about-us/leadership-team/leadership-team.component';
import { LocationAndHoursComponent } from './home/about-us/location-hours/location-hours.component';
import { MissionStatementComponent } from './home/about-us/mission-statement/mission-statement.component';
import { OurTeamComponent } from './home/about-us/our-team/our-team.component';
import { AdmissionsComponent } from './home/admissions/admissions.component';
import { AssociatedMembersComponent } from './home/associated-members/associated-members.component';
import { CareersComponent } from './home/careers/careers.component';
import { BabyRoomComponent } from './home/classrooms/baby-room/baby-room.component';
import { ClassroomsComponent } from './home/classrooms/classrooms.component';
import { PreSchoolRoomComponent } from './home/classrooms/pre-school-room/pre-school-room.component';
import { ToddlerRoomComponent } from './home/classrooms/toddler-room/toddler-room.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { CovidFAQSComponent } from './home/covid/covid-faqs.component';
import { EyfsComponent } from './home/eyfs/eyfs.component';
import { BabyRoomFeeComponent } from './home/fee/baby-room-fee/baby-room-fee.component';
import { FeeComponent } from './home/fee/fee.component';
import { PreSchoolRoomFeeComponent } from './home/fee/pre-school-room-fee/pre-school-room-fee.component';
import { ToddlerRoomFeeComponent } from './home/fee/toddler-room-fee/toddler-room-fee.component';
import { FoodHygiene } from './home/food-hygiene/food-hygiene.component';
import { Funding15HoursComponent } from './home/funding/funding-15-hours/funding-15-hours.component';
import { Funding30HoursComponent } from './home/funding/funding-30-hours/funding-30-hours.component';
import { FundingComponent } from './home/funding/funding.component';
import { HomeComponent } from './home/home.component';
import { LearningLifecycleComponent } from './home/learning-lifecycle/learning-lifecycle.component';
import { NewsletterArchives } from './home/newsletter-archives/newsletter-archives.component';
import { NurseryPoliciesComponent } from './home/nursery-policies/nursery-policies.component';
import { NurserySoftwareComponent } from './home/nursery-software/nursery-software.component';
import { OperationsComponent } from './home/operations/operations.component';
import { OurGalleryComponent } from './home/our-gallery/our-gallery.component';
import { Reviews } from './home/reviews/reviews.component';
import { SchoolChecklistComponent } from './home/school-checklist/school-checklist.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NurseryComponent } from './nursery.component';

const nurseryRoutes: Routes = [
  {
    path: "",
    component: NurseryComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
        data: {
          breadcrumb: "contact-us",
        },
      },
      {
        path: "admission",
        component: AdmissionsComponent,
        data: {
          breadcrumb: "admission",
        },
      },
      {
        path: "fee",
        component: SessionRequiredComponent,
        children: [
          // {
          //   path: "",
          //   component: SessionRequiredComponent,
          //   data: { breadcrumb: "Baby Room Fee" },
          // },
          // {
          //   path: "toddler-room-fee",
          //   component: ToddlerRoomFeeComponent,
          //   data: { breadcrumb: "Toddler Room Fee" },
          // },
          // {
          //   path: "pre-school-room-fee",
          //   component: PreSchoolRoomFeeComponent,
          //   data: { breadcrumb: "Pre School Room Fee" },
          // },
        ],
        data: {
          breadcrumb: "Fee",
        },
      },
      {
        path: "classrooms",
        component: ClassroomsComponent,
        data: {
          breadcrumb: "classrooms",
        },
        children: [
          {
            path: "baby-room",
            component: BabyRoomComponent,
            data: {
              breadcrumb: "baby-room",
            },
          },
          {
            path: "toddler-room",
            component: ToddlerRoomComponent,
            pathMatch: "prefix",
            data: {
              breadcrumb: "toddler-room",
            },
          },
          {
            path: "pre-school-room",
            component: PreSchoolRoomComponent,
            pathMatch: "full",
            data: {
              breadcrumb: "pre-school-room",
            },
          },
        ],
      },
      {
        path: "operations",
        component: OperationsComponent,
        data: {
          breadcrumb: "Operations",
        },
      },
      {
        path: "about-us",
        component: AboutUsComponent,
        data: {
          breadcrumb: "about-us",
        },
        children: [
          {
            path: "director-welcome",
            component: DirectorWelcomeComponent,
            data: {
              breadcrumb: "director-welcome",
            },
          },
          {
            path: "mission-statement",
            component: MissionStatementComponent,
            data: {
              breadcrumb: "mission-statement",
            },
          },
          {
            path: "our-team",
            component: OurTeamComponent,
            data: {
              breadcrumb: "our-team",
            },
          },
          {
            path: "leadership-team",
            component: LeadershipTeamComponent,
            data: {
              breadcrumb: "leadership-team",
            },
          },
          {
            path: "faculty-and-staff",
            component: FacultyAndStaffComponent,
            data: {
              breadcrumb: "faculty-and-staff",
            },
          },
          {
            path: "facility",
            component: FacilityComponent,
            data: {
              breadcrumb: "facility",
            },
          },
          {
            path: "location-and-hours",
            component: LocationAndHoursComponent,
            data: {
              breadcrumb: "location-and-hours",
            },
          },
        ],
      },
      {
        path: "careers",
        component: CareersComponent,
        data: {
          breadcrumb: "Careers",
        },
      },
      {
        path: "policies",
        component: NurseryPoliciesComponent,
        data: {
          breadcrumb: "Nursery Policies",
        },
      },
      {
        path: "newsletter-archives",
        component: NewsletterArchives,
        data: {
          breadcrumb: "Newsletter Archives",
        },
      },
      {
        path: "covid-faqs",
        component: CovidFAQSComponent,
        data: {
          breadcrumb: "COVID-19: FAQ",
        },
      },
      {
        path: "learning",
        component: LearningLifecycleComponent,
        data: {
          breadcrumb: "Learning Lifecycle",
        },
      },
      {
        path: "funding",
        component: FundingComponent,
        data: {
          breadcrumb: "Funding",
        },
        children: [
          {
            path: "funding-15-hours",
            component: Funding15HoursComponent,
            data: {
              breadcrumb: "15 Hours",
            },
          },
          {
            path: "funding-30-hours",
            component: Funding30HoursComponent,
            data: {
              breadcrumb: "30 Hours",
            },
          },
        ],
      },
      {
        path: "nursery-software",
        component: NurserySoftwareComponent,
        data: {
          breadcrumb: "Nursery Software",
        },
      },
      {
        path: "eyfs",
        component: EyfsComponent,
        data: {
          breadcrumb: "EYFS",
        },
      },
      {
        path: "school-readiness",
        component: SchoolChecklistComponent,
        data: {
          breadcrumb: "School Readiness",
        },
      },
      {
        path: "newsletter",
        component: NewsletterComponent,
        data: {
          breadcrumb: "Newsletter",
        },
      },
      {
        path: "associated-members",
        component: AssociatedMembersComponent,
        data: {
          breadcrumb: "Associated Members",
        },
      },
      {
        path: "reviews",
        component: Reviews,
        data: {
          breadcrumb: "Google Reviews",
        },
      },
      {
        path: "our-gallery",
        component: OurGalleryComponent,
        data: {
          breadcrumb: "Our Gallery",
        },
      },
      {
        path: "food-hygiene",
        component: FoodHygiene,
        data: {
          breadcrumb: "Food Hygiene",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(nurseryRoutes)],
  exports: [RouterModule],
})
export class NurseryRoutingModule {}
