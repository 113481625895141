import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  ButtonsModule,
  InputsModule,
  MDB_DATE_OPTIONS,
  MDBBootstrapModulesPro,
  MDBSpinningPreloader,
  ToastModule,
} from 'ng-uikit-pro-standard';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from 'src/app/shared/shared.module';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthHeaderInterceptor } from './core/interceptors/auth-header.interceptor';
import { LoginModule } from './modules/auth/auth.module';
import { LayoutModule } from './modules/layout/layout.module';
import { NurseryModule } from './modules/nursery/nursery.module';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTooltipModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatSelectModule,
    CoreModule,
    FormsModule,
    LayoutModule,
    NurseryModule,
    LoginModule,
    BrowserAnimationsModule,
    ButtonsModule,
    InputsModule,
    ReactiveFormsModule,
    
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAPz9Sfn6DuVA8xtEwabgn09es7GVjjS00'
    }),
    SharedModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    DatePipe,
    MDBSpinningPreloader,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtTokenInterceptor,
    //   multi: true
    // }
    { provide: MDB_DATE_OPTIONS, useValue: { showTodayBtn: false } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas:    [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule { }
