
<router-outlet #outlet="outlet"></router-outlet>

<section id="school-checklist" *ngIf="outlet.isActivated == false">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">School Readiness</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">
          School readiness refers to whether a child is ready to make an easy and successful transition into school.
          The term ‘preschool readiness’ might be used in the same manner in reference to beginning preschool.
          School readiness can be actively facilitated with a little forward planning to ensure that children regularly participate
          in activities that develops the appropriate skills required to help optimal learning when they start school.
          While many people think of academics (e.g. writing their name, counting to 10, knowing the colors) as the important school
          readiness skills, school readiness actually refers to a much broader range of skills. In addition to some academic basics,
          school readiness skills also include self care (independent toileting and opening lunch boxes), attention and concentration,
          physical skills (e.g. having the endurance to sit upright for an entire school day), emotional regulation, language skills
          and play and social skills.
        </p>
    </div>
  </div>

  <div class="section-wrapper pt-4 pb-4">
    <div class="row">
      <div class="col-lg-12">
        <app-checklist-area></app-checklist-area>
      </div>
    </div>
  </div>
</section>






