import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-director-welcome',
  templateUrl: './director-welcome.component.html',
  //styleUrls: ['./director-welcome.component.scss']
})
export class DirectorWelcomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
