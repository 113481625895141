
<div class="session-required m-3 pt-3 pb-3">
  <div class="d-flex align-items-center w-100 justify-content-center">
    <h1 class="section-heading font-weight-bold  wow slideInDown" *ngIf="!childId">Fee</h1>
  </div>
  <div class="row" *ngIf="showFundingSessionMessage">
    <div class="col-sm-12">
      <strong><span class="mb-2 ml-3">{{ showFundingSessionMessage }} </span> </strong>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-4" *ngIf="!childId">
      <div class="md-form">
        <strong><span class="mb-2">Select DOB: </span> </strong>
        <mdb-date-picker name="dob" placeholder="Date of Birth" id="dob" [options]="myDatePickerOptions"
                         [(ngModel)]="dob" (inputFieldChanged)="selectRoom()" >
        </mdb-date-picker>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="md-form">
        <strong><span class="mb-2">Room: </span> </strong> {{ room }}
      </div>
    </div>
    <div class="col-sm-4">
      <div class="md-form">
        <strong><span class="mb-2 ml-3" style="color:green">Fee: £{{ price }} </span> </strong>
      </div>
    </div>
  </div>


  <div class="row mb-4">
    <div *ngIf="room == 'Pre-School'" class="col-sm-4">
      <span class="mb-2 ml-3">
        <mdb-checkbox value="3Yrs15Hrs" [checked]="threeYears15HoursChecked" [disabled]="threeYears15Hours"
          (change)="on3Yrs15HrsChange($event)">
          <strong> 3 Years 15 Hrs Funding </strong>
        </mdb-checkbox>
      </span>
    </div>
    <div *ngIf="room == 'Pre-School'" class="col-sm-4">
      <span class="mb-2 ml-3">
        <mdb-checkbox value="3Yrs30Hrs" [checked]="threeYears30HoursChecked" [disabled]="threeYears30Hours"
          (change)="on3Yrs30HrsChange($event)">

          <strong> 3 Years 30 Hrs Funding </strong>
        </mdb-checkbox>
      </span>
    </div>
    <div *ngIf="room == 'Toddler'" class="col-sm-4">
      <span class="mb-2 ml-3">
        <mdb-checkbox value="2Yrs15Hrs" [checked]="twoYears15HoursChecked" (change)="on2Yrs15HrsChange($event)">
          <strong> 2 Years 15 Hrs Funding </strong>
        </mdb-checkbox>
      </span>
    </div>
  </div>
  <strong>
    Please select minimum of either 3 half or 2 full days
  </strong>
  <!-- Table  -->
  <table class="table table-bordered mt-3">
    <!-- Table head -->
    <thead>
      <tr>
        <th class="fw-bold"></th>
        <th class="fw-bold">Monday</th>
        <th class="fw-bold">Tuesday</th>
        <th class="fw-bold">Wednesday</th>
        <th class="fw-bold">Thursday</th>
        <th class="fw-bold">Friday</th>
      </tr>
    </thead>
    <!-- Table head -->

    <!-- Table body -->
    <tbody>
      <tr *ngFor="let row of rowData;let i = index">
        <th class="fw-bold">{{sessionType[row.session]}}</th>
        <td *ngFor="let day of row.days">
          <mdb-checkbox [disabled]="!room" [checked]="day.isChecked" [(ngModel)]="day.isChecked"
            (change)="onChange(day.name, row.session, $event)"></mdb-checkbox>{{row.day}}
        </td>
      </tr>
    </tbody>
    <!-- Table body -->

  </table>
  <!-- Table  -->
</div>