
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IMyOptions, MdbStepperComponent } from 'ng-uikit-pro-standard';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CommonConfig } from 'src/app/core/constants/common.constants';
import { AutoCompleteKey } from 'src/app/core/enums/autocomplete.enum';
import {
  LoadChildData,
  LoadRegistrationsSuccess,
  SaveChildRegistration,
  SaveSuccessChildRegistration,
} from 'src/app/modules/forms/forms-store/store/actions/child.actions';
import { RegistrationState } from 'src/app/modules/nursery/home/registration/store/reducers/registration.reducer';

import { CommonService } from '../../../core/services/common.service';
import {moment} from 'ngx-bootstrap/chronos/testing/chain';
import {RoomType} from "../../../modules/nursery/models/child.model";
import { ChildRegistrationService } from 'src/app/modules/forms/child-registration/child-registration.service';

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.scss']
})
export class ChildComponent implements OnInit {
  @Input() uniqueIndex: string;
  @Input() stepper: MdbStepperComponent;
  @Input() childrenFormGroup: FormGroup;
  @Output() childChange: EventEmitter<any> = new EventEmitter();

  autoCompleteKeys = AutoCompleteKey;

  myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    startDate: new Date(),
    showClearDateBtn: false,
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }, // Disable dates backward starting from the given date.
  };
  anyOtherLanguageSelect = CommonConfig.anyOtherLanguageSelect;
  destroy$ = new Subject();

  genderResults: Observable<string[]>;
  religionResults: Observable<string[]>;
  ethnicityResults: Observable<string[]>;
  firstLanguageResults: Observable<string[]>;
  roomResults: Observable<string[]>;
  childId: any;

  constructor(
    private formBuilder: FormBuilder,
    private updates$: Actions,
    private store: Store<RegistrationState>,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private service: ChildRegistrationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.childrenFormChange();
    this.genderSubscriber();
    this.religionSubscriber();
    this.ethnicitySubscriber();
    this.languageSubscriber();
    this.roomSubscriber();
    this.route.params
    .pipe(takeUntil(this.destroy$))
    .subscribe((result) => {
      if (result['childId']) {
        this.childId = result['childId'];
        this.childrenFormGroup.patchValue({
          id: this.childId,
        });
        this.store.dispatch(LoadChildData({ id: result['childId'] }));
      }
    });
  }

  initForm(): void {
    this.childrenFormGroup = this.formBuilder.group({
      id: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      knownAs: ['', Validators.required],
      gender: ['', Validators.required],
      religion: ['', Validators.required],
      ethinicity: ['', Validators.required],
      firstLanguage: ['', Validators.required],
      anyOtherLanguageSpoken: ['', Validators.required],
      dob: ['', Validators.required],
      room: new FormGroup({
        roomName: new FormControl('')
      }),
      medicalNeeds: [''],
      dislike: [''],
      needs: [''],
      routine: [''],
      liveWith: ['', Validators.required],
      dietaryRequirements: ['']
    });

    this.updates$
      .pipe(ofType(LoadRegistrationsSuccess))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.childrenFormGroup.patchValue(response.childForm);
      });

    this.updates$
      .pipe(ofType(SaveSuccessChildRegistration))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.toaster.success('Your data has been submitted');
        this.router.navigateByUrl('forms/registration-form/parent-one/' + response.childForm.id);
      });
  }

  verifyChanges(event, key: AutoCompleteKey) {
    const isValid = this.commonService.isValidValue(event.target.value, key);
    if (!isValid) {
      key === AutoCompleteKey.Religion
        ? this.childrenFormGroup.patchValue({ religion: null })
        : '';
      key === AutoCompleteKey.Gender
        ? this.childrenFormGroup.patchValue({ gender: null })
        : '';
      key === AutoCompleteKey.Ethnicity
        ? this.childrenFormGroup.patchValue({ ethinicity: null })
        : '';
      key === AutoCompleteKey.FirstLanguage
        ? this.childrenFormGroup.patchValue({ firstLanguage: null })
        : '';
      key === AutoCompleteKey.Room
        ? this.childrenFormGroup.patchValue({ room: null })
        : '';
    }
  }

  selectRoom(): void {
    const value = this.service.getRoomByDob(this.childrenFormGroup.controls['dob'].value);
    this.childrenFormGroup.get(['room','roomName']).setValue(value);
  }

  languageSubscriber(): void {
    this.firstLanguageResults = this.commonService
      .languageSubscriber()
      .pipe(takeUntil(this.destroy$));
  }

  ethnicitySubscriber(): void {
    this.ethnicityResults = this.commonService
      .ethnicitySubscriber()
      .pipe(takeUntil(this.destroy$));
  }

  religionSubscriber(): void {
    this.religionResults = this.commonService
      .religionSubscriber()
      .pipe(takeUntil(this.destroy$));
  }

  genderSubscriber(): void {
    this.genderResults = this.commonService
      .genderSubscriber()
      .pipe(takeUntil(this.destroy$));
  }

  roomSubscriber(): void {
    this.roomResults = this.commonService
      .roomSubscriber()
      .pipe(takeUntil(this.destroy$));
  }

  childrenFormChange(): void {
    this.childrenFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(() => {
        this.childChange.emit(this.childrenFormGroup);
      });
  }

  async updateChildForm(): Promise<void> {
    if (this.childrenFormGroup.valid) {
      this.store.dispatch(
        SaveChildRegistration({ childForm: this.childrenFormGroup.value })
      );
    }
    this.childrenFormGroup.markAllAsTouched();
  }

  get childDetails() {
    return this.childrenFormGroup.controls;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
