import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from "../../../../services/header-hide-show.service";

@Component({
  selector: 'app-preschool-room-gallery',
  templateUrl: './preschool-room-gallery.component.html',
  //styleUrls: ['./preschool-room-gallery.component.scss'],
  host: {'class': 'w-100'}
})
export class PreSchoolRoomGalleryComponent implements OnInit {

  preSchoolGallery = [
    {
      img: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-1.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-1.jpg', description: 'Image 9'
    },
    {
      img: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-2.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-2.jpg', description: 'Image 10'
    },
    {
      img: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-3.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-3.jpg', description: 'Image 11'
    },
    {
      img: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-4.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-4.jpg', description: 'Image 12'
    },
    {
      img: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-5.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-5.jpg', description: 'Image 13'
    },
    {
      img: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-6.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/pre-school-room/gallery/pre-school-room-6.jpg', description: 'Image 14'
    }
  ];

  @Input() showHeader : boolean;

  constructor(private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
