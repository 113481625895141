import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import {HeaderHideShowService} from "../../nursery/services/header-hide-show.service";
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import { ModalModule, TooltipModule, PopoverModule, ButtonsModule } from 'ng-uikit-pro-standard';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  // styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  // Modal end

  header = true;
  intro = 'intro';
  intro2 = 'intro2';
  destroySubject: Subject<any> = new Subject<any>();
  authenticated = false;
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
  }



  ngOnInit() {

    if (this.router.url !== null && this.router.url !== '/') {
      this.header = false;
    } else {
      this.header = true;
    }
  }

  changeHeader(path: string) {
    this.header = false;
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  openModal() {
  }

  logout() {

      const loginInfoReturn = {
        'success'    : false,
        'message'    : '',
        'landingPage': '',
        'user'       : {
          'userId'     : 'admin',
          'email'      : 'admin@risenshinenursery.co.uk',
          'displayName': 'Deo' + ' ' + 'Kishore',
          'token'      : 'asas1212',
        }
      };


    }

}
