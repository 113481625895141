import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule, IMyOptions, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, FormGroupName, FormBuilder, Validator, Validators, AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-focussed-activity-form',
  templateUrl: './focussed-activity-form.component.html',
  //styleUrls: ['./focussed-activity-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FocussedActivityFormComponent),
      multi: true
    },
      {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FocussedActivityFormComponent),
      multi: true
    }
  ]
})
export class FocussedActivityFormComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() day: string;

  @Input() step: MdbStepperComponent;

  focussedActivityForm: FormGroup;

  submitted = false;
  
  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastService) { }

  ngOnInit() {

    this.focussedActivityForm = this.formBuilder.group({
      childInitials: ['', Validators.required],
      resources: ['', Validators.required],
      linkEYFS: ['', Validators.required],
      reasonForActivity: ['', Validators.required],
      supportChildren: ['', Validators.required],
      evaluation: ['', Validators.required],
    });

  }

  // convenience getter for easy access to form fields
  get form1() { return this.focussedActivityForm.controls; }

  goBack(stepper: MdbStepperComponent) {
    stepper.previous();
  }

  goForward() {
    this.submitted = true;
    if (this.focussedActivityForm.invalid) {
      this.showWarning();
      return;
    }
    else {
      if(this.day=='Friday') {
        console.log(' Data For ' + this.day + ':' + JSON.stringify(this.focussedActivityForm.value));
        this.showSuccess();
      }
      else {
        console.log(' Data For ' + this.day + ':' + JSON.stringify(this.focussedActivityForm.value));
        this.step.next();
      }
    }
  }

  showSuccess() {
    this.toastrService.info('Data Saved Successfully!!');
    setTimeout(function(){ location.reload(); }, 1000);
  }

  showWarning() {
    this.toastrService.warning('Please fill the form!!');
  }

  onReset() {
    this.submitted = false;
    this.focussedActivityForm.reset();
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.focussedActivityForm.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    //console.log("on change");
    this.focussedActivityForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    //console.log("on blur");
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.focussedActivityForm.disable() : this.focussedActivityForm.enable();
  }
  validate(c: AbstractControl): ValidationErrors | null{
    console.log("Activity Form validation", c);
    return this.focussedActivityForm.valid ? null : { invalidForm: {valid: false, message: "Activity Form fields are invalid"}};
  }

}
