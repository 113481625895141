import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-other-chk',
  templateUrl: './other-chk.component.html',
  styleUrls: ['./other-chk.component.scss'],
})
export class OtherCheckComponent implements OnInit {
  @Input() questionLabel: string;
  @Input() submitted: boolean;
  @Input() description: string;

  @Output() emitParent = new EventEmitter();
  @Output() checkClicked = new EventEmitter();

  destroy$ = new Subject();
  otherDetailsForm: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.otherDetailsForm.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe((response) => {
      this.emitParent.emit(this.otherDetailsForm);
    });

    this.otherDetailsForm.get('checkBox').valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe((r) => {
      if(r) {
        this.otherDetailsForm.get('description').setValidators(Validators.required);
      } else {
        this.otherDetailsForm.get('description').setValidators(null);
        this.otherDetailsForm.patchValue({
          description: null,
        });
      }
      this.otherDetailsForm.updateValueAndValidity();
    });

  }

  ngOnChanges(): void {
    this.initForm();
    if(this.description) {
      this.otherDetailsForm.patchValue({
        description: this.description,
        checkBox: true,
      });
    } else {
      this.otherDetailsForm.patchValue({
        description: null,
      });
    }
  }

  initForm() {
    if(!this.otherDetailsForm) {
      this.otherDetailsForm = this.fb.group({
        description: new FormControl(''),
        checkBox: new FormControl(false),
      })
    }
  }

  changeChkValue(value) {
    this.otherDetailsForm.patchValue({
      checkBox: value.checked
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
