<section id="preschool">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Pre-School</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">Explore our free flow home from home environment for our youngest children:The Pre-School room is devised for kids aged approx—3 to 24 months. The bright and buoyant room provides reclining, calming and quite environment for the babies to rest and sleep throughout the day.</p>
    </div>
  </div>

  <div class="section-wrapper">
      <!-- Nav Tabs -->
      <mdb-tabset #staticTabs [vertical]="true" [buttonClass]="'md-tabs tabs-5 ml-0 mr-0'" [contentClass]="'pl-0 pr-0'">
        <!--Panel 1-->
        <mdb-tab heading="Pre-School Info">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/pre-school-room/pre-school-room-avatar.png" class="rounded-circle" alt="Pre-School Room Avatar">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Pre-School</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Card image-->
                  <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                    <img src="../../../../../../assets/img/nursery/classrooms/pre-school-room/pre-school-room-header.png" class="cardimg" alt="Pre-School Room Header Image">

                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!--/Card image-->
                  <!--Quotation-->
                  <div class="pt-4 pb-4 text-left">
                  <p>
                    The Pre-School is a large airy room allowing for plenty of space for young babies to play, crawl and learn to toddle.
                    Within this nursery facility are low pieces of furniture to encourage children to pull themselves up and give opportunities
                    for taking their first steps. A variety or resources are available where babies can enjoy various experiences of sight,
                    sound, smell and touch which are all important in a child’s development. A home from home environment is provided where
                    the children’s individual routines and needs are met. There is also a room connecting the pre-school Unit to the Toddler room
                    where the babies enjoy mealtimes together.
                  </p>
                  <p>
                    The room is also used during the day for the children to take part in messy play and creative activities.
                    There is also a phone in the pre-school room so parents can speak to staff directly which can be reassuring for
                    parents leaving their children for the first time.
                  </p>
                </div>

                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>

        <!--Panel 2-->
        <mdb-tab heading="Daily Routine">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/pre-school-room/pre-school-room-avatar.png" class="Pre-School Room Image First">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Daily Routine</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Accordion-->
                  <mdb-accordion [multiple]="false">
                    <mdb-accordion-item [collapsed]="false" [customClass]="'border-0'">
                      <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon far icon="clock"></mdb-icon> Morning Session</mdb-accordion-item-head>
                      <mdb-accordion-item-body>
                        <div class="row my-4">
                            <!-- Grid column -->
                            <div class="col-lg-12">

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/breakfast/image-01.jpg" alt="Children Welcome, Breakfast">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">
                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">08:00 AM - 10:00 AM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Children Welcome, Breakfast</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>
                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/lessons/img-01.jpg" alt="Activity, Observation, Lessons">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">10:00 AM - 11:00 AM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Activity, Observation, Lessons</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-lg-0 mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-lg-0 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/outdoor activity/img-01.jpg" alt="Outdoor Activities, Fun and Gathering">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">11:00 AM - 12:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-lg-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Outdoor Activities, Fun and Gathering</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                            </div>
                            <!-- Grid column -->
                        </div>
                      </mdb-accordion-item-body>
                    </mdb-accordion-item>

                    <mdb-accordion-item [customClass]="'border-0'">
                      <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon far icon="clock"></mdb-icon> Afternoon Session</mdb-accordion-item-head>
                      <mdb-accordion-item-body>
                        <div class="row my-4">
                            <!-- Grid column -->
                            <div class="col-lg-12">

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/lunch/img-01.jpg" alt="Lunch Time, Food made in our own kitchen">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">
                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">12:00 PM - 13:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Lunch Time, Food made in our own kitchen</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>
                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/naptime/img-01.jpg" alt="Nap Time">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">13:00 PM - 15:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Nap Time</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-lg-0 mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-lg-0 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/study/img-01.jpg" alt="Activity, Obsevation, Study">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">15:00 PM - 16:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-lg-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Activity, Obsevation, Study</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                            </div>
                            <!-- Grid column -->
                        </div>
                      </mdb-accordion-item-body>
                    </mdb-accordion-item>
                  </mdb-accordion>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>

        <!--Panel 3-->
        <!-- <mdb-tab heading="School Readiness">
          <app-school-checklist></app-school-checklist>
        </mdb-tab> -->

        <!--Panel 4-->
        <mdb-tab heading="Fee">
          <app-pre-school-room-fee></app-pre-school-room-fee>
        </mdb-tab>

        <!--Panel 5-->
        <mdb-tab heading="Gallery">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/pre-school-room/pre-school-room-avatar.png" class="rounded-circle" alt="Pre-School Room Image Second">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Gallery</h3>
                  </mdb-card-title>
                  <hr>
                  <!-- PreSchool Room Gallery -->
                  <app-preschool-room-gallery></app-preschool-room-gallery>
                  <!-- PreSchool Room Gallery -->
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>

        <!--Panel 6-->
        <mdb-tab heading="Admissions">
          <app-pre-school-room-admission></app-pre-school-room-admission>
        </mdb-tab>

      </mdb-tabset>
      <!-- Nav Tabs -->
    </div>
</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
