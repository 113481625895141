import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../nursery/models/user.model';

const TOKEN_KEY = 'auth-accessToken';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  public currentUserSubject: BehaviorSubject<User>;
  private currentUser: User;

  constructor() {
    this.currentUser = JSON.parse(sessionStorage.getItem(USER_KEY));
    this.currentUserSubject = new BehaviorSubject(this.currentUser);
  }

  signOut() {
    sessionStorage.clear();
    sessionStorage.removeItem(TOKEN_KEY);
    this.currentUserSubject.next(null);
  }

  public saveToken(token: string) {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: User) {
    sessionStorage.removeItem(USER_KEY);
    user.id = user.userId;
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.currentUser = user;
    this.currentUserSubject.next(user);
  }

  public getUser() {
    return this.currentUser;
  }
}
