<ng-container [formGroup]="checkEligibilityFormOne">
  
    <!-- Form Field 1 -->
  <div class="form-group pl-4" [hidden]="stage!=='Step 1'">
    <h5 class="mb-3">Are you living in England?</h5>
    <span class="mb-4 d-block">Free childcare hours are different between countries in the UK.</span>
    
    <div class="form-check form-check-inline">
        <input type="radio" class="form-check-input" value="yes" id="radLivingBtn1" name="living" formControlName="living">
        <label class="form-check-label font-weight-bold text-uppercase pl-4" for="radLivingBtn1">Yes</label>
    </div>
    
    <div class="form-check form-check-inline">
        <input type="radio" class="form-check-input" value="no" id="radLivingBtn2" name="living" formControlName="living">
        <label class="form-check-label font-weight-bold text-uppercase pl-4" for="radLivingBtn2">No</label>
    </div>

    <!-- <div class="alert-text">
        <mdb-error *ngIf="submitted && form1.living.errors">Please Select Value</mdb-error>
    </div> -->

</div>

<!-- Send button -->
<button mdbBtn type="button" color="danger" rounded="true" class="z-depth-0 my-4 waves-effect" [hidden]="stage=='Step 1'" (click)="goBack(step)" mdbWavesEffect>PREVIOUS</button>
<button mdbBtn type="button" color="info" rounded="true" class="z-depth-0 my-4 waves-effect float-right" [innerHtml]="stage!=='Step 4' ? 'NEXT': 'SUBMIT' " (click)="goForward();" mdbWavesEffect>NEXT</button>

</ng-container>