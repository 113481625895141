import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})

export class CheckBoxComponent {
  @Input() label: string;
  @Input() submitted: boolean;
  @Input() disabled: boolean;
  @Output() checkClicked = new EventEmitter();
  @Input() isClicked = false;

  ngOnInit() {
    this.checkClicked.emit(false);
  }

  updateValue() {
    this.checkClicked.emit(!this.isClicked)
  }

}
