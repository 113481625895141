
<router-outlet #outlet="outlet"></router-outlet>

<section id="about-us" *ngIf="outlet.isActivated == false">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">About Us</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">
          Rise 'N' Shine nursery is committed to providing a safe and healthy environment that encourages children to explore, learn and succeed through constructive activities. We work hard as a team with full commitment and dedication to ensure the development of children at our Nursery.
        </p>
    </div>
  </div>

  <div class="section-wrapper">
      <!-- Nav Tabs -->
      <mdb-tabset #staticTabs [vertical]="true" [buttonClass]="'md-tabs tabs-5 ml-0 mr-0 indigo'" [contentClass]="'pl-0 pr-0'">
        <!--Panel 1-->
        <mdb-tab heading="Director's Welcome" id="directorWelcome">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../assets/img/nursery/our-team/anubhuti.jpg" class="rounded-circle" alt="Director">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Director's Welcome</h3>
                    <h3 class="text-center font-weight-bold">Anubhuti Rajpal</h3>
                    <div class="avatar mx-auto mt-2"><a class="p-2 fa-lg li-ic" href="https://www.linkedin.com/in/anubhuti-rajpal-347b8210a/"><mdb-icon fab="" icon="linkedin-in" class="fab"><i class="fa-linkedin-in fab"></i></mdb-icon></a></div>
                  </mdb-card-title>
                  <hr>
                  <!--Quotation-->
                  <div class="pt-4 pb-4 text-left">
                    <p>A committed and resourceful professional with detailed educational knowledge and core skills in the early years of education, Mrs. Anubhuti Rajpal comes with a strong academic background.</p>
                    <p>Graduated from Loreto Convent (Ranchi), completed her B.A. (Hons) in Sociology from Lady Sri Ram College for Women, M.A. in Sociology from Delhi School of Economics, M.Phil in Educational Studies from Jawaharlal Nehru University, New Delhi, completed Diploma for the Children's Workforce (Early Years Educator) Level 3 from London with have overall 7 years( also in early years reception) and also the franchise owner and instructor at Kumon Isle of Dogs Centre which is a leading supplementary education provider in the world.</p>
                    <p>She plans, controls, and develops the overall activities at the care and is responsible for significant team building, program development, budgeting, and forecasting.</p>
                    <p>Blessed with a keen eye for creativity, she is accountable for creating an appealing learning environment for the kids. The mother of two cute little angels is sanctified with the kindest temperament and exhibits unconditional love and discipline for her work.</p>
                    <p>A quick thinker with the capacity to work hard, Mrs. Rajpal provides full support in the implementation and execution of the classes' curriculum. She is an invisible asset in the nursery as well.</p>
                    <p>Her authentic intent towards her work and the constant need to deliver the best to the organization reflects her professional ethics.</p>
                    <p>Sincerely,<br>Anubhuti Rajpal</p>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 2-->
        <mdb-tab heading="Our Mission" id="ourMission">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/about-us/our_mission-avatar.jpg" class="rounded-circle" alt="Our Mission">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Our Mission</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Quotation-->
                  <div class="pt-4 pb-4 text-left">
                    <p>We aim to provide a service that gives peace of mind and can be tailored to suit any family’s requirements. We hope to achieve this in all our childcare environments by:</p>
                    <p>1. Providing a place where children are happy and parents feel confident leaving their children. They achieve this by:</p>
                    <ul>
                      <li>Providing a welcoming, homely, caring and personalised service.</li>
                      <li>Providing a high standard of child care practices to give parents total peace of mind by adhering to quality standards devised by quality controllers and employing qualified and/or experienced staff.</li>
                      <li>Providing a safe and secure environment dealing with all health and safety issues as a matter of urgency.</li>
                      <li>Being reliable and flexible thus allowing parents to be reliable in their careers.</li>
                      <li>Offering a range of sessions to suit individual family requirements.</li>
                      <li>Being professional by ensuring staff have common courtesy and deliver a consistent approach.</li>
                      <li>Providing a bright, stimulating and creative environment through wall displays of the children’s work and learning aids, through carefully chosen equipment and through training staff to deliver the curriculum.</li>
                      <li>Providing an outside curriculum in their secure outside play area or in the local community.</li>
                      <li>Providing a service that is kind to the environment and to train their staff to be aware of the importance of re-cycling, energy efficiency and minimising waste with a view to passing this information on to the children.</li>
                    </ul>
                    <p>2. Providing a wide range of equipment and activities that are planned and structured to ensure a balanced and all round curriculum through which children are able to learn at their own pace with the support of adults. These are achieved by:</p>
                    <ul>
                      <li>Creating and following annual and weekly activity planners that implement all four principles of the new Early Years Foundation Stage curriculum.</li>
                      <li>Offering a wide rage of equipment that is well presented for the children to use at all times.</li>
                      <li>Offering a varied menu which is displayed for parents to see.</li>
                      <li>Recording the development of each child using the new Early Years Foundation Stage curriculum as a guide.<br><br></li>
                    </ul>
                    <p>3. Ensuring equal opportunities for all children, families and staff regardless of their race, culture, gender, SEN (special educational needs), disability or age and discouraging discriminatory language and behaviour by:</p>
                    <ul>
                      <li>Promoting an anti-discriminatory environment through staffing, books, posters, stories, rhymes and play equipment.</li>
                      <li>Discouraging discriminatory language and behaviour.</li>
                      <li>Complying with equal opportunities policy when admitting children or employing staff.<br><br></li>
                    </ul>
                    <p>4. Working closely with parents/carers so that all the children’s needs are met by:</p>
                    <ul>
                      <li>Keeping regular observations, key notes and individual Personal Achievement Reports of the children and their work.</li>
                      <li>Assessing a child’s development and recording it on their own Personal Achievement Report throughout their time at Rise 'N' Shine and passing this information on to the child’s parents / primary school at the appropriate time.</li>
                      <li>Providing parent evenings every six months to discuss their child’s development with the key person.</li>
                      <li>Providing daily feedback.</li>
                      <li>Providing various notice boards with information ranging from illnesses to Ofsted reports, from activity planners to the menu.</li>
                      <li>Displaying the weekly activities and children’s work to give parents ideas to share with their children at home.</li>
                      <li>Providing opportunities for parents to become involved in the activities of the Nursery such as children’s birthday parties, talks about their interests/jobs and helping on children’s outings or fun days.</li>
                      <li>Building a photo album of each child from when they start at the Nursery to when they leave.<br><br></li>
                    </ul>
                    <p>5. Making sure that all their policies are available in their Operations Manual. Policies include their commitment to the inclusion of children with Special Educational Needs, Child Protection, Equal Opportunities and Complaints procedure.</p>
                    <p>6. Working towards achieving the principles / commitments as described in the Early Years Foundation Stage curriculum guidance and adhere to the National Standards</p>
                    <p>7. Reviewing their aims and objectives annually in May to ensure they remain current</p>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 4-->
        <mdb-tab heading="Facility" id="ourFacility">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Our Facility">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Facility</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Quotation-->
                  <div class="pt-4 pb-4 text-left">
                   <p>Rise N Shine is proud of its wide selection of infrastructure and facilities. Having an access to the outdoor environment is one of our biggest assets. Our spacious outdoor areas enable the child to play and learn in a safe and secure environment.</p>
                   <p>We help the kids to achieve all round education in a child friendly atmosphere. it is our aim that each kid enjoys their years of education and reach their full potential.</p>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 5-->
        <mdb-tab heading="Location & Hours" id="locationHours">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/about-us/location-avatar.jpg" class="rounded-circle" alt="Location & Hours">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Location And Hours</h3>
                  </mdb-card-title>
                  <hr>
                  <!-- Location Hours Box -->
                  <div class="row pt-4">
                    <div class="col-lg-6">
                      <!-- Card -->
                        <mdb-card
                        style="background-image: url('../../../../../assets/img/nursery/about-us/location-img.jpg')"
                        class="card-image mb-3 mx-2"
                      >
                        <!-- Content -->
                        <div class="text-white text-center d-block align-items-center rgba-black-strong py-5 px-4">
                          <div>
                            <h5 class="pink-text">
                              <mdb-icon fas icon="map-marker"></mdb-icon>
                              Location
                            </h5>
                            <h3 class="card-title pt-2"><strong>Rise N Shine Nursery</strong></h3>
                            <p class="pt-2">
                              34 Skylines, Limeharbour <br/>
                              London, E14 9TS <br/>
                              United Kingdom
                            </p>
                          </div>
                        </div>
                        <!-- Content -->
                      </mdb-card>
                      <!-- Card -->
                    </div>
                    <div class="col-lg-6">
                        <!-- Card -->
                        <mdb-card
                        style="background-image: url('../../../../../assets/img/nursery/about-us/location-img.jpg')"
                        class="card-image mb-3 mx-2"
                      >
                        <!-- Content -->
                        <div class="text-white text-center d-block align-items-center rgba-indigo-strong py-5 px-4">
                          <div>
                            <h5 class="orange-text">
                              <mdb-icon fas icon="clock"></mdb-icon>
                              Working Hours
                            </h5>
                            <h3 class="card-title pt-2"><strong>Timings</strong></h3>
                            <ul class="m-0 p-0" style="list-style: none;">
                              <li class="pt-2">Monday - Friday: 08:00AM - 06:00PM</li>
                              <li class="pt-2">Saturday - Sunday: Closed</li>
                              <li class="pt-2" style="font-size: 14px;">Please Note: Extended 6 to 7 available on £10 per hour</li>
                            </ul>
                          </div>
                        </div>
                        <!-- Content -->
                      </mdb-card>
                      <!-- Card -->
                    </div>
                  </div>
                <!-- End Location Hours Box -->
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabset>
      <!-- Nav Tabs -->
    </div>
</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->




