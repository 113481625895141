<!--Double navigation-->
<header>

  <!-- Navbar -->
  <app-mega-menu-bar></app-mega-menu-bar>
  <!--/. Navbar -->

  <!-- Image Carousel Slider -->
  <div id="carousel-example-2" class="carousel slide carousel-fade" data-ride="carousel" data-interval="8000">
    <ol class="carousel-indicators">
      <li data-target="#carousel-example-2" data-slide-to="0" class="active"></li>
      <li data-target="#carousel-example-2" data-slide-to="1" ></li>
      <li data-target="#carousel-example-2" data-slide-to="2"></li>
      <li data-target="#carousel-example-2" data-slide-to="3"></li>
      <li data-target="#carousel-example-2" data-slide-to="4"></li>
    </ol>
    <div *ngIf="header" class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <div class="view1">
          <img class="d-block w-100" src="../../../../assets/img/slider/slide-1.jpg" alt="About Rise N Shine Nursery">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption full-width">
          <div class="cap-wrapper">
            <div class="top-box clearfix blinking">
              <span><a href="https://files.ofsted.gov.uk/v1/file/50170972" target="_blank" class="" >
                Rise n shine rated "Good" by ofsted in 2021
                </a></span>
            </div>
            <div class="d-flex funding-box wow slideInRight clearfix">
              <mdb-card class="card-image d-flex align-items-center justify-content-center mb-3 mx-2 blue-box">
                <div class="text-white text-center d-flex align-items-center py-3 px-3">
                  <div>
                    <h3 class="box-text blue-txt text-center">
                      Ofsted Registered
                    </h3>
                    <h4 class="card-title pt-2" style="color: #E3F2FD;"><strong><mdb-icon fas icon="child"></mdb-icon>
                    Rise 'N' Shine is ofsted "Good" - 2021
                    </strong></h4>
                    <p>
                       "Highly Sensitive, nurturing and knowledgeable staff expertly support children's social and emotional well being"
                    </p>
                    <a mdbBtn href="https://files.ofsted.gov.uk/v1/file/50170972"  target="_blank" class="btn btn-rounded waves-light" mdbWavesEffect style="background: #1A237E; color: #fff;">
                      <mdb-icon fas icon="arrow-right" class="left"></mdb-icon>
                      Read More
                    </a>
                  </div>
                </div>
              </mdb-card>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="view1">
          <img class="d-block w-100" src="../../../../assets/img/slider/slide-1.jpg" alt="About Rise N Shine Nursery">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption full-width">
          <div class="cap-wrapper">
            <div class="top-box clearfix">
              <span><a [routerLink]="['/contact-us']" title="Registration">Register now for autumn term start (September'21)- Up to 30 Hours free child care for working families!</a></span>
            </div>
            <div class="d-flex funding-box wow slideInRight clearfix">
              <mdb-card class="card-image mb-3 mx-2 blue-box">
                <div class="text-white text-center d-flex align-items-center py-3 px-3">
                  <div>
                    <h3 class="box-text blue-txt text-center">
                      <mdb-icon fas icon="pound-sign"></mdb-icon>
                      We Accept Funding (15 Hours)
                    </h3>
                    <h4 class="card-title pt-2" style="color: #E3F2FD;"><strong><mdb-icon fas icon="child"></mdb-icon> 3 & 4 Yrs Old</strong></h4>
                    <p>
                      15 hours a week funded Early Education and Childcare for Everyone. Click here to know more about 15 hours funding for childcare.
                    </p>
                    <a mdbBtn [routerLink]="['/funding/funding-15-hours']" class="btn btn-rounded waves-light" mdbWavesEffect style="background: #1A237E; color: #fff;">
                      <mdb-icon fas icon="arrow-right" class="left"></mdb-icon>
                      Read More
                    </a>
                  </div>
                </div>
              </mdb-card>
              <mdb-card class="card-image mb-3 mx-2 orange-box">
                <div class="text-white text-center d-flex align-items-center py-3 px-3">
                  <div>
                    <h3 class="box-text yellow-txt text-center">
                      <mdb-icon fas icon="pound-sign"></mdb-icon>
                      We Accept Funding (30 Hours)
                    </h3>
                    <h4 class="card-title pt-2" style="color:#FFF59D;"><strong><mdb-icon fas icon="child"></mdb-icon> 3 & 4 Yrs Old</strong></h4>
                    <p>
                      30 hours a week funded early education and childcare for working parents. Click here to read more about 30 hours funding for childcare.
                    </p>
                    <a mdbBtn [routerLink]="['/funding/funding-30-hours']" class="btn btn-rounded btn-unique waves-light" color="unique" mdbWavesEffect>
                      <mdb-icon fas icon="arrow-right" class="left"></mdb-icon>
                      Read More
                      </a>
                  </div>
                </div>
              </mdb-card>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="view">
          <img class="d-block w-100" src="../../../../assets/img/slider/slide-2.jpg" alt="Parental Partnership">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption">
          <div class="cap-wrapper">
            <h3 class="h3-responsive wow slideInRight">Parental Partnership</h3>
            <p class="wow fadeInUp">Our relationship with parents is equally important to us here at Rise ‘N’ Shine Nursery. After all parents are your prime and most important teacher. We believe that children prosper greatly when staff and parents work together.</p>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="view">
          <img class="d-block w-100" src="../../../../assets/img/slider/slide-3.jpg" alt="We Offer">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption">
          <div class="cap-wrapper">
            <h3 class="h3-responsive wow slideInRight">We Offer</h3>

            <ul class="para-list wow fadeInUp">
              <li>Safe, friendly and a fun place for kids to learn and grow.</li>
              <li>Learning through indoor and outdoor play activities.</li>
              <li>A well-equipped, kind, caring and professional team.</li>
              <li>Nutritious, balanced and freshly prepared meals and snacks.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="view">
          <img class="d-block w-100" src="../../../../assets/img/slider/slide-2.jpg" alt="Rise N Shine - Admission">
          <div class="mask rgba-black-strong"></div>
        </div>
        <div class="carousel-caption">
          <div class="cap-wrapper">
            <h3 class="h3-responsive wow slideInRight">Admission</h3>

            <p class="wow fadeInUp">When the start of the school is right. The future of the kid is bright. Enroll your little ones at RISE ‘N’ SHINE NURSERY!</p>
            <a mdbBtn [routerLink]="['/admission']" class="rs-btn-pink ml-0 mr-0 wow fadeInUp" rounded="true" mdbWavesEffect>Enroll Now</a>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carousel-example-2" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-example-2" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <!--/. End Image Carousel Slider -->

  <!-- breadcrumb -->

  <!-- breadcrumb -->

  <!-- Login Modal -->
<!--  <app-login-modal></app-login-modal>-->
  <!-- End Login Modal -->

</header>
<!--/.Double navigation-->

<!-- <div *ngIf="isModalShown" [config]="{ show: true }" bsModal #autoShownModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md modal-center position-relative">
    <div class="modal-content">
       <span (click)="isModalShown = false" class="cross-icon position-absolute">
        <i class="fa fa-times"></i>
       </span>
       <img src="../../../../assets/img/banner.jpg">
    </div>
  </div>
</div> -->



