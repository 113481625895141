<mdb-icon fas icon="edit" *ngIf="isInReview" (click)="isEditAble = !isEditAble; toggleForm()" class="pencil-icon"></mdb-icon>
<form [formGroup]="form" class="question-1-wrapper" (ngSubmit)="submitForm()">
  <h3 class="main-heading">
    {{isInReview ? 'User Information' : 'Yeah!! Almost Done.'}}
  </h3>
  <div class="badges">
    <!-- First Row-->
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form  mt-3 mb-3">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-fullName" name="fullName" mdbInput type="text" id="fullName"
            formControlName="fullName" class="form-control" mdbValidate>
          <label for="fullName">Full Name</label>
          <mdb-error *ngIf="form?.get('fullName')?.touched && form?.get('fullName')?.errors">
            Please provide your Full Name
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form mt-3 mb-3">
          <input appNumbersOnly autocomplete="new-phone" name="phone" mdbInput type="text" id="phone"
            formControlName="phoneNumber" class="form-control" mdbValidate>
          <label for="phone">Phone Number</label>
          <mdb-error *ngIf="form?.get('phoneNumber')?.touched && form?.get('phoneNumber')?.errors">
            Please provide your Phone
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form mt-3 mb-3">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-addressLine1" name="addressLine1" mdbInput type="text" id="addressLine1"
            formControlName="addressLine1" class="form-control" mdbValidate>
          <label for="addressLine1">Address Line 1</label>
          <mdb-error *ngIf="form?.get('addressLine1')?.touched && form?.get('addressLine1')?.errors">
            Please provide your Address Line 1
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form mt-3 mb-3">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-addressLine2" name="addressLine2" mdbInput type="text" id="addressLine2"
            formControlName="addressLine2" class="form-control" mdbValidate>
          <label for="addressLine2">Address Line 2</label>
          <mdb-error *ngIf="form?.get('addressLine2')?.touched && form?.get('addressLine2')?.errors">
            Please provide your addressLine 2
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form mt-3 mb-3">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-city" name="city" mdbInput type="text" id="city" formControlName="city"
            class="form-control" mdbValidate>
          <label for="city">City</label>
          <mdb-error *ngIf="form?.get('city')?.touched && form?.get('city')?.errors">
            Please provide your City
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="md-form mt-3 mb-3">
          <input autocomplete="new-postcode" name="postcode" mdbInput type="text" id="postcode"
            formControlName="postcode" class="form-control" mdbValidate>
          <label for="postcode">Post Code</label>
          <mdb-error *ngIf="form?.get('postcode')?.touched && form?.get('postcode')?.errors">
            Please provide your Post Code
          </mdb-error>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section m-4" *ngIf="isEditAble" [ngClass]="isInReview ? 'justify-content-center' : '' ">
    <button mdbBtn  *ngIf="!isInReview" type="button" (click)="back()" class="btn btn-link waves-light" mdbWavesEffect>
      Go Back
    </button>
    <button mdbBtn type="submit"  [class.disabled]="form.invalid"  class="rs-btn-info btn btn-rounded waves-light"
      mdbWavesEffect>
      {{isInReview ? 'Update' : 'Finish'}}
    </button>
  </div>
</form>
