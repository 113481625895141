import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonsModule,
  CheckboxModule,
  FileInputModule,
  InputUtilitiesModule,
  MDBBootstrapModulesPro,
  MDBSpinningPreloader,
  ModalModule,
  PopoverModule,
  StepperModule,
  TooltipModule,
  WavesModule,
} from 'ng-uikit-pro-standard';
import * as bootstrapAccordionModule from 'ngx-bootstrap/accordion';
import * as bootstrapBsDropdownModule from 'ngx-bootstrap/dropdown';
import * as bootstrapModalModule from 'ngx-bootstrap/modal';
import * as bootstrapPaginationModule from 'ngx-bootstrap/pagination';
import * as bootStrapPopOverModule from 'ngx-bootstrap/popover';
import * as bootstrapTabsModule from 'ngx-bootstrap/tabs';

import { CustomStoreModule } from './../modules/nursery/home/registration/store/store.module';
import { NannyStoreModule } from './../modules/nursery/nursery-forms/store/nursery-store.module';
import { AddressComponent } from './components/address/address.component';
import { CustomAutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { BasicInfoFormComponent } from './components/basic-info-form/basic-info-form.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { ChildComponent } from './components/child/child.component';
import { DailyCheckListComponent } from './components/daily-check-list/daily-check-list.component';
import {
  EmergencyContactNotParentsComponent,
} from './components/emergency-contact-not-parents/emergency-contact-not-parents.component';
import { FlyOutComponent } from './components/flyout/flyout.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MedicalComponent } from './components/medical/medical.component';
import { ModalToasterComponent } from './components/modal-toaster/modal-toaster.component';
import { ModalToasterService } from './components/modal-toaster/modal-toaster.sevrice';
import { MonthlyCheckListComponent } from './components/monthly-check-list/monthly-check-list.component';
import { OtherCheckComponent } from './components/other-chk/other-chk.component';
import { ParentDetailsComponent } from './components/parent-details/parent-details.component';
import { ParentsComponent } from './components/parents/parents.component';
import { PersonDetailsComponent } from './components/person-details/person-details.component';
import { QuestionCardComponent } from './components/question-card/question-card.component';
import { SessionRequiredComponent } from './components/session-required/session-required.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { WeeklyCheckListComponent } from './components/weekly-check-list/weekly-check-list.component';
import { DynamicLoaderDirective } from './directive/dynamic-loader.directive';
import { NumberDirective } from './directive/number-only.directive';
import { OutSideClickDirective } from './directive/outside-click.directive';
import { SkipPageDirective } from './directive/skip.directive';
import { IsdComponent } from './components/isd/isd.component';

const Modules = [
  ButtonsModule,
  CheckboxModule,
  FileInputModule,
  InputUtilitiesModule,
  MDBBootstrapModulesPro,
  ModalModule,
  PopoverModule,
  StepperModule,
  TooltipModule,
  WavesModule,
  // MdbValidationModule
  //
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CustomStoreModule,
  NannyStoreModule,
]

@NgModule({
  imports: [Modules, bootStrapPopOverModule.PopoverModule.forRoot(),
    bootstrapBsDropdownModule.BsDropdownModule.forRoot(),
    bootstrapTabsModule.TabsModule.forRoot(),
    bootstrapAccordionModule.AccordionModule.forRoot(),
    bootstrapPaginationModule.PaginationModule.forRoot(),
    bootstrapModalModule.ModalModule.forRoot(),
  ],
  declarations: [
    DynamicLoaderDirective,
    ModalToasterComponent,
    SessionRequiredComponent,
    SkipPageDirective,
    EmergencyContactNotParentsComponent,
    LoaderComponent,
    PersonDetailsComponent,
    DailyCheckListComponent,
    WeeklyCheckListComponent,
    OutSideClickDirective,
    ChildComponent,
    MedicalComponent,
    FlyOutComponent,
    BasicInfoFormComponent,
    QuestionCardComponent,
    ParentsComponent,
    MonthlyCheckListComponent,
    OtherCheckComponent,
    CustomAutoCompleteComponent,
    CheckBoxComponent,
    ParentDetailsComponent,
    NumberDirective,
    StepperComponent,
    AddressComponent,
    IsdComponent,
  ],
  exports: [
    ModalToasterComponent,
    Modules,
    bootStrapPopOverModule.PopoverModule,
    bootstrapPaginationModule.PaginationModule,
    bootstrapModalModule.ModalModule,
    LoaderComponent,
    DynamicLoaderDirective,
    DailyCheckListComponent,
    FlyOutComponent,
    MonthlyCheckListComponent,
    WeeklyCheckListComponent,
    QuestionCardComponent,
    bootstrapTabsModule.TabsModule,
    bootstrapAccordionModule.AccordionModule,
    bootstrapBsDropdownModule.BsDropdownModule,
    OtherCheckComponent,
    EmergencyContactNotParentsComponent,
    OutSideClickDirective,
    ChildComponent,
    CustomAutoCompleteComponent,
    MedicalComponent,
    PersonDetailsComponent,
    BasicInfoFormComponent,
    SessionRequiredComponent,
    ParentsComponent,
    AddressComponent,
    CheckBoxComponent,
    ParentDetailsComponent,
    NumberDirective,
    SkipPageDirective,
    StepperComponent,
  ],
  providers: [ModalToasterService, MDBSpinningPreloader]
})

export class SharedModule { }
