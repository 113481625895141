import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grdFilter'
})
export class GrdFilterPipe implements PipeTransform {
  transform(items: any, filter: any, data: any,): any {


    if (!filter) {
      return items;
    }

    if (!Array.isArray(data)) {
      return items;
    }

    if (filter && Array.isArray(data)) {
      /* console.log(items,filter)*/
      let hasFilter = false;
      for (let key in filter) {
        if (filter[key]) {
          hasFilter = true;
          break;
        }
      }
      if (filter.sa === 'today') {
        return this.getFilteredShowAround(data, filter.sa);
      } else if (filter.sa === 'past') {
        return this.getFilteredShowAround(data, filter.sa);
      } else if (filter.sa === 'future') {
        return this.getFilteredShowAround(data, filter.sa);
      }
      if (hasFilter) {
        const filterKeys = Object.keys(filter);
        return data.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
          });
        });
      } else {
        return items;
      }
    }
  }

  getFilteredShowAround(items: any[], key: string) {
    let result;
    const latestDate = new Date();
    if (key === 'today') {
      result = items.filter(item => {
        const currentDate = new Date(item.showaroundDateTime.replace(/-/g, "/"));
        if (new Date().getDate() === currentDate.getDate() &&
          new Date().getMonth() === currentDate.getMonth() &&
          new Date().getFullYear() === currentDate.getFullYear()) {
          return item;
        }
      })
    } else if (key === 'future') {
      result = items.filter((item) => {
        if (item.showaroundDateTime) {
          const shDate = new Date(item.showaroundDateTime.replace(/-/g, "/"));
          if ((latestDate.getDay() !== shDate.getDay())) {
            return item && new Date(item.showaroundDateTime.replace(/-/g, "/")) > new Date();
          }
        }
      });
    } else if (key === 'past') {
      result = items.filter((item) => {
        if (item.showaroundDateTime) {
          return item && new Date(item.showaroundDateTime.replace(/-/g, "/")) < new Date()
        }
      });
    }
    return result;
  }
}
