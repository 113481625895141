import { Child } from './child.model';
import { Parent } from './parent.model';
import { ChildSession } from './child.session';
import { Address } from './address.model';

export class Registration {
  id: number;
  child: Child;
  parentOne: Parent;
  parentTwo: Parent;emergencyContactOne: Parent;
  emergencyContactTwo: Parent;
  childSessions: ChildSession[];
  creationDate: string;
  medicalDetails = new MedicalDetails();
  immuneDetails = new ImmuneDetails();
  individualDetails = new IndividualDetail();
} // todo remove


export class ChildRegistrationForm {
  id: number;
  child: Child;
  parentOne: Parent;
  parentTwo: Parent;
  emergencyContactOne: Parent;
  emergencyContactTwo: Parent;
  childSessions: ChildSession[];
  creationDate: string;
  medicalDetails = new MedicalDetails();
  immuneDetails = new ImmuneDetails();
  individualDetails = new IndividualDetail();
}


export class ConsentData {
  label: string;
  consentText: string;
  date: Date;
  consents = [new Consents()];
  ip?: string;

  constructor(consentText = null, label = null, consents = [], date = new Date(), ip = null) {
    this.label = label;
    this.consentText = consentText;
    this.date = date;
    this.consents = consents;
    this.ip = ip;
  }
}

export class Consents {
  isAllowed: boolean;
  label: string;

  constructor(isAllowed = false, label = null) {
    this.isAllowed = isAllowed;
    this.label = label;
  }
}

export class IndividualDetail {
  question = [new Question()];
}

export class Question {
  question: string;
  answer?: string;

  constructor(question = null, answer = null) {
    this.question = question,
      this.answer = answer
  }
}

export class ImmuneDetails {
  vaccines = [];
  allergies = [];

  constructor(vaccines = null,
    allergies = null,
  ) {
    this.vaccines = vaccines;
    this.allergies = allergies;
  }
}

export class MedicalDetails {
  doctorDetails = new PersonalDetails();
  healthVisitorDetails = new PersonalDetails();
  hasRedBook: boolean;
  services: basicInfo[];

  constructor(doctorDetails = null,
    healthVisitorDetails = null,
    hasRedBook = null,
    services = [new basicInfo()]
  ) {
    this.doctorDetails = doctorDetails || new PersonalDetails();
    this.healthVisitorDetails = healthVisitorDetails || new PersonalDetails();
    this.hasRedBook = hasRedBook;
    this.services = services;
  }
}

export class PersonalDetails {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  addressForm = new Address();

  constructor(firstName = null, lastName = null, mobileNumber = null, addressForm = null) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.mobileNumber = mobileNumber;
    this.addressForm = addressForm || new Address();
  }
}

export class basicInfo {
  name: string;
  serviceName: string;
  date: string;
  contactInfo: string;

  constructor(name = null, date = null, contactInfo = null, serviceName = null) {
    this.name = name;
    this.serviceName = serviceName;
    this.date = date;
    this.contactInfo = contactInfo;
  }
}
