import { createSelector, createFeatureSelector } from '@ngrx/store';
import {eyfsStateFeatureKey, EyfsState} from '../reducers/eyfs.reducer';

export const selectRegistrationState = createFeatureSelector<EyfsState>(eyfsStateFeatureKey);

export const selectEyfsFromStore = createSelector(selectRegistrationState,
  (eyfsState: EyfsState) => {
    return eyfsState.eyfs;
  }
);
