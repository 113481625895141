import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";

import { FormGroup, FormControl, FormGroupName, FormBuilder, Validators, FormArray } from '@angular/forms';
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from "ng-uikit-pro-standard";
import {ContactUsService} from "../../../services/contact-us.service";
import {ContactUs} from "../../../models/contact-us.model";
import { startWith, map} from 'rxjs/operators';
import {HeaderHideShowService} from "../../../services/header-hide-show.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-toddler-room',
  templateUrl: './toddler-room.component.html',
  //styleUrls: ['./toddler-room.component.scss']
})
export class ToddlerRoomComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  @Input() showHeader : boolean;

  //
  titleSearchText = new Subject();
  titleResults: Observable<string[]>;
  titleData: string[] = [
    'Mr.',
    'Ms.',
    'Mrs.',
    'Miss',
  ];


  ageGroupSearchText = new Subject();
  ageGroupResults: Observable<string[]>;
  ageGroupData: string[] = [
    '0-2 Years',
    '2-3 Years',
    '3-5 Years',
  ];

  contactUsForm: FormGroup;

  map = {
    lat: 51.499136,
    lng: -0.012697,
  };

  submitted = false;

  modalRef: MDBModalRef;

  constructor(private router: Router,
              private contactUsService: ContactUsService,
              private formBuilder: FormBuilder,
              private modalService: MDBModalService,  
              private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {

    this.firstFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
    this.secondFormGroup = new FormGroup({
      email: new FormControl('', Validators.required)
    });
    this.thirdFormGroup = new FormGroup({
      email: new FormControl('', Validators.required)
    });

    // Title Auto complete
    this.titleResults = this.titleSearchText.pipe(
        startWith(''),
        map((value: string) => this.filter(value, this.titleData))
      );
  
      // Age Group Auto complete
      this.ageGroupResults = this.ageGroupSearchText.pipe(
        startWith(''),
        map((value: string) => this.filter(value, this.ageGroupData))
      );

      this.contactUsForm = this.formBuilder.group({
        title: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        ageGroup: ['', Validators.required],
        message: ['', Validators.required],
      });
      
      this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  goBack(stepper: MdbStepperComponent) {
    stepper.previous();
  }

  goForward(stepper: MdbStepperComponent) {
    stepper.next();
  }
  
  get email() { return this.firstFormGroup.get('email'); }
  get password() { return this.secondFormGroup.get('password'); }
  
  filter(value: string, data: string[]): string[] {
    const filterValue = value.toLowerCase();
    return data.filter((item: string) => item.toLowerCase().includes(filterValue));
    }

    saveContactUs(contactUs: ContactUs): Observable<any> {
        let result: Observable<Object>;
        result = this.contactUsService.saveContactUs(contactUs)
        return result;
    }

    // convenience getter for easy access to form fields
    get f() { return this.contactUsForm.controls; }

    onSubmit(formElement) {

    this.submitted = true;
    console.log(formElement.value);
    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
    console.log(" ---- Invalid Form Return ---");
    return;
    }
    // Make sure to create a deep copy of the form-model
    const result: ContactUs = Object.assign({}, this.contactUsForm.value);
    let postResult: Observable<Object>;
    this.contactUsService.saveContactUs(result).subscribe(res => {
    console.log('Issue added!');

    this.headerHideShowService.isHeaderShown(true);
    console.log(" --- onClose --- " + event);
    this.router.navigate(['/']);

    this.openModal();
    });
    }

    openModal() {
    console.log("--- Opening Modal ---");
    //this.modalRef = this.modalService.show(ModalComponent)
    }

    onReset() {
    this.submitted = false;
    this.contactUsForm.reset();
    }

    onOpen(event: any) {
    console.log(event);

    }

}
