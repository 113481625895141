import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppointmentConfig, getScheduleDays } from 'src/app/core/constants/appoinment.config';

import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { ContactUs } from '../../models/contact-us.model';
import { ContactUsService } from '../../services/contact-us.service';
import { initUserContactInfoStore } from '../contact-us/store/actions/user-contact-us.action';
import { selectUserContactFromStore } from '../contact-us/store/selectors/user-contact-us.selector';
import { ContactUsState } from '../registration/store/reducers/contact-us.reducer';
import { InstallationService } from './../../services/installation.service';
import { AppointmentModel } from './models/appointment.model';
import { GroupedAppointment } from './models/grouped-appointment.model';
import { TimeSlotModel } from './models/timeslot.model';

@Component({
  selector: 'app-appointment-calendar',
  templateUrl: './appointment-calendar.component.html',
  styleUrls: ['./appointment-calendar.component.scss'],
})
export class AppointmentCalendarComponent implements OnInit {
  @Input() appointments: AppointmentModel[];
  @Input() premiumAppointmentCost: number;
  @Input() appointmentsCost: number;
  @Input() premiumAppointmentTimeRange = '8am - 10am';
  @Input() standardEarlyAppointmenTimeRange = '8am - 1pm';
  @Input() standardLateAppointmenTimeRange = '1pm - 6pm';
  @Output() selected: EventEmitter<AppointmentModel> = new EventEmitter();
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  public selectedAppointment = new AppointmentModel();
  public groupedAppointments: GroupedAppointment[] = [];
  public dateHeading: string;
  public selectedDateString: string;
  public selectedTimeSlot: string;
  public optionSelected: boolean[];
  public premiumAppointmentsIncluded: boolean;
  public appointmentDates: string[] = [];
  public config = AppointmentConfig;
  myDatePickerOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    dateFormat: 'mmm dd, yyyy',
    disableWeekends: true,
    showClearDateBtn: false,
    disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() - 1 }, // Disable dates backward starting from the given date.
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 2, day: new Date().getDate() }, // Disable dates forward starting from the given date.
  };


  isNextAllowed = true;
  chooseManual = false;
  isPreviousAllowed: boolean;
  contactUsShowAroundDetails: string[];
  modalText: string;
  isBookingDisabled: boolean;
  timer;
  public contactUsForm: FormGroup;
  hasBooked: boolean;
  @Output() bookedEmit = new EventEmitter();
  isModalShown: boolean;

  constructor(private installationService: InstallationService,
    private store: Store<ContactUsState>,
    private contactUsService: ContactUsService,
    private toaster: ToastrService,
    private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.setupAvailiblity();
    this.isBookingDisabled = !this.localStorageService.getContactUs();
    this.store.pipe(select(selectUserContactFromStore)).subscribe((response) => {
      this.isBookingDisabled = !response.userContactUs.email;
      this.updateSlotBooking();
    });
  }

  updateSlotBooking() {
    this.appointments.forEach(item => {
      if (this.localStorageService?.getContactUs()?.showaroundDateTime) {
        const showAroundTime = this.localStorageService.getContactUs().showaroundDateTime
        if (item.id === showAroundTime.substr(0, showAroundTime.lastIndexOf(':'))) {
          item.timeSlot.booked = true;
        } else {
          item.timeSlot.booked = false;
        }
      }
    })
  }

  setupAvailiblity() {
    this.getAvailability();
    this.setupCalendar();
    this.isBookingDisabled = !this.localStorageService.getContactUs();
  }

  getAvailability(forceRefresh?: boolean): void {
    if (!this.contactUsShowAroundDetails?.length || forceRefresh) {
      this.contactUsService.getShowAroundDates().subscribe(data => {
        if (data) {
          this.contactUsShowAroundDetails = JSON.parse(data);
          this.setUnAvailability();
        }
      }, (err) => {
        console.log(JSON.parse(err.error).message);
      });
    } else {
      this.setUnAvailability();
    }
  }

  setUnAvailability(): void {
    this.appointments.forEach(item => {
      item.timeSlot.available = true;
      item.timeSlot.booked = false;
    });
    for (let i = 0; i < this.appointments.length; i++) {
      const dow = new Date(this.appointments[i].appointmentDate).toString().split(' ')[0];
      this.config.excludeDays.map(data => {
        if (data === dow) {
          this.appointments[i].timeSlot.available = false;
        }
      });
      for (let j = 0; j < this.contactUsShowAroundDetails.length; j++) {
        if (this.appointments[i].id === this.contactUsShowAroundDetails[j].substr(0, this.contactUsShowAroundDetails[j].lastIndexOf(':'))) {
          this.appointments[i].timeSlot.available = false;
          break;
        }
      }
      if (this.getDifference(this.appointments[i].id) <= this.config.slotAvailability) {
        this.appointments[i].timeSlot.available = false;
      }
      if (this.localStorageService?.getContactUs()?.showaroundDateTime) {
        const showAroundTime = this.localStorageService.getContactUs().showaroundDateTime
        if (this.appointments[i].id === showAroundTime.substr(0, showAroundTime.lastIndexOf(':'))) {
          this.appointments[i].timeSlot.booked = true;
          break;
        }
      }
    }
  }

  getDifference(date): any {
    return ((<any>new Date(date) - <any>new Date()) / (1000 * 60 * 60)).toFixed(1) as any;
  }

  setupCalendar(): void {
    if (this.appointments?.length) {
      this.getAppointmentDates();
      this.checkPremiumAppointment();
      this.groupAppointments();
      this.getMonthHeading();
      this.clearSelectedAppointments();
    }
  }

  calendarMoveAllowed(value: boolean): void {
    this.installationService.toggleMinDays(value);
    this.isNextAllowed = this.config.maxDays > this.config.minDays;
    this.isPreviousAllowed = this.config.minDays >= 0;
    if ((value === true && this.isNextAllowed)
      || value === false && this.isPreviousAllowed) {
      this.changeCalendar();
    }
  }

  changeCalendar(): void {
    this.appointmentDates = [];
    this.groupedAppointments = [];
    this.appointments = this.installationService.getAppointmentCalendar();
    this.setUnAvailability();
    this.setupCalendar();
  }

  public groupAppointments() {
    let index = 0;
    this.appointmentDates.forEach((date) => {
      const appointmentsPerDay: AppointmentModel[] = this.appointments.filter((record) => {
        return record.appointmentDate === date;
      });

      const dailyAppointments = appointmentsPerDay.map((appointment) => {
        const timeRange = this.getTimeSlotTimeRange(appointment.timeSlot);
        appointment.index = index;
        index++;
        appointment.timeSlot.cost =
          this.premiumAppointmentsIncluded && timeRange === this.premiumAppointmentTimeRange
            ? this.premiumAppointmentCost
            : this.appointmentsCost;
        appointment.timeSlot.timeRange = timeRange;
        return appointment;
      });

      // ADD Missing records as unavailable
      if (this.premiumAppointmentsIncluded) {
        const premiumAppointment = dailyAppointments.find((record) => {
          return this.getTimeSlotTimeRange(record.timeSlot) === this.premiumAppointmentTimeRange;
        });

        if (!premiumAppointment) {
          dailyAppointments.unshift({
            appointmentDate: date,
            index: -1,
            timeSlot: {
              timeRange: this.premiumAppointmentTimeRange,
              available: false,
            },
          } as AppointmentModel);
        }
      }

      const dow = new Date(date).toString().split(' ')[0];
      this.groupedAppointments.push({
        date,
        dayOfWeek: new Date(date).toString().split(' ')[0],
        day: this.ordinal(new Date(date).getDate()),
        appointments: dailyAppointments,
      });
    });
  }

  slotSelected(slot) {
    console.log('Please fill contact us first, then you can book a show-around');
    if (this.getDifference(slot.id) <= this.config.slotAvailability) {
      slot.timeSlot.available = false;
      return;
    }
    this.selectedAppointment = slot;
    this.clearSelectedAppointments();
    this.optionSelected[slot.index] = true;
    this.setupSlot();
    this.selected.emit(this.selectedAppointment);
  }

  setupSlot(is24hr?: boolean) {
    this.selectedDateString = `${this.ordinal(new Date(this.selectedAppointment.appointmentDate).getDate())} ${new Date(
      this.selectedAppointment.appointmentDate
    ).toLocaleString('default', { month: 'long' })} ${new Date(
      this.selectedAppointment.appointmentDate
    ).getFullYear()}`;
    if (!is24hr) {
      this.selectedTimeSlot = `${this.selectedAppointment.timeSlot.startTime} - ${this.selectedAppointment.timeSlot.endTime}`;
    } else {
      this.selectedTimeSlot = `${this.convertTimeToAmPm(
        this.selectedAppointment.timeSlot.startTime
      )} - ${this.convertTimeToAmPm(this.selectedAppointment.timeSlot.endTime)}`;
    }
  }

  updateSlot(slot): void {
    this.selectedAppointment = slot;
    if (this.selectedAppointment.timeSlot.startTime && this.selectedAppointment.appointmentDate) {
      const startTime = this.getMinutesFromHours(this.selectedAppointment.timeSlot.startTime);
      const endTime = startTime + 30;
      this.selectedAppointment.timeSlot.endTime = this.convertMinsToHrsMins(endTime);
      this.setupSlot(false);
    }
  }

  getMinutesFromHours(timeInHour: string): number {
    const timeParts = timeInHour.split(':');
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }

  convertMinsToHrsMins = (mins) => {
    let hours = Math.floor(mins / 60);
    let min = mins % 60;
    hours = (hours < 10 ? '0' + hours : hours) as any;
    min = (min < 10 ? '0' + min : min) as any;
    return `${hours}:${min}`;
  }

  clearSelectedAppointment(): void {
    this.selectedAppointment = new AppointmentModel();
    this.selectedDateString = null;
    this.selectedTimeSlot = null;
  }

  clearSelectedAppointments() {
    this.optionSelected = this.appointments.map(() => false);
  }

  ordinal(srcNumber) {
    const englishOrdinalRules = new Intl.PluralRules('en', {
      type: 'ordinal',
    });
    const suffixes = {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th',
    };
    const suffix = suffixes[englishOrdinalRules.select(srcNumber)];
    return srcNumber + suffix;
  }

  convertTimeToAmPm(time: string) {
    const hour = (time.substring(0, 2) as unknown) as number;
    const minute = (time.substring(2, 5) as unknown) as number;
    const suffix = hour >= 12 ? 'pm' : 'am';
    const hours = (hour % 12) + minute + suffix;
    return hours;
  }

  getMonthHeading() {
    const maxDate = new Date(
      Math.max.apply(
        null,
        this.appointmentDates.map((date) => new Date(date))
      )
    );
    const minDate = new Date(
      Math.min.apply(
        null,
        this.appointmentDates.map((date) => new Date(date))
      )
    );

    const maxDateString = `${maxDate.toLocaleString('default', { month: 'long' })} ${maxDate.getFullYear()}`;
    const minDateString = `${minDate.toLocaleString('default', { month: 'long' })} ${minDate.getFullYear()}`;
    if (maxDateString === minDateString) {
      this.dateHeading = maxDateString;
    } else {
      this.dateHeading = `${minDateString} - ${maxDateString}`;
    }
  }

  getAppointmentDates() {
    const dates = [];
    this.appointments.forEach((appointment) => {
      const existingDate = this.appointmentDates.find((record) => {
        return record === appointment.appointmentDate;
      });
      if (!existingDate) {
        dates.push(appointment.appointmentDate);
      }
    });

    const maxDate = new Date(
      Math.max.apply(
        null,
        dates.map((date) => new Date(date))
      )
    );
    const minDate = new Date(
      Math.min.apply(
        null,
        dates.map((date) => new Date(date))
      )
    );
    const dt = minDate;
    while (minDate <= maxDate) {
      this.appointmentDates.push(this.formatDate(new Date(dt)));
      dt.setDate(dt.getDate() + 1);
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  getTimeSlotTimeRange(timeSlot: TimeSlotModel) {
    return `${timeSlot.startTime} - ${timeSlot.endTime}`;
  }

  checkPremiumAppointment() {
    this.appointmentDates.forEach((date) => {
      const appointmentRecord = this.appointments.find((record) => {
        const timeRange = this.getTimeSlotTimeRange(record.timeSlot);
        return timeRange === this.premiumAppointmentTimeRange;
      });
      if (appointmentRecord) {
        this.premiumAppointmentsIncluded = true;
      }
    });
  }

  onSubmit() {
    const contactUs = this.localStorageService.getContactUs();
    contactUs.comment = 'Booked from appointment calendar';
    contactUs.status = 'Show';

    let modalMessage = 'Thank you, we have updated your show around date and time to ' + `${this.selectedAppointment.appointmentDate} ${this.selectedAppointment.timeSlot.startTime}`;
    if (this.chooseManual) {
      modalMessage = modalMessage + 'We will try to accommodate you.';
    }
    const contactUsHistory = this.contactUsService.createContactUsHistory();
    contactUs.status = 'Show';
    contactUs.contactUsHistoryList.push(contactUsHistory);
    this.bookShowAround(contactUs, modalMessage);
  }

  private bookShowAround(contactUs: ContactUs, modalMessage: string) {
    contactUs.showaroundDateTime = `${this.selectedAppointment.appointmentDate} ${this.selectedAppointment.timeSlot.startTime}:00`;
    const contactUsData = this.contactUsService.saveShowAround(contactUs);
    contactUsData.subscribe(data => {
      this.contactUsShowAroundDetails.push(contactUs.showaroundDateTime);
      this.getAvailability(true);
      this.setUnAvailability();
      this.localStorageService.saveContactUs(contactUs);
      this.clearSelectedAppointments();
      this.modalText = modalMessage;
      this.showModal();
      this.store.dispatch(initUserContactInfoStore({ contactInfo: contactUs }));
    });
  }

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal?.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }


  @HostListener('window:resize', [])
  onResize() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.config = AppointmentConfig;
      this.config['showScheduleDays'] = getScheduleDays();
      this.groupedAppointments = [];
      this.appointments = [];
      this.setupAvailiblity();
      this.changeCalendar();
    }, 250);
  }

  checkIfDisabled(e): void {
    if (this.isBookingDisabled) {
      e.preventDefault();
      const modalText = 'Please fill contact us first, then you can book a show-around';
      this.toaster.warning(modalText)
    }
  }
}
