import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from '../../../../services/header-hide-show.service';

@Component({
  selector: 'app-garden-gallery',
  templateUrl: './garden-gallery.component.html',
  // styleUrls: ['./garden-gallery.component.scss'],
  host: {'class': 'w-100'}
})
export class GardenGalleryComponent implements OnInit {

  gardenGallery = [
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_1.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_1.jpg', description: 'Image 15'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_2.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_2.jpg', description: 'Image 16'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_3.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_3.jpg', description: 'Image 17'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_4.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_4.jpg', description: 'Image 18'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_6.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_6.jpg', description: 'Image 19'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_7.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_7.jpg', description: 'Image 20'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_8.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_8.jpg', description: 'Image 21'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_9.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_9.jpg', description: 'Image 22'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_10.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_10.jpg', description: 'Image 23'
    },
    {
      img: '../../../../assets/img/nursery/home/gallery/garden_11.jpg',
      thumb: '../../../../assets/img/nursery/home/gallery/garden_11.jpg', description: 'Image 24'
    }
  ];

  @Input() showHeader: boolean;

  constructor(private headerHideShowService: HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
