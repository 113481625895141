import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoaderService {

  private static loaderEnabled: boolean;
  constructor() { }

  get loaderEnabled() {
    return LoaderService.loaderEnabled;
  }

  public static showLoader() {
    LoaderService.loaderEnabled = true;
  }

  public static hideLoader() {
    LoaderService.loaderEnabled = false;
  }
}



export function LoaderEnabled(): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {

    const original = descriptor.value;
    descriptor.value = function () {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        LoaderService.showLoader();
      }, 200);
      return original.apply(this, arguments)
        .pipe(
          map((res) => {
            clearTimeout(this.timer);
            setTimeout(() => {
              LoaderService.hideLoader();
            }, 200);
            return res;
          }),
          catchError((err) => {
            clearTimeout(this.timer);
            setTimeout(() => {
              LoaderService.hideLoader();
            }, 200);
            throw err;
          })
        );
    };
    return descriptor;
  };

}
