<form [formGroup]="form" class="question-1-wrapper" (ngSubmit)="submitForm()">
  <h3 class="main-heading">
    Create your credentials...
  </h3>
  <div class="badges">
    <!-- First Row-->
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form  mt-3 mb-3">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-email" name="email" mdbInput type="text" id="email" formControlName="email"
            class="form-control" mdbValidate>
          <label for="email">Email</label>
          <mdb-error *ngIf="form?.get('email')?.touched && form?.get('email')?.errors">
            Please provide your email
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- First Col-->
      <div class="col-md-12 col-sm-12">
        <div class="md-form mt-3 mb-3">
          <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
          <input autocomplete="new-password" name="password" mdbInput type="password" id="password" formControlName="password"
            class="form-control" mdbValidate>
          <label for="password">password</label>
          <mdb-error *ngIf="form?.get('password')?.touched && form?.get('password')?.errors">
            Please provide your password
          </mdb-error>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <button mdbBtn type="button" (click)="back()" class="btn btn-link waves-light" mdbWavesEffect>
      Go Back
    </button>
    <button mdbBtn type="submit" [class.disabled]="form.invalid" class="rs-btn-info btn btn-rounded waves-light" mdbWavesEffect>
      SAVE AND NEXT
    </button>
  </div>
</form>
