import { LayoutModule } from './../layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonsModule, MDBBootstrapModulesPro, TableModule, WavesModule } from 'ng-uikit-pro-standard';

import { CoreModule } from '../../core/core.module';
import { NewsletterModule } from '../nursery/newsletter/newsletter.module';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { LoginRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';

@NgModule({
  declarations: [
    LoginComponent,
    AuthComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    FormsModule,
    HttpClientModule,
    MDBBootstrapModulesPro,
    WavesModule,
    TableModule,
    ButtonsModule,
    CoreModule,
  ],
  providers: [authInterceptorProviders]
})

export class LoginModule { }
