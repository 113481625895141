import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CommonConfig } from 'src/app/core/constants/common.constants';
import { AutoCompleteKey } from 'src/app/core/enums/autocomplete.enum';
import { RelationShipObject } from 'src/app/modules/nursery/models/child.model';

import { CommonService } from './../../../core/services/common.service';

@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
})
export class ParentsComponent implements OnInit {

  @Output() parentChange: EventEmitter<any> = new EventEmitter();
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormName: string;
  @Input() parentData: RelationShipObject;
  @Input() uniqueIndex: string;

  autoCompleteKeys = AutoCompleteKey;
  titleResults: Observable<string[]>;
  relationshipToChildResults: Observable<string[]>;
  parentalResponsibilityResults: Observable<string[]>;
  ableToCollectChildResults: Observable<string[]>;

  titleSearchText = new Subject();
  relationshipToChildSearchText = new Subject();
  parentalResponsibilitySearchText = new Subject();
  ableToCollectChildSearchText = new Subject();
  destroy$ = new Subject();

  titleData: string[] = CommonConfig.titles;
  relationshipToChildData: string[] = CommonConfig.relationshipToChildData;
  parentalResponsibilityData: string[] = CommonConfig.yesNoData;
  ableToCollectChildData: string[] = CommonConfig.yesNoData;

  constructor(private formBuilder: FormBuilder, private commonService: CommonService) { }

  ngOnInit(): void {
    this.initForm();
    this.formChanges();
    this.titleSearch();
    this.ableToCollectChildSearch();
    this.parentalResponsibilitySearch();
    this.relationshipToChildSearch();
  }

  initForm(): void {
    this.parentFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      parentalResponsibility: ['', Validators.required],
      relationshipToChild: ['', Validators.required],
      niNumber: [''],
      ableToCollectChild: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', Validators.compose([Validators.required])],
      landlineNumber: ['',Validators.compose([Validators.required])],
      placeOfWork: [''],
      jobTitle: [''],
      department: [''],
      officePhoneNumber: ['', Validators.compose([Validators.required])],
    });
    this.parentChange.emit(this.parentFormGroup);
  }

  ngOnChanges(): void {
    if(this.parentData) {
      this.parentFormGroup.patchValue(this.parentData);
      this.parentFormGroup.patchValue({
        parentalResponsibility: this.parentData.parentalResponsibility ? 'Yes' : 'No',
        ableToCollectChild: this.parentData.ableToCollectChild ? 'Yes' : 'No',
      })
    }
  }

  verifyChanges(event, key: AutoCompleteKey) {
    const isValid = this.commonService.isValidValue(event.target.value, key);
    if (!isValid) {
      key === AutoCompleteKey.Title ? this.parentFormGroup.patchValue({ title: null }) : '';
      key === AutoCompleteKey.RelationShip ? this.parentFormGroup.patchValue({ relationshipToChild: null }) : '';
      key === AutoCompleteKey.Responsibility ? this.parentFormGroup.patchValue({ parentalResponsibility: null }) : '';
      key === AutoCompleteKey.Able_To_Collect ? this.parentFormGroup.patchValue({ ableToCollectChild: null }) : '';
    }
  }

  ableToCollectChildSearch(): void {
    this.ableToCollectChildResults = this.commonService.ableToCollectChildSearch()
      .pipe(takeUntil(this.destroy$));
  }

  setNumberValue(value: string, controlName: string): void {
    const obj = {};
    obj[controlName] = value;
    this.parentFormGroup.patchValue(obj);
  }

  parentalResponsibilitySearch(): void {
    this.parentalResponsibilityResults = this.commonService.parentalResponsibilitySearch()
      .pipe(takeUntil(this.destroy$));
  }

  relationshipToChildSearch(): void {
    this.relationshipToChildResults = this.commonService.relationshipToChildSearch()
      .pipe(takeUntil(this.destroy$));
  }

  titleSearch(): void {
    this.titleResults = this.commonService.titleSearch()
      .pipe(takeUntil(this.destroy$));
  }

  formChanges(): void {
    this.parentFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(() => {
        this.parentChange.emit(this.parentFormGroup);
      });
  }

  get formGetterSetter() {
    return this.parentFormGroup.controls;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
