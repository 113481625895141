import { Component, OnInit } from '@angular/core';
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-pre-school-room-admission',
  templateUrl: './pre-school-room-admission.component.html',
  //styleUrls: ['./pre-school-room-admission.component.scss']
})
export class PreSchoolRoomAdmissionComponent implements OnInit {
  
  modalRef: MDBModalRef;

  constructor() { }

  ngOnInit() {
  }

  openModal() {
    console.log('--- Opening Modal ---');
    // this.modalRef = this.modalService.show(ModalComponent)
  }

}