<section id="best-features" class="text-center mt-5">

  <!-- Heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown">Nursery App & Policies</h1>

  <!--Grid row-->
  <div class="d-flex justify-content-center pb-4">

    <!--Grid column-->
    <div class="col-lg-10 col-sm-10">

      <!-- Description -->
      <p class="mt-3 wow section-text slideInUp">Welcome to Rise 'N' Shine Nursery!
        An exceptional Nursery and Pre-School in Isle of dogs, London, providing high quality childcare to
        Isle of dogs and the surrounding area.</p>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->
  <div class="row mr-0 ml-0">

    <div class="col-lg-4 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">
  
        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/icons/nursery-app-icon.png"
            alt="Card image cap">
          <a  [routerLink]="['nursery-software']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
  
        <!-- Card content -->
        <div class="card-body card-body-cascade">
  
          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Nursery App</h4>
          <!-- Text -->
          <p class="card-text section-text">In today’s generation, technology plays a very vital role and for us this is equally essential. Moreover, during these current Pandemic times no nurseries allow parents to enter. So the big question is how will the parents know what their kids are learning?</p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['nursery-software']" mdbWavesEffect>Read More</a>
  
        </div>
  
      </div>
      <!-- Card -->
    </div>

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/icons/nursery-policies.png"
              alt="Card image cap">
            <a [routerLink]="['policies']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Nursery Policies</h4>
            <!-- Text -->
            <p class="card-text section-text">We work hard as a team with full commitment and dedication to ensure the development of children at our Nursery. Join us to be part of our team, Kindly drop your resume with some details we will be in touch with you soon..</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['policies']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

    <div class="col-lg-4 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/icons/newsletter-icon.png"
            alt="Card image cap">
          <a [routerLink]="['newsletter-archives']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Newsletter Archives</h4>
          <!-- Text -->
          <p class="card-text section-text">Here you will find all our weekly newsletter so you can stay up-to-date with news and announcements from Rainbow Splash Nursery. For latest update get connected to our newsletter section or you can contact us</p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['newsletter-archives']" mdbWavesEffect>Read More</a>

        </div>

      </div>
      <!-- Card -->
  </div>

  </div>

  <!--Grid row-->

</section>
