<app-parents [parentData]="realtionData" (parentChange)="updateParentsForm($event, 'parentForm')" [uniqueIndex]="1">
</app-parents>
<div>
  <div id="address-info">
    <div class="d-flex align-items-center">
      <h3 class="font-weight-bold mt-4">Office Address</h3>
      <div  *ngIf="allAddresses?.length" class="btn-group mt-4 ml-4" dropdown>
        <a dropdownToggle class="d-flex align-items-center justify-content-center">
          <img src="../../../../assets/img/form-icons/paste.png">
          <span class="ml-2">Auto Fill Address</span>
        </a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem"><a (click)="patchAddress(item, 'officeAddressForm')"  class="dropdown-item" *ngFor="let item of allAddresses">
              {{realtionType[item.relationType]}}'s - {{item.addressType === 1 ? 'Home Address' : 'Office Address'}}
            </a></li>
        </ul>
      </div>
    </div>
    <hr class="mt-1 mb-4 ml-0 mr-0 rounded border-line">

  </div>
  <app-address [isRequired]="false" [addressData]="realtionData?.officeAddress"
    (addressChange)="updateParentsForm($event, 'officeAddressForm' )" [headerName]="'Office Address'" [uniqueIndex]="2">
  </app-address>
</div>
<div>
  <div id="address-info">
    <div class="d-flex align-items-center">
      <h3 class="font-weight-bold mt-4">Home Address</h3>
      <div *ngIf="allAddresses?.length" class="btn-group mt-4 ml-4" dropdown>
        <a dropdownToggle class="d-flex align-items-center justify-content-center">
          <img src="../../../../assets/img/form-icons/paste.png">
          <span class="ml-2">Auto Fill Address</span>
        </a>
        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
          <li role="menuitem"><a (click)="patchAddress(item, 'homeAddressForm')"  class="dropdown-item" *ngFor="let item of allAddresses">
              {{realtionType[item.relationType]}}'s - {{item.addressType === 1 ? 'Home Address' : 'Office Address'}}
            </a></li>
        </ul>
      </div>
    </div>
    <hr class="mt-1 mb-4 ml-0 mr-0 rounded border-line">
  </div>
  <app-address [isRequired]="true" [addressData]="realtionData?.homeAddress"
    (addressChange)="updateParentsForm($event, 'homeAddressForm')" [headerName]="'Home Address'" [uniqueIndex]="3">
  </app-address>
</div>