<section id="booking-nnay" *ngIf="!showInvoice" class="section-wrapper">
  <div>
    <h2 class="text-center mb-0">
      <span class="d-block p-2 bg-warning text-white">
        {{showInvoice ? 'Invoice Summary - Review' : 'When do you need help?'}}
      </span>
    </h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-lg-6">
        <main>
          <div class="component-wrapper">
            <app-booking-form (emitSuccess)="showInvoice = true"></app-booking-form>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
<section *ngIf="showInvoice" id="booking-nnay" class="section-wrapper">
  <div>
    <h2 class="text-center mb-0">
      <span class="d-block p-2 bg-warning text-white">
        {{showInvoice ? 'Invoice Summary - Review' : 'When do you need help?'}}
      </span>
    </h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-lg-12">
        <main>
          <div class="component-wrapper">
            <div class="row">
              <div class="col-lg-12">
                <app-invoice (emitGoBack)="showInvoice = false"></app-invoice>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</section>
