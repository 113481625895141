<div class="auto-complete d-flex">
  <div class="col-md-12 col-lg-4 col-sm-12 no-gutters p-0">
    <div class="md-form">
      <mdb-select-2 [multiple]="true" placeholder="Choose your option" [label]="customLabel"
        [formControl]="selectControl">
        <mdb-select-option *ngFor="let option of options" [value]="option">{{ option }}</mdb-select-option>
      </mdb-select-2>
      <mdb-error *ngIf="submitted && selectControl.errors">
        Please select the {{customLabel}}
      </mdb-error>
    </div>
    <div class="chips-wrapper pt-2 pb-2">
      <span *ngFor="let option of selectedOptions" class="m-2 badge badge-pill badge-default">{{option}}
        <mdb-icon fas icon="times" (click)="removeItem(option)"></mdb-icon>
      </span>
    </div>
  </div>
  <div *ngIf="showMore" class="col-md-4 col-sm-12 p-0">
    <div class="md-form position-relative">
      <label>Add Other {{customLabel}}</label>
      <input mdbInput type="text" [(ngModel)]="newOption" class="form-control">
      <a class="save" (click)="pushNewOption()"> Save</a>
    </div>
  </div>
</div>
