import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(10%)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(10%)', opacity: 0 }))
      ])
    ]
    )
  ],
})

export class QuestionCardComponent {
  @Input() question;
  @Input() currentLength: number;
  @Input() totalCount: number;
  @Output() saveEmit = new EventEmitter();
  @Output() deleteEmit = new EventEmitter();

  remove(question: any): void {
    this.deleteEmit.emit(question);
  }

  save(question): void {
    this.saveEmit.emit(question);
  }
}
