import { AdminMainLayoutComponent } from './admin-main-layout/admin-main-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout.routing.module'
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SnowFallComponent } from './animation/snow-fall/snow-fall.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {BreadcrumbModule} from 'angular-crumbs';
import { FooterComponent } from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {MDBBootstrapModule} from 'ng-uikit-pro-standard';
import { MenuBarComponent } from '../layout/header/components/menu-bar/menu-bar.component';
import { MegaMenuBarComponent } from '../layout/header/components/mega-menu-bar/mega-menu-bar.component';
import { ModalModule } from 'ngx-bootstrap/modal'
@NgModule({
  imports: [
    CommonModule,
    BreadcrumbModule,
    RouterModule,
    MDBBootstrapModule,
    ModalModule.forRoot(),
  ],
  exports: [
    MainLayoutComponent,
    AdminMainLayoutComponent,
  ],
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SnowFallComponent,
    MenuBarComponent,
    MegaMenuBarComponent,
    AdminMainLayoutComponent,
  ]
})

export class LayoutModule { }
