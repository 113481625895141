
<!-- Nursery Policies Section -->
<section id="covid-faqs">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">COVID-19: FAQ'S</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">THANK YOU TO EVERYONE WHO HAS SHARED THEIR QUESTIONS AND CONCERNS WITH US: IT IS ALWAYS IMPORTANT FOR US TO UNDERSTAND HOW YOU ARE FEELING AND WHAT YOU WOULD LIKE TO KNOW.  WE HAVE SET OUT THE FREQUENTLY ASKED QUESTIONS AND ANSWERS BELOW:  WE WILL CONTINUE TO ADD TO THIS LIST SO DO CHECK IN REGULARLY.
        </p>
    </div>
  </div>
  <div class="section-wrapper">
     <!--Panel 1-->
     <div class="row">
      <div class="col-12">
          <mdb-accordion [multiple]="false">

            <!-- Baby Registration -->
            <mdb-accordion-item [collapsed]="false" [customClass]="'border-0'">
              <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="snowflake"></mdb-icon> COVID-19: FREQUENTLY ASKED QUESTIONS</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="row">
                    <!-- Grid column -->
                    <div class="col-lg-12">
                      <div class="faqs-list">
                        <div class="question-wrap">
                          <h4>Q. What is your overall approach to operating nurseries during the pandemic?</h4>
                          <p>Rise 'N' Shine key priority at all times is the health, safety and wellbeing of the children in our care, their families and our staff.  In specific regard to the current pandemic, we are operating to our own comprehensive and regularly updated COVID-19 polices regarding infection control, and we will also continue to introduce additional measures in line with the latest guidance from the NHS, Public Health Bodies, and government. Please <a href="#" title="Policies & Procedures">click here</a> to see the policy and procedures.</p>
                          <p>During the period of lockdown when the majority of nurseries were closed, Rise 'N' Shine continued to operate a number of hub nurseries, several on hospital sites, in order to provide ongoing care for vulnerable children and those whose parents/carers are key workers.  The practices which were developed to minimise risk and keep everyone safe in these circumstances have proved highly effective, and have formed the foundation for our protocols at all nurseries as we begin to reopen more widely.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. What safety measures will be in place for my child’s return to nursery?</h4>
                          <p>In line with government and NHS recommendations we have put many additional measures in place, which include those relating to nursery access restrictions, social distancing, and hygiene practices.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. How will you be managing social distancing requirements?</h4>
                          <p>At drop off and pick up times we are asking parents to hand their child to a member of staff at the nursery entrance and remain a 2 metre distance from other parents. We are putting the same measures in place at the end of the day when parents arrive to collect their child. Where we are able to, we will be adding markers to help with this.</p>
                          <p>Children will be cared for in consistent groups of children and staff. We have made changes to our indoor environments and the organisation of activities so as to encourage children to naturally use all areas of the room, rather than congregate in one area. Outdoor play will be limited to one group at a time. We have also looked at our staff areas and provided guidance to our staff on how to implement social distancing in the nursery.  We recognise that social distancing for our nurseries represents a challenge for young children who like close contact with adults and other children and we want to reassure you that we won’t stop hugging children when they need a cuddle or some reassurance.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. How are you managing access to the nursery?</h4>
                          <p>No staff member who has COVID-19 symptoms, (or who has a member of their family displaying symptoms) will be admitted into the nursery until they have either completed self-isolation in line with government guidance or have had a test and tested negative.  Parents will be asked to confirm at the nursery entrance that neither their child nor anybody in their household is displaying any COVID-19 symptoms such as a new continuous cough or a high temperature of 37.8°C or more.</p>
                          <p>Non-essential visitors are not permitted at the nursery.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. Will staff be wearing PPE such as masks?</h4>
                          <p>Staff will wear PPE when very close contact is required such as at drop of and pick up times, nappy changing and also if a child becomes unwell during the day and needs to go into the Isolation room. Staff can wear PPE throughout the day if they wish to.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. Are staff being tested?</h4>
                          <p>In line with the current government guidelines staff do not need to be routinely tested at this stage.  Staff and children can be tested if they display symptoms.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. What additional cleaning and hygiene processes are you putting in place?</h4>
                          <p>Each nursery has a dedicated member of the team responsible for regularly cleaning and disinfecting surfaces and objects that are frequently touched by children and adults. These include high-risk contact areas such as door handles and doors, toys and children’s resources, phones, keyboards, light switches, taps, toilet flushes, sinks, countertops, handrails and bannisters, shared PCs, including children’s computers/iPads. We have removed activities which could pose a risk for cross contamination, such as play dough and sand/water trays. As well as ensuring that staff and children are cleaning hands more often than usual, we are  ensuring good respiratory hygiene by promoting the ‘catch it, bin it, kill it’ approach to any coughs or sneezes. Children will be supported to ensure they wash their hands properly.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. What happens if a child or staff member becomes ill during the nursery day?</h4>
                          <p>If children or staff develop any symptoms such as a new continuous cough or a high temperature of 37.8°C we will take immediate action. Staff will be asked to return home immediately, self-isolate and seek a test. For children we have a dedicated Isolation room where they will be taken and cared for by a member staff who is known to them until a parent can come and collect them.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. How will you support children’s understanding of the new measures and practices?</h4>
                          <p>In all of our nurseries we are using existing and newly developed additional resources related to our safety Superhero Candy Floss to help children understand the ways in which they can keep themselves safe.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. Will my child’s key person remain the same?</h4>
                          <p>We will endeavour where possible not to change a child's key person: however, due to the need to reduce group sizes we do anticipate that for some children this could mean a key person change. If this is necessary we will communicate with families before any changes are made.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. How will you settle child in back to nursery routine?</h4>
                          <p>We are currently updating our settling in procedures in line with the Government's COVID-19 guidance and we will share these with parents soon. We will always ensure we take each individual child’s needs into account when settling into nursery.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. What if my child is poorly (not COVID-19 related) do they have to stay away?</h4>
                          <p>We ask parents to retain their usual vigilance around not sending their child to nursery if unwell, unless it is a very minor illness.  Please call your nursery manager if in any doubt.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. Will the nursery retain its usual opening times?</h4>
                          <p>The nursery will operate as closely as possible to its usual schedule, but we ask for flexibility around drop off and pick up in order to comply with social distancing. Your nursery manager will explain this in more detail as required.</p>
                        </div>
                        <div class="question-wrap">
                          <h4>Q. I have some anxiety about returning my child to nursery and would like to understand more – who should I speak with?</h4>
                          <p>Your nursery manager will be able to give you specific information relating to your nursery and offer you reassurance.</p>
                        </div>
                      </div>
                    </div>
                    <!-- Grid column -->
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
            
          </mdb-accordion>
      </div>
    </div>   
  </div>
</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
