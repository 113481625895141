<div id="home-section" class="home-section">

  <!--Section: Best Features-->
  <app-our-feature></app-our-feature>
  <!--Section: Best Features-->

  <hr class="my-1 rounded border-line mb-2">

  <!--Section: Ratings & Gallery -->
  <app-ratings-gallery></app-ratings-gallery>
  <!--Section: Ratings & Gallery -->

  <hr class="my-1 rounded border-line mb-2">

  <!--Section: Learning -->
  <app-learning-section></app-learning-section>
  <!--Section: Learning -->

  <hr class="my-1 rounded border-line mb-2">

  <!--Section: Admission, Fee -->
  <app-admission-fee-section></app-admission-fee-section>
  <!--Section: Admission, Fee -->

  <hr class="my-1 rounded border-line mb-2">

  <!--Section: Nursery App & Policies -->
  <app-nursery-app-policies-section></app-nursery-app-policies-section>
  <!--Section: Nursery App & Policies -->

  <!--Section: Google Reviews-->
  <!-- <app-reviews></app-reviews> -->
  <!--Section: Google Reviews-->

  <hr class="my-1 rounded border-line mb-2">

  <!-- Contact Us Section -->
  <app-contact-us [headerShow]="true"></app-contact-us>
  <!-- End Contact Us Section -->

</div>
