<section id="best-features" class="text-center mt-5">

  <!-- Heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown">Admission, Fee & Funding</h1>

  <!--Grid row-->
  <div class="d-flex justify-content-center pb-4">

    <!--Grid column-->
    <div class="col-lg-10 col-sm-10">

      <!-- Description -->
      <p class="mt-3 wow section-text slideInUp">Welcome to Rise 'N' Shine Nursery!
        An exceptional Nursery and Pre-School in Isle of dogs, London, providing high quality childcare to
        Isle of dogs and the surrounding area.</p>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->
  <div class="row mr-0 ml-0">

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/icons/admission-icon.png"
              alt="Card image cap">
            <a [routerLink]="['admission']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Admission</h4>
            <!-- Text -->
            <p class="card-text section-text">Show Around - After booking an appointment at Rise 'N' Shine nursery, parents would be invited for initial viewing where you would be allowed to experience our light and spacious learning areas. One of our staff will assist you throughout the visit and answer any questions..</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['admission']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/icons/fee-icon.png"
              alt="Card image cap">
            <a [routerLink]="['fee']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Fee</h4>
            <!-- Text -->
            <p class="card-text section-text">At Rise N Shine Day Nursery we understand that finding a nursery with flexibility to
              cater to your needs may be difficult. Leading a very busy lifestyle ourselves we understand the importance of flexibility
              which is why our fee structure caters directly to your needs. Parents can book a combination of full days
              and half days upon registering and we have calculated the cost already for you whilst still offering a very competitive monthly rate.</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['fee']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/icons/funding-icon.png"
              alt="Card image cap">
            <a  [routerLink]="['funding']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Funding</h4>
            <!-- Text -->
            <p class="card-text section-text">Every 3 & 4 year old is eligible for government funding up to 570 hours per annum (15 hours – Universal Offer). The funding starts from the term after the child turns 3. To access the funding you will need to fill out and sign a Declaration Form..</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['funding']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

  </div>

  <!--Grid row-->

</section>
