<section id="best-features" class="text-center mt-5">

  <!-- Heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown">Our Ratings & Gallery</h1>

  <!--Grid row-->
  <div class="d-flex justify-content-center pb-4">

    <!--Grid column-->
    <div class="col-lg-10 col-sm-10">

      <!-- Description -->
      <p class="mt-3 wow section-text slideInUp">Welcome to Rise 'N' Shine Nursery!
        An exceptional Nursery and Pre-School in Isle of dogs, London, providing high quality childcare to
        Isle of dogs and the surrounding area.</p>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->
  <div class="row mr-0 ml-0">

    <div class="col-lg-3 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/nursery/ofsted-good.jpg" alt="Card image cap">
          <a href="https://files.ofsted.gov.uk/v1/file/50170972"  target="_blank">
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Ofsted Rating</h4>
          <!-- Text -->
          <p class="card-text section-text">
            Rise 'N' Shine is ofsted "Good" - 2021</p>
            <p  class="card-text section-text">
              "Highly Sensitive, nurturing and knowledgeable staff expertly support children's social and emotional well being"
            </p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" 
          href="https://files.ofsted.gov.uk/v1/file/50170972"  target="_blank"
          mdbWavesEffect>Read More</a>

        </div>

      </div>
      <!-- Card -->
    </div>

    <div class="col-lg-3 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/icons/food-hygine.png" alt="Card image cap">
          <a [routerLink]="['food-hygiene']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Food Hygiene</h4>
          <!-- Text -->
          <p class="card-text section-text">
            We do not just set our high standards into childcare but across the board too. Rise N Shine Day Nursery
            have been graded with a five-star hygiene rating with the use of the kitchen facilities.</p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['food-hygiene']" mdbWavesEffect>Read More</a>

        </div>

      </div>
      <!-- Card -->
    </div>

    <div class="col-lg-3 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/icons/reviews-icon.png" alt="Card image cap">
          <a [routerLink]="['reviews']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Google Reviews</h4>
          <!-- Text -->
          <p class="card-text section-text">Don't Just take our word for the amazing care that our day nursery has
            to offer, take a look at real reviews from genuine parents who use our childcare services
            daily and see that they think</p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['reviews']" mdbWavesEffect>Read More</a>

        </div>

      </div>
      <!-- Card -->
    </div>

    <div class="col-lg-3 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/nursery/classrooms/classrooms.jpg"
            alt="Card image cap">
          <a [routerLink]="['our-gallery']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title pt-3 pb-4">Our Gallery</h4>
          <!-- Text -->
          <div class="view view-cascade overlay m-0">
            <img class="card-img-top" src="../../../../../assets/img/icons/gallery-icon.png" alt="Card image cap">
            <a [routerLink]="['our-gallery']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>

      </div>
      <!-- Card -->
    </div>


  </div>

  <!--Grid row-->

</section>
