import { NannyServiceState } from './../../../store/reducers/nanny-service.reducer';
import { Subject } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { ChildrenCountGroup } from 'src/app/shared/models/nanny-service.model';

import { selectNannyFromStore } from '../../../store/selectors/nanny-service.selector';
import { NannyService } from '../../nanny-service.service';
import { NannyServiceModel } from './../../../../../../shared/models/nanny-service.model';
import { addChildCountInfo, saveChildCountInfo, saveChildCountInfoError } from './../../../store/actions/nanny-service.action';

@Component({
  selector: 'app-children-group-question',
  templateUrl: './children-group-question.component.html',
  styleUrls: ['./children-group-question.component.scss']
})
export class ChildrenGroupComponent implements OnInit {

  @Input() isInReview: boolean;
  @Input() isEditAble = true;
  @Output() successEmit = new EventEmitter();
  @Output() goBack = new EventEmitter();

  childrenGroup: ChildrenCountGroup[];
  destroy$ = new Subject();

  constructor(private nannyService: NannyService,
    private updates$: Actions,
    private store: Store<NannyServiceModel>) { }

  async ngOnInit(): Promise<void> {
    let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
    this.childrenGroup = data?.nannyService?.childrenCountGroup || this.nannyService.getChildrenGroups();
    this.store.dispatch(addChildCountInfo({ childCountInfo: this.childrenGroup }));
    this.updates$.pipe(ofType(saveChildCountInfoError))
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.successEmit.next();
      });
  }

  toggleCount(childrenGroup: ChildrenCountGroup, key: string): void {
    key === 'back' ? childrenGroup.count-- : childrenGroup.count++;
    this.store.dispatch(addChildCountInfo({ childCountInfo: this.childrenGroup }));
  }

  async submitForm(): Promise<void> {
    if (this.isValid) {
      let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
      this.store.dispatch(saveChildCountInfo({ nannyService: data.nannyService }));
    }
  }

  get isValid(): boolean {
    return this.childrenGroup?.some(item => item.count > 0)
  }

  back(): void {
    this.goBack.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
