<div id="preschool-room-fee" class="row">
  <div class="col-12">
    <mdb-card class="testimonial-card mb-5">
      <!--Bacground color-->
      <div class="card-up">
      </div>

      <!--Avatar-->
      <div class="avatar mx-auto">
        <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Pre-School Room Fee">
      </div>

      <mdb-card-body>
        <!--Name-->
        <mdb-card-title>
          <h3>Pre School Room Fee Per Month</h3>
        </mdb-card-title>
        <hr>
        <!--Accordion-->
        <mdb-accordion [multiple]="false">

          <mdb-accordion-item [collapsed]="false" [customClass]="'border-0 mb-3'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">
              <mdb-icon fas icon="pound-sign"></mdb-icon> Fee With Universal 15 Hrs Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-6 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button"
                          (click)="preSchoolFiveDaysFee15Hrs.toggle()" mdbWavesEffect>
                    5 Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Full Days
                        <mdb-badge pill="true" primary="true">£1121.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button"
                          (click)="preSchoolFourDaysFee15Hrs.toggle()" mdbWavesEffect>
                    4 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolFourDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days
                        <mdb-badge pill="true" primary="true">£958.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1059.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Fees Row -->
              <div class="row">
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button"
                          (click)="babyThreeDaysFee.toggle()" mdbWavesEffect>
                    3 Days a week
                  </button>
                  <div class="" mdbCollapse #babyThreeDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days
                        <mdb-badge pill="true" primary="true">£712.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£813.50</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1020.55</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button"
                          (click)="preSchoolTwoDaysFee15Hrs.toggle()" mdbWavesEffect>
                    2 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolTwoDaysFee15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days
                        <mdb-badge pill="true" primary="true">£539.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£640.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£848.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 3 Half Days
                        <mdb-badge pill="true" primary="true">£1110.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-accordion-item-body>

          </mdb-accordion-item>

          <mdb-accordion-item [customClass]="'border-0 mb-3'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee With Universal 30 Hrs Per Month</mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-6 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveDaysFee30Hrs.toggle()" mdbWavesEffect>
                    5 Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveDaysFee30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Full Days
                        <mdb-badge pill="true" primary="true">£821.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourDaysFee30Hrs.toggle()" mdbWavesEffect>
                    4 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolFourDaysFee30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days
                        <mdb-badge pill="true" primary="true">£658.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£736.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Fees Row -->
              <div class="row">
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolThreeDaysFee30Hrs.toggle()" mdbWavesEffect>
                    3 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolThreeDaysFee30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days
                        <mdb-badge pill="true" primary="true">£412.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£490.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£698.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolTwoDaysFee30Hrs.toggle()" mdbWavesEffect>
                    2 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolTwoDaysFee30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days
                        <mdb-badge pill="true" primary="true">£239.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£317.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£524.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 3 Half Days
                        <mdb-badge pill="true" primary="true">£836.50</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item [customClass]="'border-0 mb-3'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee Without Any Funding Per Month </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fees Row -->
              <div class="row pt-4">
                <div class="col-lg-6 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveDaysFee.toggle()" mdbWavesEffect>
                    5 Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Full Days
                        <mdb-badge pill="true" primary="true">£1501.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourDaysFee.toggle()" mdbWavesEffect>
                    4 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolFourDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days
                        <mdb-badge pill="true" primary="true">£1338.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1415.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Fees Row -->
              <div class="row">
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolThreeDaysFee.toggle()" mdbWavesEffect>
                    3 Days week a week
                  </button>
                  <div class="" mdbCollapse #preSchoolThreeDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days
                        <mdb-badge pill="true" primary="true">£1092.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£1170.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1377.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-6 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolTwoDaysFee.toggle()" mdbWavesEffect>
                    2 Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolTwoDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days
                        <mdb-badge pill="true" primary="true">£918.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 1 Half Day
                        <mdb-badge pill="true" primary="true">£996.00</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 2 Half Days
                        <mdb-badge pill="true" primary="true">£1203.30</mdb-badge>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        2 Full Days and 3 Half Days
                        <mdb-badge pill="true" primary="true">£1516.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item [customClass]="'border-0'">
            <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee For Half Days Per Month </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <!-- Fee Row -->
              <div class="row pt-4">
                <div class="col-lg-4 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveHalfDaysFee.toggle()" mdbWavesEffect>
                    5 Half Days a week
                  </button>
                  <div class="" mdbCollapse #preSchoolFiveHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Half Days
                        <mdb-badge pill="true" primary="true">£945.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourHalfDaysFee.toggle()" mdbWavesEffect>
                    4 Half Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFourHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Half Days
                        <mdb-badge pill="true" primary="true">£840.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolThreeHalfDaysFee.toggle()" mdbWavesEffect>
                    3 Half Days a week
                  </button>

                  <div class="" mdbCollapse #preSchoolThreeHalfDaysFee="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Half Days a week
                        <mdb-badge pill="true" primary="true">£630.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Fee Row 15 Hrs Funding-->
              <div class="row pt-4">
                <div class="col-lg-4 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveHalfDaysFeePlus15Hrs.toggle()" mdbWavesEffect>
                    5 Half Days + 15 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveHalfDaysFeePlus15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Half Days + 15 Hrs Funding
                        <mdb-badge pill="true" primary="true">£590.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFourHalfDaysFeePlus15Hrs.toggle()" mdbWavesEffect>
                    4 Half Days + 15 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFourHalfDaysFeePlus15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Half Days + 15 Hrs Funding
                        <mdb-badge pill="true" primary="true">484.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolThreeHalfDaysFeePlus15Hrs.toggle()" mdbWavesEffect>
                    3 Half Days + 15 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #preSchoolThreeHalfDaysFeePlus15Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Half Days + 15 Hrs Funding
                        <mdb-badge pill="true" primary="true">£274.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Fee Row 30 Hrs Funding-->
              <div class="row pt-4">
                <div class="col-lg-4 float-left">
                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolFiveHalfDaysFeePlus30Hrs.toggle()" mdbWavesEffect>
                    5 Half Days + 30 Hrs Funding a week
                  </button>

                  <div class="" mdbCollapse #preSchoolFiveHalfDaysFeePlus30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        5 Half Days + 30 Hrs Funding
                        <mdb-badge pill="true" primary="true">£312.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button"
                          (click)="preSchoolFourHalfDaysFeePlus30Hrs.toggle()" mdbWavesEffect>
                    4 Half Days + 30 Hrs Funding
                  </button>

                  <div class="" mdbCollapse #preSchoolFourHalfDaysFeePlus30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        4 Half Days + 30 Hrs Funding
                        <mdb-badge pill="true" primary="true">228.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 float-left">

                  <button mdbBtn class="rs-btn-info btn btn-rounded waves-light" type="button" (click)="preSchoolThreeHalfDaysFeePlus30Hrs.toggle()" mdbWavesEffect>
                    3 Half Days + 30 Hrs Funding
                  </button>

                  <div class="" mdbCollapse #preSchoolThreeHalfDaysFeePlus30Hrs="bs-collapse">
                    <ul class="list-group mt-4 mb-4">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        3 Half Days + 30 Hrs Funding
                        <mdb-badge pill="true" primary="true">£0.00</mdb-badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </mdb-accordion-item-body>
          </mdb-accordion-item>

        </mdb-accordion>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
