import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { CommonConfig } from 'src/app/core/constants/common.constants';
import {
  ChildObject,
  ChildSessionDTO,
  DiscountType,
  FundingSessionMonths, RoomType,
  SessionType
} from 'src/app/modules/nursery/models/child.model';

import {Session} from '../../../modules/nursery/models/child.session';
import {ToastrService} from 'ngx-toastr';
import {ChildRegistrationService} from '../../../modules/forms/child-registration/child-registration.service';
import {Store} from '@ngrx/store';
import {ChildRegistrationForm} from '../../../modules/nursery/models/registration.model';
import {ActivatedRoute} from '@angular/router';
import {CheckboxComponent, IMyOptions} from 'ng-uikit-pro-standard';
import { HeaderHideShowService } from 'src/app/modules/nursery/services/header-hide-show.service';

@Component({
  selector: 'app-session-required',
  templateUrl: './session-required.component.html',
})
export class SessionRequiredComponent implements OnInit {
  @ViewChild(CheckboxComponent) checkbox: CheckboxComponent;
  @Output() parentChange = new EventEmitter<ChildSessionDTO>();
  @Output() discountCodesEmitter = new EventEmitter<string[]>();
  @Input() childSession: ChildSessionDTO;
  @Input() childId: string;
  sessionType = SessionType;
  rowData: any[] = CommonConfig.getSessions;
  fundingDates: any[] = CommonConfig.getFundingDates;
  resultData: ChildSessionDTO;
  price: number;
  room: string;
  childObject: ChildObject;
  dob: string;
  myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    startDate: new Date(),
    showClearDateBtn: false,
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }, // Disable dates backward starting from the given date.
  };
  twoYears15Hours: boolean;
  threeYears15Hours: boolean;
  threeYears30Hours: boolean;

  twoYears15HoursChecked: boolean;
  threeYears15HoursChecked: boolean;
  threeYears30HoursChecked: boolean;

  discountCodes: string[] = [];

  showFundingSessionMessage: string;

  constructor(
    private childRegistrationService: ChildRegistrationService,
    protected store: Store<ChildRegistrationForm>,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private headerHideShowService: HeaderHideShowService,
  ) {}

  ngOnInit(): void {
    this.headerHideShowService.isHeaderShown(false);
    this.resetSessionSelectionTable();
    this.updateFundings();
    this.updatePrice();
  }

  private updateFundings() {
    this.activatedRoute.params.subscribe(params => {
      if(params?.childId) {
        this.childRegistrationService.getChildrenRegistration(params.childId).subscribe(cObject => {
          this.room = cObject?.room.roomName;
          this.childObject = cObject;
          this.updatePrice();
          if (this.childObject.room.roomName === RoomType.PreSchool
            || this.childObject.room.roomName === RoomType.Toddler) {
            this.findTheFundingPeriod(this.childObject.dob);
          }
        });
  
        this.childRegistrationService.getFeeDiscounts(params.childId).subscribe(data => {
          data.forEach((feeDiscount) => {
            switch (feeDiscount.discount.discountCode) {
              case DiscountType.TwoYears15Hours: {
                this.twoYears15HoursChecked = true;
                this.discountCodes.push(DiscountType.TwoYears15Hours);
                break;
              }
              case DiscountType.ThreeYears15Hours: {
                this.threeYears15HoursChecked = true;
                this.threeYears30Hours = true;
                this.discountCodes.push(DiscountType.ThreeYears15Hours);
                break;
              }
              case DiscountType.ThreeYears30Hours: {
                this.threeYears30HoursChecked = true;
                this.threeYears15Hours = true;
                this.discountCodes.push(DiscountType.ThreeYears30Hours);
                break;
              }
            }
          });
        });
      }
    });
  }

  selectRoom(): void {
    const value = this.childRegistrationService.getRoomByDob(this.dob);
    this.room = value;
    this.updatePrice();
  }

  ngOnChanges() {
    if (this.childSession) {
      const {am, pm , full} = this.getSessions();
      this.rowData[SessionType.AM].days.forEach((item) => {
        am.forEach((i) => {
          if (item.name.toString().toLowerCase() === i) {
            item.isChecked = true;
            this.setOutputResult();
          }
        });
      });
      this.rowData[SessionType.PM].days.forEach((item) => {
        pm.forEach((i) => {
          if (item.name.toString().toLowerCase() === i) {
            item.isChecked = true;
            this.setOutputResult();
          }
        });
      });
      this.rowData[SessionType.Full].days.forEach((item) => {
        full.forEach((i) => {
          if (item.name.toString().toLowerCase() === i) {
            item.isChecked = true;
            this.setOutputResult();
          }
        });
      });
    }
  }

  resetSessionSelectionTable() {
      const {am, pm , full} = this.getSessions();
      this.rowData[SessionType.AM].days.forEach((item) => {
          item.isChecked = false;
      });
      this.rowData[SessionType.PM].days.forEach((item) => {
          item.isChecked = false;
      });
      this.rowData[SessionType.Full].days.forEach((item) => {
          item.isChecked = false;
      });
  }

  getNumberOfFullAndHalf(session: ChildSessionDTO): {
    full: number;
    half: number;
  } {
    const obj = { full: 0, half: 0 };
    for (const key in session) {
      if (
        (session[key]?.toString() === SessionType.AM.toString() ||
          session[key]?.toString() === SessionType.PM.toString())
      ) {
        obj.half++;
      } else if (session[key]?.toString() === SessionType.Full.toString()) {
        obj.full++;
      }
    }
    return obj;
  }

  getSessions(): { am: string[]; pm: string[]; full: string[] } {
    const am = [];
    const pm = [];
    const full = [];
    for (const key in this.childSession) {
      if (this.childSession[key] === SessionType.AM.toString()) {
        am.push(key);
      } else if (this.childSession[key] === SessionType.PM.toString()) {
        pm.push(key);
      } else if (this.childSession[key] === SessionType.Full.toString()) {
        full.push(key);
      }
    }
    return { am, pm, full };
  }

  onChange(day: string, session: Session, event): void {
    if (event.checked) {
      this.rowData.forEach((item) => {
        if (item.session === session) {
          item.days.forEach((l) => {
            if (l.name === day) {
              l.isChecked = true;
            }
          });
        } else {
          this.rowData.forEach((i) => {
            if (i.session !== session) {
              i.days.forEach((l) => {
                if (l.name === day) {
                  l.isChecked = false;
                }
              });
            }
          });
        }
      });
    }
    this.setOutputResult();
  }

  setOutputResult(): void {
    this.resultData = {} as ChildSessionDTO;

    this.rowData.forEach((item) => {
      item.days.forEach((l) => {
        if (l.isChecked) {
          this.resultData[l.name] = item.session;
        }
      });
    });
    this.resultData.childId = this.childId;
    if (this.childSession) {
      this.resultData.id = this.childSession.id;
    }
    this.updatePrice();
    this.parentChange.emit(this.resultData);
  }

  on3Yrs15HrsChange($event) {
    if ($event.checked) {
      this.discountCodes = this.discountCodes.filter(item => item !== DiscountType.ThreeYears30Hours);
      this.discountCodes.push( DiscountType.ThreeYears15Hours);
      this.threeYears30Hours = true;
    } else {
      this.discountCodes = this.discountCodes.filter(item => item !== DiscountType.ThreeYears15Hours);
      this.threeYears30Hours = false;
    }
    this.discountCodesEmitter.emit(this.discountCodes);
    this.updatePrice();
  }

  on3Yrs30HrsChange($event) {
    if ($event.checked) {
      this.discountCodes = this.discountCodes.filter(item => item !== DiscountType.ThreeYears15Hours);
      this.discountCodes.push(DiscountType.ThreeYears30Hours);
      this.threeYears15Hours = true;
    } else {
      this.discountCodes = this.discountCodes.filter(item => item !== DiscountType.ThreeYears30Hours);
      this.threeYears15Hours = false;
    }
    this.discountCodesEmitter.emit(this.discountCodes);
    this.updatePrice();
  }

  on2Yrs15HrsChange($event) {
    if ($event.checked) {
      this.discountCodes = this.discountCodes.filter(item => item !== DiscountType.TwoYears15Hours);
      this.discountCodes.push(DiscountType.TwoYears15Hours);
      this.twoYears15Hours = true;
    } else {
      this.discountCodes = this.discountCodes.filter(item => item !== DiscountType.TwoYears15Hours);
      this.twoYears15Hours = false;
    }
    this.discountCodesEmitter.emit(this.discountCodes);
    this.updatePrice();
  }

  private updatePrice() {
    this.resultData = {} as ChildSessionDTO;
    let totalFull = 0;
    let totalAM = 0;
    let totalPM = 0;

    this.rowData.forEach((item) => {
      item.days.forEach((l) => {
        if (l.isChecked) {
          this.resultData[l.name] = item.session;
          switch (item.session) {
            case 0: {
              totalFull = totalFull + 1;
              break;
            }
            case 1: {
              totalAM = totalAM + 1;
              break;
            }
            case 2: {
              totalPM = totalPM + 1;
              break;
            }
          }
        }
      });
    });
    const totalHalfDay = totalAM + totalPM;
    if (this.room && (totalFull >= 2 ||  totalHalfDay >= 3)) {
       this.childRegistrationService.getFee(this.room, totalFull, totalAM + totalPM, this.discountCodes)
         .subscribe(result => {
          this.price = result.price;
        });
    } else if (this.room && (totalFull || totalHalfDay)) {
      this.toastr.info('Please select minimum of either 3 half or 2 full days');
      this.price = 0;
    }
  }

  findTheFundingPeriod(dob: string | Date) {
    const date = new Date(dob);
    date.setFullYear(new Date().getFullYear());
    const currentYear = new Date().getFullYear();

    this.fundingDates.forEach( fundingDate => {
      switch (fundingDate.session) {
        case FundingSessionMonths.Jan: {
          if (date > new Date(fundingDate.days.startDate) && date < new Date(fundingDate.days.endDate)) {
            this.showFundingSessionMessage = 'Your 15/30 hours funding will start from April ' + currentYear
              + ' if you are eligible';
          }
          break;
        }
        case FundingSessionMonths.April: {
          if (date > new Date(fundingDate.days.startDate) && date < new Date(fundingDate.days.endDate)) {
            this.showFundingSessionMessage = 'Your 15/30 hours funding will start from Sept ' + currentYear
            + ' if you are eligible';
          }
          break;
        }
        case FundingSessionMonths.September: {
          if (date > new Date(fundingDate.days.startDate) && date < new Date(fundingDate.days.endDate)) {
            this.showFundingSessionMessage = 'Your 15/30 hours funding will start from Jan ' + currentYear
              + ' if you are eligible';
          }
          break;
        }
      }
    });
  }


}
