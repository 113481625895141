
<!-- Careers Section -->
<section id="careers">
  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Join Our Team</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
      <p class="section-text mt-3 wow slideInUp text-center">Rise 'N' Shine is a well established, environmentally friendly, personal and family run childcare business operating two day nurseries in the local area of Isle of Dogs Canary. We work hard as a team with full commitment and dedication to ensure the development of children at our Nursery.</p>
      <p class="section-text mt-3 wow slideInUp text-center">Do you enjoy being a part of children’s learning and development? We always like to hear from cheerful, motivated and reliable childcare practitioners who wish to work in one of our day nurseries.</p>
    </div>
  </div>

  <!-- Row-->
  <div class="row clearfix justify-content-center">
    <div class="col-md-5 mb-5">
      <img class="img-fluid" src="../../../../../assets/img/nursery/careers/img-01.jpg" alt="Careers Image">
    </div>
    <div class="col-md-5">
      <h4 class="orange-text mb-4">Qualification</h4>
      <p class="section-text">Ideally candidates should hold minimum level 3 childcare qualification, we do like to hear from candidates with level 2 childcare.  We believe our staff are our best asset and so support you with practical in-house training every two months with internal support to help you achieve your own professional development.</p>
    </div>
  </div>
  <!-- Row-->
  <div class="row clearfix justify-content-center">
    <div class="col-md-5">
      <h4 class="orange-text mb-4">Successful candidates should have:</h4>
      <ul>
        <li>A clean DBS</li>
        <li>A strong desire to work with, and care for children.</li>
        <li>Have good communication skills</li>
        <li>Reliable, and punctual</li>
        <li>Enjoy working in a team to provide children with the best learning opportunities, within a safe, happy, fun, creative, and stimulating environment.</li>
        <li>Good time management to ensure children are supported through their day moving smoothly through learning activities and childcare.</li>
      </ul>
    </div>
    <div class="col-md-5 mb-5">
      <img class="img-fluid" src="../../../../../assets/img/nursery/careers/img-02.jpg" alt="Careers Image">
    </div>
  </div>
  <!-- Row-->
  <div class="row clearfix justify-content-center">
    <div class="col-md-5 mb-5">
      <img alt="Careers Image" class="img-fluid" src="../../../../../assets/img/nursery/careers/img-03.jpg">
    </div>
    <div class="col-md-5">
      <h4 class="orange-text mb-4">In return we reward you with:</h4>
      <ul>
        <li>Annual pay review</li>
        <li>Staff pension with company contribution</li>
        <li>Increased holiday for length of service</li>
        <li>Christmas party and meal</li>
        <li>Yearly 100% attendance bonus</li>
        <li>A family friendly working environment</li>
        <li>Generous childcare discount</li>
        <li>Employee of the month scheme</li>
        <li>Staff team building activities</li>
        <li>Celebrate staff member birthdays</li>
        <li>Building your confidence personally and professionally</li>
      </ul>
    </div>
  </div>
  <!-- Row-->
  <div class="row clearfix">
    <div class="col-md-10 mt-3 mb-3 mx-auto">
      <p class="section-text text-center">If you are interested in finding out more regarding working at Rise 'N' Shine or would like to be considered for a vacant position, please contact us:</p>
      <p class="section-text text-center">Even if we do not have a vacancy suitable for you, we will keep your details on file.</p>
    </div>
    <div class="col-md-10 mx-auto">
      <!-- Career Form -->
      <mdb-card>

        <mdb-card-header class="blue white-text text-center py-4">
          <h3>
            <strong>JOIN OUR TEAM</strong>
          </h3>
        </mdb-card-header>

        <!--Card content-->
        <mdb-card-body class="px-lg-5">

          <!-- Form -->
          <form [formGroup]="careersForm" (ngSubmit)="saveCareers()">

            <!-- <p class="mt-4 font-weight-bold text-center">Join us to be part of our organisation. Kindly fill in your details below:</p> -->

            <!-- Form Row -->
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <!-- First name -->
                <div class="md-form">
                  <input type="text" id="firstName" formControlName="firstName" class="form-control" mdbInput mdbValidate>
                  <label for="firstName">First name</label>
                  <mdb-error *ngIf="submitted && form1.firstName.errors">Please Enter Your First Name</mdb-error>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <!-- Last name -->
                <div class="md-form">
                  <input type="text" id="lastName" formControlName="lastName" class="form-control" mdbInput>
                  <label for="lastName">Last name</label>
                  <mdb-error *ngIf="submitted && form1.lastName.errors">Please Enter Your Last Name</mdb-error>
                </div>
              </div>
            </div>
            <!-- End Form Row -->

            <!-- Form Row -->
            <div class="form-row">
              <div class="col-lg-12">
                <div class="md-form">
                  <input type="email" id="email" formControlName="email" class="form-control" mdbInput>
                  <label for="email">E-mail</label>
                  <mdb-error *ngIf="submitted && form1.email.errors">
                    Please Enter Your Email Address
                  </mdb-error>
                </div>
              </div>
            </div>
            <!-- End Form Row -->

            <!-- Form Row -->
            <div class="form-row">
              <div class="col-lg-12">
                <div class="md-form">
                  <input type="text" id="phoneNumber" formControlName="phoneNumber" class="form-control" aria-describedby="materialRegisterFormPhoneHelpBlock"
                    mdbInput>
                  <label for="phoneNumber">Phone number</label>
                  <mdb-error *ngIf="submitted && form1.phoneNumber.errors">
                    Please Enter Your Phone Number
                  </mdb-error>
                </div>
              </div>
            </div>
            <!-- End Form Row -->

            <!-- Form Row -->
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <!-- Designation -->
                <div class="md-form">
                  <mdb-select id="position" formControlName="position" class="designation-select" [options]="optionsSelect" placeholder="Select Designation"></mdb-select>
                  <mdb-error *ngIf="submitted && form1.position.errors">
                    Please Select Your Position
                  </mdb-error>
                </div>
              </div>

              <div class="col-lg-6 col-sm-12">
                <!-- Qualification -->
                <div class="md-form">
                  <mdb-select id="qualification" formControlName="qualification" class="qualification-select" [options]="qualificationOptions" placeholder="Select Qualification"></mdb-select>
                  <mdb-error *ngIf="submitted && form1.qualification.errors">
                    Please Select Your Qualification
                  </mdb-error>
                </div>
              </div>
            </div>
            <!-- End Form Row -->

            <!-- Form Row -->
            <div class="form-row">
              <div class="col-lg-12">
<!--                <div class="md-form">-->
<!--                  <div class="file-field">-->
<!--                    <div class="btn btn-primary btn-sm float-right mr-0">-->
<!--                      <span>Choose file</span>-->
<!--                      <input type="file" (change)="onFileChanged($event)">-->
<!--                    </div>-->
<!--                    <div class="file-path-wrapper pl-0">-->
<!--                      <input id="cvFile" formControlName="cvFile" class="file-path validate" type="text" placeholder="Upload your file">-->
<!--                      <mdb-error *ngIf="submitted && form1.cvFile.errors">-->
<!--                        Please Select File-->
<!--                      </mdb-error>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->


                <div class="file-field md-form">
                  <div mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect>
                    <span>Choose file</span>
                    <input type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                  </div>
                  <div class="file-path-wrapper">
                    <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
                  </div>
                </div>

              </div>
            </div>
            <!-- End Form Row -->

            <!-- Form Row -->
            <div class="form-row">
              <div class="col-lg-12">
                <div class="md-form">
                  <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                  <textarea mdbInput type="text" id="message" formControlName="message" class="md-textarea"></textarea>
                  <label for="message">Message</label>
                  <mdb-error *ngIf="submitted && form1.message.errors">
                    Please write your message
                  </mdb-error>
                </div>
              </div>
            </div>
            <!-- End Form Row -->

            <!-- Sign up button -->
            <button mdbBtn type="reset" color="danger" rounded="true" class="z-depth-0 my-4 waves-effect" (click)="onReset()" mdbWavesEffect>Reset</button>
            <button mdbBtn type="submit" color="info" rounded="true" class="z-depth-0 my-4 waves-effect sbmbtn" data-toggle="modal" data-target="#basicExample" mdbWavesEffect>Submit</button>
          </form>
          <!-- Form -->

        </mdb-card-body>

      </mdb-card>
      <!-- Career Form -->
    </div>
  </div>
</section>
<!-- Career Section -->

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting your resume. We will get back to you soon.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
