import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-section',
  templateUrl: './learning-section.component.html'
  //styleUrls: ['./learning-section.component.scss']
})
export class LearningSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
