<router-outlet #outlet="outlet"></router-outlet>

<section *ngIf="outlet.isActivated == false" id="funding">
    <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Funding</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">

      <p class="section-text mt-3 wow slideInUp text-center">Every 3 & 4 year old is eligible for government funding up to 570 hours per annum (15 hours – Universal Offer). The funding starts from the term after the child turns 3. To access the funding you will need to fill out and sign a Declaration Form, which the nursery manager will give you.
        In addition up to 1140 hours of government funding is available to some eligible working parents of 3 & 4 year olds (<a [routerLink]="['/funding/funding-30-hours']" floating="true">30 hours Extended Offer</a>). To find out whether or not you are eligible and what help you can get towards your go to the government website: <a href="https://www.childcarechoices.gov.uk/" target="_blank"> Childcare Choices </a> . You will also find useful information regarding current and upcoming changes to funding.
      </p>
    </div>
  </div>

  <!-- Grid row -->
  <div class="d-flex justify-content-center align-items-center row">

    <!-- Grid Column -->
    <div class="col-lg-5 pb-4">
        <!--Card-->
        <mdb-card>
            <!--Card image-->
            <div class="view overlay waves-light" mdbWavesEffect>
            <mdb-card-img src="../../../../../assets/img/nursery/funding/funding-15-hours.jpg"></mdb-card-img>
            <a [routerLink]="['/funding/funding-15-hours']">
                <div class="mask rgba-white-slight"></div>
            </a>
            </div>
            <!--/.Card image-->

            <!--Card content-->
            <mdb-card-body>

            <a mdbBtn [routerLink]="['/funding/funding-15-hours']" floating="true" class="activator orange waves-light" mdbWavesEffect>
                <mdb-icon fas icon="chevron-right" class="text-center waves-light" mdbWavesEffect></mdb-icon>
            </a>

            <!--Title-->
            <mdb-card-title>
                <h4 class="pink-text font-weight-bold">Free Childcare - 15 Hours</h4>
                <mdb-icon fas icon="child"></mdb-icon><span class="dark-grey-text"> 3-4 Yrs</span>
            </mdb-card-title>

            <hr>

            <!--Text-->
            <mdb-card-text>
                All 3 to 4-year-olds in England can get 570 free hours per year. Know more about 15 hours funding.
            </mdb-card-text>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>

    <!-- Grid column -->
    <div class="col-lg-5 pb-4">
        <!--Card-->
        <mdb-card>
            <!--Card image-->
            <div class="view overlay waves-light" mdbWavesEffect>
            <mdb-card-img src="../../../../../assets/img/nursery/funding/funding-30-hours.jpg"></mdb-card-img>
            <a [routerLink]="['/funding/funding-30-hours']">
                <div class="mask rgba-white-slight"></div>
            </a>
            </div>
            <!--/.Card image-->

            <!--Card content-->
            <mdb-card-body>

            <a mdbBtn [routerLink]="['/funding/funding-30-hours']" floating="true" class="activator orange waves-light" mdbWavesEffect>
                <mdb-icon fas icon="chevron-right" class="waves-light text-center" mdbWavesEffect></mdb-icon>
            </a>

            <!--Title-->
            <mdb-card-title>
                <h4 class="deep-orange-text font-weight-bold">Free Childcare - 30 Hours</h4>
                <mdb-icon fas icon="child"></mdb-icon><span class="dark-grey-text"> 3-4 Yrs</span>
            </mdb-card-title>

            <hr>

            <!--Text-->
            <mdb-card-text>
                You can get 30 hours of free childcare per week for 38 weeks of the year (during school term time). Know more about 30 hours funding.
            </mdb-card-text>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>

  </div>
  <!-- Grid row -->

</section>
