
import { AppointmentConfig } from 'src/app/core/constants/appoinment.config';
import { AppointmentModel } from '../home/appointment-calendar/models/appointment.model';


export class MockAppointment {

  config = AppointmentConfig;

  addDaysToCurrentDate(days: number): Date {
    const oldDate = new Date();
    oldDate.setDate(oldDate.getDate() + days);
    return oldDate;
  }

  formatDate(date: Date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  createData(): any[] {
    const data = [];
    for (let i = 0; i < this.config.showScheduleDays; i++) {
      this.config.timeSlots.forEach(item => {
        const obj = {};
        obj['id'] = i;
        obj['startTime'] = item.start;
        obj['endTime'] = item.end;
        obj['date'] = this.formatDate(this.addDaysToCurrentDate(this.config.minDays + i));
        data.push(obj);
      });
    }
    return data;
  }


  getAppointments(): AppointmentModel[] {
    const data = this.createData();
    const appointments: AppointmentModel[] = [];
    data.forEach(item => {
      appointments.push(this.createAppointment(item.date, item.startTime, item.endTime));
    });
    return appointments;
  }

  createAppointment(date: string, startTime: string, endTime: string) {
    return {
      id: `${date} ${startTime}`,
      appointmentDate: date,
      appointmentPriority: 'test',
      timeslotAllocatedQuota: 3,
      timeslotRemainingQuota: 3,
      appointmentType: {
        callCode: 'test',
        type: 'test',
        typeGroup: 'test',
      },
      timeSlot: {
        startTime,
        endTime,
        timeslotCode: 'test',
      },
    } as AppointmentModel;
  }
}
