<section id="google-reviews" class="text-center">
  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center mb-5">Our Google Reviews</h2>

  <div class="row clearfix">
    
    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards2>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
                <div class="name-circle">RA</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Rouge Abbasi</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>My experience with Rise N Shine Day Nursery has been Great. All the staff have been so involved and engaging since the day 1. The Manager of the nursery Ebru was so knowledgeable
                and passionate about working with me for my sons developments...</p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn d-block" data-card="card-2" (click)="cards2.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>My experience with Rise N Shine Day Nursery has been Great. All the staff have been so involved and engaging since the day 1.
              The Manager of the nursery Ebru was so knowledgeable and passionate about working with me for my sons developments that I 
              enrolled him on the day I went in for the tour of the nursery. All the other staff are pleasant and welcoming...
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-2" (click)="cards2.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards3>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">EG</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Elena Giannakopoulou</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>It's a very nice place with a homey atmosphere. The team is very attentive, approachable and always show a genuine interest in my child. Management is so helpful and they 
                have a clear plan for every child helping them learn and develop...
              </p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-3" (click)="cards3.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Reviews</h4>
          <hr>
          <div class="content-wrap">
            <p>It's a very nice place with a homey atmosphere. The team is very attentive, approachable and always show a genuine interest in my child. 
              Management is so helpful and they have a clear plan for every child helping them learn and develop. My kid is really developing in all areas.
              They also have a lovely garden where kids spend a lot of time when...
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-3" (click)="cards3.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards4>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">AT</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Anna Tomkova</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>My daughter has been with the Rise 'N' Shine Nursery for 7 months before it was time to start the school and I am more than happy that I chose this particular place for her. 
                Every single day she was excited to go the nursery and always...</p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-4" (click)="cards4.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>My daughter has been with the Rise 'N' Shine Nursery for 7 months before it was time to start the school and I am more than happy that I chose this particular place for her. 
              Every single day she was excited to go the nursery and always said she had a great day. Through the app that they use you can easily communicate with the nursery and see your 
              child's profile...
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-4" (click)="cards4.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards5>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">TB</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Thomas Bourne</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>I work in Skyline Business Park and saw the Rainbow Splash Day nursery from my office and decided to go and pop in to see what it was like. My daughter is currently at another 
                nursery on the Isle Of Dogs and the price is a bit much for my liking...
              </p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-5" (click)="cards5.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>I work in Skyline Business Park and saw the Rainbow Splash Day nursery from my office and decided to go and pop in to see what it was like. My daughter is currently at another 
              nursery on the Isle Of Dogs and the price is a bit much for my liking and it got an Ofstead of inadequate although my wife is very happy with the staff etc despite the... 
          </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-5" (click)="cards5.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

  </div>

  <div class="row clearfix">

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards6>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">DJ</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Deepankar Joshi</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>Had a great experience with the owners and the manager. We recently moved to this place, and the kids are being looked after really nicely and we can see positive growth. 
                Kids love the new garden they have opened...</p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn d-block pt-4" data-card="card-6" (click)="cards6.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>Had a great experience with the owners and the manager. We recently moved to this place, and the kids are being looked after really nicely and we can see positive growth. 
              Kids love the new garden they have opened. All the Best to the team!
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-6" (click)="cards6.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards7>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">LS</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Lorna Stapleton</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>Love this Nursery. They look after my little girl very well. She is doing so well here and coming along in general very well. Learning new things everyday 
                and having fun! Highly recommend
              </p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-7" (click)="cards7.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>Love this Nursery. They look after my little girl very well. She is doing so well here and coming along in general very well. Learning new things everyday 
              and having fun! Highly recommend
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-7" (click)="cards7.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards8>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">JS</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Junaid Shaikh</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>One of the nicest staff and management I came across, very attentive and responsible for children. I don't need to say more you can read rest of the reviews.</p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-8" (click)="cards8.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>One of the nicest staff and management I came across, very attentive and responsible for children. I don't need to say more you can read rest of the reviews.
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-8" (click)="cards8.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards9>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">GS</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Gabi Stevens</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>My child started a couple of months ago and is very happy and settled. The  staff are smiley, friendly and knowledgable. My little one sbsolutely love it there. I would recommend it to all my friends.</p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-9" (click)="cards9.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>My child started a couple of months ago and is very happy and settled. The  staff are smiley, friendly and knowledgable. My little one sbsolutely love it there. I would recommend it to all my friends.
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-9" (click)="cards9.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

  </div>

  <div class="row clearfix">

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards10>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">DJ</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Diwakar Joshi</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>Good place as kids come back happy. The  management/teachers take feedback positively and make changes wherever required.
                Happy to send both my kids to the nursery.
              </p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn d-block pt-4" data-card="card-10" (click)="cards10.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>Good place as kids come back happy. The  management/teachers take feedback positively and make changes wherever required.
              Happy to send both my kids to the nursery.
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-10" (click)="cards10.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards11>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">TW</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Terri Watson</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>My little girl has been going to this nursery from when it opened. It’s an amazing little nursery the staff are really lovely and my daughter is very happy here.
              </p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-11" (click)="cards11.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>
              My little girl has been going to this nursery from when it opened. It’s an amazing little nursery the staff are really lovely and my daughter is very happy here.
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-11" (click)="cards11.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards12>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">TR</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Travel</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p>Amazing place,our son goes there and we are so happy. He learned so much. The staff is very professional and they keep you updated about everything. Anu and Ellen are very helpful 
                and they follow the development of your child...</p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-12" (click)="cards12.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <div class="content-wrap">
            <p>
              Amazing place,our son goes there and we are so happy. He learned so much. The staff is very professional and they keep you updated about everything. Anu and Ellen are very helpful 
              and they follow the development of your child with extra details.
            </p>
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-12" (click)="cards12.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards13>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">SS</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Sammy sunnucks</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap">
              <p></p>
            </div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-13" (click)="cards13.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <h4>Sammy sunnucks</h4>
          <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
          <!--Social Icons-->
          <div class="bottom-content-wrap">
          </div>
          <hr>
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-13" (click)="cards13.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

  </div>

  <div class="row clearfix">

    <!--Grid column-->
     <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards14>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">LS</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Louise Sunderland</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap"></div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-14" (click)="cards14.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <h4>Louise Sunderland</h4>
          <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
          <div class="bottom-content-wrap">
          </div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-14" (click)="cards14.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

     <!--Grid column-->
     <div class="col-lg-3 col-md-12">

      <mdb-card-rotating #cards15>
        <!--Front Side-->
        <div class="face front tp-box_side tp-box_front">

          <!-- Image-->
          <div class="card-up">
            <img src="../../../../../assets/img/icons/google-reviews-bg.png" class="img-fluid">
          </div>
          <!--Avatar-->
          <div class="avatar">
            <!-- <img src="../../../../../assets/img/icons/user-icon-placeholder.png" class="rounded-circle img-responsive"> -->
            <div class="initial-name-wrap rounded-circle">
              <div class="name-circle">BT</div>
            </div>
          </div>
          <!--Content-->
          <div class="card-body">
            <h4>Becky Turpin</h4>
            <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
            <div class="content-wrap"></div>
            <!--Triggering button-->
            <a class="rotate-btn" data-card="card-15" (click)="cards15.toggle()">
              <mdb-icon fas icon="redo"></mdb-icon> Click here to see more</a>
          </div>
        </div>
        <!--/.Front Side-->

        <!--Back Side-->
        <div class="back tp-box_side tp-box_back">

          <!--Content-->
          <h4>Google Review</h4>
          <hr>
          <h4>Becky Turpin</h4>
          <p><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i><i class="fas fa-star stars"></i></p>
          <div class="bottom-content-wrap"></div>
          <hr>
          <!--Social Icons-->
          <ul class="list-inline list-unstyled">
            <li class="list-inline-item">
              <a class="btn rs-btn-red btn-rounded waves-effect waves-light mb-2" mdbbtn="" mdbwaveseffect="" rounded="true"  href="https://www.google.com/search?source=hp&ei=UJk8YM7hI7KortoPqeSekAE&iflsig=AINFCbYAAAAAYDynYEgRDI6YUodMyEIMLWBlLB13664q&q=rise+n+shine+nursery+uk&oq=rise+n+shine+nursery+uk&gs_lcp=Cgdnd3Mtd2l6EAMyCAghEBYQHRAeMggIIRAWEB0QHjIICCEQFhAdEB46CAgAELEDEIMBOgUIABCxAzoCCAA6BQguELEDOggILhCxAxCTAjoICC4QxwEQrwE6CAguELEDEIMBOgcIABBGEP8BOgIILjoLCC4QsQMQxwEQrwE6BAgAEAo6CwguEMcBEK8BEJMCOgUIABDJAzoGCAAQFhAeUOAFWM4nYLopaAFwAHgAgAHZAYgB3RySAQYwLjIzLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz&ved=0ahUKEwjOyZfTyo7vAhUylEsFHSmyBxIQ4dUDCAc&uact=5#lrd=0x4876031006c51723:0x8110da14d5c0d760,1,,," target="_blank">See Google Reviews</a>
            </li>
          </ul>
          <!--Triggering button-->
          <a class="rotate-btn" data-card="card-15" (click)="cards15.toggle()">
            <mdb-icon fas icon="undo"></mdb-icon> Click here to rotate back</a>

        </div>
        <!--/.Back Side-->
      </mdb-card-rotating>

    </div>
    <!--Grid column-->

  </div>

</section>
