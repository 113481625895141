import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-checklist-area',
  templateUrl: './checklist-area.component.html'
  // styleUrls: ['./checklist-area.component.scss']
})
export class ChecklistAreaComponent implements OnInit {

  writingChecklistForm: FormGroup;
  colouringChecklistForm: FormGroup;
  puttingChecklistForm: FormGroup;
  drawingChecklistForm: FormGroup;
  singingChecklistForm: FormGroup;
  dressingChecklistForm: FormGroup;
  eatingChecklistForm: FormGroup;
  playingChecklistForm: FormGroup;
  greetingChecklistForm: FormGroup;
  cleaningChecklistForm: FormGroup;
  holdingChecklistForm: FormGroup;
  cuttingChecklistForm: FormGroup;

  constructor() {
    $(document).ready(function() {
      $('.tabs-white').parent().removeClass('col-md-3').addClass('col-xl-3 col-lg-12');
      $('.tabs-white').parent().next().removeClass('col-md-9').addClass('col-xl-9 col-lg-12');
    });
  }

  ngOnInit() {
  }

}
