<mdb-icon fas icon="edit" *ngIf="isInReview" (click)="isEditAble = !isEditAble;" class="pencil-icon"></mdb-icon>
<form class="question-1-wrapper" (ngSubmit)="submitForm()">
  <h3 class="main-heading">
    {{isInReview ? 'Selected Services' : ' Please check if any additional care required?'}}
  </h3>
  <div class="badges">
      <div class="services" *ngFor="let service of optionalService">
        <label>{{service.serviceName}}</label>
        <mdb-checkbox [disabled]="!isEditAble" [name]="service.serviceName" (ngModelChange)="updateStore()" [value]="service.isChecked" [(ngModel)]="service.isChecked"></mdb-checkbox>
      </div>
  </div>
  <div class="footer-section m-4" *ngIf="isEditAble" [ngClass]="isInReview ? 'justify-content-center' : '' ">
    <button mdbBtn  *ngIf="!isInReview" type="button" (click)="back()" class="btn btn-link waves-light" mdbWavesEffect>
      Go Back
    </button>
    <button mdbBtn type="submit" class="rs-btn-info btn btn-rounded waves-light"
      mdbWavesEffect>
      {{isInReview ? 'Update' : 'SAVE AND NEXT'}}
    </button>
  </div>
</form>
