import { Component, OnInit, Input, HostListener } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EligibilityCheck} from '../../../models/funding.model';
import {FormGroup, FormControl, FormGroupName, FormBuilder, Validator, Validators, AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import {Router} from '@angular/router';
import * as $ from 'jquery';


@Component({
  selector: 'app-funding-30-hours',
  templateUrl: './funding-30-hours.component.html',
  // styleUrls: ['./funding-30-hours.component.scss']
})
export class Funding30HoursComponent implements OnInit {

  public nestedForm: FormGroup = new FormGroup({
    eligibilityFormOne: new FormControl(''),
    eligibilityFormTwo: new FormControl(''),
    eligibilityFormThree: new FormControl(''),
    eligibilityFormFour: new FormControl('')
  });

  checkEligibilityFormOne: FormGroup;
  checkEligibilityFormTwo: FormGroup;
  checkEligibilityFormThree: FormGroup;
  checkEligibilityFormFour: FormGroup;

  submitted = false;

  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  checkEligibilityModal = [
    {
      img: '../../../../assets/img/nursery/funding/childcare-funding.jpg',
      thumb: '../../../../assets/img/nursery/funding/childcare-funding.jpg', description: 'Image 1'
    }
  ];

  createAccountModal = [
    {
      img: '../../../../assets/img/nursery/funding/childcare-funding.jpg',
      thumb: '../../../../assets/img/nursery/funding/childcare-funding.jpg', description: 'Image 1'
    }
  ];

  codeModal = [
    {
      img: '../../../../assets/img/nursery/funding/childcare-funding.jpg',
      thumb: '../../../../assets/img/nursery/funding/childcare-funding.jpg', description: 'Image 1'
    }
  ];

  constructor(private router: Router,
    private formBuilder: FormBuilder) {
    $(document).ready(function() {
      $('.mdb-lightbox figure').removeClass('col-md-4').addClass('col-md-12');
      $('.stepper').addClass('p-0');
      $('.step-new-content').addClass('pl-1 pr-0');
      $('.card-body ul').addClass('stepper-height');
    });
   }

  isVertical = false;

  @HostListener('window:resize') onWindowResize() {

    if (window.innerWidth <= 1199) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }

  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get form1() { return this.nestedForm.controls; }

  public onSubmit() {

    if (this.nestedForm.invalid) {
      console.log('Invalid Nested Form');
      return;
    } else {

      this.submitted = true;

      // Make sure to create a deep copy of the form-model
      const result: EligibilityCheck = Object.assign({}, this.nestedForm.value);

      this.retval.subscribe(data => {
        console.log(' Data Submitted Successfully: ' + JSON.stringify(this.nestedForm.value));
      });

    }

  }

}
