import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NannyServiceModel } from 'src/app/shared/models/nanny-service.model';

import { NannyServiceState } from '../../../store/reducers/nanny-service.reducer';
import { selectNannyFromStore } from '../../../store/selectors/nanny-service.selector';
import { addUserDetails, saveUserDetails, saveUserDetailsError } from './../../../store/actions/nanny-service.action';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnChanges {

  @Input() isInReview: boolean;
  @Input() isEditAble = true;
  @Output() successEmit = new EventEmitter();
  @Output() goBack = new EventEmitter();

  form: FormGroup;
  destroy$ = new Subject();

  constructor(private fb: FormBuilder, private updates$: Actions,
    private store: Store<NannyServiceModel>) { }

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.subscribeForm();
    let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
    if (data?.nannyService?.userDetails) {
      this.form.patchValue(data?.nannyService?.userDetails)
    }
    this.updates$.pipe(ofType(saveUserDetailsError))
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.successEmit.next();
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      fullName: new FormControl('', Validators.compose([Validators.required])),
      phoneNumber: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])),
      addressLine1: new FormControl('', Validators.required),
      addressLine2: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      postcode: new FormControl('', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(10)])),
    });
    this.toggleForm();
  }

  ngOnChanges(): void {
    this.toggleForm();
  }

  toggleForm(): void {
    if (this.form) {
      this.isEditAble ? this.form.enable() : this.form.disable();
    }
  }

  subscribeForm(): void {
    this.form.valueChanges.subscribe(() => {
      this.store.dispatch(addUserDetails({ userDetails: this.form.value }));
    });
  }

  async submitForm(): Promise<void> {
    if (this.form.valid) {
      let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
      this.store.dispatch(saveUserDetails({ nannyService: data.nannyService }));
    }
  }

  back(): void {
    this.goBack.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
