<section id="best-features" class="text-center">

  <!-- Heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown">Be a step closer to being a part of Rise 'N' Shine Nursery Family.
    Take a sneak peak of what our Nursery is like!</h1>

  <!--Grid row-->
  <div class="d-flex justify-content-center pb-4">

    <!--Grid column-->
    <div class="col-lg-10 col-sm-10">

      <!-- Description -->
      <p class="mt-3 wow section-text slideInUp">Welcome to Rise 'N' Shine Nursery!
        An exceptional Nursery and Pre-School in Isle of dogs, London, providing high quality childcare to
        Isle of dogs and the surrounding area.</p>
    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

  <div class="row mr-0 ml-0">

    <div class="col-lg-4 pb-4">
      <!-- Card -->
      <div class="card card-cascade narrower wow fadeInLeft">

        <!-- Card image -->
        <div class="view view-cascade overlay">
          <img class="card-img-top" src="../../../../../assets/img/nursery/about-us/about-us.jpg"
            alt="Card image cap">
          <a  [routerLink]="['about-us']">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>

        <!-- Card content -->
        <div class="card-body card-body-cascade">

          <!-- Title -->
          <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">About Us</h4>
          <!-- Text -->
          <p class="card-text section-text">Rise 'N' Shine nursery is committed to providing a safe and healthy environment that encourages
            children to explore, learn and succeed through constructive activities.
            We work hard as a team with full commitment and dedication to ensure the development of children at our Nursery.</p>
          <!-- Button -->
          <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['about-us']" mdbWavesEffect>Read More</a>

        </div>

      </div>
      <!-- Card -->
  </div>

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/nursery/classrooms/classrooms.jpg"
              alt="Card image cap">
            <a [routerLink]="['classrooms']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Classrooms</h4>
            <!-- Text -->
            <p class="card-text section-text">We have created a friendly atmosphere within our classrooms to ensure that every child is valued,
              nurtured and encouraged to become independent learners. Our staff carry out regular observations of every child to ensure that their learning and development
              incorporates their interests & needs.</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['classrooms']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

    <div class="col-lg-4 pb-4">
        <!-- Card -->
        <div class="card card-cascade narrower wow fadeInLeft">

          <!-- Card image -->
          <div class="view view-cascade overlay">
            <img class="card-img-top" src="../../../../../assets/img/avatar/team.jpg"
              alt="Card image cap">
            <a [routerLink]="['about-us/our-team']">
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>

          <!-- Card content -->
          <div class="card-body card-body-cascade">

            <!-- Title -->
            <h4 class="rs-f-medium-rem secondary-heading font-weight-bold card-title mt-4 mb-4">Our Amazing Team</h4>
            <!-- Text -->
            <p class="card-text section-text">At Rise N Shine Day Nursery, our team members love taking care of children.
              All of our staff are either NNEB (National Nursery Examination Board) or equivalent,
              or participating in the National Vocational Qualification (NVQ) in early years and childcare.
              All of our staff are trained in first aid.</p>
            <!-- Button -->
            <a mdbBtn class="btn rs-btn-info" rounded="true" [routerLink]="['about-us/our-team']" mdbWavesEffect>Read More</a>

          </div>

        </div>
        <!-- Card -->
    </div>

  </div>

  <!--Grid row-->

</section>
