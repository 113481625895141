<div class="checklist-left">
    <div class="tabs-wrapper">
        <!-- Tabset tabs -->
        <mdb-tabset [vertical]="true" [buttonClass]="'tabs-white flex-column list-group'" [contentClass]="'vertical'">
          <!--Panel 1-->
          <mdb-tab heading="<a class='list-group-item mb-0'> <i class='fas fa-pencil-alt fa-1x'></i> Writing, Coloring and Putting </a>">
            <div class="row">
              <div class="col-lg-4">
                  <br>
                  <!-- Heading-->
                  <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-pencil-alt fa-1x red-text float-right"></i></h3>
                  <!-- End Heading-->

                  <!-- Border Line -->
                  <hr />
                  <!-- End Border Line -->

                  <!-- Writing Form Checklist -->

                    <!-- Material Checkbox  -->
                    <mdb-checkbox name="wr-check-1" class="d-block">recognise my name</mdb-checkbox>
                    <mdb-checkbox name="wr-check-1" class="d-block pt-3">write the first letter</mdb-checkbox>
                    <mdb-checkbox name="wr-check-3" class="d-block pt-3">copy my name</mdb-checkbox>
                    <mdb-checkbox name="wr-check-4" class="d-block pt-3">write my name</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-paint-brush fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Colouring Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="col-check-1" class="d-block">hold my pencil carefully</mdb-checkbox>
                  <mdb-checkbox name="col-check-2" class="d-block pt-3">draw a face</mdb-checkbox>
                  <mdb-checkbox name="col-check-3" class="d-block pt-3">colour in carefully</mdb-checkbox>
                  <mdb-checkbox name="col-check-4" class="d-block pt-3">name the colours I use</mdb-checkbox>
              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-socks fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Wearing Form Checklist -->
                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="put-check-1" class="d-block">put on my socks</mdb-checkbox>
                  <mdb-checkbox name="put-check-2" class="d-block pt-3">find my shoes</mdb-checkbox>
                  <mdb-checkbox name="put-check-3" class="d-block pt-3">put on my shoes</mdb-checkbox>
                  <mdb-checkbox name="put-check-4" class="d-block pt-3">fasten my shoes</mdb-checkbox>
              </div>
            </div>
          </mdb-tab>
          <!--Panel 2-->
          <mdb-tab heading="<a class='list-group-item mb-0'> <i class='fas fa-paint-brush fa-1x' class='mr-1'></i> Drawing, Singing and Dressing </a>">
            <div class="row">
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-shapes fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Drawing Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="draw-check-1" class="d-block">count from 1 to 10</mdb-checkbox>
                  <mdb-checkbox name="draw-check-1" class="d-block pt-3">recognise some numbers</mdb-checkbox>
                  <mdb-checkbox name="draw-check-3" class="d-block pt-3">recognise spot patterns on a dice</mdb-checkbox>
                  <mdb-checkbox name="draw-check-4" class="d-block pt-3">recognise some common 2D shapes</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-music fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Singing Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="sing-check-1" class="d-block">sing simple rhymes</mdb-checkbox>
                  <mdb-checkbox name="sing-check-2" class="d-block pt-3">sing and clap to a song</mdb-checkbox>
                  <mdb-checkbox name="sing-check-3" class="d-block pt-3">tap a beat</mdb-checkbox>
                  <mdb-checkbox name="sing-check-4" class="d-block pt-3">move to music</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-tshirt fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Dressing Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="dres-check-1" class="d-block">find my coat</mdb-checkbox>
                  <mdb-checkbox name="dres-check-2" class="d-block pt-3">put my arms into my coat</mdb-checkbox>
                  <mdb-checkbox name="dres-check-3" class="d-block pt-3">zip up my coat</mdb-checkbox>
                  <mdb-checkbox name="dres-check-4" class="d-block pt-3">fasten buttons on my clothes</mdb-checkbox>

              </div>
            </div>
          </mdb-tab>
          <!--Panel 3-->
          <mdb-tab heading="<a class='list-group-item mb-0'> <i class='fas fa-hamburger fa-1x' class='mr-1'></i> Eating, Playing and Greeting </a>">
            <div class="row">
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-apple-alt fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Eating Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="eat-check-1" class="d-block">eat with a knife and fork</mdb-checkbox>
                  <mdb-checkbox name="eat-check-1" class="d-block pt-3">pour myself a drink</mdb-checkbox>
                  <mdb-checkbox name="eat-check-3" class="d-block pt-3">taste different foods</mdb-checkbox>
                  <mdb-checkbox name="eat-check-4" class="d-block pt-3">wash my hands before and after meals</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-car-side fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Playing Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="play-check-1" class="d-block">tidy away my toys</mdb-checkbox>
                  <mdb-checkbox name="play-check-2" class="d-block pt-3">clear away things I’ve used</mdb-checkbox>
                  <mdb-checkbox name="play-check-3" class="d-block pt-3">put my clothes away</mdb-checkbox>
                  <mdb-checkbox name="play-check-4" class="d-block pt-3">help at home</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-hand-paper fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Talking Form Checklist -->
                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="greet-check-1" class="d-block">say please when I ask</mdb-checkbox>
                  <mdb-checkbox name="greet-check-2" class="d-block pt-3">say thank you when I get</mdb-checkbox>
                  <mdb-checkbox name="greet-check-3" class="d-block pt-3">ask to go to the toilet</mdb-checkbox>
                  <mdb-checkbox name="greet-check-4" class="d-block pt-3">take turns talking in a group</mdb-checkbox>
              </div>
            </div>
          </mdb-tab>
          <!--Panel 4-->
          <mdb-tab heading="<a class='list-group-item mb-0'> <i class='fas fa-cut fa-1x' class='mr-1'></i> Cleaning, Holding and Cutting </a>">
            <div class="row">
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-hands-wash fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Cleaning Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="clean-check-1" class="d-block">wash my hands</mdb-checkbox>
                  <mdb-checkbox name="clean-check-1" class="d-block pt-3">dry my hands</mdb-checkbox>
                  <mdb-checkbox name="clean-check-3" class="d-block pt-3">go to the toilet when I need to</mdb-checkbox>
                  <mdb-checkbox name="clean-check-4" class="d-block pt-3">wipe/clean myself when I’ve been to the toilet</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-hands-helping fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Holding Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="hold-check-1" class="d-block">walk up and down stairs using the handrail</mdb-checkbox>
                  <mdb-checkbox name="hold-check-2" class="d-block pt-3">hold hands on the street</mdb-checkbox>
                  <mdb-checkbox name="hold-check-3" class="d-block pt-3">take turns when playing</mdb-checkbox>
                  <mdb-checkbox name="hold-check-4" class="d-block pt-3">share toys with a friend</mdb-checkbox>

              </div>
              <div class="col-lg-4">
                <br>
                <!-- Heading-->
                <h3 class="font-weight-bold mb-3">I Can <i class="fas fa-cut fa-1x float-right red-text"></i></h3>
                <!-- End Heading-->

                <!-- Border Line -->
                <hr />
                <!-- End Border Line -->

                <!-- Cutting Form Checklist -->

                  <!-- Material Checkbox  -->
                  <mdb-checkbox name="cut-check-1" class="d-block">cut along a line</mdb-checkbox>
                  <mdb-checkbox name="cut-check-2" class="d-block pt-3">cut ‘snips’ into paper</mdb-checkbox>
                  <mdb-checkbox name="cut-check-3" class="d-block pt-3">cut out a shape</mdb-checkbox>
                  <mdb-checkbox name="cut-check-4" class="d-block pt-3">cut out shapes to make a picture</mdb-checkbox>

              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
    </div>
  </div>
