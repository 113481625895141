import { RestrictTimeDirective } from './../../shared/directive/restrictTime.directive';
import { AgmCoreModule } from '@agm/core';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { SharedModule } from 'src/app/shared/shared.module';

import { NurseryFormsModule } from '../../modules/nursery/nursery-forms/nursery-forms.module';
import { LayoutModule } from './../layout/layout.module';
import { AboutUsComponent } from './home/about-us/about-us.component';
import { DirectorWelcomeComponent } from './home/about-us/director-welcome/director-welcome.component';
import { FacilityComponent } from './home/about-us/facility/facility.component';
import { FacultyAndStaffComponent } from './home/about-us/faculty-and-staff/faculty-and-staff.component';
import { LeadershipTeamComponent } from './home/about-us/leadership-team/leadership-team.component';
import { LocationAndHoursComponent } from './home/about-us/location-hours/location-hours.component';
import { MissionStatementComponent } from './home/about-us/mission-statement/mission-statement.component';
import { OurTeamComponent } from './home/about-us/our-team/our-team.component';
import { StaffComponent } from './home/about-us/staff/staff.component';
import { AdmissionFeeSectionComponent } from './home/admission-fee-section/admission-fee-section.component';
import { AdmissionsComponent } from './home/admissions/admissions.component';
import { BabyRoomAdmissionComponent } from './home/admissions/baby-room-admission/baby-room-admission.component';
import {
  PreSchoolRoomAdmissionComponent,
} from './home/admissions/pre-school-room-admission/pre-school-room-admission.component';
import { ToddlerRoomAdmissionComponent } from './home/admissions/toddler-room-admission/toddler-room-admission.component';
import { AppointmentCalendarComponent } from './home/appointment-calendar/appointment-calendar.component';
import { AssociatedMembersComponent } from './home/associated-members/associated-members.component';
import { CareersComponent } from './home/careers/careers.component';
import { BabyRoomComponent } from './home/classrooms/baby-room/baby-room.component';
import { ClassroomsComponent } from './home/classrooms/classrooms.component';
import { PreSchoolRoomComponent } from './home/classrooms/pre-school-room/pre-school-room.component';
import { ToddlerRoomComponent } from './home/classrooms/toddler-room/toddler-room.component';
import { BabyRoomGalleryComponent } from './home/components/our-gallery/baby-room-gallery/baby-room-gallery.component';
import {
  BabyOutdoorActivitiesComponent,
} from './home/components/our-gallery/baby-room-gallery/outdoor-activities/baby-outdoor-activities.component';
import { GardenGalleryComponent } from './home/components/our-gallery/garden-gallery/garden-gallery.component';
import {
  PreschoolOutdoorActivitiesComponent,
} from './home/components/our-gallery/pre-school-room-gallery/outdoor-activities/preschool-outdoor-activities.component';
import {
  PreSchoolRoomGalleryComponent,
} from './home/components/our-gallery/pre-school-room-gallery/preschool-room-gallery.component';
import {
  ToddlerOutdoorActivitiesComponent,
} from './home/components/our-gallery/toddler-room-gallery/outdoor-activities/toddler-outdoor-activities.component';
import {
  ToddlerRoomGalleryComponent,
} from './home/components/our-gallery/toddler-room-gallery/toddler-room-gallery.component';
import { SocialShareComponent } from './home/components/social-share/social-share.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { CovidFAQSComponent } from './home/covid/covid-faqs.component';
import { EyfsLeftSectionComponent } from './home/eyfs/eyfs-left-section/eyfs-left-section.component';
import { EyfsRightSectionComponent } from './home/eyfs/eyfs-right-section/eyfs-right-section.component';
import { EyfsComponent } from './home/eyfs/eyfs.component';
import { EyfsModule } from './home/eyfs/eyfs.module';
import { BabyRoomFeeComponent } from './home/fee/baby-room-fee/baby-room-fee.component';
import { FeeComponent } from './home/fee/fee.component';
import { PreSchoolRoomFeeComponent } from './home/fee/pre-school-room-fee/pre-school-room-fee.component';
import { ToddlerRoomFeeComponent } from './home/fee/toddler-room-fee/toddler-room-fee.component';
import { FoodHygiene } from './home/food-hygiene/food-hygiene.component';
import { Funding15HoursComponent } from './home/funding/funding-15-hours/funding-15-hours.component';
import {
  EligibilityStepFourComponent,
} from './home/funding/funding-30-hours/eligibility-step-four/eligibility-step-four.component';
import {
  EligibilityStepOneComponent,
} from './home/funding/funding-30-hours/eligibility-step-one/eligibility-step-one.component';
import {
  EligibilityStepThreeComponent,
} from './home/funding/funding-30-hours/eligibility-step-three/eligibility-step-three.component';
import {
  EligibilityStepTwoComponent,
} from './home/funding/funding-30-hours/eligibility-step-two/eligibility-step-two.component';
import { Funding30HoursComponent } from './home/funding/funding-30-hours/funding-30-hours.component';
import { FundingComponent } from './home/funding/funding.component';
import { HomeComponent } from './home/home.component';
import { LearningLifecycleComponent } from './home/learning-lifecycle/learning-lifecycle.component';
import { LearningSectionComponent } from './home/learning-section/learning-section.component';
import { NewsletterArchives } from './home/newsletter-archives/newsletter-archives.component';
import {
  NurseryAppPoliciesSectionComponent,
} from './home/nursery-app-policies-section/nursery-app-policies-section.component';
import { NurseryPoliciesComponent } from './home/nursery-policies/nursery-policies.component';
import { NurserySoftwareComponent } from './home/nursery-software/nursery-software.component';
import { OperationsComponent } from './home/operations/operations.component';
import { OurFeatureComponent } from './home/our-feature/our-feature.component';
import { OurGalleryComponent } from './home/our-gallery/our-gallery.component';
import { RatingsGalleryComponent } from './home/ratings-gallery/ratings-gallery.component';
import { RegistrationModule } from './home/registration/registration.module';
import { Reviews } from './home/reviews/reviews.component';
import { ChecklistAreaComponent } from './home/school-checklist/checklist-area/checklist-area.component';
import { SchoolChecklistComponent } from './home/school-checklist/school-checklist.component';
import { PriceEngine } from './nursery-forms/nanny-service/booking/booking-form/price-calculation.engine';
import { NurseryComponent } from './nursery.component';
import { NurseryRoutingModule } from './nursery.routing.module';
import { UserContactUsStoreModule } from './home/contact-us/store/user-contact-us-store.module';
import * as datePicker from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    HomeComponent,
    NurseryComponent,
    ContactUsComponent,
    RestrictTimeDirective,
    AboutUsComponent,
    ClassroomsComponent,
    OurTeamComponent,
    FacilityComponent,
    DirectorWelcomeComponent,
    BabyRoomComponent,
    ToddlerRoomComponent,
    PreSchoolRoomComponent,
    OurFeatureComponent,
    AdmissionsComponent,
    MissionStatementComponent,
    FacultyAndStaffComponent,
    LeadershipTeamComponent,
    LocationAndHoursComponent,
    StaffComponent,
    CareersComponent,
    NurseryPoliciesComponent,
    CovidFAQSComponent,
    BabyRoomAdmissionComponent,
    ToddlerRoomAdmissionComponent,
    PreSchoolRoomAdmissionComponent,
    BabyRoomFeeComponent,
    PreSchoolRoomFeeComponent,
    ToddlerRoomFeeComponent,
    FeeComponent,
    LearningLifecycleComponent,
    NurserySoftwareComponent,
    FundingComponent,
    Funding15HoursComponent,
    Funding30HoursComponent,
    EligibilityStepOneComponent,
    EligibilityStepTwoComponent,
    EligibilityStepThreeComponent,
    EligibilityStepFourComponent,
    OperationsComponent,
    EyfsComponent,
    EyfsLeftSectionComponent,
    EyfsRightSectionComponent,
    SchoolChecklistComponent,
    ChecklistAreaComponent,
    AssociatedMembersComponent,
    NewsletterArchives,
    Reviews,
    FoodHygiene,
    OurGalleryComponent,
    RatingsGalleryComponent,
    BabyRoomGalleryComponent,
    BabyOutdoorActivitiesComponent,
    GardenGalleryComponent,
    PreSchoolRoomGalleryComponent,
    PreschoolOutdoorActivitiesComponent,
    ToddlerRoomGalleryComponent,
    ToddlerOutdoorActivitiesComponent,
    LearningSectionComponent,
    AdmissionFeeSectionComponent,
    NurseryAppPoliciesSectionComponent,
    SocialShareComponent,
    AppointmentCalendarComponent,
  ],
  imports: [
    CommonModule,
    CdkStepperModule,
    LayoutModule,
    datePicker.BsDatepickerModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    UserContactUsStoreModule,
    RegistrationModule,
    EyfsModule,
    NurseryRoutingModule,
    NurseryFormsModule,
    ReactiveFormsModule,
    SharedModule,
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: ''
    }),
  ],
  // entryComponents: [ ModalComponent ],
  providers: [
    PriceEngine,
  ]

})

export class NurseryModule { }
