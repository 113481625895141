import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule, IMyOptions, ModalDirective} from 'ng-uikit-pro-standard';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, FormGroupName, FormBuilder, Validator, Validators, AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  // styleUrls: ['./common-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonFormComponent),
      multi: true
    },
      {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommonFormComponent),
      multi: true
    }
    ]
})
export class CommonFormComponent implements OnInit, ControlValueAccessor, Validator {

  roomSelect: Array<any>;

  staffSelect: Array<any>;

  public dateInfoOptions: IMyOptions = {
    closeAfterSelect: true
  };

  commonForm: FormGroup;

  submitted = false;

  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  constructor(private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.roomSelect = [
      { value: 'Jasmine', label: 'Jasmine' },
      { value: 'Lotus', label: 'Lotus' },
      { value: 'Lily', label: 'Lily' },
      { value: 'Rose', label: 'Rose' },
      { value: 'Sunflower', label: 'Sunflower' },
      { value: 'Tulip', label: 'Tulip' }
    ];

    this.staffSelect = [
      { value: 'Antoinette', label: 'Antoinette' },
      { value: 'Ellen', label: 'Ellen' },
      { value: 'Kelly', label: 'Kelly' },
      { value: 'Alisha', label: 'Alisha' },
      { value: 'Tahamina', label: 'Tahamina' },
      { value: 'Shuhadha', label: 'Shuhadha' },
      { value: 'Ramona', label: 'Ramona' },
      { value: 'Maleka', label: 'Maleka' },
      { value: 'Sumaya', label: 'Sumaya' }
    ];

    this.commonForm = this.formBuilder.group({
      staff: ['', Validators.required],
      room: ['', Validators.required],
      dateInfo: ['', Validators.required],
    });
  }

   // convenience getter for easy access to form fields
   get form1() { return this.commonForm.controls; }


  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.commonForm.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    // console.log("on change");
    this.commonForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    // console.log("on blur");
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.commonForm.disable() : this.commonForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    console.log('Common Form validation', c);
    return this.commonForm.valid ? null : { invalidForm: {valid: false, message: 'Common Form fields are invalid'}};
  }

}
