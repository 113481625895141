import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  ContinousProvisionPlanningState,
  continousProvisionPlanningStateFeatureKey
} from '../reducers/continous-provision-planning.reducer';


export const selectCPPState =
  createFeatureSelector<ContinousProvisionPlanningState>(continousProvisionPlanningStateFeatureKey);

export const selectCPPFromStore = createSelector(selectCPPState,
  (continousProvisionPlanningState: ContinousProvisionPlanningState) => {
    return continousProvisionPlanningState;
  }
);
