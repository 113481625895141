import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-eyfs',
  templateUrl: './eyfs.component.html'
  // styleUrls: ['./eyfs.component.scss']
})
export class EyfsComponent implements OnInit {


  public mainArea: string;
  public subArea: string;

  constructor() { }

  ngOnInit() {
  }

  onMainAreaUpdate(value: string) {
    console.log('----- EyfsComponent Main Area Value ---- ' + value);
    this.mainArea = value;
  }

  onSubAreaUpdate(value: any) {
    console.log('----- EyfsComponent Sub Area Value ---- ' + value);
    this.subArea = value;
  }
}
