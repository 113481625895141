<!-- Navbar -->
<mdb-navbar #nav SideClass="navbar fixed-top navbar-expand-lg scrolling-navbar navbar-dark">

    <!-- Navbar brand -->
    <mdb-navbar-brand>
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="../../../../assets/img/logo/rise-n-shine-logo.png" height="60" alt="Rise N Shine Nursery, Isle of Dogs Canary, UK">
      </a>
    </mdb-navbar-brand>

    <links>
    <!-- Links -->
    <ul class="navbar-nav ml-auto" >
      <li class="nav-item dropdown mega-dropdown" mdbDropdown>
<!--        <a mdbDropdownToggle class="nav-link dropdown-toggle no-caret" aria-haspopup="true"-->
<!--          aria-expanded="false"><mdb-icon fas icon="baby-carriage"></mdb-icon>Nanny Service</a>-->
          <div class="dropdown-menu mega-menu v-2 row z-depth-1 special-color">
            <div class="row mx-md-4 mx-1">
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Services</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/contact-us']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Find Job
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/nanny-service/0']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Find Care
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a [routerLink]="['/contact-us']" mdbDropdownToggle class="nav-link" aria-haspopup="true"
          aria-expanded="false"><mdb-icon fas icon="phone"></mdb-icon> Contact Us</a>
       
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a mdbDropdownToggle class="nav-link dropdown-toggle no-caret" aria-haspopup="true"
          aria-expanded="false"><mdb-icon fas icon="school"></mdb-icon> About Us</a>
          <div class="dropdown-menu mega-menu v-2 row z-depth-1 special-color">
            <div class="row mx-md-4 mx-1">
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Who We Are</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/about-us']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> About Us
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/about-us']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Our Mission
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/about-us']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Facility
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/about-us']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Location & Hours
                    </a>
                  </li>
                  <!-- <li class="">
                    <a class="menu-item pl-0">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Newsletter
                    </a>
                  </li> -->
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/newsletter-archives']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Newsletter Archives
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/covid-faqs']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Covid-19 FAQ's
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Staff & Careers</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/about-us/our-team']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Our Staff
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/careers']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Join Our Team
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Our Nurseries</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Rise 'N' Shine
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" href="https://www.rainbowsplashdaynursery.co.uk/" target="_blank">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Rainbow Splash
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Reach Us</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/contact-us']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Contact
                    </a>
                  </li>
<!--                  <li class="">-->
<!--                    <a class="menu-item pl-0" [routerLink]="['/nanny-service']">-->
<!--                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Nanny Service-->
<!--                    </a>-->
<!--                  </li>-->
                </ul>
              </div>
            </div>
          </div>
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a mdbDropdownToggle class="nav-link dropdown-toggle no-caret" aria-haspopup="true"
          aria-expanded="false"><mdb-icon fas icon="book-reader"></mdb-icon> Classrooms</a>
          <div class="dropdown-menu mega-menu v-2 row z-depth-1 special-color">
            <div class="row mx-md-4 mx-1">
              <div class="col-md-6 col-lg-4 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Our Classrooms</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Classrooms
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms/baby-room']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Baby Room
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms/toddler-room']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Toddler
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms/pre-school-room']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Pre-School
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Education</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/eyfs']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> EYFS
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/school-readiness']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> School Readiness
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/learning']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Children's Learning Cycle
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/nursery-software']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Nursery App
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a mdbDropdownToggle class="nav-link dropdown-toggle no-caret" aria-haspopup="true"
           aria-expanded="false"><mdb-icon fas icon="book-reader"></mdb-icon> Learning </a>
        <div class="dropdown-menu mega-menu v-2 row z-depth-1 special-color">
          <div class="row mx-md-4 mx-1">
            <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
              <h6 class="sub-title text-uppercase font-weight-bold white-text">Our Approach</h6>
              <ul class="list-unstyled">
                <li class="">
                  <a class="menu-item pl-0" [routerLink]="['/eyfs']">
                    <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> EYFS
                  </a>
                </li>
                <li class="">
                  <a class="menu-item pl-0" [routerLink]="['/school-readiness']">
                    <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> School Readiness
                  </a>
                </li>
                <li class="">
                  <a class="menu-item pl-0" [routerLink]="['/learning']">
                    <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Children Learning Cycle
                  </a>
                </li>
                <li class="">
                  <a class="menu-item pl-0" [routerLink]="['/nursery-software']">
                    <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon> Nursery App
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a mdbDropdownToggle class="nav-link dropdown-toggle no-caret" aria-haspopup="true"
          aria-expanded="false"><mdb-icon fas icon="child"></mdb-icon> Admission</a>
          <div class="dropdown-menu mega-menu v-2 row z-depth-1 special-color">
            <div class="row mx-md-4 mx-1">
              <div class="col-md-6 col-lg-4 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text">Admission</h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms/baby-room']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Baby Room Admission
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms/toddler-room']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Toddler Room Admission
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" [routerLink]="['/classrooms/pre-school-room']">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-3"></mdb-icon> Pre-School Room Admission
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a   [routerLink]="['/fee']" class="nav-link dropdown-toggle no-caret" aria-haspopup="true"
          aria-expanded="false"><mdb-icon fas icon="pound-sign"></mdb-icon> Fee</a>
      
      </li>
      <li *ngIf="!isRegistration" class="nav-item dropdown mega-dropdown" mdbDropdown>
        <a mdbDropdownToggle class="nav-link dropdown-toggle no-caret" aria-haspopup="true"
          aria-expanded="false"><mdb-icon fas icon="book-open"></mdb-icon> Policies</a>
          <div class="dropdown-menu mega-menu v-2 row z-depth-1 special-color">
            <div class="row mx-md-4 mx-1">
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text"></h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/access-and-storage-of-Information.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Access and Storage of Information
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/accidents-and-first-aid.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Accidents and First Aid
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/nursery-policies/admission.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Admission
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/adverse-weather.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Adverse Weather
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/alcohol-and-substance-misuse.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Alcohol and Substance Misuse
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/allergies-and-allergic-reactions.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Allergies and Allergic Reactions
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/arrivals-and-departures.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Arrivals and Departures
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/biting.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Bitting
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/camera-mobile-phone-and-recording-device-use.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Camera, Mobile Phone and Recording Device
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/caring-for-babies-and-toddlers.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Caring for Babies and Toddler
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text"></h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/complaints-and-compliments.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Complaints and Compliments
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/confidentiality.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Confidentiality
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/early-learning-opportunities-statement.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Early Learning Opportunities Statement
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/equipment-and-resources.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Equipment and Resources
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/fire-saftey.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Fire Safety
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/health-and-safety.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Health and Safety – General Policy
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/lost-child-procedure-from-nursery.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Lost Child Procedure from Nursery
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/lost-child-procedure-from-outings.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Lost Child Procedure from Outings
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/medication.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Medication
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/monitoring-staff-behaviour-policy.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Monitoring Staff Behaviour Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text"></h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/new-absence-management-procedure-policy.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>New Absence Management Procedure Policy
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/outdoor-play.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Outdoor Play
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/overall-approach-to-risk-assessment.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Overall Approach To Risk Assessment
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/parents-and-carers-as-partners.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Parents and Carers as Partners
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/promoting-positive-behaviour.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Promoting Positive Behaviour
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/risk-management-assesment.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Risk Management Assesment
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/safe-care-and-practice.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Safe Care and Practice
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/safe-recruitment-of-staff.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Safe Recruitment of Staff
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/safeguarding-and-child-protection-policy.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Safeguarding and Child Protection Policy
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/settling-in.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Settling In
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-lg-3 sub-menu my-lg-5 my-4">
                <h6 class="sub-title text-uppercase font-weight-bold white-text"></h6>
                <ul class="list-unstyled">
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/sickness-and-illness.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Sickness and Illness
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/special-educational-needs-and-disabilities.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Special Educational Needs and Disabilities (SEND)
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/suitability-of-staff.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Suitability of Staff
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/supervision-of-children.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Supervision of Children
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/supervision-of-visitors.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Supervision of Visitors
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/visits-and-outings.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Visits And Outings
                    </a>
                  </li>
                  <li class="">
                    <a class="menu-item pl-0" target="_blank" href="../../../../../../assets/docs/nursery-policies/whistleblowing.pdf">
                      <mdb-icon fas icon="caret-right" class="pl-1 pr-1"></mdb-icon>Whistleblowing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </li>
      <li *ngIf="!isRegistration" class="nav-item mega-dropdown ml-3 d-lg-block d-none">
        <div class="btn-group" mdbDropdown>
          <a mdbDropdownToggle mdbWavesEffect type="button" class="dropdown-toggle waves-light" mdbWavesEffect
             style="height: 40px; width: 40px">
            <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-1.jpg" alt=""
                 class="img-fluid rounded-circle z-depth-0" ></a>
          <div class="dropdown-menu dropdown-primary login-dropdown">
            <a class="dropdown-item" *ngIf="!isLoggedIn" mdbWavesEffect [routerLink]="['/login']">
              Login
            </a>
            <a class="dropdown-item" [routerLink]="['/pages']" routerLinkActive="active" *ngIf="isLoggedIn" mdbWavesEffect>
              {{currentUser.firstName + ' ' +  currentUser.lastName}}
            </a>
          </div>
        </div>
      </li>
      <li  class="nav-item mega-dropdown d-lg-none d-block">
        <a class="nav-link waves-light" *ngIf="!isLoggedIn" mdbWavesEffect [routerLink]="['/login']">
          <mdb-icon fas icon="sign-in-alt"></mdb-icon>Login
        </a>
      </li>

      <li class="nav-item mega-dropdown d-lg-none d-block">
        <a class="nav-link waves-light" *ngIf="isLoggedIn" mdbWavesEffect (click)="logout()">
          Logout
        </a>
      </li>

      <li class="nav-item mega-dropdown d-lg-none d-block">
        <a class="nav-link waves-light" *ngIf="isLoggedIn" [routerLink]="['/pages']" routerLinkActive="active"  mdbWavesEffect>
          {{currentUser.firstName + ' ' +  currentUser.lastName}}
        </a>
      </li>

    </ul>
    <!-- Links -->
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/. Navbar -->
