import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../../nursery/models/user.model';
import {environment} from '../../../../environments/environment';
import { LoaderEnabled } from 'src/app/shared/components/loader/loader.service';

const AUTH_API = environment.apiUrl2 + '/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject: BehaviorSubject<any>;
  public token = '';
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
  }

  @LoaderEnabled()
  login(credentials): Observable<any> {
    return this.http.post(environment.apiUrl2 + `/oauth/login`, {
      grant_type: 'password',
      username: credentials.username,
      password: credentials.password
    }, httpOptions);
  }

  register(user: User): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
    }, httpOptions).pipe(map(data => {
      // store user details and jwt accessToken in local storage to keep user logged in between page refreshes
      this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
    }));
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
