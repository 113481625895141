import { createReducer, MetaReducer, on } from '@ngrx/store';

import { environment } from '../../../../../../../environments/environment';
import { Registration } from '../../../../models/registration.model';
import {
  addConsentDetails,
  saveImmuneDetails,
  saveIndividualDetails,
  saveIndividualDetailsError,
  saveMedicalDetailsError,
  addMedicalDetails,
  saveMedicalDetailsSuccess,
  saveConsentDetails,
  saveConsentDetailsError,
  saveConsentDetailsSuccess,
} from '../actions/registration.actions';
import {
  addDoctorAddress,
  addImmuneDetails,
  addIndividualDetails,
  saveImmuneDetailsSuccess,
  saveIndividualDetailsSuccess,
} from './../actions/registration.actions';

export const registrationStateFeatureKey = 'registrationState';

export interface RegistrationState {
  registration: Registration;
}

export const initialState: RegistrationState = {
  registration: {} as Registration,
};

export const _registrationReducers = createReducer(initialState,


  // Add Medical Detail
  on(addDoctorAddress, (state, action) => {
    return {
      ...state,
      registration: {
        ...state.registration,
        medicalDetails: {
          ...state.registration.medicalDetails,
          doctorDetails: {
            ...state.registration.medicalDetails.doctorDetails,
            addressForm: action.address
          }
        }
      }
    };
  }),

  on(addMedicalDetails, (state, action) => {
    return {
      ...state,
      registration: {
        ...state.registration,
        medicalDetails: action.medicalDetails
      }
    };
  }),

  // Add Medical Detail
  on(saveMedicalDetailsSuccess, (state, action) => {
    return {
      ...state,
      registration: {
        ...action.registration
      }
    };
  }),
  // Add Medical Detail
  on(saveMedicalDetailsError, (state, action) => {
    return {
      ...state,
      registration: {
        ...action.registration
      }
    };
  }),
  // Add immune
  on(addImmuneDetails, (state, action) => {
    return {
      ...state,
      registration: {
        ...state.registration,
        immuneDetails: action.immuneDetails
      }
    };
  }),
  // Save immune
  on(saveImmuneDetails, (state, action) => {
    return {
      ...state,
      registration: {
        ...action.registration
      }
    };
  }),
  on(saveImmuneDetailsSuccess, (state, action) => {
    return {
      ...state,
      registration: {
        ...action.registration
      }
    };
  }),
  // Immune failure
  on(saveImmuneDetailsSuccess, (state, action) => {
    return {
      ...state,
      registration: {
        ...action.registration
      }
    };
  }),

  //

    // Add immune
    on(addIndividualDetails, (state, action) => {
      return {
        ...state,
        registration: {
          ...state.registration,
          individualDetails: action.individual
        }
      };
    }),
    // Save immune
    on(saveIndividualDetails, (state, action) => {
      return {
        ...state,
        registration: {
          ...action.registration
        }
      };
    }),
    on(saveIndividualDetailsSuccess, (state, action) => {
      return {
        ...state,
        registration: {
          ...action.registration
        }
      };
    }),
    // Immune failure
    on(saveIndividualDetailsError, (state, action) => {
      return {
        ...state,
        registration: {
          ...action.registration
        }
      };
    }),

    // Add immune
    on(addConsentDetails, (state, action) => {
      return {
        ...state,
        registration: {
          ...state.registration,
          consent: action.consent
        }
      };
    }),
    // Save immune
    on(saveConsentDetails, (state, action) => {
      return {
        ...state,
        registration: {
          ...action.registration
        }
      };
    }),
    on(saveConsentDetailsSuccess, (state, action) => {
      return {
        ...state,
        registration: {
          ...action.registration
        }
      };
    }),
    // Immune failure
    on(saveConsentDetailsError, (state, action) => {
      return {
        ...state,
        registration: {
          ...action.registration
        }
      };
    }),

);

export function registrationReducers(state, action) {
  return _registrationReducers(state, action);
}

export const registrationMetaReducers: MetaReducer<RegistrationState>[] = !environment.production ? [] : [];
