<section class="contact-us" id="contact-us">
  <!--Section heading-->
  <mdb-card class="testimonial-card">

    <!--Avatar-->
    <div class="avatar mx-auto">
      <img src="../../../../../assets/img/nursery/contact-us/contact-us-avatar.png" class="rounded-circle"
        alt="Contact Us">
    </div>

    <p class="pb-4"></p>

    <div class="row ml-0 mr-0">

      <!--Grid column-->
      <div class="col-sm-12 col-lg-4">
        <!--Form with header-->
        <mdb-card>
          <mdb-card-body>
            <h4 class="card-title">
              <strong> 1. Write to us</strong>
            </h4>
            <br>
            <form class="example-form" [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
              <p *ngIf="!f?.id?.value"> Please fill in the details below as required. We will contact you very soon.</p>
              <div *ngIf="f?.id?.value && !bookingDate" class="alert alert-success" role="alert">
                <p class="mb-0"> You have filled the contact us form. Please book the show around now.</p>
              </div>
              <div *ngIf="f?.id?.value && bookingDate" class="alert alert-success" role="alert">
                <p class="mb-0"> Your show around is already booked for <br />
                  {{(bookingDate.toString()?.replace(' ', 'T')) | date :'dd-MM-yy h:mm a'}}
                </p>
              </div>
              <br>

              <input type="hidden" id="id" formControlName="id">

              <!--Body-->
              <div class="form-row clearfix">
                <div class="col-md-12">
                  <div class="btn-group mb-1 radio-grp">
                    <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
                    <label mdbBtn color="info" mdbRadio="Mr." formControlName="title" mdbWavesEffect>
                      Mr.
                    </label>
                    <label mdbBtn color="info" mdbRadio="Ms." formControlName="title" mdbWavesEffect>
                      Ms.
                    </label>
                    <label mdbBtn color="info" mdbRadio="Mrs." formControlName="title" mdbWavesEffect>
                      Mrs.
                    </label>
                    <label mdbBtn color="info" mdbRadio="Miss" formControlName="title" mdbWavesEffect>
                      Miss
                    </label>
                    <span class="error-text" *ngIf="submitted && f.title.errors">
                      Please select title
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-row clearfix">
                <div class="col-md-12">
                  <div class="md-form mt-4">
                    <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
                    <input mdbInput type="text" id="fullName" formControlName="fullName" class="form-control">
                    <label for="fullName">Full Name</label>
                    <mdb-error *ngIf="submitted && f.fullName.errors">
                      Please provide your full name
                    </mdb-error>
                  </div>
                </div>
              </div>

              <div class="form-row clearfix">
                <div class="col-md-12">
                  <div class="md-form mt-0">
                    <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
                    <input mdbInput type="text" id="email" formControlName="email" class="form-control">
                    <label for="email">Your email</label>
                    <mdb-error *ngIf="submitted && f.email.errors">
                      Please provide your valid email address
                    </mdb-error>
                  </div>
                </div>
              </div>

              <div class="form-row clearfix phonenum">
                <div class="col-md-3 col-4">
                  <div class="md-form mt-2">
                    <mdb-icon fas icon="fas fa-phone" class="prefix grey-text"></mdb-icon>
                    <input mdbInput type="text" id="isd" formControlName="isd" class="form-control"
                      (keypress)="keyPress($event)" maxlength="4">
                    <label for="isd">ISD Code</label>
                    <mdb-error *ngIf="submitted && f.isd.errors">
                      Provide isd
                    </mdb-error>
                  </div>
                </div>

                <div class="col-md-9 col-8">
                  <div class="md-form mt-2">
                    <input mdbInput type="text" id="phoneNumber" formControlName="phoneNumber" class="form-control"
                      (keypress)="keyPress($event)" maxlength="11">
                    <label for="phoneNumber">Phone Number</label>
                    <mdb-error *ngIf="submitted && f.phoneNumber.errors">
                      Please provide valid phone number
                    </mdb-error>
                  </div>
                </div>
              </div>

              <div class="form-row clearfix">
                <div class="col-md-12">
                  <div class="btn-group mt-2 radio-grp">
                    <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon>
                    <label mdbBtn color="info" mdbRadio="0-2 Years" formControlName="ageGroup" mdbWavesEffect>
                      0-2 Yr
                    </label>
                    <label mdbBtn color="info" mdbRadio="2-3 Years" formControlName="ageGroup" mdbWavesEffect>
                      2-3 Yr
                    </label>
                    <label mdbBtn color="info" mdbRadio="3-5 Years" formControlName="ageGroup" mdbWavesEffect>
                      3-5 Yr
                    </label>
                    <span class="error-text" *ngIf="submitted && f.ageGroup.errors">
                      Please select age group
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-row clearfix estimated-start-date">
                <div class="col-md-1 col-1">
                  <div class="md-form mt-2">
                    <mdb-icon fas icon="fas fa-calendar" class="prefix grey-text"></mdb-icon>
                  </div>
                </div>
                <div class="col-md-11 col-11">
                  <div class="md-form mt-2 center-date-picker">
                    <mdb-date-picker id="estimatedStartDate" formControlName="estimatedStartDate"
                    [options]="estimatedStartDatePickerOptions" inline="true" [placeholder]="'Expected start date'"
                    required></mdb-date-picker>
                    <mdb-error *ngIf="submitted && f.estimatedStartDate?.errors">
                      Please enter estimated start date
                    </mdb-error>
                  </div>
                </div>
              </div>

              <div class="form-row clearfix">
                <div class="col-md-12">
                  <div class="md-form">
                    <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                    <textarea mdbInput type="text" id="message" formControlName="message"
                      class="md-textarea"></textarea>
                    <label for="message">Message</label>
                    <mdb-error *ngIf="submitted && f.message.errors">
                      Please write your message
                    </mdb-error>
                  </div>
                </div>
              </div>

              <div class="text-center clearfix">
                <button type="submit" mdbBtn class="rs-btn-info btn btn-rounded waves-effect waves-light"
                  data-toggle="modal" data-target="#basicExample" mdbWavesEffect>Submit
                </button>
                <button mdbBtn class="rs-btn-orange btn btn-rounded waves-effect waves-light" mdbWavesEffect
                  type="reset" (click)="onReset()">Reset
                </button>
              </div>
            </form>
          </mdb-card-body>
        </mdb-card>
        <!--Form with header-->
      </div>
      <!--Grid column-->
      <br>
      <!--Grid column-->
      <div class="col-sm-12 col-lg-4 showaround">
        <!--Form with header-->
        <mdb-card>
          <mdb-card-body>
            <h4 class="card-title">
              <strong>2. {{bookingDate ? 'Update' : 'Book'}} your showaround.</strong>
            </h4>
            <br>
            <app-appointment-calendar [appointments]="appointments" [premiumAppointmentCost]="premiumAppointmentCost"
              [appointmentsCost]="appointmentsCost">
            </app-appointment-calendar>
          </mdb-card-body>
        </mdb-card>
        <!--Form with header-->
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-sm-12 col-lg-4">
        <!--Form with header-->
        <mdb-card>
          <mdb-card-body>
            <h4 class="card-title">
              <strong>3. Location.</strong>
            </h4>
            <br>
            <agm-map class="z-depth-1-half map-container" style="height: 400px;" [latitude]="map.lat"
              [longitude]="map.lng">
              <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
            </agm-map>

            <div class="row ml-0 mr-0 text-center">
              <div class="col-lg-6">
                <a mdbBtn floating="true" color="blue" class="accent-1 waves-light" mdbWavesEffect>
                  <mdb-icon fas icon="map-marked"></mdb-icon>
                </a>
                <p>34 Skylines, Limeharbour</p>
                <p>London, E14 9TS </p>
                <p>United Kingdom</p>
              </div>

              <div class="col-lg-6">
                <a mdbBtn floating="true" color="blue" class="accent-1 waves-light" mdbWavesEffect>
                  <mdb-icon fas icon="phone"></mdb-icon>
                </a>
                <p>020 3887 0220</p>
                <p>Mon - Fri, 8:00-06:00</p>
              </div>

              <div class="col-lg-6">
                <a mdbBtn floating="true" color="blue" class="accent-1 waves-light" mdbWavesEffect>
                  <mdb-icon fas icon="envelope"></mdb-icon>
                </a>
                <p>info@risenshinenursery.co.uk</p>
              </div>
            </div>
          </mdb-card-body>
        </mdb-card>
        <!--Form with header-->
      </div>
      <!--Grid column-->
    </div>

    <hr class="my-1 rounded border-line mb-2">


  </mdb-card>

</section>

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-full-width">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
        </div>

        <div class="row d-flex justify-content-center align-items-center">
          <p class="pt-3 mx-4">
            <strong>You can book your show Around now.</strong>
          </p>
        </div>

        <div class="row d-flex justify-content-center align-items-center">
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
            (click)="autoShownModal.hide();">Close</a>
        </div>
      </div>
    </div>
  </div>
</div>