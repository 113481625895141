import { BookingInfo } from './../../modules/nursery/nursery-forms/nanny-service/booking/booking-form/booking-form.interface';
import { Address } from 'src/app/modules/nursery/models/address.model';
export class NannyServiceModel {
  childrenCountGroup: ChildrenCountGroup[];
  optionalService: OptionalService[];
  userCredentials: UserCredentials;
  userDetails: UserDetails;
  slots: BookingInfo;
}

export class ChildrenCountGroup {
  count: number;
  label: string;
}

export class OptionalService {
  serviceName: string;
  isChecked: boolean;
}

export class UserCredentials {
  email: string;
  password: string;
}

export class UserDetails extends Address {
  fullName: string;
  phoneNumber: number;
}
