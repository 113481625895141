import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteMedicalService, SuccessDeleteMedicalService } from 'src/app/modules/forms/forms-store/store/actions/medical.actions';
import { MedicalDetail, OtherService } from 'src/app/modules/nursery/models/child.model';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
})
export class MedicalComponent implements OnInit {
  @Input() medicalData: MedicalDetail = {} as MedicalDetail;
  @Output() childChange: EventEmitter<any> = new EventEmitter();
  @Output() emitNext = new EventEmitter();
  medicalFormGroup: FormGroup;
  destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder,
    private store: Store<MedicalDetail>,
    private router: Router,
    private toaster: ToastrService,
    private updates$: Actions) {}

  ngOnInit(): void {
    this.medicalFormGroup = this.formBuilder.group({
      id: [''],
      hasRedBook: [false, Validators.required],
      services: this.formBuilder.array([]),
    });

    this.medicalFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.childChange.emit(this.medicalFormGroup);
      });

    this.updates$
      .pipe(ofType(SuccessDeleteMedicalService))
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.toaster.success('Services deleted successfuly');
        this.services.removeAt(parseInt(response.index));
        this.medicalData.services.splice(parseInt(response.index), 1);
        this.childChange.emit(this.medicalFormGroup);
      });
  }

  updateFormData(formGroup: FormGroup, formName: string): void {
    this.medicalFormGroup.addControl(formName, formGroup);
  }

  ngOnChanges(): void {
    if (this.medicalData) {
      this.medicalFormGroup.patchValue(this.medicalData);
      this.childChange.emit(this.medicalFormGroup);
    } else {
      this.medicalData = {
        services: [],
      } as MedicalDetail;
    }
  }

  push() {
    if (!this.medicalData?.services?.length) {
      this.medicalData['services'] = [];
    }
    this.medicalData.services.push(null);
    this.childChange.emit(this.medicalFormGroup);
  }

  updateBasicForm(form: FormGroup, id: number) {
    if (this.services.get(id.toString())) {
      this.services.setControl(id, form);
    } else {
      this.services.push(form);
    }
    this.childChange.emit(this.medicalFormGroup);
  }

  removeForm(i: number, service: OtherService): void {
    if(service?.id) {
      this.store.dispatch(DeleteMedicalService({
        childId: this.medicalData.childId.toString(),
        index: i.toString(),
        serviceId: service.id.toString(),
      }))
    } else {
      this.services.removeAt(i);
      this.medicalData.services.splice(i, 1);
      this.childChange.emit(this.medicalFormGroup);
    }
  }

  moveNext() {
    this.emitNext.emit();
  }

  get services() {
    return this.medicalFormGroup.get('services') as FormArray;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
