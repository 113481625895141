import { createAction, props } from '@ngrx/store';

import { ContactUs } from './../../../../models/contact-us.model';


export const getShowAroundDates = createAction(
  '[Get Dates] Getting Date',
   props<{dates: string[]}>()
);

export const updateUserContactUsInfo = createAction(
  '[Save contactInfo] Saving contactInfo to database',
  props<{contactInfo: ContactUs}>()
);

export const saveUserContactUs = createAction(
  '[Save contactInfo] Saving contactInfo to database',
  props<{contactInfo: ContactUs}>()
);

export const saveUserContactInfoSuccess = createAction(
  '[Save saveUserContactInfoSuccess Success] Saving saveUserContactInfoSuccess to database was successful',
  props<{contactInfo: ContactUs}>()
);

export const initUserContactInfoStore = createAction(
  '[Save initUserContactInfoStore Success] Saving initUserContactInfoStore to database was successful',
  props<{contactInfo: ContactUs}>()
);

export const saveUserContactInfoError404 = createAction(
  '[Save saveUserContactInfoError404 Success] Saving saveUserContactInfoError404',
  props<{contactInfo: ContactUs}>()
);

export const saveUserContactInfoError = createAction(
  '[Save saveUserContactInfoError Error] Saving saveUserContactInfoError to database was error',
  props<{contactInfo: ContactUs}>()
);
