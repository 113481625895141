import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  Renderer2,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarComponent } from 'ng-uikit-pro-standard';
import { HeaderHideShowService } from '../../../../nursery/services/header-hide-show.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenStorageService } from '../../../../auth/_services/token-storage.service';
import { User } from '../../../../nursery/models/user.model';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-mega-menu-bar',
  templateUrl: './mega-menu-bar.component.html',
  // styleUrls: ['./mega-menu-bar.component.scss']
})
export class MegaMenuBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('nav', { static: true }) nav: NavbarComponent;

  isLoggedIn = false;
  header = true;
  destroySubject: Subject<any> = new Subject<any>();
  authenticated = false;
  currentUser: User;
  isRegistration: boolean;

  constructor(
    private router: Router,
    private appService: AppService,
    private tokenStorage: TokenStorageService,
    private _headerHideShowService: HeaderHideShowService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    /** Making Navbar Item Active On Click **/
    $(document).ready(function () {
      const selector = '.nav-item';
      $(selector).on('click', function () {
        $(selector).removeClass('active');
        $(this).addClass('active');
      });
    });

  }

  transformDropdowns() {
    const dropdownMenu = Array.from(
      this.el.nativeElement.querySelectorAll('.dropdown-menu')
    );
    const navHeight = this.nav.navbar.nativeElement.clientHeight + 'px';

    dropdownMenu.forEach((dropdown) => {
      this.renderer.setStyle(dropdown, 'transform', `translateY(${navHeight})`);
    });
  }

  @HostListener('click', [])
  onClick() {
    const toggler = this.el.nativeElement.querySelector('.navbar-toggler');
    const togglerIcon = this.el.nativeElement.querySelector(
      '.navbar-toggler-icon'
    );
    if (event.target === toggler || event.target === togglerIcon) {
      console.log('test');
      setTimeout(() => {
        this.transformDropdowns();
      }, 351);
    }
  }

  @HostListener('document:scroll', [])
  onScroll() {
    this.transformDropdowns();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.transformDropdowns();
  }

  ngAfterViewInit() {
    this.transformDropdowns();
  }

  ngOnInit() {
    if (this.router.url !== null && this.router.url !== '/') {
      this.header = false;
    } else {
      this.header = true;
    }
    this._headerHideShowService.change
      .pipe(takeUntil(this.destroySubject))
      .subscribe((isHeaderShown: boolean) => {
        this.header = isHeaderShown;
      });

    this.tokenStorage.currentUserSubject.subscribe((user) => {
      if (user != null) {
        this.currentUser = user;
        this.isLoggedIn = true;
      } else {
        this.currentUser = null;
        this.isLoggedIn = false;
        sessionStorage.removeItem('auth-accessToken');
      }
    });
    this.appService.isRegistration.subscribe(response => {
      this.isRegistration = response
    });

  }

  changeHeader(path: string) {
    this.header = false;
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  logout() {
    this.tokenStorage.signOut();
    this.router.navigate(['/']);
  }
}
