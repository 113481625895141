import { Component, OnInit } from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-our-gallery',
  templateUrl: './our-gallery.component.html',
  styleUrls: ['./our-gallery.component.scss']
})
export class OurGalleryComponent implements OnInit {

  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) { }

  ngOnInit() {

  }

}
