import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { selectNannyFromStore } from '../../../store/selectors/nanny-service.selector';
import { ModalToasterService } from './../../../../../../shared/components/modal-toaster/modal-toaster.sevrice';
import { NannyServiceModel } from './../../../../../../shared/models/nanny-service.model';
import { saveConfirmPaymentAmount, saveConfirmPaymentAmountError } from './../../../store/actions/nanny-service.action';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {
  nannyData: NannyServiceModel;
  @Output() emitGoBack = new EventEmitter();
  destroy$ = new Subject();
  constructor(private store: Store<NannyServiceModel>,
    private updates$: Actions,
    private modal: ModalToasterService,
  ) { }

  ngOnInit() {
    this.store.pipe(select(selectNannyFromStore)).subscribe(response => {
      this.nannyData = response.nannyService;
    });
    this.updates$.pipe(ofType(saveConfirmPaymentAmountError))
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.modal.toggleModal.next(true);
      });
  }

  goBack(): void {
    this.emitGoBack.emit();
  }

  submit(): void {
    this.store.dispatch(saveConfirmPaymentAmount({ nannyService: this.nannyData }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
