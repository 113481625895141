import { createAction, props } from '@ngrx/store';

import { Address } from '../../../../models/address.model';
import { Career } from '../../../../models/careers.model';
import { Child } from '../../../../models/child.model';
import { ChildSession } from '../../../../models/child.session';
import { ContactUs } from '../../../../models/contact-us.model';
import { ContinousProvisionPlanning } from '../../../../models/continous-provision-planning.model';
import { FocussedActivity } from '../../../../models/focussed-activity.model';
import { NannyRegistration } from '../../../../models/nanny-registration.model';
import { Onboard } from '../../../../models/onboard.model';
import { Parent } from '../../../../models/parent.model';
import { ConsentData, MedicalDetails, Registration } from '../../../../models/registration.model';
import { ImmuneDetails, IndividualDetail } from './../../../../models/registration.model';


// Save Contact Us
export const saveContactUs = createAction(
  '[Child Session] Adding Child Session',
  props<{contactUs: ContactUs}>()
);

export const saveContactUsSuccess = createAction(
  '[Save Child Session Reg Success] Saving Child Session Reg to database was successful',
  props<{contactUs: ContactUs}>()
);

export const saveContactUsError = createAction(
  '[Save Child Session Reg Error] Saving Child Session Reg to database was not successful',
  props<{contactUs: ContactUs}>()
);

// Save Career Form Info
export const saveCareers = createAction(
  '[Child Session] Adding Child Session',
  props<{careers: Career}>()
);

export const saveCareersSuccess = createAction(
  '[Save Child Session Reg Success] Saving Child Session Reg to database was successful',
  props<{careers: Career}>()
);

export const saveCareersError = createAction(
  '[Save Child Session Reg Error] Saving Child Session Reg to database was not successful',
  props<{careers: Career}>()
);

// Save Onboard Form Info
export const saveOnboard = createAction(
  '[Child Session] Adding Child Session',
  props<{onboard: Onboard}>()
);

export const saveOnboardSuccess = createAction(
  '[Save Child Session Reg Success] Saving Child Session Reg to database was successful',
  props<{onboard: Onboard}>()
);

export const saveOnboardError = createAction(
  '[Save Child Session Reg Error] Saving Child Session Reg to database was not successful',
  props<{onboard: Onboard}>()
);

// Save Continous Provision Planning Form Info
export const saveContinousPlanning = createAction(
  '[Child Session] Adding Child Session',
  props<{continousPlanning: ContinousProvisionPlanning}>()
);

export const saveContinousPlanningSuccess = createAction(
  '[Save Child Session Reg Success] Saving Child Session Reg to database was successful',
  props<{continousPlanning: ContinousProvisionPlanning}>()
);

export const saveContinousPlanningError = createAction(
  '[Save Child Session Reg Error] Saving Child Session Reg to database was not successful',
  props<{continousPlanning: ContinousProvisionPlanning}>()
);

// Save Focussed Activity Form Info
export const saveFocussedActivity = createAction(
  '[Child Session] Adding Child Session',
  props<{focussedActivity: FocussedActivity}>()
);

export const saveFocussedActivitySuccess = createAction(
  '[Save Child Session Reg Success] Saving Child Session Reg to database was successful',
  props<{focussedActivity: FocussedActivity}>()
);

export const saveFocussedActivityError = createAction(
  '[Save Child Session Reg Error] Saving Child Session Reg to database was not successful',
  props<{focussedActivity: FocussedActivity}>()
);

// Save Nanny Registration Form Info
export const saveNannyRegistration = createAction(
  '[Child Session] Adding Child Session',
  props<{nannyRegistration: NannyRegistration}>()
);

export const saveNannyRegistrationSuccess = createAction(
  '[Save Child Session Reg Success] Saving Child Session Reg to database was successful',
  props<{nannyRegistration: NannyRegistration}>()
);

export const saveNannyRegistrationError = createAction(
  '[Save Child Session Reg Error] Saving Child Session Reg to database was not successful',
  props<{nannyRegistration: NannyRegistration}>()
);


// Medical details Handling
export const addDoctorAddress = createAction(
  '[Medical Details Doctor Address] Adding Medical Details Doctor',
    props<{address: Address}>()
);


export const addMedicalDetails = createAction(
  '[Medical Details] Adding Medical Details',
    props<{medicalDetails: MedicalDetails}>()
);

export const saveMedicalDetails = createAction(
  '[Save Medical Details] Saving Medical Details to database',
  props<{registration: Registration}>()
);

export const saveMedicalDetailsSuccess = createAction(
  '[Save Medical Details Success] Saving Medical Details to database was successful',
  props<{registration: Registration}>()
);

export const saveMedicalDetailsError = createAction(
  '[Save Medical Details Error] Saving Medical Details to database was not successful',
  props<{registration: Registration}>()
);

//

export const addImmuneDetails = createAction(
  '[Immune Details] Adding Immune Details',
    props<{immuneDetails: ImmuneDetails}>()
);

export const saveImmuneDetails = createAction(
  '[Save Immune Details] Saving Immune Details to database',
  props<{registration: Registration}>()
);

export const saveImmuneDetailsSuccess = createAction(
  '[Save Immune Details Success] Saving Immune Details to database was successful',
  props<{registration: Registration}>()
);

export const saveImmuneDetailsError = createAction(
  '[Save Immune Details Error] Saving Immune Details to database was not successful',
  props<{registration: Registration}>()
);

//

export const addIndividualDetails = createAction(
  '[Individual Details] Adding Individual Details',
    props<{individual: IndividualDetail}>()
);

export const saveIndividualDetails = createAction(
  '[Save Individual Details] Saving Individual Details to database',
  props<{registration: Registration}>()
);

export const saveIndividualDetailsSuccess = createAction(
  '[Save Individual Details Success] Saving Individual Details to database was successful',
  props<{registration: Registration}>()
);

export const saveIndividualDetailsError = createAction(
  '[Save Individual Details Error] Saving Individual Details to database was not successful',
  props<{registration: Registration}>()
);

//

export const addConsentDetails = createAction(
  '[Consent Details] Adding Consent Details',
    props<{consent: ConsentData[]}>()
);

export const saveConsentDetails = createAction(
  '[Save Consent Details] Saving Consent Details to database',
  props<{registration: Registration}>()
);

export const saveConsentDetailsSuccess = createAction(
  '[Save Consent Details Success] Saving Consent Details to database was successful',
  props<{registration: Registration}>()
);

export const saveConsentDetailsError = createAction(
  '[Save Consent Details Error] Saving Consent Details to database was not successful',
  props<{registration: Registration}>()
);
