
<!-- Onboard Section -->
<section id="learning-lifecycle">
  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center mb-4" style="color: #FFBB33;">Children Learning Cycle</h1>

  <!-- Lifecycle Circle -->
  <div class="row pt-4 pb-4">
    <div class="col-lg-12">
      <div class="lifecycle">
        <h3>Learning Cycle</h3>
        <div class="arrow-img"></div>
      </div>
      <ul class='circle-container'>
        <li>
          <a data-toggle="modal" data-target="#planningModal" (click)="frame.show()"><img src='../../../../../assets/img/icons/circle-one.png' alt="Step 1 - Planning"></a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#resourceModal" (click)="frame2.show()"><img src='../../../../../assets/img/icons/circle-two.png' alt="Step 2 - Resource Collection"></a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#activityModal" (click)="frame3.show()"><img src='../../../../../assets/img/icons/circle-three.png' alt="Step 3 - Activity"></a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#observationModal" (click)="frame4.show()"><img src='../../../../../assets/img/icons/circle-four.png' alt="Step 4 - Observation"></a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#approvalModal" (click)="frame5.show()"><img src='../../../../../assets/img/icons/circle-five.png' alt="Step 5 - Approval By The Manager"></a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#parentsModal" (click)="frame6.show()"><img src='../../../../../assets/img/icons/circle-six.png' alt="Step 6 - Sent To Parents"></a>
        </li>
        <li>
          <a data-toggle="modal" data-target="#nextActivityModal" (click)="frame7.show()"><img src='../../../../../assets/img/icons/circle-seven.png' alt="Step 7 - Planning of Next Activity"></a>
        </li>
      </ul>

    </div>
  </div>
  <!-- End Lifecycle Circle -->

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="row pt-5">
      <div class="col-lg-12">
        <p class="section-text text-left wow slideInDown mt-2"><strong>Early years education forms the essential part in the overall development of the child. It is during this period that a kid learns by engaging its sense of sight, smell, touch and hearing. </strong></p>
        <p class="section-text text-left wow slideInDown">For instance, with the help of sense of touch the child learns about different shapes, sizes of objects, hearing helps the child develop the art of listening and understanding about the world around them and sense of sight helps them to identify objects, patterns and words.</p>
        <p class="section-text text-left wow slideInDown">Our excellent program would provide your kid a variety of learning experiences that are fun, exciting and learning oriented.</p>
        <p class="section-text text-left wow slideInDown"><strong>We introduce your child with innovative ideas and concepts along with the following skills:</strong></p>
        <ul class="para-list">
          <li>Learning to coordinate and cooperate with each other</li>
          <li>Improving communication skills</li>
          <li>Expressing their creative side.</li>
          <li>To become aware of the surroundings</li>
          <li>Building up confidence</li>
        </ul>
        <p class="section-text text-justify mb-2"><strong>With the help of the seven areas of EYFS we help the child learn and development helps the child to effectively acquire skills, new knowledge and nurture them aesthetically and globally.</strong> </p>
      </div>
    </div>
  </div>

</section>
<!-- Onboard Section End -->

<!-- Step 1 Planning Modal  -->
<div mdbModal #frame="mdbModal" class="modal fade top right" id="planningModal" tabindex="-1" role="dialog" aria-labelledby="planningModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step1">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 1: Planning</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/planning-1.jpg" alt="Step 1 - Planning" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text text-justify mb-2">The curriculum is planned using the Early Years Foundation Stage, here the Practitioner follows a smooth routine that consists of free choice of activities and experiences and is pursued by the group time, where the key person begins the day with 1 week comprehensive planning. <span id="readmore-link-one" class="read-more">read more..</span></p>
            <div id="readmore-text-one">
              <p class="section-text text-justify mb-2">While planning, the practitioner selects activity that is of great interest to the children and also considers how interesting activities can be assimilated into a theme.</p>
              <p class="section-text text-justify mb-2">All the activities are based around the age, stage and preference and skills of the children. Moreover, our thoughtfully curated plan ensures that children have fun in a developmentally appropriate ways. All the activities are planned to assist children in their development.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="section-text text-justify mb-2">As the child joins the nursery, the practitioner observes him/her for 2 weeks. Free play activities are carried out for 2 weeks where the practitioner closely watches the child. Thorough analysis and observation help them to find out their interest. <span id="readmore-link-two" class="read-more">read more..</span></p>
            <div id="readmore-text-two">
              <p class="section-text text-justify mb-2">Early years education forms the essential part in the overall development of the child. It is during this period that a kid learns by engaging its sense of sight, smell, touch and hearing.</p>
              <p class="section-text text-justify mb-2">For instance, with the help of sense of touch the child  learns about different shapes, sizes of objects, hearing helps the child develop the art of listening and understanding about the world around them and sense of sight helps them to identify objects, patterns and words.</p>
              <p class="section-text text-justify mb-2">Our excellent program would provide your kid a variety of learning experiences that are fun, exciting and learning oriented. </p>
              <ul class="para-list">
                <li>We introduce your child with innovative ideas and concepts along with the following skills:</li>
                <li>Learning to coordinate and cooperate with each other</li>
                <li>Improving communication skills</li>
                <li>Expressing their creative side.</li>
                <li>To become aware of the surroundings</li>
                <li>Building up confidence</li>
              </ul>
              <p class="section-text text-justify mb-2">With the help of the seven areas of EYFS we help the child learn and development helps the child to effectively acquire skills, new knowledge and nurture them aesthetically and globally. </p>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/planning-2.jpg" alt="Step 1 - Planning Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="col-12">
          <mdb-image-modal [modalImages]="planningGallery"></mdb-image-modal>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Step 2 Resource Collection Modal  -->
<div mdbModal #frame2="mdbModal" class="modal fade top right" id="resourceModal" tabindex="-1" role="dialog" aria-labelledby="resourceModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step2">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 2: Resource Collection</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/resource-collection-1.jpg" alt="Step 2 - Resource Collection" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text text-justify mb-2">All the resources that are needed to conduct the activity are listed down by the practitioner and goes to the manager for approval.</p>
            <p class="section-text text-justify mb-2">Here the practitioner defines the purpose of the selected activity and all those areas of EYFS that it would cover. <span id="readmore-link-three" class="read-more">read more..</span></p>
            <div id="readmore-text-three">
              <p class="section-text text-justify mb-2">For instance, if the activity is based on Apple Print Pumpkins, here all the items that are related to this activity such as – Apple, Paint, Paper and Black Marker are listed down. </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <p class="section-text text-justify mb-2">With the help of this activity the practitioner would cover the seven areas of EYFS – <span id="readmore-link-four" class="read-more">read more..</span></p>
            <div id="readmore-text-four">
              <p class="section-text text-justify">Understanding, Personal, Social, and Emotional: Self-awareness and self-confidence, Physical Development: Moving and Handling, Expressive Art and Design: Being Imaginative and exploring and using media and material and Communication & language: Speaking. Here the linking is based on the area of the related activity.</p>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/resource-collection-2.jpg" alt="Step 2 - Resource Collection Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>

      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="col-12">
          <mdb-image-modal [modalImages]="resourceGallery"></mdb-image-modal>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Step 3 Activity Modal  -->
<div mdbModal #frame3="mdbModal" class="modal fade top right" id="activityModal" tabindex="-1" role="dialog" aria-labelledby="activityModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step3">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 3: Activity</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/activity-1.jpg" alt="Step 3 - Activity" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text text-justify mb-2">The practitioner carries out the activities in two ways – Focussed and Group Activity. Here we follow three focused activity and two group activities in the classroom. <span id="readmore-link-five" class="read-more">read more..</span></p>
            <div id="readmore-text-five">
              <p class="section-text text-justify mb-2">Focussed activity helps the child’s brain to stop focussing on anything else but the task at hand. It helps to improve the child’s mental timing that speeds up the child’s ability to process and respond to the command, whereas in the group activity we help children to work closely with a team towards a common goal, through this we motivate children to work as a team.</p>
              <p class="section-text text-justify mb-2">In the focussed activity we follow the activity according to the interest of the individual child. It is a 1-1 activity that is carried out with the help of the practitioner. We involve the kids in lots of games or activities which helps children practice focus and self-control.</p>
              <p class="section-text text-justify mb-2">This way the practitioner closely observes the child while he/she is engaged in the activity, make note and then links it to the seven areas of EYFS.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="section-text text-justify mb-2">Whereas in the group a general activity is carried out that involves the overall interest of the children. Here the practitioner watches the children more closely and observe their behaviour when they are working with a team. <br/><span id="readmore-link-six" class="read-more">read more..</span></p>
            <div id="readmore-text-six">
              <p class="section-text text-justify mb-2">This way we help the kids enhance their social skills, conversational skills through structured interaction. Here again the practitioner makes the note and links it to EYFS.</p>
              <p class="section-text text-justify mb-2">Activities that link to the kid’s interest are used to motivate children to access different areas of EYFS curriculum.</p>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/activity-2.jpg" alt="Step 3 - Activity Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="col-12">
          <mdb-image-modal [modalImages]="activityGallery"></mdb-image-modal>
        </div>
      </div>
      <!--Footer -->
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Step 4 Observation Modal  -->
<div mdbModal #frame4="mdbModal" class="modal fade top right" id="observationModal" tabindex="-1" role="dialog" aria-labelledby="observationModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step4">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 4: Observation</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame4.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/observation-1.jpg" alt="Step 4 - Observation" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text text-justify mb-2">Practitioner observes the children, take written notes and photographs while the kids are engaged in the activity. Observations are made about the child’s development and the activities that they enjoy. <span id="readmore-link-seven" class="read-more">read more..</span></p>
            <div id="readmore-text-seven">
              <p class="section-text text-justify mb-2">We mark the kids according to three levels: Secure, Emerging and Developing. For instance, if the practitioner is teaching the child 1-5 and if he/she manages to grasp it then the practitioner marks that child as Secure, but if a child manages to lean only 1-3, the practitioner marks the child as Emerging. This way the practitioner keeps on moving ahead with the child until he/she gets a secure level and knows when the child is ready to move on.</p>
              <p class="section-text text-justify mb-2">Progress of the children are then linked to the seven areas of EYFS – Physical Development, Communication and Language, Personal, Social and Emotional Development, Maths, Literacy, Understanding the World and Expressive Arts and Designs and is supported with images and videos.</p>
              <p class="section-text text-justify mb-2">These regular assessments and analysis helps the practitioner track individual child’s performance and progress and help them to highlight any areas of difficulty that will benefit them from additional support.</p>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-8">
            <p class="section-text text-justify mb-2">Our regular observations help the practitioner to: <span id="readmore-link-eight" class="read-more">read more..</span></p>
            <div id="readmore-text-eight">
              <ul class="para-list mb-2">
                <li>Better understand the child</li>
                <li>Helps them to document the development</li>
                <li>Helps to show the child’s communication style</li>
              </ul>
              <p class="section-text text-justify mb-2">With the help of detailed documentation the practitioner are able to gain insights as to how the child is coping up in the classroom.</p>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/observation-2.jpg" alt="Step 4 - Observation Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="col-12">
          <mdb-image-modal [modalImages]="observationGallery"></mdb-image-modal>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Step 5 Approval Modal  -->
<div mdbModal #frame5="mdbModal" class="modal fade left" id="approvalModal" tabindex="-1" role="dialog" aria-labelledby="approvalModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step5">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 5: Approval By The Manager</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame5.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/manager-approval-1.jpg" alt="Step 5 - Approval By The Manager" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text text-justify mb-2">All the observations of the kids with the links to the seven areas of EYFS are listed down on one of the most advanced App and goes to the manager for approval. The manager keeps the track of every activity in the nursery.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="section-text text-justify">It is only after the consent of the manager that the assessment, report or the daily activity of the child gets displayed to the manager.</p>
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/manager-approval-2.png" alt="Step 5 - Approval By The Manager Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="col-12">
          <mdb-image-modal [modalImages]="managerApprovalGallery"></mdb-image-modal>
        </div>
      </div>
      <!--Footer -->
    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Step 6 Parents Modal  -->
<div mdbModal #frame6="mdbModal" class="modal fade left" id="parentsModal" tabindex="-1" role="dialog" aria-labelledby="parentsModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step6">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 6: Sent To Parents</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame6.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/parents-1.jpg" alt="Step 6 - Sent to Parents" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text mb-2 text-justify">The final stage to observation is bringing the information to parents and working with them closely to make sure that the kid is supported both at home and in the classroom. <span id="readmore-link-nine" class="read-more">read more..</span></p>
            <div id="readmore-text-nine">
              <p class="section-text mb-2 text-justify">Parents love to see their children grow hence; we send observations, links of the seven areas of EYFS and next activity to the parents through one of the most advanced App. This helps to bridge the gap between the parents and the nursery.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="section-text mb-2 text-justify">Parents are able to receive live updates, view observations and progress, links of the seven areas of EYFS, images and videos. <span id="readmore-link-ten" class="read-more">read more..</span></p>
            <div id="readmore-text-ten">
              <p class="section-text mb-2 text-justify">Moreover, parents can like and share their feedback too. This helps us to establish good communication with the parents.</p>
              <p class="section-text text-justify">With our daily diary feature in the App parents stay connected with their children’s day at the nursery.</p>
            </div>
            </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/parents-2.jpg" alt="Step 6 - Sent to Parents Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>
      </div>

      <!--Footer-->

      <!--Footer -->

    </div>
    <!--/.Content-->
  </div>
</div>

<!-- Step 7 Activity Modal  -->
<div mdbModal #frame7="mdbModal" class="modal fade left" id="nextActivityModal" tabindex="-1" role="dialog" aria-labelledby="nextActivityModal"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header step7">
        <h3 class="heading lead text-uppercase font-weight-bold mx-auto">STEP 7: Planning of Next Activity</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame7.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row pb-3">
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/next-activity-1.jpg" alt="Step 7 - Planning of Next Activity" class="img-fluid z-depth-1-half rounded-circle">
          </div>
          <div class="col-8">
            <p class="section-text mb-2 text-justify">The planning of the next step is based on the initial observations and assessment of the child. The next step is framed using the practitioner knowledge of child development, which is supported by the next steps in the EYFS. <span id="readmore-link-eleven" class="read-more">read more..</span></p>
            <div id="readmore-text-eleven">
              <p class="section-text mb-2 text-justify">The next step activity is applicable for both – group as well as individual children. For instance, if the kid is involved in the activity that is called “Name Different Animals, their colors and make sound” here the kids are looking at the flash cards and naming the animals that they see and if they get the correct answer they get a point.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <p class="section-text mb-2 text-justify">As the practitioner displays the flashcards the kids’ jumps with joy and answers all the questions. Here the children recognised the animals and did very well. <span id="readmore-link-twelve" class="read-more">read more..</span></p>
            <div id="readmore-text-twelve">
              <p class="section-text mb-2 text-justify">Here the NEXT STEPS would be to encourage the children to actively show more level of engagement and interest of storytelling books with colourful images. This is how the practitioner plans out the next step.</p>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <img src="../../../../../assets/img/icons/next-activity-2.jpg" alt="Step 7 - Planning of Next Activity Two" class="img-fluid z-depth-1-half rounded-circle">
          </div>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="col-12">
          <mdb-image-modal [modalImages]="nextActivityGallery"></mdb-image-modal>
        </div>
      </div>
      <!--Footer -->

    </div>
    <!--/.Content-->
  </div>
</div>


