import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toddler-room-fee',
  templateUrl: './toddler-room-fee.component.html',
  //styleUrls: ['./toddler-room-fee.component.scss']
})
export class ToddlerRoomFeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
