import { createFeatureSelector, createSelector } from '@ngrx/store';

import { userContactStateFeatureKey, UserContactUsState } from '../reducers/user-contact-us.reducer';

export const selectUserContactUsState = createFeatureSelector<UserContactUsState>(userContactStateFeatureKey);

export const selectUserContactFromStore = createSelector(selectUserContactUsState,
  (d: UserContactUsState) => {
    return d;
  }
);


