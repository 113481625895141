<ng-container [formGroup]="focussedActivityForm">
   
    <!-- Form Row-->
    <div class="form-row">
        <!-- Grid column -->
        <div class="col-lg-6 col-sm-12">
            <div class="md-form">
            <textarea type="text" id="childInitials" formControlName="childInitials" class="md-textarea form-control" mdbInput mdbValidate></textarea>
            <label for="childInitials">Activity Area of learning Child’s initials
                </label>
            <mdb-error *ngIf="submitted && form1.childInitials.errors">Please provide answer</mdb-error>
            </div>
        </div>
        <!-- Grid column -->
        <div class="col-lg-6 col-sm-12">
            <div class="md-form">
            <textarea type="text" id="resources" formControlName="resources" class="md-textarea form-control" mdbInput mdbValidate></textarea>
            <label for="resources">Resources needed to conduct activity</label>
            <mdb-error *ngIf="submitted && form1.resources.errors">Please provide answer</mdb-error>
            </div>
        </div>
    </div>

    <!-- Form Row-->
    <div class="form-row">
        <!-- Grid column -->
        <div class="col-lg-6 col-sm-12">
            <div class="md-form">
            <textarea type="text" id="linkEYFS" formControlName="linkEYFS" class="md-textarea form-control" mdbInput mdbValidate></textarea>
            <label for="linkEYFS">How is this activity linked to the EYFS</label>
            <mdb-error *ngIf="submitted && form1.linkEYFS.errors">Please provide answer</mdb-error>
            </div>
        </div>
        <!-- Grid column -->
        <div class="col-lg-6 col-sm-12">
            <div class="md-form">
            <textarea type="text" id="reasonForActivity" formControlName="reasonForActivity" class="md-textarea form-control" mdbInput mdbValidate></textarea>
            <label for="reasonForActivity">Reasons why you have decided to conduct this activity</label>
            <mdb-error *ngIf="submitted && form1.reasonForActivity.errors">Please provide answer</mdb-error>
            </div>
        </div>
    </div>

    <!-- Form Row-->
    <div class="form-row">
        <!-- Grid column -->
        <div class="col-lg-6 col-sm-12">
            <div class="md-form">
            <textarea type="text" id="supportChildren" formControlName="supportChildren" class="md-textarea form-control" mdbInput mdbValidate></textarea>
            <label for="supportChildren">How will you support the children during this activity?</label>
            <mdb-error *ngIf="submitted && form1.supportChildren.errors">Please provide answer</mdb-error>
            </div>
        </div>
        <!-- Grid column -->
        <div class="col-lg-6 col-sm-12">
            <div class="md-form">
            <textarea type="text" id="evaluation" formControlName="evaluation" class="md-textarea form-control" mdbInput mdbValidate></textarea>
            <label for="evaluation">Evaluation/ what was the outcome/ what can be improved for next time</label>
            <mdb-error *ngIf="submitted && form1.evaluation.errors">Please provide answer</mdb-error>
            </div>
        </div>
    </div>

    <button mdbBtn type="button" color="danged" rounded="true" class="z-depth-0 my-4 waves-effect" [hidden]="day=='Monday'" (click)="goBack(step)" mdbWavesEffect>PREVIOUS</button>
    <button mdbBtn type="button" color="info" rounded="true" class="z-depth-0 my-4 waves-effect float-right" [innerHtml]="day!=='Friday' ? 'NEXT': 'SUBMIT' " (click)="goForward();" mdbWavesEffect>NEXT</button>
  </ng-container>
  