import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MdbStepperComponent, ToastService} from 'ng-uikit-pro-standard';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, FormGroupName, FormBuilder, Validator, Validators, AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import {Router} from '@angular/router';


@Component({
  selector: 'app-eligibility-step-one',
  templateUrl: './eligibility-step-one.component.html',
  // styleUrls: ['./eligibility-step-one.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EligibilityStepOneComponent),
      multi: true
    },
      {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EligibilityStepOneComponent),
      multi: true
    }
  ]
})
export class EligibilityStepOneComponent implements OnInit, ControlValueAccessor, Validator {

  submitted = false;

  living: '';

  @Input() stage: string;

  @Input() step: MdbStepperComponent;

  checkEligibilityFormOne: FormGroup;

  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastService) { }

  ngOnInit() {
    this.checkEligibilityFormOne = this.formBuilder.group({
      living: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get form1() { return this.checkEligibilityFormOne.controls; }

  goBack(stepper: MdbStepperComponent) {
    stepper.previous();
  }

  goForward() {
    if (this.checkEligibilityFormOne.controls['living'].value === 'No') {
      console.log('Its No');
    }

    if (this.checkEligibilityFormOne.value) {
    if (this.checkEligibilityFormOne.invalid) {
      this.showWarning();
      return;
    } else {
      if (this.stage !== 'Step 4') {
        console.log(' Data For ' + this.stage + ':' + JSON.stringify(this.checkEligibilityFormOne.value));
        this.step.next();
      } else {
        console.log(' Data For ' + this.stage + ':' + JSON.stringify(this.checkEligibilityFormOne.value));
        this.showSuccess();
      }
    }
    }

  }

  showSuccess() {
    this.toastrService.info('You are eligible for 30 hours. For more details visit government website.');
    setTimeout(function() { location.reload(); }, 3000);
  }

  showWarning() {
    this.toastrService.warning('Please Select Value!!');
  }

  onReset() {
    this.submitted = false;
    this.checkEligibilityFormOne.reset();
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.checkEligibilityFormOne.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    // console.log("on change");
    this.checkEligibilityFormOne.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    // console.log("on blur");
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.checkEligibilityFormOne.disable() : this.checkEligibilityFormOne.enable();
  }
  validate(c: AbstractControl): ValidationErrors | null {
    console.log('Eligibility Form validation', c);
    return this.checkEligibilityFormOne.valid ? null : { invalidForm: {valid: false, message: 'Eligibilty Check Form Invalid'}};
  }

}
