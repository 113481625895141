import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent  {

  @Input() array = [];
  @Input() isClickAble: boolean;

  @Output() clickEmit = new EventEmitter();

  clicked(item): void {
    this.clickEmit.emit(item)
  }
}
