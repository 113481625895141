import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {RegistrationState} from '../../registration/store/reducers/registration.reducer';
import {selectEyfsFromStore} from '../store/selectors/eyfs.selector';
import {Eyfs} from '../../../models/eyfs.model';

@Component({
  selector: 'app-eyfs-right-section',
  templateUrl: './eyfs-right-section.component.html'
})
export class EyfsRightSectionComponent implements OnInit {

  // public checkModel: any = { left: true, middle: false, right: false };
  public radioModel = '';

  comLanguageForm1: FormGroup;
  comLanguageForm2: FormGroup;
  comLanguageForm3: FormGroup;
  comLanguageForm4: FormGroup;
  comLanguageForm5: FormGroup;
  comLanguageForm6: FormGroup;
  comLanguageForm7: FormGroup;
  comLanguageForm8: FormGroup;

  constructor(private store: Store<RegistrationState>) { }

  ngOnInit() {
    this.store.pipe(select(selectEyfsFromStore)).subscribe((eyfs: Eyfs) => {
        console.log('--- EyfsRightSectionComponent Main Area ---' + eyfs.mainArea);
        console.log('--- EyfsRightSectionComponent Sub Area ---' + eyfs.subArea);

      }
    );
  }

  onSubmit() {
  }

}
