import { AppointmentTypeModel } from './appointment-type.model';
import { TimeSlotModel } from './timeslot.model';

export class AppointmentModel {
  appointmentDate: string;
  appointmentPriority: string;
  appointmentType: AppointmentTypeModel;
  timeSlot = new TimeSlotModel();
  timeslotAllocatedQuota: number;
  timeslotRemainingQuota: number;
  index?: number;
  id: string;

  constructor(appointmentDate = null,
    appointmentPriority = null,
    appointmentType = null,
    timeSlot = new TimeSlotModel(),
    timeslotAllocatedQuota = null,
    timeslotRemainingQuota = null,
    index = null,
    id = null) {
    this.appointmentDate = appointmentDate;
    this.appointmentPriority = appointmentPriority;
    this.appointmentType = appointmentType;
    this.timeSlot = timeSlot;
    this.timeslotAllocatedQuota = timeslotAllocatedQuota;
    this.timeslotRemainingQuota = timeslotRemainingQuota;
    this.index = index;
    this.id = id;
  }
}
