<div class="page-wrapper">

  <!-- Header Section -->
  <app-header fxLayout="column"></app-header>
  <!-- End Header Section -->

  <!-- Sidebar Section -->
  <app-sidebar fxLayout="column" fxFlex="300px"></app-sidebar>
  <!-- End Sidebar Section -->

  <!-- Main section -->
  <main class="main-wrapper">

    <!-- Snow Fall Animation -->
    <!--    <app-snow-fall></app-snow-fall>-->
    <!-- Snow Fall Animation -->

    <!-- Breadcrumb Section -->
    <breadcrumb #parent *ngIf="showBreadCrumb">
      <ol class="breadcrumb">

        <li  class="breadcrumb-item ">
          <a href="#" [routerLink]="['/']">
            <mdb-badge info="true">Home</mdb-badge>
          </a>
        </li>

        <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
          <li *ngIf="!route.terminal" class="breadcrumb-item">
            <a href="#" [routerLink]="[route.url]">
              <mdb-badge default="true">{{ route.displayName }}</mdb-badge>
            </a>
          </li>
          <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">
            <mdb-badge warning="true">{{ route.displayName }}</mdb-badge>
          </li>
        </ng-template>

      </ol>
    </breadcrumb>
    <!-- End Breadcrumb Section -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </main>
  <!-- End Main Section -->

  <!-- Footer Section-->
  <app-footer fxLayout="column"></app-footer>
  <!-- End Footer Section -->

  <!-- Side Menu Links -->
  <div id="sidelinks" class="side-menu-links d-lg-block d-md-block d-none">
    <ul>
      <li>
        <a class="d-flex w-100 align-items-center justify-content-around" href="tel:+44-203-887-0220" title="tel:+44-203-887-0220">
          <span class="side-link-call">
            <mdb-icon fas="" icon="phone" class="fas"><i class="fas fa-phone"></i></mdb-icon>
          </span>
          <span class="icon-text">Call us</span>
        </a>
      </li>
      <li>
        <a class="d-flex w-100 align-items-center justify-content-around" href="https://wa.me/447824832415" target="_blank" title="whats app at 447824832415">
          <span class="side-link-email">
            <i class="fab fa-whatsapp"></i>
          </span>
          <span class="icon-text">Whats App</span>
        </a>
      </li>
      <li>
        <a class="d-flex w-100 align-items-center justify-content-around" href="#" [routerLink]="['/contact-us']" title="Contact Us">
          <span class="side-link-email">
            <mdb-icon fas="" icon="envelope-open" class="fas"><i class="fas fa-envelope-open"></i></mdb-icon>
          </span>
          <span class="icon-text">Contact Us</span>
        </a>
      </li>
      <li>
        <a class="d-flex w-100 align-items-center justify-content-around" href="https://www.facebook.com/risenshinenursery" target="_blank" title="risenshinenursery">
          <span class="side-link-email">
            <mdb-icon fab icon="facebook-square"></mdb-icon>
          </span>
          <span class="icon-text">Facebook</span>
        </a>
      </li>
      <li>
        <a class="d-flex w-100 align-items-center justify-content-around" href="https://www.instagram.com/risenshinenursery" target="_blank" title="risenshinenursery">
          <span class="side-link-email">
            <mdb-icon fab icon="instagram"></mdb-icon>
          </span>
          <span class="icon-text">Instagram</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- End Side Menu Links -->

  <!-- Member Links -->
  <div id="member-links" class="side-member-links d-lg-block d-md-block d-none">
    <ul>
      <li>
        <a [routerLink]="['/associated-members']" title="Ofsted Registered" class="side-link-ndna">
          <img src="../../../../assets/img/icons/ofsted_registered.png" alt="Ofsted Registered" width="75">
        </a>
      </li>
      <li>
        <a [routerLink]="['/associated-members']" title="NDNA" class="side-link-ndna">
          <img src="../../../../assets/img/icons/ndna.png" alt="NDNA" width="75">
        </a>
      </li>
      <li>
        <a [routerLink]="['/associated-members']" title="PACEY" class="side-link-pacey">
          <img src="../../../../assets/img/icons/pacey.png" alt="Pacey" width="75">
        </a>
      </li>
      <li>
        <a [routerLink]="['/associated-members']" title="JIGSAW" class="side-link-jigsaw">
          <img src="../../../../assets/img/icons/jigsaw.png" alt="JIGSAW" width="75">
        </a>
      </li>
      <li>
        <a [routerLink]="['/associated-members']" title="EYA" class="side-link-eya">
          <img src="../../../../assets/img/icons/eya.png" alt="EYA" width="75">
        </a>
      </li>
    </ul>
  </div>

</div>
