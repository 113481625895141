import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from '../../../services/header-hide-show.service';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  // styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {

  constructor(private headerHideShowService: HeaderHideShowService) { }

  ngOnInit() {
    this.headerHideShowService.isHeaderShown(false);
  }

}
