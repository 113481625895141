import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-choose-service',
  templateUrl: './choose-service.component.html',
  styleUrls: ['./choose-service.component.scss']
})
export class ChooseServiceComponent {
  @Output() successEmit = new EventEmitter();

  emitSuccess(): void {
    this.successEmit.emit();
  }

}
