import { Injectable } from "@angular/core";

@Injectable()
export class PriceEngine {
  pricePerMinute = 12;
  priceOffer = 4;

  getPrice(numberOfChildren: number, hoursRequired: number): number {
    if (numberOfChildren === 1) {
      return (hoursRequired * this.pricePerMinute / 60);
    } else if (numberOfChildren > 1) {
      let cost = 0;
      for (let i = 1; i <= numberOfChildren; i++) {
        if (i === 1) {
          cost = this.pricePerMinute
        } else {
          cost = cost + this.priceOffer;
        }
      }
      return Math.round((cost * (hoursRequired / 60)));
    }
  }
}