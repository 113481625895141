import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderEnabled } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';

import {
  AllAddresses,
  ChildObject, ChildSessionAndFeeDiscountDTO,
  ChildSessionDTO,
  ConsentData,
  ConsentPostData,
  ConsenViewModel, FeeDiscount,
  FormStatus,
  ImmuneDataObject,
  IndividualData,
  MedicalDetail,
  RelationShipObject,
  RelationType,
  RoomType,
} from '../../nursery/models/child.model';
import { BaseServiceService } from '../../nursery/services/base-service.service';

export interface HttpResponse<T> {
  body: T;
}

@Injectable({providedIn: 'root'})
export class ChildRegistrationService extends BaseServiceService {
  modal = new Subject();
  token;
  formData = new BehaviorSubject<FormStatus>(null);
  constructor(private readonly _http: HttpClient) {
    super();
  }

  getRoomByDob(dob: string): RoomType  {
    return this.getRoomByAge(this.calculateAge(dob));
  }

  public calculateAge(birthdate) {
    let age;
    if (birthdate) {
      const bdate = new Date(birthdate);
      const timeDiff = Math.abs(Date.now() - bdate.getTime() );
      age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }
    return age;
  }

  getRoomByAge(roomByAge: string): RoomType {
    switch (roomByAge.toString()) {
      case '0': 
      case '1': {
        return RoomType.Baby;
      }
      case '2': {
        return RoomType.Toddler;
      }
      case '3':
      case '4':
      case '5': {
        return RoomType.PreSchool;
      }
      default: {
        return RoomType.NoRoom;
      }
    }
  }

  @LoaderEnabled()
  getChildrenRegistration(id: string): Observable<ChildObject> {
    return this._http
      .get<HttpResponse<ChildObject>>(
        environment.apiUrl + '/child/' + id,
        this.attachToken()
        )
      .pipe(
        catchError(r => this.logOutError(r)),
        map((response: any) => {
          return response.body;
        })
      );
  }

  @LoaderEnabled()
  getFeeDiscounts(id: string): Observable<FeeDiscount[]> {
    return this._http
      .get<HttpResponse<ChildObject>>(
        environment.apiUrl2 + '/feeDiscount/' + id,
        this.attachToken()
      )
      .pipe(
        catchError(r => this.logOutError(r)),
        map((response: any) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveChildrenRegistration(childObject: ChildObject): Observable<ChildObject> {
    return this._http
      .post<HttpResponse<ChildObject>>(
        environment.apiUrl + '/child',
        childObject,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  getRelationnalData(
    childId: string,
    type: RelationType
  ): Observable<RelationShipObject> {
    return this._http
      .get<HttpResponse<RelationShipObject>>(
        `${environment.apiUrl}/${childId}/parents/${type}`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveRelationalData(
    payload: RelationShipObject
  ): Observable<RelationShipObject> {
    return this._http
      .post<HttpResponse<RelationShipObject>>(
        `${environment.apiUrl}/${payload.childId}/parents`,
        payload,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  getMedicalData(childId: string): Observable<MedicalDetail> {
    return this._http
      .get<HttpResponse<MedicalDetail>>(
        `${environment.apiUrl}/${childId}/medical`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveMedicalData(payload: MedicalDetail): Observable<MedicalDetail> {
    return this._http
      .post<HttpResponse<MedicalDetail>>(
        `${environment.apiUrl}/${payload.childId}/medical`,
        payload,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveChildSessionData(childSessionDTO: ChildSessionDTO, discountCodes): Observable<ChildSessionDTO> {
    return this._http
      .post<HttpResponse<MedicalDetail>>(
        `${environment.apiUrl}/session/${childSessionDTO.childId}/${discountCodes}`,
        childSessionDTO,
         this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  getFee(roomName, totalFull, totalHalf, discounts): Observable<any> {
    return this._http
      .get<HttpResponse<MedicalDetail>>(
        `${environment.apiUrl2}/fee/rns/${roomName}/${totalFull}/${totalHalf}/${discounts}`,
        this.attachToken()
      )
      .pipe(
        map((response) => {
          return (response as any);
        })
      );
  }

  @LoaderEnabled()
  getChildSessionData(childId: string): Observable<ChildSessionDTO> {
    return this._http
      .get<HttpResponse<MedicalDetail>>(
        `${environment.apiUrl}/${childId}/session`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveImmuneData(
    payload: ImmuneDataObject,
    childId: string
  ): Observable<ImmuneDataObject> {
    return this._http
      .post<HttpResponse<ImmuneDataObject>>(
        `${environment.apiUrl}/${childId}/immune`,
        payload,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  getImmuneData(childId: string): Observable<ImmuneDataObject> {
    return this._http
      .get<HttpResponse<ImmuneDataObject>>(
        `${environment.apiUrl}/${childId}/immune`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveIndividualData(
    payload: IndividualData[],
    childId: string
  ): Observable<IndividualData[]> {
    return this._http
      .post<HttpResponse<IndividualData[]>>(
        `${environment.apiUrl}/${childId}/individual`,
        payload,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  getIndividualData(childId: string): Observable<IndividualData[]> {
    return this._http
      .get<HttpResponse<IndividualData[]>>(
        `${environment.apiUrl}/${childId}/individual`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  deleteService(childId: string, serviceId: string): Observable<void> {
    return this._http
      .delete<HttpResponse<ImmuneDataObject>>(
        `${environment.apiUrl}/${childId}/${serviceId}`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return JSON.parse(response as any).body;
        })
      );
  }

  getConsentMaterData(): Observable<ConsenViewModel[]> {
    return this._http
      .get<HttpResponse<ConsentData>>(
        `${environment.apiUrl}/consents/master`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          const parsedData = (response as any).body;
          const allArray = [
            ...parsedData[1],
            ...parsedData[2],
            ...parsedData[3],
            ...parsedData[4],
          ];
          const result: ConsenViewModel[] = [];
          const grouped = this.groupBy(allArray, 'groupLabel');
          for (const key in grouped) {
            const consent: ConsenViewModel = {} as ConsenViewModel;
            consent['label'] = grouped[key][0].groupLabel;
            consent['consentText'] = grouped[key][0].groupDescription;
            consent.consents = [];
            grouped[key].forEach((item) => {
              consent.consents.push({
                label: item.label,
                isAllowed: false,
                consentMasterId: item.id,
              });
            });
            result.push(consent);
          }
          return result;
        })
      );
  }

  @LoaderEnabled()
  getFormStatus(childId: string): Observable<FormStatus> {
    return this._http
      .get<HttpResponse<FormStatus>>(
        `${environment.apiUrl}/child/registration/status/${childId}`,
        this.attachToken()
      )
      .pipe(
        map((response) => {
          return JSON.parse(response as any).body;
        })
      );
  }

  @LoaderEnabled()
  getFormStatusByEmailId(email: string): Observable<FormStatus> {
    return this._http
      .get<HttpResponse<FormStatus>>(
        `${environment.apiUrl}/child/registration/statusByEmail/${email}`,
         this.attachToken()
      )
      .pipe(
        catchError(r => this.logOutError(r)),
        map((response: any) => {
          this.formData.next(response.body);
          return response.body;
        })
      );
  }

  @LoaderEnabled()
  getVerifiedUser(username: string, password: string): Observable<any> {
    return this._http
      .post<HttpResponse<any>>(
        `${environment.apiUrl2}/oauth/login`,
        {
          grant_type: 'password',
          password,
          username: username,
        },
       this.httpOptions,
      )
      .pipe(
        map((response) => {
          return JSON.parse(response as any);
        })
      );
  }

  @LoaderEnabled()
  getAddressByChildId(childId: string): Observable<AllAddresses[]> {
    return this._http
      .get<HttpResponse<AllAddresses[]>>(
        `${environment.apiUrl}/child/address/${childId}`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  @LoaderEnabled()
  finallySubmit(childId: string): Observable<any> {
    return this._http.get<HttpResponse<any>>(
      `${environment.apiUrl}/doc/${childId}`,
      this.attachToken()
      );
  }

  @LoaderEnabled()
  getConsentByChildId(childId: string): Observable<ConsentPostData[]> {
    return this._http
      .get<HttpResponse<ConsentPostData[]>>(
        `${environment.apiUrl}/${childId}/consent`,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return JSON.parse(response as any).body;
        })
      );
  }

  @LoaderEnabled()
  saveConsentByChildId(
    childId: string,
    payload: ConsentPostData[]
  ): Observable<ConsentPostData[]> {
    return this._http
      .post<HttpResponse<ConsentPostData[]>>(
        `${environment.apiUrl}/${childId}/consent`,
        payload,
        this.attachToken()
        )
      .pipe(
        map((response) => {
          return (response as any).body;
        })
      );
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  @LoaderEnabled()
  getUserIp(): Observable<any> {
    const apiKey = 'ca07143d45d94f83b416a0102b9068c9';
    return this._http.get(
      `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&include=useragent`
    );
  }

  getAllQuestions(): Observable<IndividualData[]> {
    return of([
      {
        questionId: 1,
        questionText:
          'Has Your Child Any Food Allergies or Special Dietary Requirements?',
        description: '',
        status: false,
      },
      {
        questionId: 2,
        questionText: 'Are There Any Foods You Do Not Want Your Child To Have?',
        description: '',
        status: false,
      },
      {
        questionId: 3,
        questionText: 'Has Your Child Any Cultural Or Religious Requirements?',
        description: '',
        status: false,
      },
      {
        questionId: 4,
        questionText: 'Any Other Details That May Be Useful',
        description: '',
        status: false,
      },
    ]);
  }
}
