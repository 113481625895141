<!-- Childcare Funding Section -->
<div id="childcare-funding">
    <!-- About Funding -->
    <section id="about-funding" class="about-funding">
      <img class="d-block mx-auto mb-4 wow slideInDown" src="../../../../../assets/img/icons/round-circle-orange.png" width="40" height="40" alt="Rounder Circle"  />
      <h1 class="section-heading font-weight-bold wow slideInDown text-center">WE ACCEPT 15 HOURS FREE CHILDCARE, THE FUNDED 15 HRS FUNDING AMOUNT IS DEDUCTED FROM OUR MONTHLY FEE</h1>
      <h3 class="section-heading font-weight-bold wow slideInDown text-center mt-4">  <a [routerLink]="['/fee']" title="Registration">Click Here To See After 15 Hrs Discount Fee</a> </h3>
    </section>
    <!-- End About Funding -->

    <!-- Seprator Line-->
    <hr class="rs-mt-40 rs-mb-40 clearfix" />
    <!-- End Seprator Line-->

    <!-- Childcare Feature Section -->
    <section id="childcare" class="childcare-feature">
        <div class="feature-wrapper">
          <div class="feature-inner">

            <!-- Row 1 -->
            <div class="row clearfix">
              <div class="col-lg-6 col-md-12 p-0 pr-0">
                  <div class="featured-img img-block tm-animation-floating mt-2">
                      <img class="img-fluid" src="../../../../../assets/img/nursery/funding/15-hours/15-hours-funding.png" alt="Featured Image One">
                  </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="text-wrapper wow slideInRight">
                  <div class="line-1 mb-3">
                    The Government currently offers ‘free early education’ (FEE) to all 3 year olds and all 4 year olds up to 15 hours per week term time (38 weeks a year), <strong> although Rise 'N' Shine 'stretch' this to offer 570 free hours over a year.</strong> In addition, eligible working families can claim a further 15 hours per week term time for their 3&4 year olds through the 'Extended Entitlement' (EE), set up through their HMRC government gateway account, which we also 'stretch' to offer a further 570 free hours over a year.
                  </div>

<!--                  <div class="line-1 mb-3">-->
<!--                    The aim of FEE is to ensure all eligible children have access to good quality early education which is free at the point of delivery. As a provider of good quality early education, Rise 'N' Shine nursery is registered to claim the FEE on your behalf, as well as the EE.-->
<!--                  </div>-->

                  <div class="line-1 mb-3">
                    <a [routerLink]="['/fee']" title="Registration">Click Here To See After 15 Hrs Discount Fee</a>
                  </div>

                    <div class="button-wrap mt-3">
                      <a mdbBtn [routerLink]="['/contact-us']" class="btn btn-rounded rs-btn-blue" mdbWavesEffect title="Learn More">Contact Us</a>
                    </div>
                  </div>
              </div>
            </div>
            <!-- End Row 1 -->

          </div>
        </div>
    </section>
    <!-- End Childcare Feature Section -->

  </div>
  <!-- Childcare Funding Section -->
