<section id="admission">

  <div class="row">

            <div class="col-12">
              <mdb-card class="testimonial-card">

                <mdb-card-body>

                  <!-- Nav Tabs -->
                  <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-indigo tabs-3'" [contentClass]="'card'">
                    <mdb-tab heading="Baby Room" tabOrder="1">
                      <app-baby-room-admission></app-baby-room-admission>
                    </mdb-tab>

                    <mdb-tab heading="Toddler Room" [tabOrder]="tabs.length + staticTabsNumber">
                      <app-toddler-room-admission></app-toddler-room-admission>
                    </mdb-tab>

                    <mdb-tab heading="Pre-School Room" [tabOrder]="tabs.length + staticTabsNumber">
                      <app-pre-school-room-admission></app-pre-school-room-admission>
                    </mdb-tab>
                  </mdb-tabset>
                  <hr>
                  <!-- Content-->
                  <div class="pt-4 pb-4 pl-3 pr-3 text-left">
                    <h5 class="font-weight-bold deep-orange-lighter-hover mb-3">Find Out More</h5>
                    <p> To find out more about the <b>Rise 'N' Shine Nursery</b>, please <a [routerLink]="['/contact-us']">Contact Us</a> to arrange a visit.
                      We are conveniently located in close to Crossharbour DLR, South Quay DLR Station, and less than 15 minutes from Canary wharf Underground
                      Station & bus connections.
                    </p>
                    <h5 class="font-weight-bold deep-orange-lighter-hover mb-3">Different Backgrounds</h5>
                    <p>We welcome contact from families of different backgrounds, and wherever we can we will acknowledge and respect
                      children of different religious beliefs, family circumstances, and whatever their dietary requirements.
                    </p>
                    <h5 class="font-weight-bold deep-orange-lighter-hover mb-3">EYFS</h5>
                    <p>Days at the <b>Rise 'N' Shine Nursery</b> are stimulating and fun.  And they will give your child access to all
                      areas of the Early Years Foundation Stage – the government’s standards for the learning, development and care
                      of children from birth up to age 5.
                    </p>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>


</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
