import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-associated-members',
  templateUrl: './associated-members.component.html',
  host: {'class': 'w-100'}
  //styleUrls: ['./associated-members.component.scss']
})
export class AssociatedMembersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
