import { Component, OnInit } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-newsletter-archives',
  templateUrl: './newsletter-archives.component.html',
  //styleUrls: ['./newsletter-archives.component.scss'],
  host: {'class': 'w-100'}
})
export class NewsletterArchives implements OnInit {

  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) { }

  ngOnInit() {
    
  }
  
}

