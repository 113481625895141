import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ContactUs } from '../models/contact-us.model';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ContactUsHistory } from '../models/contact-us-history.model';
import { LoaderEnabled } from '../../../shared/components/loader/loader.service';
import {ToastrService} from 'ngx-toastr';
import {RegisterUser} from '../models/register-user.model';
import {UserRegistration} from '../models/user-registration.model';

@Injectable({ providedIn: 'root' })
export class ContactUsService {
  constructor(private readonly _http: HttpClient, private toastr: ToastrService) {}

  private _apiUrl = environment.apiUrl2;

  // Http Headers.. put it in the http Interceptor
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    'responseType': 'text' as 'json'
  };

  @LoaderEnabled()
  deleteContactUs(contactUsId: string): Observable<any> {
    return this._http.delete(
      `${this._apiUrl}/delete-contact-us/${contactUsId}`
    );
  }

  getShowAroundDates(): Observable<any> {
    return this._http
      .get(this._apiUrl + '/get-show-around-dates', this.httpOptions)
      .pipe(
        catchError(this.handleError('error while getting show around list'))
      );
  }

  @LoaderEnabled()
  saveContactUs(contactUs: ContactUs): Observable<any> {
    return this._http.post(this._apiUrl + '/save-contact-us', contactUs);
  }

  @LoaderEnabled()
  sendEmailToClient(contactUs: ContactUs): Observable<any> {
    return this._http
      .post(this._apiUrl + '/send-email-to-client', contactUs)
      .pipe(
        catchError((err) => {
          console.log('error caught in service');
          console.error(err);
          // Handle the error here
          return throwError(err); // Rethrow it back to component
        })
      );
  }

  @LoaderEnabled()
  updateContactUs(contactUs: ContactUs): Observable<any> {
    return this._http
      .post(this._apiUrl + '/update-contact-us', contactUs)
      .pipe(
        catchError(
          this.handleError(
            'error while saving emergency contact one registration',
            contactUs
          )
        )
      );
  }

  @LoaderEnabled()
  saveShowAround(contactUs: ContactUs): Observable<any> {
    return this._http
      .post(this._apiUrl + '/save-show-around-details', contactUs)
      .pipe(
        catchError(
          this.handleError(
            'error while saving emergency contact one registration',
            contactUs
          )
        )
      );
  }

  @LoaderEnabled()
  sendRegistrationPack(contactUs: ContactUs, reSendRegPack: boolean): Observable<any> {
    const userRegistration: UserRegistration = {
      authType: 'client',
      email: contactUs.email,
      enabled: true,
      phoneNumber: contactUs.phoneNumber,
      provider: 'risenshine',
      reSendRegPack: reSendRegPack,
    };

    const registerUserDto: RegisterUser = { userRegistration, contactUs};

    return this._http.post(this._apiUrl + '/registerUser', registerUserDto);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log('${operation} failed: ${error.message}');
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }

  public createContactUsHistory(contactUsParam?: ContactUs): ContactUsHistory {
    let contactUs;
    if (!contactUsParam) {
      const sessionItem = localStorage.getItem('contact-us');
      contactUs = sessionItem ? JSON.parse(sessionItem) : null;
    } else {
      contactUs = contactUsParam;
    }

    const contactUsHistory: ContactUsHistory = {} as ContactUsHistory;
    // Create Contact Us History
    if (contactUs) {
      contactUsHistory.contactUsId = contactUs.id;
      contactUsHistory.comment = contactUs.comment;
      contactUsHistory.message = contactUs.message;
      contactUsHistory.creationDateTime = contactUs.creationDateTime;
      contactUsHistory.showaroundDateTime = contactUs.showaroundDateTime;
      contactUsHistory.status = contactUs.status;
      contactUsHistory.subject =
        contactUs.subject !== undefined ? contactUs.subject : '';
      contactUsHistory.user = contactUs.user;
    }
    return contactUsHistory;
  }
}
