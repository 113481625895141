import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-weekly-check-list',
  templateUrl: './weekly-check-list.component.html',
  styleUrls: ['./weekly-check-list.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(10%)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(10%)', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class WeeklyCheckListComponent {
  @Input() arrayCheckList;
  selectedQuestion = 18;
  day = new Date().getDay();
  selectedWeek: string;
  modalRef?: BsModalRef;
  newQuestion: string;

  constructor(private modalService: BsModalService, private toaster: ToastrService) { }

  ngOnInit() {
  }

  save(question: any) {
    question.loader = true;
    this.toaster.success('Question saved successfully');
    setTimeout(() => {
      question.loader = false;
    }, 1000)
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md bootstrap-modal-center' });
  }

  saveNewQuestion(): void {
    const newObj = {
      label: this.newQuestion,
      value: '1',
      description: null, flag: null, loader: false
    };
    this.arrayCheckList.weeks.forEach(item => {
      item.questions.push(newObj)
    });
    this.modalRef.hide();
    this.newQuestion = null;
    this.toaster.success('Question added successfully');
  }

  remove(question: any): void {
    this.arrayCheckList.weeks.forEach(item => {
      if (item.weekDay === this.selectedWeek) {
        item.questions = item.questions.filter(ques => ques.label !== question.label);
      }
    });
    this.toaster.success('Question removed successfully');
  }
}