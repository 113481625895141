import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-school-room-fee',
  templateUrl: './pre-school-room-fee.component.html',
  //styleUrls: ['./pre-school-room-fee.component.scss']
})
export class PreSchoolRoomFeeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
