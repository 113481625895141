<!--Section: Features v.3-->
<section id="facility" class="text-center">

  <!--Section heading-->
  <h2 class="section-heading font-weight-bold">
    Why is it so great?
  </h2>
    <!--Grid row-->
    <div class="d-flex justify-content-center pb-4">

      <!--Grid column-->
      <div class="col-lg-10 col-sm-10">

        <!-- Description -->
        <p class="mt-3 section-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
      </div>
      <!--Grid column-->

    </div>
  <!--Grid row-->

  <!--Grid row-->
  <div class="row pt-2">

    <!--Grid column-->
    <div class="col-lg-5 text-center text-lg-left">
      <img src="https://mdbootstrap.com/img/Photos/Others/screens-section.jpg" alt="" class="img-fluid z-depth-0" alt="Facility">
    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-7">

      <!--Grid row-->
      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="reply" size="lg" class="indigo-text"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Safety</h5>
          <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit
            maiores nam, aperiam minima assumenda
            deleniti hic.</p>
        </div>
      </div>
      <!--Grid row-->

      <!--Grid row-->
      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="reply" size="lg" class="indigo-text"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Technology</h5>
          <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit
            maiores nam, aperiam minima assumenda
            deleniti hic.</p>
        </div>
      </div>
      <!--Grid row-->

      <!--Grid row-->
      <div class="row pb-3">
        <div class="col-2 col-md-1">
          <mdb-icon fas icon="reply" size="lg" class="indigo-text"></mdb-icon>
        </div>
        <div class="col-10">
          <h5 class="font-weight-bold text-left mb-3 dark-grey-text">Finance</h5>
          <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit
            maiores nam, aperiam minima assumenda
            deleniti hic.</p>
        </div>
      </div>
      <!--Grid row-->

    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

</section>
<!--Section: Features v.3-->
