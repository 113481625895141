import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, Validator, Validators } from "@angular/forms";

@Component({
  selector: "app-auto-complete",
  templateUrl: "./auto-complete.component.html",
  styleUrls: ["./auto-complete.component.scss"],
})
export class CustomAutoCompleteComponent {
  @Input() customLabel: string;
  @Input() options: string[];
  @Input() customRequired: boolean;
  @Input() submitted: boolean;
  @Output() emitResult = new EventEmitter();

  newOption: string;
  showMore: boolean;
  selectedOptions: string[] = [];
  selectControl = new FormControl([]);
  @Input() set vaccines(value: string[]) {
    if(value?.length) {
      this.selectedOptions = [...value]
      this.selectControl.setValue(this.selectedOptions);
    }
  }

  ngOnInit(): void {
    this.selectControl.valueChanges.subscribe((value: any[]) => {
      this.selectedOptions = value.filter((item) => item !== "Other");
      if (value.includes("Other")) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
      this.emitOutput();
    });
    this.emitOutput();
  }

  ngOnChanges(): void {
    if (this.customRequired) {
      this.selectControl.setValidators(Validators.required);
    } else {
      this.selectControl.setValidators(null);
    }
    this.selectControl.updateValueAndValidity();
  }

  pushNewOption() {
    if (!this.selectedOptions.includes(this.newOption.toLowerCase())) {
      this.selectedOptions.push(this.newOption.toLowerCase());
    }
    this.newOption = "";
    this.emitOutput();
  }

  removeItem(item: string): void {
    this.selectedOptions = this.selectedOptions.filter((el) => el !== item);
    this.selectControl.patchValue(this.selectedOptions);
    this.emitOutput();
  }

  emitOutput(): void {
    this.emitResult.emit({
      options: this.selectedOptions,
      formControl: this.selectControl,
    });
  }
}
