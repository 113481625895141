<ng-container [formGroup]="commonForm">
  <!-- Form Row-->
  <div class="form-row">
     <!-- Grid column -->
    <div class="col-lg-4 col-sm-12">
      <div class="md-form pl-2 m-3">
        <mdb-select id="staff" formControlName="staff" class="staff-select" [options]="staffSelect" placeholder="Select Staff" mdbValidate></mdb-select>
          <mdb-error *ngIf="submitted && form1.staff.errors">
            Please Select Staff
          </mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-4 col-sm-12">
      <div class="md-form pl-2 m-3">
        <mdb-select id="room" formControlName="room" class="room-select" [options]="roomSelect" placeholder="Select Room" mdbValidate></mdb-select>
          <mdb-error *ngIf="submitted && form1.room.errors">
            Please Select Room
          </mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-4 col-sm-12">
      <div class="md-form pl-2 pr-2 m-3">
        <mdb-date-picker formControlName="dateInfo" id="dateInfo" [options]="dateInfoOptions" placeholder="Select Date" mdbValidate></mdb-date-picker>
        <label for="dateInfo" class="active text-left"></label>
        <mdb-error *ngIf="submitted && form1.dateInfo.errors">
          Please Select Date
        </mdb-error>
      </div>
    </div>
  </div>
</ng-container>
