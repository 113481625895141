import { Component, OnInit } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-nursery-policies',
  templateUrl: './nursery-policies.component.html',
  //styleUrls: ['./nursery-policies.component.scss']
})
export class NurseryPoliciesComponent implements OnInit {

  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) { }

  ngOnInit() {
    
  }
  
}
