import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChildRegistrationService } from 'src/app/modules/forms/child-registration/child-registration.service';
import { CopyAddress, HomeAddress, RelationShipObject, RelationType } from 'src/app/modules/nursery/models/child.model';

@Component({
  selector: 'app-parent-details',
  templateUrl: './parent-details.component.html',
})
export class ParentDetailsComponent implements OnInit {
  @Input() realtionData: RelationShipObject;
  @Output() childChange = new EventEmitter();
  @Input() childId: string;
  destroy$ = new Subject();
  officeAddressForm: FormGroup;
  homeAddressForm: FormGroup;
  parentForm: FormGroup;
  allAddresses: CopyAddress[];
  realtionType = RelationType;

  constructor(private service: ChildRegistrationService) {}

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    if(this.realtionData) {
      this.emitAllForms();
    }

    this.service.getAddressByChildId(this.childId)
    .pipe(takeUntil(this.destroy$))
    .subscribe(response => {
      this.allAddresses = [];
       response.forEach(item => {
         if(item?.homeAddress) {
          const obj: CopyAddress = item.homeAddress as CopyAddress;
          obj.addressType = 1;
          obj.relationType = item.type as number;
          this.allAddresses.push(obj);
         }
         if(item?.officeAddress?.addressLineOne) {
          const obj: CopyAddress = item.officeAddress as CopyAddress;
          obj.addressType = 2;
          obj.relationType = item.type as number;
          this.allAddresses.push(obj);
         }
       });
    });
  }

  patchAddress(item: HomeAddress, type: string) {
    delete item.id;
    (this[type] as FormGroup).patchValue(item);
  }

  updateParentsForm(form: FormGroup, key: string): void {
    this[key] = form;
    this.emitAllForms();
  }

  emitAllForms(): void {
    const allForms = {
      officeAddressForm: this.officeAddressForm,
      homeAddressForm: this.homeAddressForm,
      parentForm: this.parentForm,
    }
    this.childChange.emit(allForms);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
