
<!-- Nursery Policies Section -->
<section id="our-gallery">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center pb-5"><i class="fas fa-camera-retro"></i> Our Gallery</h1>

  <!-- Section description -->
  
  <!-- End Section description -->

  <div class="section-wrapper">
     <!--Panel 1-->
     <div class="row">
        <div class="col-md-12">
            <!-- Nav Tabs -->
            <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3'" [contentClass]="'card'">
              <!--Panel 1-->
              <mdb-tab heading="Baby Room Gallery">
                <div class="row">
                  <div class="col-12">
                    <!-- Social Share Link -->
                    <app-social-share></app-social-share>
                    <!-- Social Share Link -->
                    <mdb-accordion [multiple]="false">
                      <mdb-accordion-item [collapsed]="false" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Indoor Gallery</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Baby Room Gallery -->
                            <app-baby-room-gallery></app-baby-room-gallery>
                            <!-- Baby Room Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                      <mdb-accordion-item [collapsed]="true" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Outdoor Activities</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Outdoor Activities Gallery -->
                            <app-baby-outdoor-activities></app-baby-outdoor-activities>
                            <!-- Outdoor Activities Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                      <mdb-accordion-item [collapsed]="true" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Garden Gallery</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Garden Gallery -->
                            <app-garden-gallery></app-garden-gallery>
                            <!-- Garden Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                    </mdb-accordion>
                  </div>
                </div>
              </mdb-tab>
              <!--Panel 2-->
              <mdb-tab heading="Toddler Room Gallery">
                <div class="row">
                  <div class="col-12">
                    <mdb-accordion [multiple]="false">
                      
                      <mdb-accordion-item [collapsed]="false" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Indoor Gallery</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Toddler Room Gallery -->
                            <app-toddler-room-gallery></app-toddler-room-gallery>
                            <!-- Toddler Room Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                      <mdb-accordion-item [collapsed]="true" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Outdoor Activities</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Outdoor Activities Gallery -->
                            <app-toddler-outdoor-activities></app-toddler-outdoor-activities>
                            <!-- Outdoor Activities Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                      <mdb-accordion-item [collapsed]="true" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Garden Gallery</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Garden Gallery -->
                            <app-garden-gallery></app-garden-gallery>
                            <!-- Garden Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                    </mdb-accordion>
                  </div>
                </div>
              </mdb-tab>
              <!--Panel 3-->
              <mdb-tab heading="Pre-School Room Gallery">
                <div class="row">
                  <div class="col-12">
                    <mdb-accordion [multiple]="false">
                      
                      <mdb-accordion-item [collapsed]="false" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Indoor Gallery</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                           <!-- PreSchool Room Gallery -->
                           <app-preschool-room-gallery></app-preschool-room-gallery>
                           <!-- PreSchool Room Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                      <mdb-accordion-item [collapsed]="true" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Outdoor Activities</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Outdoor Activities Gallery -->
                            <app-preschool-outdoor-activities></app-preschool-outdoor-activities>
                            <!-- Outdoor Activities Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                      <mdb-accordion-item [collapsed]="true" [customClass]="'border-0 mb-3 mt-4'">
                        <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'">Garden Gallery</mdb-accordion-item-head>
                        <mdb-accordion-item-body>
                            <!-- Garden Gallery -->
                            <app-garden-gallery></app-garden-gallery>
                            <!-- Garden Gallery -->
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>

                    </mdb-accordion>
                    
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
        </div>
      </div>   
  </div>
</section>
