<form #person="ngForm" [formGroup]="personFormGroup">
  <div class="row m-1">
    <div class="col-md-4 col-sm-12">
      <div class="md-form">
        <input maxlength="40" mdbInput type="text" id="firstName" formControlName="firstName" class="form-control" mdbValidate>
        <label for="firstName">First Name</label>
        <mdb-error *ngIf="formGetterSetter.firstName.touched && formGetterSetter.firstName.errors">
          Please provide your first name
        </mdb-error>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="md-form">
        <input maxlength="40" mdbInput type="text" id="lastName" formControlName="lastName" class="form-control" mdbValidate>
        <label for="lastName">Last Name</label>
        <mdb-error *ngIf="formGetterSetter.lastName.touched && formGetterSetter.lastName.errors">
          Please provide you last name
        </mdb-error>
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <app-isd [inputValue]="medicalFormData?.mobileNumber" controlName="Mobile Number" (numberValue)="setNumberValue($event, 'mobileNumber')"></app-isd>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-address [addressData]="medicalFormData?.officeAddress" (addressChange)="updateAddressChange($event)">
      </app-address>
    </div>
  </div>
</form>
