export class Address {
  id: number;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  postcode: string;
  country: string
  state: string;
  constructor(addressLineOne = null,
    addressLineTwo = null,
    city = null,
    postcode = null,
    state = null,
    country = null) {
    this.addressLineOne = addressLineOne;
    this.addressLineTwo = addressLineTwo;
    this.city = city;
    this.postcode = postcode;
    this.country = country;
    this.state = state;
  }
}
