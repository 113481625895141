import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DoctorDetails } from 'src/app/modules/nursery/models/child.model';

@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
})
export class PersonDetailsComponent {
  @Input() medicalFormData: DoctorDetails;

  @Output() updateParentForm = new EventEmitter();

  destroy$ = new Subject();
  personFormGroup: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.personFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateParentForm.emit(this.personFormGroup);
      });
  }

  ngOnChanges() {
    if (this.medicalFormData) {
      !this.personFormGroup ? this.initForm() : null;
      this.personFormGroup.patchValue(this.medicalFormData);
    }
  }

  setNumberValue(value: string, controlName: string): void {
    const obj = {};
    obj[controlName] = value;
    this.personFormGroup.patchValue(obj);
  }

  initForm() {
    this.personFormGroup = this.fb.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      mobileNumber: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(15),
          Validators.minLength(8),
        ])
      ),
    });
  }

  updateAddressChange(form: FormGroup) {
    this.personFormGroup.addControl('officeAddress', form);
    this.updateForm();
  }

  updateForm(): void {
    this.updateParentForm.emit(this.personFormGroup);
  }

  get formGetterSetter() {
    return this.personFormGroup.controls;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
