import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {ContinousProvisionPlanning} from '../models/continous-provision-planning.model';
import {catchError, retry, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContinousPlanningService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  private _apiUrl = 'http://localhost:8080/api';

  // Http Headers.. put it in the http Interceptor
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    'responseType': 'blob' as 'json'
  };

  saveContinousPlanning(ContinousPlanning: ContinousProvisionPlanning): Observable<any> {
    return this._http.post(this._apiUrl + '/save-continous-planning', ContinousPlanning, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving emergency contact one registration', ContinousPlanning))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log('${operation} failed: ${error.message}');
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }

}
