<div class="main__container mt-5 mb-5" fxLayout="column">
    <div class="main__header" fxLayoutAlign="center center" fxLayout="column">
      <h1>Newsletter</h1>
      <h4>Generate your personalized Newsletter within minutes.</h4>
    </div>
    <div class="main__content mt-4 mb-4" fxLayoutAlign="center center" fxLayout="column">
      <h4>Generate Template</h4>
      <settings></settings>
    </div>
</div>
