import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { NavbarComponent } from 'ng-uikit-pro-standard';
import {HeaderHideShowService} from '../../../../nursery/services/header-hide-show.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TokenStorageService} from '../../../../auth/_services/token-storage.service';
import {User} from '../../../../nursery/models/user.model';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  // styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  header = true;
  destroySubject: Subject<any> = new Subject<any>();
  authenticated = false;
  currentUser: User;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private _headerHideShowService: HeaderHideShowService) { }

  ngOnInit() {
    if (this.router.url !== null && this.router.url !== '/') {
      this.header = false;
    } else {
      this.header = true;
    }
    this._headerHideShowService.change
      .pipe(takeUntil(this.destroySubject)).subscribe((isHeaderShown: boolean) => {
        this.header = isHeaderShown;
    });

    this.tokenStorage.currentUserSubject.subscribe(user => {
      if (user != null) {
        this.currentUser = user;
        this.isLoggedIn = true;
      } else {
        this.currentUser = null;
        this.isLoggedIn = false;
        localStorage.removeItem('auth-accessToken');
      }
    });
  }

  changeHeader(path: string) {
    this.header = false;
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  openModal() {
  }
  
  logout() {
    this.tokenStorage.signOut();
    this.router.navigate(['/']);
  }

}
