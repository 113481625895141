import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {Eyfs} from '../../../models/eyfs.model';
import {mainAreaUpdated, subAreaUpdated} from '../store/actions/eyfs.actions';


@Component({
  selector: 'app-eyfs-left-section',
  templateUrl: './eyfs-left-section.component.html'
})
export class EyfsLeftSectionComponent implements OnInit {

  constructor(private store: Store<Eyfs>) { }

  ngOnInit() {
  }

  mainAreaClicked(mainArea: string) {
    this.store.dispatch(mainAreaUpdated({mainArea : mainArea}));
  }

  subAreaClicked(subArea: string) {
    this.store.dispatch(subAreaUpdated({subArea : subArea}));
  }
}
