import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import {
  ButtonsModule,
  CheckboxModule,
  InputUtilitiesModule,
  MDBBootstrapModulesPro,
  MDBSpinningPreloader,
  ModalModule,
  PopoverModule,
  StepperModule,
  TimePickerModule,
  TooltipModule,
  WavesModule,
} from 'ng-uikit-pro-standard';

import { SharedModule } from '../../../shared/shared.module';
import * as continousProvisionPlanningStore from '../nursery-forms/store/reducers/continous-provision-planning.reducer';
import { LayoutModule } from './../../layout/layout.module';
import { CommonFormComponent } from './common-form/common-form.component';
import { ActivityFormComponent } from './continous-provision-planning/activity-form/activity-form.component';
import { ContinousProvisionPlanningComponent } from './continous-provision-planning/continous-provision-planning.component';
import { FocussedActivityFormComponent } from './focussed-activity/focussed-activity-form/focussed-activity-form.component';
import { FocussedActivityComponent } from './focussed-activity/focussed-activity.component';
import { BookingFormComponent } from './nanny-service/booking/booking-form/booking-form.component';
import { BookingComponent } from './nanny-service/booking/booking.component';
import { InvoiceComponent } from './nanny-service/booking/invoice/invoice.component';
import { NannyServiceComponent } from './nanny-service/nanny-service.component';
import { NannyService } from './nanny-service/nanny-service.service';
import {
  ChildrenGroupComponent,
} from './nanny-service/question-components/children-group-question/children-group-question.component';
import {
  ChooseServiceComponent,
} from './nanny-service/question-components/choose-service.component.ts/choose-service.component';
import { OptionalServiceComponent } from './nanny-service/question-components/optional-services/optional-services.component';
import { UserCredentialsComponent } from './nanny-service/question-components/user-credentials/user-credentials.component';
import { UserInfoComponent } from './nanny-service/question-components/user-info/user-info.component';
import { NurseryFormComponent } from './nursery-form.component';
import { NurseryFormsRoutingModule } from './nursery-forms.routing.module';

@NgModule({
  declarations: [
    ContinousProvisionPlanningComponent,
    CommonFormComponent,
    UserInfoComponent,
    BookingFormComponent,
    InvoiceComponent,
    BookingComponent,
    NurseryFormComponent,
    ChooseServiceComponent,
    UserCredentialsComponent,
    ActivityFormComponent,
    FocussedActivityComponent,
    FocussedActivityFormComponent,
    NannyServiceComponent,
    ChildrenGroupComponent,
    OptionalServiceComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    StepperModule,
    WavesModule,
    SharedModule,
    NurseryFormsRoutingModule,
    CheckboxModule,
    InputUtilitiesModule,
    TimePickerModule,
    WavesModule,
    ModalModule,
    TooltipModule,
    PopoverModule,
    ButtonsModule,
    MDBBootstrapModulesPro.forRoot(),
    StoreModule.forFeature(
      continousProvisionPlanningStore.continousProvisionPlanningStateFeatureKey,
      continousProvisionPlanningStore.continousProvisionPlanningReducers,
      { metaReducers: continousProvisionPlanningStore.continousProvisionPlanningStateMetaReducers }),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: ''
    })
  ],
  providers: [
    MDBSpinningPreloader,
    NannyService,

  ]
})

export class NurseryFormsModule { }
