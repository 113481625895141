import { Component, OnInit } from '@angular/core';
import {HeaderHideShowService} from "../../services/header-hide-show.service";


@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  //styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {

  constructor(private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {
    this.headerHideShowService.isHeaderShown(false);
  }

}
