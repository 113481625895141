<section id="director-welcome" class="text-center">

<!--Grid row-->
<div class="row ml-0 mr-0">

  <!--Grid column-->
  <div class="col-md-12">

    <mdb-card class="testimonial-card">
      <!--Bacground color-->
      <div class="card-up indigo">
      </div>

      <!--Avatar-->
      <div class="avatar mx-auto">
        <img src="../../../../../../assets/img/nursery/about-us/director-avatar.jpg" class="rounded-circle" alt="About Director">
      </div>

      <mdb-card-body>
        <!--Name-->
        <mdb-card-title>
          <h4>Letter from Anubhuti Rajpal</h4>
        </mdb-card-title>
        <hr>
        <!--Quotation-->
        <div class="pt-4 pb-4">
        <p>
          <mdb-icon fas icon="quote-left"></mdb-icon>  We take great pride in our diverse and devoted community of teachers, administrators, and families.
        </p>

        <p>
          <mdb-icon fas icon="quote-left"></mdb-icon>  Since our founding in 2000, Montclare has become one of the leading early childhood programs on the Upper West Side and in Manhattan. We take great pride in our diverse and devoted community of teachers, administrators, and families.  Tucked away in a beautiful landmark building, our students enjoy our exceptional facility which offers two gyms, a climbing wall, a rooftop play-space, an art studio, and a library.
        </p>

        <p>
          <mdb-icon fas icon="quote-left"></mdb-icon>  Montclare’s dedicated teachers and administrators work together and with our children and their families to create the best possible educational environment for all of our students.
        </p>

        <p>
          <mdb-icon fas icon="quote-left"></mdb-icon>  If you would like to know more about Montclare, I welcome hearing from you. It will be my privilege to tell you more about our wonderful nursery.

          Sincerely,

          Anubhuti Rajpal
        </p>
      </div>


      </mdb-card-body>
    </mdb-card>

  </div>
  <!--Grid column-->


</div>
<!--Grid row-->

</section>
