import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromStore from './store/reducers/eyfs.reducer';
import {ButtonsModule, CheckboxModule, IconsModule, WavesModule} from 'ng-uikit-pro-standard';

@NgModule({
  imports: [
    CommonModule, WavesModule, IconsModule, ButtonsModule, CheckboxModule,
    StoreModule.forFeature(fromStore.eyfsStateFeatureKey, fromStore.eyfsReducers,
      { metaReducers: fromStore.eyfsMetaReducers }),
  ]
})
export class EyfsModule { }
