
<router-outlet #outlet="outlet"></router-outlet>

<section id="operations" *ngIf="outlet.isActivated == false">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Operations</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">

        </p>
    </div>
  </div>

  <div class="section-wrapper">
      <!-- Nav Tabs -->
      <mdb-tabset #staticTabs [vertical]="true" [buttonClass]="'md-tabs tabs-5 ml-0 mr-0 indigo'" [contentClass]="'pl-0 pr-0'">
        <!--Panel 1-->
        <mdb-tab heading="Premises" id="premises">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../assets/img/nursery/home/gallery/garden_7.jpg" class="rounded-circle" alt="Premises">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Premises</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Quotation-->
                  <div class="pt-4 pb-4 text-left">
                    <p class="dark-grey-text text-center">The style and layout of each of our day nurseries is unique.</p>
                  </div>
                  <div class="row clearfix mb-3">
                    <div class="col-lg-4 pb-2">
                        <img src="../../../../assets/img/nursery/home/gallery/garden_7.jpg" class="img-fluid" alt="Rise N Shine Nursery">
                      </div>
                      <div class="col-lg-8">
                        <p class="dark-grey-text text-left">Our Rise 'N' Shine day nursery is located in Isle of Dogs, Skylines Village on a quiet road only
                            five minutes walk from Shortlands station. It occupies the ground and first floors and is split into three purpose built units
                            catering for different age groups. The ‘babies’ unit cares for our youngest children from 3 months with a stimulating play area
                            and separate sleep room. Approaching 2 years, the children move up to our separate ‘toddler’ unit. Around 3 years, the children
                            move into our separate ‘pre-school’ unit. All units have direct access to our secure rear garden which is surfaced in part Astroturf,
                            part bark and part paving to provide an all weather natural environment where the children can explore safely and be physical.
                            There is also a separate allotment to the side and outdoor classroom to the rear.
                        </p>
                      </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-lg-4 pb-2 order-lg-2">
                        <img src="../../../../assets/img/nursery/home/gallery/garden_8.jpg" class="img-fluid" alt="Rise N Shine Nursery">
                    </div>
                    <div class="col-lg-8 order-lg-1">
                        <p class="dark-grey-text text-left">Our Rainbow Splash day nursery is located in Isle of Dogs, it is only five minutes walk from the station and High Street.
                            It is split into three purpose built units catering for different age groups. The ‘babies’ unit cares for our youngest children from 3 months with a
                            stimulating play area and separate sleep room. Approaching 2 years, the children move up to our separate open plan ‘toddler’ unit. Around 3 years,
                            the children move into our separate open plan ‘pre-school’ unit. The secure large garden is surfaced in part Astroturf, park bark and part rubber
                            matting to provide an all weather natural environment where the children can explore safely and be physical. There is also a separate allotment
                            and outdoor classroom to the front.</p>
                    </div>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 2-->
        <mdb-tab heading="Age Group / Units" id="ageGroup">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Age Group">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Age Groups / Units</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Quotation-->
                  <div class="pt-3 text-left">
                    <p>Each unit within the nursery caters for a different age and/or ability group covering the children’s learning, meal times and sleep at different times of the day.
                        There is a wide range of resources available in each unit which are carefully selected to be safe for the children, age appropriate and maximise learning potential
                        under a variety of learning areas. These include shop brought equipment, resources made by staff and children (e.g flashcards, books etc) and heuristic equipment
                        which is a collection of everyday objects made of natural materials such as wooden items, wicker, sea shells etc. The use of equipment is monitored very carefully
                        and replenishment orders are made on a quarterly basis.
                    </p>
                    <p>All furniture is size appropriate for the age groups and takes into consideration the children being able to access the resources independently. Some of the children’s
                        own work and photos of them at play are displayed at child height throughout each unit.
                    </p>
                  </div>
                  <div class="pt-3 text-left">
                    <h4 class="blue-text">Babies Unit</h4>
                    <p>The babies will be cared for in a warm, bright and stimulating environment where they will have lots of fun throughout the day playing with toys stimulating all their senses.
                        There is a separate sleep room so they can rest on demand without distraction. They will also be taken for regular walks as well as joining in simple activities such as finger
                        and foot painting, singing and music. Babies will also be encouraged in their physical and mental development so they begin to reach the early milestones of sitting up, crawling,
                        walking, beginning to feed themselves and repeating common words.
                    </p>
                  </div>
                  <div class="pt-3 text-left">
                    <h4 class="blue-text">Toddlers</h4>
                    <p>The toddlers will be encouraged to expand on the skills they have already learned with stimulating challenges and the provision of fun learning experiences delivered through play.
                        Toddlers will be introduced to more creative and messy play and will be given every opportunity to explore their environment, socialise, share and make friends with other children.
                        We are aware that at this stage, children become more independent and staff will gradually lead them into a slightly more structured environment where themed activities are planned to
                        enhance the children's skills and give them a greater knowledge of the world around them. The children will begin to join group activities and will be given every opportunity to make
                        choices that will enable them to develop their own personalities.
                    </p>
                  </div>
                  <div class="pt-3 text-left">
                    <h4 class="blue-text">Pre-school</h4>
                    <p>Pre-school children will build on the skills already learned and will follow a more structured environment of planned and free choice activities to meet the seven key areas of learning.
                        When planning to meet these areas our staff will assess individual needs, abilities and interests. Staff will encourage and motivate the children to develop at their own pace and help them
                        to gain self-confidence and self respect. Children also have access to extra curricular activities offered by outside teachers.
                    </p>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 3-->
        <mdb-tab heading="Staffing" id="staffing">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../assets/img/nursery/operations/avatar/staffing.jpg" class="rounded-circle" alt="Our Facility">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Staffing</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Quotation-->
                  <div class="pt-3 text-left">
                    <p>We are extremely fortunate to have a strong team of friendly, qualified and experienced staff who work well together to create a warm and welcoming environment.
                        They all have Enhanced DBS disclosures and are safe to work with children.
                    </p>
                    <p>
                        The Nursery Manager or Deputy is on the premises at all times to answer any queries you may have. Photos of these individuals along with the rest of the team are
                        displayed in the nursery entrance.
                    </p>
                    <p>Our staff:child ratio's are maintained in line with best practice as follows:</p>
                    <ul>
                        <li>3 months - 2 years 1:3</li>
                        <li>2 - 3 years 1:4</li>
                        <li>3 - 8 years 1:8</li>
                    </ul>
                  </div>
                  <div class="pt-3 text-left">
                    <p>
                        We operate a ‘key person system’ for all children under the age of 5 years. The key person, where possible, will carry out the child’s daily personal care needs i.e. nappy changing,
                        feeding, giving medicine, toilet training, etc, and will ensure they have equal access and are fully included within the nursery. The key person will also regularly observe the child's
                        development during the days they attend nursery, continually update the child's Personal Achievement Report, review and set development targets every three months and build a working
                        relationship with the child's parents during Progress Report Meetings.
                    </p>
                    <p>
                        We actively encourage our staff to further their training, both in-house and externally, in areas of advanced childcare qualifications, curriculum planning, first aid, food hygiene,
                        child protection and special educational needs.
                    </p>
                    <p>Volunteers and student placements from local colleges are welcome and will assist staff in a supervised role, but will not be included in the staff:child ratio's.</p>
                    <p>We operate a contingency plan to cover unforeseen staff absences and holidays.</p>
                </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
         <!--Panel 4-->
         <mdb-tab heading="Curriculum" id="curriculum">
            <div class="row">
              <div class="col-12">
                <mdb-card class="testimonial-card">
                  <!--Bacground color-->
                  <div class="card-up">
                  </div>

                  <!--Avatar-->
                  <div class="avatar mx-auto">
                    <img src="../../../../../assets/img/nursery/operations/avatar/curicullum.jpg" class="rounded-circle" alt="Our Facility">
                  </div>

                  <mdb-card-body>
                    <!--Name-->
                    <mdb-card-title>
                      <h3>Curriculum</h3>
                    </mdb-card-title>
                    <hr>
                    <!--Quotation-->
                    <div class="pt-4 text-left">
                        <p>The children are cared for and taught in their separate units throughout the day, but opportunities are created for the units to come together to enhance social relationships and learn from each other, especially in the garden.</p>
                        <p>Children value routine and knowing what's coming next, so we stick to our daily routine as much as possible in all units as follows:</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 8.00am - 9.00am</h5>
                        <p>The children arrive and take part in free play activities which have been set up in advance to encourage symbolic play, fine and gross motor skills play and stories.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 9.00am – 9.30am</h5>
                        <p>Breakfast time, followed by the first routine nappy change.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 9.30am – 12.00am</h5>
                        <p><strong>Morning Education Session:</strong>During this time the children have access to both adult structured and free choice activities following the Early Years Foundation Stage curriculum. This session includes group circle time and garden play.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 12.00pm - 1.00pm</h5>
                        <p>Lunch time. Before lunch the nursery carry out the second routine nappy change.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 1.00pm – 2.00pm (approx.)</h5>
                        <p>Sleep/quiet rest period, followed by third routine nappy change, and then milk and snack time in Toddlers and Pre-school.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 2.00pm - 4.00pm</h5>
                        <p><strong>Afternoon Education Session:</strong> During this time the children have access to both adult structured and free choice activities following the Early Years Foundation Stage curriculum. This session includes garden play.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 4.00pm – 4.30pm</h5>
                        <p>Tea time, followed by the forth routine nappy change.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <h5 class="orange-text"><mdb-icon fas icon="clock" class="mr-0"></mdb-icon> 4.30pm - 6.00pm</h5>
                        <p>The children take part in free choice activities encouraging social learning, role play, matching and sorting, music and winding down for the end of the day.</p>
                    </div>
                    <div class="pt-2 text-left">
                        <p>Rise 'N' Shine support the children in all aspects of the daily routine in their unit, but are flexible to include the personal needs of individual children regardless
                            of their age. They provide many opportunities for them to develop their particular likes and interests, but at the same time encourage them to try out new things.
                            Whatever the child’s age, Rise 'N' Shine encourage good manners, respect for others, good personal hygiene, independence, individual contributions, responsibility and
                            pride in their achievements.
                        </p>
                        <p>The children work their way through keyworking groups/units based on age and level of ability. The keyworkers have in-depth knowledge of the key development targets of their group
                            and how to extend these to prepare for the next group up.
                        </p>
                       </div>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </mdb-tab>
        <!--Panel 5-->
        <mdb-tab heading="Security & Safety" id="securitySafety">
            <div class="row">
              <div class="col-12">
                <mdb-card class="testimonial-card">
                  <!--Bacground color-->
                  <div class="card-up">
                  </div>

                  <!--Avatar-->
                  <div class="avatar mx-auto">
                    <img src="../../../../../assets/img/nursery/operations/avatar/safety.jpg" class="rounded-circle" alt="Location & Hours">
                  </div>

                  <mdb-card-body>
                    <!--Name-->
                    <mdb-card-title>
                      <h3>Security and Safety</h3>
                    </mdb-card-title>
                    <hr>
                    <!--Quotation-->
                    <div class="pt-3 text-left">
                      <p>The safety of children is very important to us. Some of our many safety features include:</p>
                      <ul>
                        <li>Ensuring staff have up to date Enhanced DBS disclosures and are safe to work with children</li>
                        <li>High level handle, chain or bar on the front door to prevent children escaping</li>
                        <li>Photo and password policy relating to the collection of children</li>
                        <li>First aid trained staff in all units</li>
                        <li>Risk assessments carried out daily, both inside and out</li>
                        <li>Daily record of people on the premises</li>
                        <li>Close supervision of sleeping children</li>
                        <li>Child size toilet facilities and paper towels dispensers</li>
                        <li>Detailed policies to ensure safety of children on outings and what to do if a child gets lost</li>
                        <li>Off street parking</li>
                        <li>Safety gates on all stairs and some doors</li>
                        <li>Fire alarm with practice drills every six weeks</li>
                        </ul>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </mdb-tab>
      </mdb-tabset>
      <!-- Nav Tabs -->
    </div>
</section>






