import { Component, OnInit } from '@angular/core';
import {HeaderHideShowService} from '../services/header-hide-show.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  // styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor(private headerHideShowService: HeaderHideShowService) { }



  ngOnInit() {
    this.headerHideShowService.isHeaderShown(true);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}

