  <div class="chooseService">
    <h3 class="main-heading">
      What would you like to do?
    </h3>
    <div class="nanny-service-wrapper">
      <span class="custom-buttons" mdbWavesEffect>Find a Job
        <mdb-icon fas icon="search-dollar"></mdb-icon>
      </span>
      <span class="custom-buttons" mdbWavesEffect (click)="emitSuccess()">Find Care
        <mdb-icon fas icon="baby-carriage" ></mdb-icon>
      </span>
    </div>
  </div>
