import {Component, HostListener, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ContinousPlanningService} from '../../services/continous-planning.service';
import {FormBuilder} from '@angular/forms';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ContinousProvisionPlanningState} from '../store/reducers/continous-provision-planning.reducer';

@Component({
  selector: 'app-continous-provision-planning',
  templateUrl: './continous-provision-planning.component.html',
  // styleUrls: ['./continous-provision-planning.component.scss']
  host: {'class': 'w-100'}
})

export class ContinousProvisionPlanningComponent implements OnInit {

  // public nestedForm: FormGroup = new FormGroup({
  //   common: new FormControl(''),
  //   activity: new FormControl('')
  // });

  submitted = false;
  modalRef: MDBModalRef;
  retval: Observable<boolean>;
  isVertical = false;
  alive = false;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<ContinousProvisionPlanningState>,
    private continousPlanningService: ContinousPlanningService) {}

  // convenience getter for easy access to form fields
  // get form1() { return this.nestedForm.controls; }

  @HostListener('window:resize') onWindowResize() {

    if (window.innerWidth <= 1199) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }

  ngOnInit() {}

  public onSubmit() {

    // if (this.continousProvisionPlanningForm.invalid) {
    //   console.log('Invalid  Form');
    //   return;
    // }
    //
    // this.submitted = true;
    //
    // let cppFromStore: ContinousProvisionPlanning;
    // this.store.pipe(select(selectCPPFromStore)).subscribe((continousProvisionPlanning) => {
    //   cppFromStore = continousProvisionPlanning.continousPlanning;
    //   }
    // );
    //
    // this.store.dispatch(saveContinousProvisionPlanningAction({ continousProvisionPlanning: cppFromStore}));

  }
}
