import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          document.documentElement.scrollTop = 0; // || window.scrollTo(0, 0);
        }, 190);
      }
    });
  }

  title = 'Cookie dispenser';

  ngOnInit(): void {
  }
}