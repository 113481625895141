import { Component, OnInit } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-covid-faqs',
  templateUrl: './covid-faqs.component.html',
  //styleUrls: ['./covid-faqs.component.scss']
})
export class CovidFAQSComponent implements OnInit {

  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) { }

  ngOnInit() {
    
  }
  
}
