import { saveUserContactInfoError404, saveUserContactInfoError } from './../actions/user-contact-us.action';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContactUs } from 'src/app/modules/nursery/models/contact-us.model';
import { ContactUsService } from 'src/app/modules/nursery/services/contact-us.service';
import { saveUserContactInfoSuccess, saveUserContactUs } from '../actions/user-contact-us.action';

@Injectable()
export class UserContactUsEffects {

  constructor(private actions$: Actions, private contactService: ContactUsService) { }


  @Effect({ dispatch: true })
  public saveContactUs$ = this.actions$.pipe(
    ofType(saveUserContactUs),
    map((action) => action.contactInfo),
    mergeMap((payload) => this.contactService.saveContactUs(payload)
      .pipe(
        map((contact) => {
          return saveUserContactInfoSuccess({ contactInfo: contact });
        }),
        catchError(error => {
          console.log('Caught error while saving child!' + error);
          if (error?.error?.message?.includes('User Already Exists')) {
            const object: ContactUs = error.error.object;
            object.errorMessage = error?.error?.message;
            return of(saveUserContactInfoError404({contactInfo: object}));
          } else {
            return of(saveUserContactInfoError({ contactInfo: payload }));
          }
        })
      )));

}
