import { Directive, ElementRef, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class OutSideClickDirective {

  constructor(private elementRef: ElementRef) { }

  @Output() clickOutside: EventEmitter<any> = new EventEmitter();
  @Input() elementVisible: boolean;

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (this.elementVisible) {
      if (!clickedInside) {
        this.clickOutside.emit();
      }
    }
  }
}
