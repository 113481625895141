import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full'},
  { path: 'pages',  loadChildren: (): Promise<object> => import('./modules/pages/pages.module').then(m => m.PagesModule)},
  { path: 'forms',  loadChildren: (): Promise<object> => import('./modules/forms/forms.module').then(m => m.FormsModule)},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
