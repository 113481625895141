import {createReducer, MetaReducer, on} from '@ngrx/store';
import {addContinousProvisionPlanningAction} from '../actions/continous-provision-planning.actions';
import {ContinousProvisionPlanning} from '../../../models/continous-provision-planning.model';
import {environment} from '../../../../../../environments/environment';

export const continousProvisionPlanningStateFeatureKey = 'continousProvisionPlanningState';

export interface ContinousProvisionPlanningState {
  continousPlanning: ContinousProvisionPlanning[];
}

export const initialState: ContinousProvisionPlanningState = {
  continousPlanning: {} as ContinousProvisionPlanning[]
};

export const _continousProvisionPlanningReducers = createReducer(initialState,
  //
  on(addContinousProvisionPlanningAction, (state: ContinousProvisionPlanningState = initialState, action) => (
    {
       ...state,
            continousPlanning: action.continousProvisionPlanning
  })),
);

export function continousProvisionPlanningReducers(state, action) {
  return _continousProvisionPlanningReducers(state, action);
}

export const continousProvisionPlanningStateMetaReducers:
  MetaReducer<ContinousProvisionPlanningState>[] = !environment.production ? [] : [];
