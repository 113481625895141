<section id="location-hours" class="text-center">

  <!--Grid row-->
  <div class="row ml-0 mr-0 justify-content-center">
    <!--Grid column-->
    <div class="col-lg-4 col-md-8 justify-content-center">
      <!-- Material form login -->
      <mdb-card>

        <mdb-card-header class="info-color white-text text-center py-4">
          <h5>
            <strong>Sign in</strong>
          </h5>
        </mdb-card-header>


        <!--Card content-->
        <mdb-card-body class="px-lg-5 pt-0">

          <!-- Form -->
          <form class="example-form"  [formGroup]="loginFormGroup" (ngSubmit)="onSubmit(loginFormGroup)">

            <!-- Username -->
            <div class="md-form">
              <input
                formControlName="username"
                type="text"
                class="form-control"
                id="username"
                name="username"
                required
                mdbInput>
              <label for="username">Username</label>

              <mdb-error *ngIf="submitted && f.username.errors">
                Please provide users name
              </mdb-error>

            </div>

            <!-- Password -->
            <div class="md-form">
              <input
                formControlName="password"
                type="password"
                class="form-control"
                id="password"
                name="password"
                required
                mdbInput>
              <label for="password">Password</label>

              <mdb-error *ngIf="submitted && f.password.errors">
                Please provide password
              </mdb-error>

            </div>


            <div class="form-group">
              <div class="alert alert-danger" role="alert"
                   *ngIf="submitted && isLoginFailed">
                Login failed: {{ errorMessage }}
              </div>
            </div>

            <!-- Sign in button -->
            <div class="text-center">
              <button type="submit" mdbBtn class="rs-btn-info btn btn-rounded waves-effect waves-light" data-toggle="modal"
                      data-target="#basicExample" mdbWavesEffect>Sign In
              </button>

            </div>




          </form>
          <!-- Form -->

        </mdb-card-body>

      </mdb-card>
    </div>
  </div>
  <!-- Material form login -->
</section>
