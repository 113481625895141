import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { ChildRegistrationService } from '../../forms/child-registration/child-registration.service';

import { User } from '../../nursery/models/user.model';
import { ChildFormService } from '../../nursery/services/child-form.service';
import { HeaderHideShowService } from '../../nursery/services/header-hide-show.service';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  host: { class: 'w-100' },
})
export class LoginComponent implements OnInit {
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  submitted = false;

  loginFormGroup: FormGroup;
  private currentUser: User;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private childService: ChildRegistrationService,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private headerHideShowService: HeaderHideShowService
  ) {
    this.authService.currentUserSubject.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  get f() {
    return this.loginFormGroup.controls;
  }

  ngOnInit() {
    this.headerHideShowService.isHeaderShown(false);

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.router.navigate(['pages']);
    }

    this.loginFormGroup = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit(formElement) {
    this.submitted = true;
    console.log(formElement.value);
    // stop here if form is invalid

    if (this.loginFormGroup.invalid) {
      console.log(' ---- Invalid Form Return ---');
      return;
    }

    this.authService.login(this.loginFormGroup.value).subscribe(
      (loginResponse) => {
        const data: any = jwtDecode(loginResponse.access_token);
        if (data?.authorities?.includes('PARENT')) {
          sessionStorage.setItem(
            'userAccessInfo',
            JSON.stringify(loginResponse)
          );
          sessionStorage.setItem('userAccessToken', loginResponse.access_token);
          this.childService
            .getFormStatusByEmailId(this.loginFormGroup.value.username)
            .subscribe((response) => {
              if (response) {
                this.router.navigateByUrl(
                  `/forms/registration-form/dashboard/${response.childId}`
                );
              }
            });
        } else if (data.authorities.includes('ADMIN')) {
          this.tokenStorage.saveToken(loginResponse.access_token);
          this.tokenStorage.saveUser(loginResponse);
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.router.navigate(['pages']);
        }
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage() {
    console.log('--- reloadPage -- ');
    // window.location.reload();
  }

  onReset() {
    this.submitted = false;
    this.loginFormGroup.reset();
  }
}
