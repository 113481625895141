import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ratings-gallery',
  templateUrl: './ratings-gallery.component.html'
  //styleUrls: ['./ratings-gallery.component.scss']
})
export class RatingsGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
