<div class="md-stepper-horizontal">
  <ng-container *ngFor="let item of array;let i =index">
    <div (click)="clicked(item)" class="md-step" [class.click-able]="isClickAble" [class.active]="item.checked">
      <div class="md-step-circle"><span>{{i + 1}}</span></div>
      <div class="md-step-title" *ngIf="!item.label">Step {{i + 1}}</div>
      <div class="md-step-title" *ngIf="item.label">{{item.label}}</div>
      <div class="md-step-bar-left"></div>
      <div class="md-step-bar-right"></div>
    </div>
  </ng-container>
</div>
