import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NannyServiceEffects } from './effects/nanny-service.effect';
import * as fromStore from './reducers/nanny-service.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.nannyStateFeatureKey,
      fromStore.nannyReducers,
      { metaReducers: fromStore.nannyMetaReducers }),
    EffectsModule.forFeature([NannyServiceEffects]),
  ],
  declarations: []
})
export class NannyStoreModule { }

