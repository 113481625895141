<!-- Navbar -->
<mdb-navbar SideClass="navbar fixed-top navbar-expand-lg scrolling-navbar navbar-dark" [containerInside]="false">

    <mdb-navbar-brand>
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="../../../../assets/img/logo/rise-n-shine-logo.png" height="60" alt="Rise N Shine Nursery, Isle of Dogs Canary, UK">
      </a>
    </mdb-navbar-brand>

    <links>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/learning']">
            <mdb-icon fas icon="book"></mdb-icon> Children Learning Cycle <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/funding']">
            <mdb-icon fas icon="university"></mdb-icon>Funding
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/nursery-software']">
            <mdb-icon fas icon="mobile"></mdb-icon> Nursery App
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/admission']">
            <mdb-icon fas icon="child"></mdb-icon> Admission
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/careers']">
            <mdb-icon fas icon="graduation-cap"></mdb-icon> Careers
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/fee']">
            <mdb-icon fas icon="pound-sign"></mdb-icon> Fee
          </a>
        </li>
        <li class="nav-item d-lg-none d-md-block d-sm-block">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/about-us']">
            <mdb-icon fas icon="school"></mdb-icon> About Us
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/contact-us']">
            <mdb-icon fas icon="envelope"></mdb-icon> Contact Us
          </a>
        </li>
        <li class="nav-item" *ngIf="authenticated">
          <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/registration-form']">
            <mdb-icon fas icon="users"></mdb-icon> Registration
          </a>
        </li>

        <li class="nav-item" *ngIf="authenticated">
          <a class="nav-link waves-light" mdbWavesEffect (click)="logout()">
            <mdb-icon fas icon="sign-out-alt"></mdb-icon> Logout
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light" *ngIf="!isLoggedIn" mdbWavesEffect [routerLink]="['/login']">
            <mdb-icon fas icon="sign-in-alt"></mdb-icon>Login
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link waves-light" *ngIf="isLoggedIn" mdbWavesEffect (click)="logout()">
            Logout
          </a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/login']" routerLinkActive="active" class="nav-link waves-light" *ngIf="isLoggedIn" mdbWavesEffect>
            {{currentUser.firstName + currentUser.lastName}}
          </a>
        </li>

      </ul>
    </links>
  </mdb-navbar>
<!--/. Navbar -->
