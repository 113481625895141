
<!-- Form Section -->
<section id="provision-planning">
  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center mb-2">Continous Provision Planning</h1>

  <!-- Section Description-->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
      <p class="section-text mt-3 wow slideInUp text-center">We work hard as a team with full commitment and dedication to ensure the development of children at our Nursery.</p>
    </div>
  </div>
  <!-- End Section Description -->

  <!--Form -->
  <div class="row">
    <div class="col-md-12">
      <mdb-card>
        <mdb-card-header class="info-color white-text text-center py-4">
          <h5>
            <strong>CONTINOUS PROVISION PLANNING FORM</strong>
          </h5>
        </mdb-card-header>
        <mdb-card-body class="px-lg-5 pt-0">
<!--          <form [formGroup] ="nestedForm" (ngSubmit) ="onSubmit()">-->

            <app-common-form formControlName="common"></app-common-form>

            <hr class="border-line mt-5 mb-5">

            <mdb-stepper #stepper [linear]="true" [vertical]="isVertical">

                <!-- Provision Planning Monday -->
                <mdb-step name="MONDAY">
                  <app-activity-form [day]="'Monday'" [step]="stepper" ></app-activity-form>
                </mdb-step>

                <!-- Provision Planning Tuesday -->
                <mdb-step name="TUESDAY">
                  <app-activity-form [day]="'Tuesday'" [step]="stepper"></app-activity-form>
                </mdb-step>

                <!-- Provision Planning Wednesday -->
                <mdb-step name="WEDNESDAY">
                  <app-activity-form [day]="'Wednesday'" [step]="stepper"></app-activity-form>
                </mdb-step>

                <!-- Provision Planning Thursday -->
                <mdb-step name="THURSDAY">
                  <app-activity-form [day]="'Thursday'" [step]="stepper"></app-activity-form>
                </mdb-step>

                <!-- Provision Planning Friday -->
                <mdb-step name="FRIDAY">
                  <app-activity-form [day]="'Friday'" [step]="stepper"></app-activity-form>
                </mdb-step>

            </mdb-stepper>

<!--          </form>-->
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
  <!-- End Form -->

</section>
<!-- Form Section -->
