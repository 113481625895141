import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-checklist',
  templateUrl: './school-checklist.component.html'
  // styleUrls: ['./school-checklist.component.scss']
})
export class SchoolChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
