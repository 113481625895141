  <div class="m-3" [formGroup]="addressFormGroup">
    <!-- Row 1 -->
    <div class="form-row clearfix">
      <div [class]="'col-sm-12 ' + layoutClass">
        <div class="md-form">
          <label [attr.for]="'addressLineOne'+uniqueIndex">Address Line 1</label>
          <input  autocomplete="new-addressLineOne" maxlength="30" name="addressLineOne" mdbInput mdbValidate type="text" [id]="'addressLineOne'+uniqueIndex" formControlName="addressLineOne"
            class="form-control">
          <mdb-error *ngIf="addressFormGetterSetter.addressLineOne.touched && addressFormGetterSetter.addressLineOne.errors">
            Please enter address line 1
          </mdb-error>
        </div>
      </div>
      <div [class]="'col-sm-12 ' + layoutClass">
        <div class="md-form">
          <label [attr.for]="'addressLineTwo'+uniqueIndex">Address Line 2</label>
          <input autocomplete="new-addressLineTwo" maxlength="30" name="addressLineTwo"  mdbInput mdbValidate type="text" [id]="'addressLineTwo'+uniqueIndex" formControlName="addressLineTwo"
            class="form-control">
          <mdb-error *ngIf="addressFormGetterSetter.addressLineTwo.touched && addressFormGetterSetter.addressLineTwo.errors">
            Please enter address line 2
          </mdb-error>
        </div>
      </div>
      <div [class]="'col-sm-12 ' + layoutClass">
        <div class="md-form">
          <label [attr.for]="'city'+uniqueIndex">City</label>
          <input autocomplete="new-city" maxlength="30" name="city" mdbValidate mdbInput type="text" [id]="'city'+uniqueIndex" formControlName="city" class="form-control">
          <mdb-error *ngIf="addressFormGetterSetter.city.touched && addressFormGetterSetter.city.errors">
            Please enter city
          </mdb-error>
        </div>
      </div>

    </div>
    <!-- Row 1 End -->

    <!-- Row 2 -->
    <div class="form-row clearfix">
      <div [class]="'col-sm-12 ' + layoutClass">
        <div class="md-form">
          <label [attr.for]="'state'+uniqueIndex">State</label>
          <input autocomplete="new-state" name="state" maxlength="30" mdbValidate mdbInput type="text" [id]="'state'+uniqueIndex" formControlName="state"
            class="form-control">
          <mdb-error *ngIf="addressFormGetterSetter.state.touched && addressFormGetterSetter.state.errors">
            Please enter state
          </mdb-error>

        </div>
      </div>
      <div [class]="'col-sm-12 ' + layoutClass">
        <div class="md-form">
          <label [attr.for]="'country'+uniqueIndex">Country</label>
          <input autocomplete="new-country" name="country" maxlength="30" mdbValidate mdbInput type="text" [id]="'country'+uniqueIndex" formControlName="country"
            class="form-control">
          <mdb-error *ngIf="addressFormGetterSetter.country.touched && addressFormGetterSetter.country.errors">
            Please enter country
          </mdb-error>
        </div>
      </div>
      <div [class]="'col-sm-12 ' + layoutClass">
        <div class="md-form">
          <label [attr.for]="'postcode'+uniqueIndex">Post Code</label>
          <input autocomplete="new-postcode" name="postcode"  maxlength="10"  mdbValidate mdbInput type="text" [id]="'postcode'+uniqueIndex" formControlName="postcode"
            class="form-control">
          <mdb-error *ngIf="addressFormGetterSetter.postcode.touched && addressFormGetterSetter.postcode.errors">
            Please enter post code
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- Row 2 End -->

  </div>
