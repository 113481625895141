import { createAction, props } from '@ngrx/store';
import { ChildObject } from 'src/app/modules/nursery/models/child.model';


export const LoadChildData = createAction(
  '[ChildRegistrationForm] Load ChildData',
  props<{ id: string }>()
);

export const LoadRegistrationsSuccess = createAction(
  '[ChildRegistrationForm] Load Child Data Success',
  props<{ childForm: ChildObject }>()
);

export const LoadRegistrationsFailure = createAction(
  '[ChildRegistrationForm] Load Child Data Failure',
  props<{ error: any }>()
);

export const SaveChildRegistration = createAction(
  '[Add Child Reg] Adding Child attribute to store',
   props<{childForm: ChildObject}>()
);

export const SaveSuccessChildRegistration = createAction(
  '[Add Child Reg] Save Success Child attribute to store',
   props<{childForm: ChildObject}>()
);

export const SaveFailedChildRegistration = createAction(
  '[Add Child Reg] Failed Adding Child attribute to store',
   props<{childForm: ChildObject}>()
);

