<form [formGroup]="emergencyContactFormGroup">
  <div class="m-3">
    <!-- Row 1 -->
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label class="active">Title</label>
          <input mdbValidate autocomplete="new-title" maxlength="10" name="title" appendToBody="false"
            (focusout)="verifyChanges($event, autoCompleteKeys.Title)" formControlName="title" type="text"
            class="completer-input form-control mdb-autocomplete" [mdbAutoCompleter]="auto" />

          <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of titleResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <mdb-error
            *ngIf="emergencyContactFormGroup.get('title').touched && emergencyContactFormGroup.get('title').errors">
            Please enter title.
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'firstName'+uniqueIndex">First Name</label>
          <input autocomplete="new-firstName" maxlength="30" name="firstName" mdbInput mdbValidate type="text"
            formControlName="firstName" [id]="'firstName'+uniqueIndex" class="form-control">
          <mdb-error
            *ngIf="emergencyContactFormGroup.get('firstName').touched && emergencyContactFormGroup.get('firstName').errors">
            Please enter first name.
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label [attr.for]="'lastName'+uniqueIndex">Last Name</label>
          <input autocomplete="new-lastName" maxlength="30" name="lastName" mdbInput mdbValidate type="text"
            formControlName="lastName" [id]="'lastName'+uniqueIndex" class="form-control">
          <mdb-error
            *ngIf="emergencyContactFormGroup.get('lastName').touched && emergencyContactFormGroup.get('lastName').errors">
            Please enter last name.
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- Row 1 End -->

    <!-- Row 2 -->
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label class="active">Relationship to the child</label>
          <input maxlength="30" autocomplete="new-relationshipToChild" name="relationshipToChild" mdbValidate
            formControlName="relationshipToChild" type="text" class="completer-input form-control mdb-autocomplete"
            (focusout)="verifyChanges($event, autoCompleteKeys.RelationShip)"
            [mdbAutoCompleter]="relationshipToChildAuto" />

          <mdb-auto-completer appendToBody="false" #relationshipToChildAuto="mdbAutoCompleter"
            textNoResults="I have found no results :(">
            <mdb-option *ngFor="let option of relationshipToChildResults | async" [value]="option">
              {{ option }}
            </mdb-option>
          </mdb-auto-completer>

          <mdb-error
            *ngIf="emergencyContactFormGroup.get('relationshipToChild').touched && emergencyContactFormGroup.get('relationshipToChild').errors">
            Please enter title.
          </mdb-error>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-isd [inputValue]="emergencyContactOne?.landlineNumber" controlName="LandLine Number"
          (numberValue)="setNumberValue($event, 'landlineNumber')"></app-isd>

      </div>
      <div class="col-md-4 col-sm-12">
        <app-isd [inputValue]="emergencyContactOne?.mobileNumber" controlName="Mobile Number"
          (numberValue)="setNumberValue($event, 'mobileNumber')"></app-isd>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="md-form">
          <label class="active">Password For Collecting Children</label>
          <input maxlength="50" autocomplete="new-passwordForCollectingChild" name="passwordForCollectingChild" mdbInput
            mdbValidate type="text" formControlName="passwordForCollectingChild" id="passwordForCollectingChild"
            class="form-control">
          <mdb-error
            *ngIf="emergencyContactFormGroup.get('passwordForCollectingChild').touched && emergencyContactFormGroup.get('passwordForCollectingChild').errors">
            Password is required
          </mdb-error>
        </div>
      </div>
    </div>
    <!-- Row 2 End -->
  </div>
  <div id="address-info">
    <div id="address-info">
      <div class="d-flex align-items-center">
        <h3 class="font-weight-bold mt-4">Home Address</h3>
        <div *ngIf="allAddresses?.length" class="btn-group mt-4 ml-4" dropdown>
          <a dropdownToggle class="d-flex align-items-center justify-content-center">
            <img src="../../../../assets/img/form-icons/paste.png">
            <span class="ml-2">Auto Fill Address</span>
          </a>
          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
            <li role="menuitem"><a (click)="patchAddress(item)" class="dropdown-item" *ngFor="let item of allAddresses">
                {{relationType[item.relationType]}}'s - {{item.addressType === 1 ? 'Home Address' : 'Office Address'}}
              </a></li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="mt-4 mb-4 ml-0 mr-0 rounded border-line">
  </div>
  <app-address (addressChange)="updateAddressForm($event)" [headerName]="'Home Address'" [uniqueIndex]="3">
  </app-address>
</form>