import { createReducer,  MetaReducer, on } from '@ngrx/store';
import { mainAreaUpdated, subAreaUpdated } from '../actions/eyfs.actions';
import {environment} from '../../../../../../../environments/environment';
import {Eyfs} from '../../../../models/eyfs.model';

export const eyfsStateFeatureKey = 'eyfsState';

export interface EyfsState {
  eyfs: Eyfs;
}

export const initialState: EyfsState = {
  eyfs: {} as Eyfs
};

export const _eyfsReducers = createReducer(initialState,

  // Main Area Updated
  on(mainAreaUpdated, (state: EyfsState = initialState, action) => (
    {
    ...state,
    eyfs: {
      ...state.eyfs,
      mainArea: action.mainArea
    }
  })),
  // Sub Area Updated
  on(subAreaUpdated, (state: EyfsState = initialState, action) => (
    {
      ...state,
      eyfs: {
        ...state.eyfs,
        subArea: action.subArea
      }
    })),
);

export function eyfsReducers(state, action) {
  return _eyfsReducers(state, action);
}

export const eyfsMetaReducers: MetaReducer<EyfsState>[] = !environment.production ? [] : [];
