import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {CareersService} from '../../services/careers.service';
import {Career} from '../../models/careers.model';
import {HeaderHideShowService} from '../../services/header-hide-show.service';
import {Router} from '@angular/router';
import {ModalDirective} from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  // styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  optionsSelect: Array<any>;

  qualificationOptions: Array<any>;

  selectedFile: File;

  @Input() headerShow: boolean;

  submitted = false;

  careersForm: FormGroup;

  @ViewChild('frame', { static: true }) public frame: ModalDirective;

  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  constructor(private router: Router,
    private careersService: CareersService,
    private formBuilder: FormBuilder,
    private headerHideShowService: HeaderHideShowService) {

    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  //
  titleSearchText = new Subject();
  titleResults: Observable<string[]>;
  titleData: string[] = [
    'Mr.',
    'Ms.',
    'Mrs.',
    'Miss',
  ];

  ngOnInit() {

    this.optionsSelect = [
      { value: 'Apprentice', label: 'Apprentice' },
      { value: 'Baby Room - Nursery Practitioner', label: 'Baby Room - Nursery Practitioner' },
      { value: 'Toddler Room - Nursery Practitioner', label: 'Toddler Room - Nursery Practitioner' },
      { value: 'Pre-School - Nursery Practitioner', label: 'Pre-School - Nursery Practitioner' },
      { value: 'Baby Room Leader', label: 'Baby Room Leader' },
      { value: 'Toddler Room Leader', label: 'Toddler Room Leader' },
      { value: 'Pre-School Room Leader', label: 'Pre-School Room Leader' },
      { value: 'Deputy Manager', label: 'Deputy Manager' },
    ];

    this.qualificationOptions = [
      { value: 'Level 2', label: 'Level 2' },
      { value: 'Level 3', label: 'Level 3' },
      { value: 'Level 5', label: 'Level 5' },
      { value: 'Level 6', label: 'Level 6' },
    ];

    this.careersForm = this.formBuilder.group({
      title: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      position: ['', Validators.required],
      qualification: ['', Validators.required],
      cvFile: [''],
      message: [''],
    });

    this.headerHideShowService.isHeaderShown(this.headerShow);

  }

  // convenience getter for easy access to form fields
  get form1() { return this.careersForm.controls; }

  // only number will be add
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  filter(value: string, data: string[]): string[] {
    if (value === null) {
      value = '';
    }
    const filterValue = value.toLowerCase();
    return data.filter((item: string) => item.toLowerCase().includes(filterValue));
  }

  public onFileChanged(event) {
    // Select File
    this.selectedFile = event.target.files[0];
  }

  saveCareers() {

    if (this.careersForm.invalid) {
      console.log(' ---- Invalid Form Return ---');
      return;
    }
    const uploadImageData = new FormData();
    uploadImageData.append('document', this.selectedFile, this.selectedFile.name);

    // Make sure to create a deep copy of the form-model
    const career: Career = Object.assign({}, this.careersForm.value);

    this.careersService.saveCareers(career, uploadImageData)
      .subscribe(data => {
        console.log('Career Saved' + JSON.stringify(data));
        this.frame.show();
        this.onReset();
    });

  }

  onSubmit(formElement) {

    this.submitted = true;
    console.log(formElement.value);
    // stop here if form is invalid
    if (this.careersForm.invalid) {
      console.log(' ---- Invalid Form Return ---');
      return;
    }
    // Make sure to create a deep copy of the form-model
    const result: Career = Object.assign({}, this.careersForm.value);


    // this.retval = this.careersService.saveCareers(result);

    this.retval.subscribe(data => {
      console.log('Careers Data');
    });

  }

  onReset() {
    this.submitted = false;
    this.careersForm.reset();
  }


  showFiles() {
    let files = '';
    for (let i = 0; i < this.files.length; i ++) {
      files += this.files[i].name;
      if (!(this.files.length - 1 === i)) {
        files += ',';
      }
    }
    return files;
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: 'your-path-to-backend-endpoint',
      method: 'POST',
      data: { foo: 'bar' },
    };
    this.files = [];
    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  onUploadOutput(output: UploadOutput | any): void {

    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
    this.showFiles();
  }

}
