import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from '../../../../services/header-hide-show.service';

@Component({
  selector: 'app-toddler-room-gallery',
  templateUrl: './toddler-room-gallery.component.html',
  // styleUrls: ['./toddler-room-gallery.component.scss'],
  host: {'class': 'w-100'}
})
export class ToddlerRoomGalleryComponent implements OnInit {

  toddlerGallery = [
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/gallery/toddler-room-1.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/gallery/toddler-room-1.jpg', description: 'Image 7'
    },
    {
      img: '../../../../assets/img/nursery/classrooms/toddler-room/gallery/toddler-room-2.jpg',
      thumb: '../../../../assets/img/nursery/classrooms/toddler-room/gallery/toddler-room-2.jpg', description: 'Image 8'
    }
  ];

  @Input() showHeader: boolean;

  constructor(private headerHideShowService: HeaderHideShowService) { }

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.showHeader);
  }

  // refreshPage() {
  //   window.location.reload();
  // }

}
