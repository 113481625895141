import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonConfig } from '../constants/common.constants';
import { AutoCompleteKey } from '../enums/autocomplete.enum';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private genderData: string[] = CommonConfig.genderData;
  private religionData: string[] = CommonConfig.religionData;
  private ethnicityData: string[] = CommonConfig.ethnicityData;
  private firstLanguageData: string[] = CommonConfig.firstLanguageData;
  private titleData: string[] = CommonConfig.titles;
  private relationshipToChildData: string[] = CommonConfig.relationshipToChildData;
  private parentalResponsibilityData: string[] = CommonConfig.yesNoData;
  private ableToCollectChildData: string[] = CommonConfig.yesNoData;
  private roomData: string[] = CommonConfig.rooms;

  private genderSearchText = new Subject();
  private religionSearchText = new Subject();
  private ethnicitySearchText = new Subject();
  private firstLanguageSearchText = new Subject();
  private titleSearchText = new Subject();
  private relationshipToChildSearchText = new Subject();
  private parentalResponsibilitySearchText = new Subject();
  private ableToCollectChildSearchText = new Subject();
  private roomSearchText = new Subject();


  titleResults: Observable<string[]>;

  languageSubscriber(): Observable<string[]> {
    return this.firstLanguageSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.firstLanguageData))
    );
  }

  ethnicitySubscriber(): Observable<string[]> {
    return this.ethnicitySearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.ethnicityData))
    );
  }

  religionSubscriber(): Observable<string[]> {
    return this.religionSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.religionData))
    );
  }

  genderSubscriber(): Observable<string[]> {
    return this.genderSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.genderData))
    );
  }

  roomSubscriber(): Observable<string[]> {
    return this.roomSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.roomData))
    );
  }

  ableToCollectChildSearch(): Observable<string[]> {
    return this.ableToCollectChildSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.ableToCollectChildData))
    );
  }

  parentalResponsibilitySearch(): Observable<string[]> {
    return this.parentalResponsibilitySearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.parentalResponsibilityData))
    );
  }

  relationshipToChildSearch(): Observable<string[]> {
    return this.relationshipToChildSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.relationshipToChildData))
    );
  }

  titleSearch(): Observable<string[]> {
    return this.titleSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.titleData))
    );
  }

  relationShipSearch(): Observable<string[]> {
    return this.relationshipToChildSearchText.pipe(
      startWith(''),
      map((value: string) => this.filter(value, this.relationshipToChildData))
    );
  }

  filter(value: string, data: string[]): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return data.filter((item: string) => item.toLowerCase().includes(filterValue));
  }

  isValidValue(value: string, key: AutoCompleteKey): boolean {
    switch (key) {
      case AutoCompleteKey.Religion:
        return !!this.filter(value, this.religionData)?.length;
      case AutoCompleteKey.Gender:
        return !!this.filter(value, this.genderData)?.length;
      case AutoCompleteKey.RelationShip:
        return !!this.filter(value, this.relationshipToChildData)?.length;
      case AutoCompleteKey.Title:
        return !!this.filter(value, this.titleData)?.length;
      case AutoCompleteKey.Responsibility:
        return !!this.filter(value, this.parentalResponsibilityData)?.length;
      case AutoCompleteKey.Yes_No:
      case AutoCompleteKey.Able_To_Collect:
        return !!this.filter(value, this.ableToCollectChildData)?.length;
      case AutoCompleteKey.Ethnicity:
        return !!this.filter(value, this.ethnicityData)?.length;
      case AutoCompleteKey.FirstLanguage:
        return !!this.filter(value, this.firstLanguageData)?.length;
      case AutoCompleteKey.Room:
        return !!this.filter(value, this.roomData)?.length;
    }

  }

  addValidation(form: FormGroup, addValidation: boolean) {
    for (let control in form.value) {
      if (addValidation) {
        form.get(control).setValidators([Validators.required])
      } else {
        form.get(control).clearValidators();
      }
      form.get(control).updateValueAndValidity();
    }
  }

}
