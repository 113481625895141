import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appRestrictTime]'
})
export class RestrictTimeDirective {

  constructor(private _el: ElementRef, private toaster: ToastrService) { }

  @Output() emitClear = new EventEmitter();

  @Input() minTime;
  @Input() maxTime;

  @HostListener('input', ['$event'])
  onInputChange(event) {
    const initialValue = this._el.nativeElement.value;
    const min = new Date(`1/1/1970 ${this.minTime}`);
    const max = new Date(`1/1/1970 ${this.maxTime}`);
    const compare = new Date(`1/1/1970 ${initialValue}`)
    if (max >= compare && compare >= min) {
      return;
    }
    else {
      this._el.nativeElement.value = '';
      this.emitClear.emit();
      this.toaster.warning(`We are available between ${this.minTime} to ${this.maxTime}`)
    }
  }
}
