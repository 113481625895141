<div class="eyfs-right-section">
    <h4 class="mb-3">CL: Listening and attention</h4>
    <!-- Nav Tabs -->
    <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-cyan tabs-3'" [contentClass]="'card'">
      <!--Panel 1-->
      <mdb-tab heading="0-11">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band 0-11</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll0-11-1" name="cll011Btn" mdbInput>
                <label class="form-check-label" for="cll0-11-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll0-11-2" name="cll011Btn" mdbInput>
                <label class="form-check-label" for="cll0-11-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll0-11-3" name="cll011Btn" mdbInput>
                <label class="form-check-label" for="cll0-11-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form Age Group 0 to 11 months -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm1">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-011-1" class="d-block">Turns towards a familiar sound then locates range of sounds with accuracy.</mdb-checkbox>
              <mdb-checkbox name="cll-check-011-2" class="d-block pt-3">Listens to, distinguishes and responds to intonations and sounds of voices.</mdb-checkbox>
              <mdb-checkbox name="cll-check-011-3" class="d-block pt-3">Reacts in interaction with others by smiling, looking and moving.</mdb-checkbox>
              <mdb-checkbox name="cll-check-011-4" class="d-block pt-3">Quietens or alerts to the sound of speech.</mdb-checkbox>
              <mdb-checkbox name="cll-check-011-5" class="d-block pt-3">Looks intently at a person talking, but stops responding if speaker turns away.</mdb-checkbox>
              <mdb-checkbox name="cll-check-011-6" class="d-block pt-3">Listens to familiar sounds, words, or finger plays.</mdb-checkbox>
              <mdb-checkbox name="cll-check-011-7" class="d-block pt-3">Fleeting Attention - not under child's control, new stimuli takes whole attention.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 2-->
      <mdb-tab heading="8-20">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band 8-20</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll8-20-1" name="cll820Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll8-20-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll8-20-2" name="cll820Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll8-20-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll8-20-3" name="cll820Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll8-20-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form Age Group 8 to 20 months -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm2">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-820-1" class="d-block">Moves whole bodies to sounds they enjoy, such as music or a regular beat.</mdb-checkbox>
              <mdb-checkbox name="cll-check-820-2" class="d-block pt-3">Has a strong exploratory impulse.</mdb-checkbox>
              <mdb-checkbox name="cll-check-820-3" class="d-block pt-3">Concentrates intently on an object or activity of own choosing for short periods.</mdb-checkbox>
              <mdb-checkbox name="cll-check-820-4" class="d-block pt-3">Pay attention to dominant stimulus - easily distracted by noises or other people talking.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 3-->
      <mdb-tab heading="16-26">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band 16-26</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll16-26-1" name="cll1626Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll16-26-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll16-26-2" name="cll1626Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll16-26-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll16-26-3" name="cll1626Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll16-26-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form Age Group 16 to 26 months -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm3">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-1626-1" class="d-block">Listens to and enjoys rhythmic patterns in rhymes and stories.</mdb-checkbox>
              <mdb-checkbox name="cll-check-1626-2" class="d-block pt-3">Enjoys rhymes and demonstrates listening by trying to join in with actions or vocalisations.</mdb-checkbox>
              <mdb-checkbox name="cll-check-1626-3" class="d-block pt-3">Rigid attention – may appear not to hear.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 4-->
      <mdb-tab heading="22-36">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band 22-36</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll22-36-1" name="cll2236Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll22-36-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll22-36-2" name="cll2236Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll22-36-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll22-36-3" name="cll2236Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll22-36-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form Age Group 22 to 36 months -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm4">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-2236-1" class="d-block">Listens with interest to the noises adults make when they read stories.</mdb-checkbox>
              <mdb-checkbox name="cll-check-2236-2" class="d-block pt-3">Recognises and responds to many familiar sounds, e.g. turning to a knock on the door, looking at or going to the door.</mdb-checkbox>
              <mdb-checkbox name="cll-check-2236-3" class="d-block pt-3">Shows interest in play with sounds, songs and rhymes.</mdb-checkbox>
              <mdb-checkbox name="cll-check-2236-4" class="d-block pt-3">Single channelled attention. Can shift to a different task if attention fully obtained – using child’s name helps focus.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 5-->
      <mdb-tab heading="30-50">
        <div class="row">
          <div class="col-12">
            <br>
           <!-- Age Band Section-->
           <span class="font-weight-bold d-block mb-3">Overall in age band 30-50</span>
           <!-- End Age Band Section-->

           <!-- Checbox Area -->
           <div class="checkbox-wrap pt-3 pb-3">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="cll30-50-1" name="cll3050Btn" mdbInput>
              <label class="form-check-label pl-4" for="cll30-50-1">Emerging</label>
            </div>
            
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="cll30-50-2" name="cll3050Btn" mdbInput>
              <label class="form-check-label pl-4" for="cll30-50-2">Developing</label>
            </div>
            
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" id="cll30-50-3" name="cll3050Btn" mdbInput>
              <label class="form-check-label pl-4" for="cll30-50-3">Secure</label>
            </div>
          </div>
          <!-- End Checbox Area -->

           <!-- Border Line -->
           <hr />
           <!-- End Border Line -->

           <!-- Form Age Group 30 to 50 months -->
           <form class="pt-3 pb-3" [formGroup]="comLanguageForm5">
             <!-- Material Checkbox  -->
             <mdb-checkbox name="cll-check-2236-1" class="d-block">Listens to others one to one or in small groups, when conversation interests them.</mdb-checkbox>
             <mdb-checkbox name="cll-check-2236-2" class="d-block pt-3">Listens to stories with increasing attention and recall.</mdb-checkbox>
             <mdb-checkbox name="cll-check-2236-3" class="d-block pt-3">Joins in with repeated refrains and anticipates key events and phrases in rhymes and stories.</mdb-checkbox>
             <mdb-checkbox name="cll-check-2236-4" class="d-block pt-3">Focusing attention – still listen or do, but can shift own attention.</mdb-checkbox>
             <mdb-checkbox name="cll-check-2236-5" class="d-block pt-3">Is able to follow directions (if not intently focused on own choice of activity).</mdb-checkbox>
           </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 6-->
      <mdb-tab heading="40-60">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band 40-60</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll40-60-1" name="cll4060Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll40-60-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll40-60-2" name="cll4060Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll40-60-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll40-60-3" name="cll4060Btn" mdbInput>
                <label class="form-check-label pl-4" for="cll40-60-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form Age Group 40 to 60 months -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm6">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-4060-1" class="d-block">Maintains attention, concentrates and sits quietly during appropriate activity.</mdb-checkbox>
              <mdb-checkbox name="cll-check-4060-2" class="d-block pt-3">Two-channelled attention – can listen and do for short span.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 7-->
      <mdb-tab heading="ELG">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band ELG</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll-elg-1" name="cllElgBtn" mdbInput>
                <label class="form-check-label pl-4" for="cll-elg-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll-elg-2" name="cllElgBtn" mdbInput>
                <label class="form-check-label pl-4" for="cll-elg-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll-elg-3" name="cllElgBtn" mdbInput>
                <label class="form-check-label pl-4" for="cll-elg-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form ELG Age Group -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm7">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-elg-1" class="d-block">Children listen attentively in a range of situations. They listen to stories, accurately anticipating key events and respond to what they hear with relevant comments, questions or actions. They give their attention to what others say and respond appropriately, while engaged in another activity.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
      <!--Panel 8-->
      <mdb-tab heading="ELG+">
        <div class="row">
          <div class="col-12">
            <br>
            <!-- Age Band Section-->
            <span class="font-weight-bold d-block mb-3">Overall in age band ELG+</span>
            <!-- End Age Band Section-->

            <!-- Checbox Area -->
            <div class="checkbox-wrap pt-3 pb-3">
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll-elgplus-1" name="cllElgPlusBtn" mdbInput>
                <label class="form-check-label pl-4" for="cll-elgplus-1">Emerging</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll-elgplus-2" name="cllElgPlusBtn" mdbInput>
                <label class="form-check-label pl-4" for="cll-elgplus-2">Developing</label>
              </div>
              
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="cll-elgplus-3" name="cllElgPlusBtn" mdbInput>
                <label class="form-check-label pl-4" for="cll-elgplus-3">Secure</label>
              </div>
            </div>
            <!-- End Checbox Area -->

            <!-- Border Line -->
            <hr />
            <!-- End Border Line -->

            <!-- Form ELG+ Age Group -->
            <form class="pt-3 pb-3" [formGroup]="comLanguageForm8">
              <!-- Material Checkbox  -->
              <mdb-checkbox name="cll-check-elgplus-1" class="d-block">Children listen to instructions and follow them accurately, asking for clarification if necessary. They listen attentively with sustained concentration to follow a story without pictures or props. They can listen in a larger group, for example, at assembly.</mdb-checkbox>
            </form>

          </div>
        </div>
      </mdb-tab>
    </mdb-tabset>
  </div>