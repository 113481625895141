<div class="eyfs-left-section">
    <h4 class="mb-3 text-white font-weight-bold">EYO</h4>
    <div class="tabs-wrapper">
        <mdb-accordion [multiple]="false">
            <mdb-accordion-item [collapsed]="false">
              <mdb-accordion-item-head  (click)="mainAreaClicked('CL')">
                <div class="box-title cal">CL</div>Communication and language
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="list-group">
                  <a id="cl-01" href="javascript:void(0);" (click)="subAreaClicked('LA')" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>Listening and attention</a>
                  <a id="cl-02" href="javascript:void(0);" (click)="subAreaClicked('U')" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Understanding</a>
                  <a id="cl-03" href="javascript:void(0);" (click)="subAreaClicked('S')" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Speaking</a>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
              <mdb-accordion-item-head>
                <div class="box-title pd">PD</div>Physical development
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="list-group">
                  <a id="pd-01" href="javascript:void(0);" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>Moving and handling</a>
                  <a id="pd-02" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Health and self-care</a>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
              <mdb-accordion-item-head>
                <div class="box-title psed">PSED</div>Personal, social and emotional
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="list-group">
                  <a id="pse-01" href="javascript:void(0);" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>Self-confidence and self-awareness</a>
                  <a id="pse-02" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Managing feelings and behaviour</a>
                  <a id="pse-03" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Making relationships</a>
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
                <mdb-accordion-item-head>
                  <div class="box-title lit">L</div>Literacy
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div class="list-group">
                    <a id="lit-01" href="javascript:void(0);" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>Reading</a>
                    <a id="lit-02" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Writing</a>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head>
                  <div class="box-title mat">M</div>Mathematics
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div class="list-group">
                    <a id="mat-01" href="javascript:void(0);" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>Numbers</a>
                    <a id="mat-02" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Shape, space and measures</a>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head>
                  <div class="box-title uw">UW</div>Understanding the World
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div class="list-group">
                    <a id="utw-01" href="javascript:void(0);" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>People and communities</a>
                    <a id="utw-02" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>The world</a>
                    <a id="utw-03" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Technology</a>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item>
                <mdb-accordion-item-head>
                  <div class="box-title ead">EAD</div>Expressive Arts and Design
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <div class="list-group">
                    <a id="ead-01" href="javascript:void(0);" class="list-group-item list-group-item-action active waves-light" mdbWavesEffect>Exploring and using media and materials</a>
                    <a id="ead-02" href="javascript:void(0);" class="list-group-item list-group-item-action waves-light" mdbWavesEffect>Being imaginative</a>
                  </div>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

          </mdb-accordion>
    </div>
  </div>
