import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Career} from '../models/careers.model';
import {catchError, retry, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CareersService {

  message: string;

  constructor(
    private readonly _http: HttpClient
  ) { }

  private _apiUrl = '/api/career/add';

  // Http Headers.. put it in the http Interceptor
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  saveCareers(career: Career, uploadCVData: FormData): Observable<any> {
    // saveCareers(career: Career, uploadCVData: FormData): Observable<any> {
    // Make a call to the Spring Boot Application to save the image
    // this._http.post(this._apiUrl, uploadCVData, this.httpOptions)
    //   .subscribe((response) => {
    //         this.message = 'Image uploaded successfully';
    //     }
    //   );

    return this._http.post(this._apiUrl, career, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving emergency contact one registration', career))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log('${operation} failed: ${error.message}');
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }

}
