import { BookingInfo } from './../../nanny-service/booking/booking-form/booking-form.interface';
import { OptionalService, UserCredentials, UserDetails } from './../../../../../shared/models/nanny-service.model';
import { createAction, props } from '@ngrx/store';
import { NannyServiceModel } from 'src/app/shared/models/nanny-service.model';

import { ChildrenCountGroup } from '../../../../../shared/models/nanny-service.model';


export const addChildCountInfo = createAction(
  '[Add ChildrenCountGroup] Adding ChildrenCountGroup store',
   props<{childCountInfo: ChildrenCountGroup[]}>()
);

export const saveChildCountInfo = createAction(
  '[Save ChildrenCountGroup] Saving ChildrenCountGroup to database',
  props<{nannyService: NannyServiceModel}>()
);

export const saveChildCountInfoSuccess = createAction(
  '[Save ChildrenCountGroup Success] Saving ChildrenCountGroup to database was successful',
  props<{nannyService: NannyServiceModel}>()
);

export const saveChildCountInfoError = createAction(
  '[Save ChildrenCountGroup Error] Saving ChildrenCountGroup to database was error',
  props<{nannyService: NannyServiceModel}>()
);
// OptionalService
export const addOptionalService = createAction(
  '[Add optionalService] Adding optionalService store',
   props<{optionalService: OptionalService[]}>()
);

export const saveOptionalService = createAction(
  '[Save OptionalService] Saving OptionalService to database',
  props<{nannyService: NannyServiceModel}>()
);

export const saveOptionalServiceSuccess = createAction(
  '[Save OptionalService Success] Saving OptionalService to database was successful',
  props<{nannyService: NannyServiceModel}>()
);

export const saveOptionalServiceError = createAction(
  '[Save OptionalService Error] Saving OptionalService to database was error',
  props<{nannyService: NannyServiceModel}>()
);

// user credentials
export const addUserCredentials = createAction(
  '[Add UserCredentials] Adding UserCredentials store',
   props<{userCredentials: UserCredentials}>()
);

export const saveUserCredentials = createAction(
  '[Save UserCredentials] Saving UserCredentials to database',
  props<{nannyService: NannyServiceModel}>()
);

export const saveUserCredentialsSuccess = createAction(
  '[Save UserCredentials Success] Saving UserCredentials to database was successful',
  props<{nannyService: NannyServiceModel}>()
);

export const saveUserCredentialsError = createAction(
  '[Save UserCredentials Error] Saving UserCredentials to database was error',
  props<{nannyService: NannyServiceModel}>()
);

// user details

export const addUserDetails = createAction(
  '[Add UserDetails] Adding UserDetails store',
   props<{userDetails: UserDetails}>()
);

export const saveUserDetails = createAction(
  '[Save UserDetails] Saving UserDetails to database',
  props<{nannyService: NannyServiceModel}>()
);

export const saveUserDetailsSuccess = createAction(
  '[Save UserDetails Success] Saving UserDetails to database was successful',
  props<{nannyService: NannyServiceModel}>()
);

export const saveUserDetailsError = createAction(
  '[Save UserDetails Error] Saving UserDetails to database was error',
  props<{nannyService: NannyServiceModel}>()
);

export const addBookingSlot = createAction(
  '[Add Booking Slot] Adding Booking Slot store',
   props<{slot: BookingInfo}>()
);

export const saveBookingSlot = createAction(
  '[Save BookingSlot] Saving BookingSlot to database',
  props<{nannyService: NannyServiceModel}>()
);

export const saveBookingSlotSuccess = createAction(
  '[Save BookingSlot Success] Saving BookingSlot to database was successful',
  props<{nannyService: NannyServiceModel}>()
);

export const saveBookingSlotError = createAction(
  '[Save BookingSlot Error] Saving BookingSlot to database was error',
  props<{nannyService: NannyServiceModel}>()
);


export const saveConfirmPaymentAmount = createAction(
  '[Save ConfirmPaymentAmount] Saving ConfirmPaymentAmount to database',
  props<{nannyService: NannyServiceModel}>()
);

export const saveConfirmPaymentAmountSuccess = createAction(
  '[Save ConfirmPaymentAmount Success] Saving ConfirmPaymentAmount to database was successful',
  props<{nannyService: NannyServiceModel}>()
);

export const saveConfirmPaymentAmountError = createAction(
  '[Save ConfirmPaymentAmount Error] Saving ConfirmPaymentAmount to database was error',
  props<{nannyService: NannyServiceModel}>()
);

