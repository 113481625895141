import { Injectable } from '@angular/core';
import { AppointmentConfig } from 'src/app/core/constants/appoinment.config';
import { MockAppointment } from './appointment-calendar.mock';


@Injectable({
  providedIn: 'root',
})
export class InstallationService {
  constructor() { }

  config = AppointmentConfig;

  toggleMinDays(next?: boolean): void {
    if (next === true) {
      this.config.minDays = this.config.minDays + this.config.showScheduleDays;
    } else if (next === false) {
      this.config.minDays = this.config.minDays - this.config.showScheduleDays;
    }
  }

  getAppointmentCalendar() {
    return new MockAppointment().getAppointments();
  }
}
