import { Component, OnInit } from '@angular/core';
import {HeaderHideShowService} from "../../services/header-hide-show.service";

@Component({
  selector: 'app-classrooms',
  templateUrl: './classrooms.component.html',
  //styleUrls: ['./classrooms.component.scss']
})
export class ClassroomsComponent implements OnInit {

  constructor(private headerHideShowService:HeaderHideShowService) { }

  ngOnInit() {
    this.headerHideShowService.isHeaderShown(false);
  }
  
  // refreshPage() {
  //   window.location.reload();
  // }
  
}
