
<!-- Nursery Policies Section -->
<section id="nursery-policies">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Nursery Policies</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">We work hard as a team with full commitment and dedication to ensure the development of children at our Nursery. Join us to be part of our team, Kindly drop your resume with some details we will be in touch with you soon.
        </p>
    </div>
  </div>
  <div class="section-wrapper pt-4 pb-2">
     <!--Panel 1-->
     <div class="row">
      <div class="col-12">
          <mdb-accordion [multiple]="false">

            <mdb-accordion-item [collapsed]="false" [customClass]="'border-0'">
              <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="landmark"></mdb-icon>  Our Policies</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="row">
                    <!-- Grid column -->
                    <div class="col-lg-12">
                      <div class="policy-list">
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/access-and-storage-of-Information.pdf" title="Complaints and Compliments" target="_blank">Access and Storage of Information</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/accidents-and-first-aid.pdf" title="Accidents and First Aid" target="_blank">Accidents and First Aid</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/admission.pdf" title="Admission" target="_blank">Admission</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/adverse-weather.pdf" title="Adverse Weather" target="_blank">Adverse Weather</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/alcohol-and-substance-misuse.pdf" title="Alcohol and Substance Misuse" target="_blank">Alcohol and Substance Misuse</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/allergies-and-allergic-reactions.pdf" title="Allergies and Allergic Reactions" target="_blank">Allergies and Allergic Reactions</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/arrivals-and-departures.pdf" title="Arrivals and Departures" target="_blank">Arrivals and Departures</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/biting.pdf" title="Biting" target="_blank">Biting</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/camera-mobile-phone-and-recording-device-use.pdf" title="Camera Mobile Phone and Recording Device Use" target="_blank">Camera Mobile Phone and Recording Device Use</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/caring-for-babies-and-toddlers.pdf" title="Caring for Babies and Toddlers" target="_blank">Caring for Babies and Toddlers</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/early-learning-opportunities-statement.pdf" title="Early Learning Opportunities Statement" target="_blank">Early Learning Opportunities Statement</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/equipment-and-resources.pdf" title="Equipment and Resources" target="_blank">Equipment and Resources</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/fire-safety.pdf" title="Fire Safety" target="_blank">Fire Safety</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/health-and-safety.pdf" title="Health and Safety" target="_blank">Health and Safety</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/lost-child-procedure-from-nursery.pdf" title="Lost Child Procedure from Nursery" target="_blank">Lost Child Procedure from Nursery</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/lost-child-procedure-from-outings.pdf" title="Lost Child Procedure from Outings" target="_blank">Lost Child Procedure from Outings</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/medication.pdf" title="Medication" target="_blank">Medication</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/monitoring-staff-behaviour-policy.pdf" title="Monitoring Staff Behaviour" target="_blank">Monitoring Staff Behaviour</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/new-absence-management-procedure-policy.pdf" title="New Absence Management Procedure Policy" target="_blank">New Absence Management Procedure Policy</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/outdoor-play.pdf" title="Outdoor Play" target="_blank">Outdoor Play</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/overall-approach-to-risk-assessment.pdf" title="Overall Approach to Risk Assessment" target="_blank">Overall Approach to Risk Assessment</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/parents-and-carers-as-partners.pdf" title="Parents and Carers As Partners" target="_blank">Parents and Carers As Partners</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/promoting-positive-behaviour.pdf" title="Promoting Positive Behaviour" target="_blank">Promoting Positive Behaviour</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/risk-management-assessment.pdf" title="Risk Management Assessment" target="_blank">Risk Management Assessment</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/safe-care-and-practice.pdf" title="Safe Care and Practice" target="_blank">Safe Care and Practice</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/safe-recruitment-of-staff.pdf" title="Safe Recruitment of Staff" target="_blank">Safe Recruitment of Staff</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/safegaurding-and-child-protection-policy.pdf" title="Safegaurding and Child Protection Policy" target="_blank">Safegaurding and Child Protection Policy</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/settling-in.pdf" title="Settling In" target="_blank">Settling In</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/sickness-and-illness.pdf" title="Sickness and Illness" target="_blank">Sickness and Illness</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/special-educational-needs-and-disabilities.pdf" title="Special Educational Needs and Disabilities" target="_blank">Special Educational Needs and Disabilities</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/suitabilty-of-staff.pdf" title="Suitability of Staff" target="_blank">Suitability of Staff</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/supervision-of-children.pdf" title="Supervision of Children" target="_blank">Supervision of Children</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/supervision-of-visitors.pdf" title="Supervision of Visitors" target="_blank">Supervision of Visitors</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/visits-and-outings.pdf" title="Visits and Outings" target="_blank">Visits and Outings</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../../assets/docs/nursery-policies/whistleblowing.pdf" title="Whistleblowing" target="_blank">Whistleblowing</a>
                        </div>
   <!--                   </div>
                    </div>

                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
              <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="landmark"></mdb-icon> Garden Policies</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="row">

                    <div class="col-lg-12">
                      <div class="policy-list">
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                      </div>
                    </div>

                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
              <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="landmark"></mdb-icon> Safety Policies</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="row">

                    <div class="col-lg-12">
                      <div class="policy-list">
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                      </div>
                    </div>

                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item [collapsed]="true" [customClass]="'border-0'">
              <mdb-accordion-item-head [customClass]="'indigo z-depth-1 white-text text-left'"><mdb-icon fas icon="landmark"></mdb-icon> Environment Policies</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <div class="row">

                    <div class="col-lg-12">
                      <div class="policy-list">
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        <div class="policy-box">
                          <span>07/09/2019 - </span><a href="../../../../../assets/docs/complaints-and-compliments.pdf" title="Complaints and Compliments" target="_blank">Complaints and Compliments</a>
                        </div>
                        -->

                      </div>
                    </div>
                    <!-- Grid column -->
                </div>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

          </mdb-accordion>
      </div>
    </div>
  </div>
</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
