import { Component, OnInit } from '@angular/core';
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-toddler-room-admission',
  templateUrl: './toddler-room-admission.component.html',
  //styleUrls: ['./toddler-room-admission.component.scss']
})
export class ToddlerRoomAdmissionComponent implements OnInit {

  modalRef: MDBModalRef;

  constructor() { }

  ngOnInit() {
  }

  openModal() {
    console.log('--- Opening Modal ---');
    // this.modalRef = this.modalService.show(ModalComponent)
  }

}
