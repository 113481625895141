<form [formGroup]="activityPlanningForm" (ngSubmit)="goForward()" >

  <!-- Form Row-->
  <div class="form-row">
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="artsAndCrafts" formControlName="artsAndCrafts" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="artsAndCrafts">Arts & Crafts</label>
        <mdb-error *ngIf="submitted && form1.artsAndCrafts.errors">Please provide answer</mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="mathsAndScience" formControlName="mathsAndScience" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="mathsAndScience">Maths & Science</label>
        <mdb-error *ngIf="submitted && form1.mathsAndScience.errors">Please provide answer</mdb-error>
      </div>
    </div>
  </div>

  <!-- Form Row-->
  <div class="form-row">
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="construction" formControlName="construction" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="construction">Construction</label>
        <mdb-error *ngIf="submitted && form1.construction.errors">Please provide answer</mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="sand" formControlName="sand" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="sand">Sand</label>
        <mdb-error *ngIf="submitted && form1.sand.errors">Please provide answer</mdb-error>
      </div>
    </div>
  </div>

  <!-- Form Row-->
  <div class="form-row">
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="water" formControlName="water" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="water">Water</label>
        <mdb-error *ngIf="submitted && form1.water.errors">Please provide answer</mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="messyPlay" formControlName="messyPlay" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="messyPlay">Messy Play</label>
        <mdb-error *ngIf="submitted && form1.messyPlay.errors">Please provide answer</mdb-error>
      </div>
    </div>
  </div>

  <!-- Form Row-->
  <div class="form-row">
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="homeCorner" formControlName="homeCorner" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="homeCorner">Home Corner</label>
        <mdb-error *ngIf="submitted && form1.homeCorner.errors">Please provide answer</mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="literacy" formControlName="literacy" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="literacy">Literacy</label>
        <mdb-error *ngIf="submitted && form1.literacy.errors">Please provide answer</mdb-error>
      </div>
    </div>
  </div>

  <!-- Form Row-->
  <div class="form-row">
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="smallWorld" formControlName="smallWorld" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="smallWorld">Small world</label>
        <mdb-error *ngIf="submitted && form1.smallWorld.errors">Please provide answer</mdb-error>
      </div>
    </div>
    <!-- Grid column -->
    <div class="col-lg-6 col-sm-12">
      <div class="md-form">
        <textarea type="text" id="ict" formControlName="ict" class="md-textarea form-control" mdbInput mdbValidate></textarea>
        <label for="ict">I.C.T</label>
        <mdb-error *ngIf="submitted && form1.ict.errors">Please provide answer</mdb-error>
      </div>
    </div>
  </div>

  <button mdbBtn type="button" color="danger" rounded="true" class="z-depth-0 my-4 waves-effect" [hidden]="day=='Monday'" (click)="goBack(step)" mdbWavesEffect>PREVIOUS</button>
  <button mdbBtn type="button" color="info" rounded="true" class="z-depth-0 my-4 waves-effect float-right" [innerHtml]="day!=='Friday' ? 'NEXT': 'SUBMIT' " mdbWavesEffect>NEXT</button>

</form>
