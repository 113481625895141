import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TokenStorageService } from '../../auth/_services/token-storage.service';
import { User } from '../../nursery/models/user.model';
import { HeaderHideShowService } from '../../nursery/services/header-hide-show.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  header = true;
  intro = 'intro';
  intro2 = 'intro2';
  destroySubject: Subject<any> = new Subject<any>();
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  isModalShown = false;
  authenticated = false;
  currentUser: User;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private _headerHideShowService: HeaderHideShowService) {

  }

  ngOnInit() {

    if (this.router.url !== null && this.router.url !== '/') {
      this.header = false;
    } else {
      this.header = true;
    }
    this._headerHideShowService.change
      .pipe(takeUntil(this.destroySubject)).subscribe((isHeaderShown: boolean) => {
        this.header = isHeaderShown;
      });

    this.tokenStorage.currentUserSubject.subscribe(user => {
      if (user != null) {
        this.currentUser = user;
        this.isLoggedIn = true;
      } else {
        this.currentUser = null;
        this.isLoggedIn = false;
        localStorage.removeItem('auth-accessToken');
      }
    });
    this.isModalShown = true;
  }

  changeHeader(path: string) {
    this.header = false;
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  openModal() {
  }

  logout() {
    this.tokenStorage.signOut();
    this.router.navigate(['/']);
  }
}
