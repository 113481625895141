import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {MdbStepperComponent, ToastService} from 'ng-uikit-pro-standard';
import {FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
  addContinousProvisionPlanningAction,
  saveContinousProvisionPlanningAction
} from '../../store/actions/continous-provision-planning.actions';
import {select, Store} from '@ngrx/store';
import {ContinousPlanningService} from '../../../services/continous-planning.service';
import {ContinousProvisionPlanning} from '../../../models/continous-provision-planning.model';
import {selectCPPFromStore} from '../../store/selectors/continous-planning.selector';
import {ContinousProvisionPlanningState} from '../../store/reducers/continous-provision-planning.reducer';

@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  // styleUrls: ['./activity-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityFormComponent),
      multi: true
    },
      {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ActivityFormComponent),
      multi: true
    }
  ]
})

export class ActivityFormComponent implements OnInit {

  @Input() day: string;
  @Input() step: MdbStepperComponent;
  activityPlanningForm: FormGroup;
  submitted = false;
  alive = false;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastService,
    private store: Store<ContinousProvisionPlanningState>,
    private continousPlanningService: ContinousPlanningService) { }

   // convenience getter for easy access to form fields
  get form1() { return this.activityPlanningForm.controls; }

  ngOnInit() {

    this.activityPlanningForm = this.formBuilder.group({
      artsAndCrafts: ['', Validators.required],
      mathsAndScience: ['', Validators.required],
      construction: ['', Validators.required],
      sand: ['', Validators.required],
      water: ['', Validators.required],
      messyPlay: ['', Validators.required],
      homeCorner: ['', Validators.required],
      literacy: ['', Validators.required],
      smallWorld: ['', Validators.required],
      ict: ['', Validators.required],
    });

    this.activityPlanningForm.valueChanges
      .subscribe(value => {
        value.day = this.day;
        this.store.dispatch(addContinousProvisionPlanningAction({continousProvisionPlanning : value}));
      });
  }

  goBack(stepper: MdbStepperComponent) {
    stepper.previous();
  }

  goForward() {
    if (this.activityPlanningForm.invalid) {
      this.showWarning();
      return;
    }
    this.submitted = true;

    let cppFromStore: ContinousProvisionPlanning[];
    this.store.pipe(select(selectCPPFromStore)).subscribe((continousProvisionPlanning) => {
        cppFromStore = continousProvisionPlanning.continousPlanning;
      }
    );

    this.store.dispatch(saveContinousProvisionPlanningAction({ continousProvisionPlanning: cppFromStore}));

    if (this.day === 'Friday') {
      console.log(' Data For ' + this.day + ':' + JSON.stringify(this.activityPlanningForm.value));
      this.continousPlanningService.saveContinousPlanning(this.activityPlanningForm.value as ContinousProvisionPlanning);
      this.showSuccess();
    } else {
      console.log(' Data For ' + this.day + ':' + JSON.stringify(this.activityPlanningForm.value));
      this.step.next();
    }

  }

  showSuccess() {
    this.toastrService.info('Data Saved Successfully!!');
    setTimeout(function() { location.reload(); }, 1000);
  }

  showWarning() {
    this.toastrService.warning('Please fill the form!!');
  }

  onReset() {
    this.submitted = false;
    this.activityPlanningForm.reset();
  }

  // public onTouched: () => void = () => {};

  // writeValue(value: any): void {
  //   value && this.activityPlanningForm.setValue(value, { emitEvent: false });
  // }
  // registerOnChange(fn: any): void {
  //   // console.log("on change");
  //   this.activityPlanningForm.valueChanges.subscribe(fn);
  // }
  // registerOnTouched(fn: any): void {
  //   // console.log("on blur");
  //   this.onTouched = fn;
  // }
  // setDisabledState?(isDisabled: boolean): void {
  //   isDisabled ? this.activityPlanningForm.disable() : this.activityPlanningForm.enable();
  // }
  // validate(c: AbstractControl): ValidationErrors | null {
  //   console.log('Activity Form validation', c);
  //   return this.activityPlanningForm.valid ? null : { invalidForm: {valid: false, message: 'Activity Form fields are invalid'}};
  // }

}
