export enum AutoCompleteKey {
  Religion = 'Religion',
  Gender = 'Gender',
  RelationShip = 'RelationShip',
  Title = 'Title',
  FirstLanguage = 'FirstLanguage',
  Ethnicity = 'Ethnicity',
  Yes_No = 'Yes_No',
  Responsibility = 'Responsibility',
  Able_To_Collect = 'Able To Collect',
  Room = 'Room',
}
