
<router-outlet #outlet="outlet"></router-outlet>

<section id="eyfs" *ngIf="outlet.isActivated == false">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">EYFS Tracking</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">
          The purpose of the Early Years Foundation Stage (EYFS) tracker is to enable parents and practitioners to support children in meeting their potential by ensuring they are making progress in all areas of their development at the right rate according to the child's age and stage of development.

          The tracker should be completed by practitioners, parents and children. It should be passed from setting to setting at times of transition to ensure continuity of development.
        </p>
    </div>
  </div>

  <div class="section-wrapper p-0">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <app-eyfs-left-section></app-eyfs-left-section>
      </div>
      <div class="col-lg-8 col-md-12">
        <app-eyfs-right-section></app-eyfs-right-section>
      </div>
    </div>
  </div>
</section>






