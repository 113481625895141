import {Component, Input, OnInit} from '@angular/core';
import {HeaderHideShowService} from '../../services/header-hide-show.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-nursery-software',
  templateUrl: './nursery-software.component.html',
  // styleUrls: ['./nursery-software.component.scss']

})
export class NurserySoftwareComponent implements OnInit {

  diaryUpdates = [
    {
      img: '../../../../assets/img/nursery/nursery-software/diary-updates.png',
      thumb: '.../../../../assets/img/nursery/nursery-software/diary-updates.png', description: 'Image 1'
    }
  ];
  learningJourney = [
    {
      img: '../../../../assets/img/nursery/nursery-software/sharing-learning-journey.png',
      thumb: '../../../../assets/img/nursery/nursery-software/sharing-learning-journey.png', description: 'Image 1'
    }
  ];
  writeObservation = [
    {
      img: '../../../../assets/img/nursery/nursery-software/write-observation.png',
      thumb: '../../../../assets/img/nursery/nursery-software/write-observation.png', description: 'Image 1'
    }
  ];
  eyfsFramework = [
    {
      img: '../../../../assets/img/nursery/nursery-software/link-eyfs.png',
      thumb: '../../../../assets/img/nursery/nursery-software/link-eyfs.png', description: 'Image 1'
    }
  ];

  constructor(private router: Router,
    private headerHideShowService: HeaderHideShowService) {
      $(document).ready(function() {
           /** Read More - Less Functionality */
          $('#about-app-link').click(function() {
            $('#about-app-read-more').slideToggle();
            if ($(this).text() === 'Read More..') {
              $(this).text('Read Less -');
            } else {
              $(this).text('Read More..');
            }
          });
          $('.mdb-lightbox figure').removeClass('col-md-4').addClass('col-md-12'); // hide breabcrumb on page load
          // $(".breadcrumb").hide() // hide breabcrumb on page load
          // $("#nursery-software").parents().find('.container').addClass('wide100'); // set container max-width on page load
          // $("#app-feature").css({'max-width': '90%', 'margin': '0 auto'}); // set about-app max-width on page load
          $('.side-menu-links').css('top', '65%'); // set container max-width on page load
      });
    }

  @Input() headerShow: boolean;

  ngOnInit() {

    this.headerHideShowService.isHeaderShown(this.headerShow);

  }

}
