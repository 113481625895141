<div fxFlex fxLayout="column">
  <mat-form-field>
    <input matInput [(ngModel)]="username" placeholder="Username">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="password" placeholder="Password" type="password">
  </mat-form-field>
  <p *ngIf="error" style="color:red;"> {{error}}</p>
  <button mat-raised-button color="primary" class="margin-bottom--xs" (click)="login()">Login</button>
  <button mat-raised-button class="margin-bottom--xs" (click)="signup()">Signup</button>
  <button mat-raised-button color="warn" class="margin-bottom--xs" (click)="dialogRef.close(false)">Close</button>
</div>
