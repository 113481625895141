<ng-container [formGroup]="checkEligibilityFormTwo">
    
<!-- Form Field -->
<div class="form-group pl-4" [hidden]="stage!=='Step 2'">
    <h5 class="mb-3">Do you have any children aged 3 or 4?</h5>
    <span class="mb-4 d-block">All 3 and 4 year olds in England are entitled to 15 hours free childcare a week in term time – this is 570 hours a year.</span>
    
    <div class="form-check form-check-inline">
        <input type="radio" class="form-check-input" value="yes" id="radChildAgeBtn1" name="childage" formControlName="childage">
        <label class="form-check-label font-weight-bold text-uppercase" for="radChildAgeBtn1">Yes</label>
    </div>
    
    <div class="form-check form-check-inline">
        <input type="radio" class="form-check-input" value="no" id="radChildAgeBtn2" name="childage" formControlName="childage">
        <label class="form-check-label font-weight-bold text-uppercase" for="radChildAgeBtn2">No</label>
    </div>

    <!-- <div class="alert-text">
        <mdb-error *ngIf="submitted && form1.childage.errors">Please Select Value</mdb-error>
    </div> -->

</div>

<!-- Send button -->
<button mdbBtn type="button" color="danger" rounded="true" class="z-depth-0 my-4 waves-effect" [hidden]="stage=='Step 1'" (click)="goBack(step)" mdbWavesEffect>PREVIOUS</button>
<button mdbBtn type="button" color="info" rounded="true" class="z-depth-0 my-4 waves-effect float-right" [innerHtml]="stage!=='Step 4' ? 'NEXT': 'SUBMIT' " (click)="goForward();" mdbWavesEffect>NEXT</button>

</ng-container>