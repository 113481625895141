export const AppointmentConfig = {
  maxDays: 20,
  minDays: 0,
  showScheduleDays: getScheduleDays(),
  slotAvailability: 3,
  timeSlots: [
  {
    start: '10:30',
    end: '11:00',
  },
  {
    start: '16:30',
    end: '17:00'
  },
  {
    start: '17:00',
    end: '17:30'
  },
  {
    start: '17:30',
    end: '18:00'
  }],
  excludeDays: ['Sat', 'Sun']
};

export function getScheduleDays() {
  const width = document.documentElement.getClientRects()[0].width;
  if (width > 0 && width < 421) {
    return 2;
  } else if (width > 421 && width < 700) {
    return 4;
  }
  else if (width > 700 && width < 992) {
    return 5;
  }
  else if (width > 990 && width < 1320) {
    return 2;
  }
  else if (width > 1320 && width < 1800) {
    return 3;
  } else {
    return 4;
  }
}
