<section id="babyroom">

  <!-- Section heading -->
  <h1 class="section-heading font-weight-bold wow slideInDown text-center">Baby Room</h1>

  <!-- Section description -->
  <div class="d-flex justify-content-center pb-4">
    <div class="col-lg-10 col-sm-10">
        <p class="section-text mt-3 wow slideInUp text-center">
          Explore our free flow home from home environment for our youngest children:The baby room is devised for kids aged approx - 3 to 24 months.  The bright and buoyant room provides reclining, calming and quite environment for the babies to rest and sleep throughout the day.
        </p>
    </div>
  </div>

  <div class="section-wrapper">
      <!-- Nav Tabs -->
      <mdb-tabset #staticTabs [vertical]="true" [buttonClass]="'md-tabs tabs-5 ml-0 mr-0'" [contentClass]="'pl-0 pr-0'">
        <!--Panel 1-->
        <mdb-tab heading="Baby Room Info">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Baby Room Image">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Baby Room</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Card image-->
                  <div class="view view-cascade overlay waves-light" mdbWavesEffect>
                    <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-header.png" class="cardimg" alt="Baby Room Header">

                    <a>
                      <div class="mask rgba-white-slight"></div>
                    </a>
                  </div>
                  <!--/Card image-->
                  <!--Quotation-->
                  <div class="pt-4 pb-4 text-left">
                  <p>
                    Explore our free flow home from home environment for our youngest children:The baby room is devised for kids aged approx—3 to 24 months. The bright and buoyant room provides reclining, calming and quite environment for the babies to rest and sleep throughout the day. Our unit is comprised of a separate sleeping room, nappy change area, an area for story time and hygienic kitchen facilities.  Our baby room comes with a well-carpeted area and is provided with a soft and soothing setting for the little tiny tots to crawl, roll, stumble, and shuffle.
                  </p>
                  <p>
                    We have a well-equipped play area that is non-messy and has non-slippery flooring, which keeps the babies safe throughout their different play activities. The no-shoe policy has been adopted to keep up the cleanliness factor and the child's safety.
                    Vast selections of resources have been added to boost up their fine motor skills and coordination; toys are handy for the babies to self-select where they can freely enjoy various experiences of sight, smell, and touch, which are essential in a child's development.
                  </p>
                  <p>
                    Our large breezy room is designed to hold the babies comfortably. Most importantly, our baby room provides a homely and fun environment for your little one to nurture and grow. Hence, we take immense pleasure in our courteous and contented baby room.
                    We focus on the essential field of learning – communication, language, physical, social, emotional development and the aim is to motivate them to discover new and exciting activities.
                  </p>
                  <p>
                    Our Baby Room Staffs: We have a team of highly trained staff who assure that your baby feels happy and protected. They stay in close connection with the parents and make sure that the child's periodic routine is followed. We also keep them well-updated with overall information on their baby's day at the nursery. Our team of dedicated staff provides verbal feedback about your child's day. Our baby room lead (insert name) is very committed to her role, and her quality care blossoms through. Each child has their diary where the staffs notifies the parents about the activities they have taken part in, how many hours they have slept, and the food they have consumed. Hence, working in close connection with parents is highly essential for us.
                  </p>
                  <p>
                    Baby Room Activities: The baby room activities are planned according to individual interests. Our activities are not just confined to paint and brush but also include conveying ourselves through dancing, singing, and using a variety of props and instruments.
                  </p>
                  <p>
                    Some of the activities that we carry out in our baby room:
                  </p>
                  <ul>
                    <li>Nursery rhymes, singing and playing various musical instruments to storytime.</li>
                    <li>Exploring various soft play activities, jumping, running, climbing.</li>
                    <li>Enhancing sensory skills with foam, water, paint, and involving kids in lots of squidgy activities.</li>
                    <li>Short walks around the playground and fields.</li>
                    <li>Role play and messy play activities.</li>
                    <li>Various imaginative plays and lots more!</li>
                  </ul>
                </div>

                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 2-->
        <mdb-tab heading="Daily Routine">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Baby Room Image Second">
                </div>

                <mdb-card-body>
                  <!--Name-->
                  <mdb-card-title>
                    <h3>Daily Routine</h3>
                  </mdb-card-title>
                  <hr>
                  <!--Accordion-->
                  <mdb-accordion [multiple]="false">
                    <mdb-accordion-item [collapsed]="false" [customClass]="'border-0'">
                      <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon far icon="clock"></mdb-icon> Morning Session</mdb-accordion-item-head>
                      <mdb-accordion-item-body>
                        <div class="row my-4">
                            <!-- Grid column -->
                            <div class="col-lg-12">

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/breakfast/image-01.jpg" alt="Children Welcome, Breakfast">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">
                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">08:00 AM - 10:00 AM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Children Welcome, Breakfast</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>
                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/lessons/img-01.jpg" alt="Activity, Observation, Lessons">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">10:00 AM - 11:00 AM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Activity, Observation, Lessons</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-lg-0 mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-lg-0 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/outdoor activity/img-01.jpg" alt="Outdoor Activities, Fun and Gathering">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">11:00 AM - 12:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-lg-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Outdoor Activities, Fun and Gathering</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                            </div>
                            <!-- Grid column -->
                        </div>
                      </mdb-accordion-item-body>
                    </mdb-accordion-item>

                    <mdb-accordion-item [customClass]="'border-0'">
                      <mdb-accordion-item-head [customClass]="'blue lighten-3 z-depth-1 white-text text-left'"><mdb-icon far icon="clock"></mdb-icon> Afternoon Session</mdb-accordion-item-head>
                      <mdb-accordion-item-body>
                        <div class="row my-4">
                            <!-- Grid column -->
                            <div class="col-lg-12">

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/lunch/img-01.jpg" alt="Lunch Time, Food made in our own kitchen">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">
                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">12:00 PM - 13:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Lunch Time, Food made in our own kitchen</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>
                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/naptime/img-01.jpg" alt="Nap Time">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">13:00 PM - 15:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Nap Time</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                              <!-- Small news -->
                              <div class="single-news mb-lg-0 mb-4">

                                <!-- Grid row -->
                                <div class="row">

                                  <!-- Grid column -->
                                  <div class="col-md-3">

                                    <!--Image-->
                                    <div class="view overlay rounded z-depth-1 mb-lg-0 mb-4">
                                      <img class="img-fluid" src="../../../../../../assets/img/nursery/classrooms/daily-routine/study/img-01.jpg" alt="Activity, Obsevation, Study">
                                      <a>
                                        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                  <!-- Grid column -->
                                  <div class="col-md-9">

                                    <!-- Excerpt -->
                                    <p class="font-weight-bold dark-grey-text text-left">15:00 PM - 16:00 PM</p>
                                    <div class="d-flex justify-content-between text-left">
                                      <div class="col-11 text-truncate pl-0 mb-lg-0 mb-3">
                                        <p class="font-weight-bold dark-grey-text">Activity, Obsevation, Study</p>
                                      </div>
                                      <a>
                                        <mdb-icon fas icon="angle-double-right"></mdb-icon>
                                      </a>
                                    </div>

                                  </div>
                                  <!-- Grid column -->

                                </div>
                                <!-- Grid row -->

                              </div>
                              <!-- Small news -->

                            </div>
                            <!-- Grid column -->
                        </div>
                      </mdb-accordion-item-body>
                    </mdb-accordion-item>
                  </mdb-accordion>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 3-->
        <mdb-tab heading="Gallery">
          <div class="row">
            <div class="col-12">
              <mdb-card class="testimonial-card">
                <!--Bacground color-->
                <div class="card-up">
                </div>

                <!--Avatar-->
                <div class="avatar mx-auto">
                  <img src="../../../../../../assets/img/nursery/classrooms/baby-room/baby-room-avatar.jpg" class="rounded-circle" alt="Baby Room Image Third">
                </div>

                <mdb-card-body>
                  <mdb-card-title>
                    <h3>Gallery</h3>
                  </mdb-card-title>
                  <hr>
                  <!-- Baby Room Gallery -->
                  <app-baby-room-gallery></app-baby-room-gallery>
                  <!-- Baby Room Gallery -->
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 4-->
        <mdb-tab heading="Fee">
         <app-baby-room-fee></app-baby-room-fee>
        </mdb-tab>
        <!--Panel 5-->
        <mdb-tab heading="Admissions">
          <app-baby-room-admission></app-baby-room-admission>
        </mdb-tab>
        <!--Panel 6-->
        <!-- <mdb-tab heading="Contact Us">
          <app-contact-us [showMap]="false"></app-contact-us>
        </mdb-tab> -->
      </mdb-tabset>
      <!-- Nav Tabs -->
    </div>
</section>

<!--Modal Popup -->
<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <h2>
            <span class="badge">v52gs1</span>
          </h2> -->
          <p class="pt-3 mx-4">
            <strong>Thank you for submitting the information. We will get back to you within 24-48 hours.</strong>
          </p>
          <!-- <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect>Get it
            <mdb-icon fas icon="book" class="ml-1"></mdb-icon>
          </a> -->
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect
             data-dismiss="modal" (click)="frame.hide()">Close</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!-- End Modal Popup -->
