import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Address } from 'src/app/modules/nursery/models/address.model';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
  @Input() layoutClass = 'col-md-4';
  @Input() addressFormGroup: FormGroup;
  @Input() addressData: Address;
  @Input() uniqueIndex: string;
  @Input() headerName: string;
  @Input() isRequired = true;
  @Output() addressChange = new EventEmitter<FormGroup>();

  destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
    this.formChange();
    this.addressChange.emit(this.addressFormGroup);
  }

  initForm(): void {
    if (this.isRequired) {
      this.addressFormGroup = this.formBuilder.group({
        id: [''],
        addressLineOne: ['', Validators.required],
        addressLineTwo: ['', Validators.required],
        city: ['', Validators.required],
        postcode: [
          '',
          Validators.compose([
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(5),
          ]),
        ],
        state: ['', Validators.required],
        country: ['', Validators.required],
      });
    } else {
      this.addressFormGroup = this.formBuilder.group({
        id: [''],
        addressLineOne: [''],
        addressLineTwo: [''],
        city: [''],
        postcode: [
          '',
          Validators.compose([
            Validators.maxLength(7),
            Validators.minLength(5),
          ]),
        ],
        state: [''],
        country: [''],
      });
    }
  }

  ngOnChanges() {
    if (this.addressData) {
      this.addressFormGroup ? null : this.initForm();
      this.addressFormGroup.patchValue(this.addressData);
    }
  }

  formChange(): void {
    this.addressFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((value) => {
        this.addressChange.emit(this.addressFormGroup);
      });
  }

  get addressFormGetterSetter() {
    return this.addressFormGroup.controls;
  }

  ngOnDestory(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
