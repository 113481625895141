<!-- Footer -->
<footer class="page-footer font-small pt-4">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <!-- Footer links -->
    <div class="row text-center text-md-left mt-3 pb-3 ml-0 mr-0">

      <!-- Grid column -->
      <div class="col-md-3 col-lg-3 col-xl-3 mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Rise N Shine Nursery</h6>
        <p>Rise 'N' Shine nursery is committed to providing a safe and healthy environment that encourages children to explore, learn and succeed through constructive activities.
        </p>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Useful Links</h6>
        <p>
          <a [routerLink]="['/']" title="About Us">Home</a>
        </p>
        <p>
          <a [routerLink]="['/about-us']" title="About Us">About Us</a>
        </p>
        <p>
          <a [routerLink]="['/classrooms']" title="Classrooms">Classrooms</a>
        </p>
        <p>
          <a [routerLink]="['/admission']" title="Admissions">Admissions</a>
        </p>
        <p>
          <a [routerLink]="['/fee']" title="Fee">Fee</a>
        </p>
        <p>
          <a [routerLink]="['/newsletter-archives']" title="Newsletter Archives">Newsletter Archives</a>
        </p>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Other links</h6>
        <p>
          <a [routerLink]="['/nursery-software']" title="Nursery Software">Nursery App</a>
        </p>
        <p>
          <a [routerLink]="['/funding']" title="Funding">Funding</a>
        </p>
        <p>
          <a [routerLink]="['/policies']" title="Nursery Policies">Policies</a>
        </p>
        <p>
          <a [routerLink]="['/covid-faqs']" title="Covid-19 FAQ">Covid-19 FAQ's</a>
        </p>
        <p>
          <a [routerLink]="['/contact-us']" title="Contact Us">Contact Us</a>
        </p>
      </div>

      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none">

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mt-3">
        <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6>
        <p>
          <mdb-icon fas icon="home" class="mr-0"></mdb-icon> 34 Skylines Village, E14 9TS, London
        </p>
        <p>
          <a href="#" [routerLink]="['/contact-us']"
             title="Email Us"><mdb-icon fas icon="envelope" class="mr-0"></mdb-icon> info@risenshinenursery.co.uk
          </a>
        </p>
        <p>
          <a href="tel:+44-203-887-0220" title="Call Us"><mdb-icon fas icon="phone" class="mr-0"></mdb-icon> + 44 20 3887 0220 </a>
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Footer links -->

    <hr />

    <!-- Grid row -->
    <div class="row d-flex align-items-center">

      <!-- Grid column -->
      <div class="col-md-7 col-lg-8">

        <!--Copyright-->
        <p class="text-center text-md-left">&copy; 2020 <a [routerLink]="['/']"><strong>Rise N Shine Nursery</strong></a>. All rights reserved.</p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-5 col-lg-4 ml-lg-0">

        <!-- Social buttons -->
        <!-- <div class="text-center text-md-right">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item">
              <a mdbBtn floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="facebook"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab fab icon="google-plus"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a mdbBtn floating="true" size="sm" class="rgba-white-slight mx-1" mdbWavesEffect>
                <mdb-icon fab icon="linkedin"></mdb-icon>
              </a>
            </li>
          </ul>
        </div> -->

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

</footer>
<!-- Footer -->
