import { Component, Input, OnInit } from '@angular/core';
import {MDBModalRef, MDBModalService, MdbStepperComponent, ModalModule} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-baby-room-admission',
  templateUrl: './baby-room-admission.component.html',
  //styleUrls: ['./baby-room-admission.component.scss']
})
export class BabyRoomAdmissionComponent implements OnInit {
  modalRef: MDBModalRef;
  @Input() showFullWidth = false;

  constructor() { }

  ngOnInit() {
  }

  openModal() {
    console.log('--- Opening Modal ---');
    // this.modalRef = this.modalService.show(ModalComponent)
  }

}
