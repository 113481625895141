import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseServiceService {
  protected _apiUrl = '/api/1.0.0';

  constructor() {}

  // Http Headers
  // protected httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   }),
  //   'responseType': 'blob' as 'json'
  // }

  // Http Headers.. put it in the http Interceptor
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    responseType: 'text' as 'json',
  };

  protected attachToken() {
    const obj = {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
    };
    if (sessionStorage.getItem('userAccessToken')) {
      obj['Authorization'] = 'Bearer ' + sessionStorage.getItem('userAccessToken');
    }
    return {
      headers: new HttpHeaders(obj),
    };
  }

  // Error handling
  protected errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Deo Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log('${operation} failed: ${error.message}');
      return of(result as T);
    };
  }

  protected logOutError(
    error: HttpErrorResponse
  ): Observable<HttpErrorResponse> {
    return of(error);
  }

  private log(message: string) {
    console.log(message);
  }
}
