import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HeaderHideShowService } from '../../services/header-hide-show.service';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  // styleUrls: ['./fee.component.scss']
  host: { 'class': 'w-100' }
})
export class FeeComponent implements OnInit {
  activeData: any;

  constructor(private headerHideShowService: HeaderHideShowService,
    private route: Router) { }

  ngOnInit() {
    this.headerHideShowService.isHeaderShown(false);
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      const selector = '.cardWrapper';

      $(selector).on('click', function () {
        $(selector).removeClass('active');
        $(this).addClass('active');
      });

      $('.card-wrapper-one').click(function () {
        $('#babyRoomFee').css('display', 'block');
        $('#toddlerRoomFee').css('display', 'none');
        $('#preSchoolRoomFee').css('display', 'none');
      });
      $('.card-wrapper-two').click(function () {
        $('#babyRoomFee').css('display', 'none');
        $('#toddlerRoomFee').css('display', 'block');
        $('#preSchoolRoomFee').css('display', 'none');
      });
      $('.card-wrapper-three').click(function () {
        $('#babyRoomFee').css('display', 'none');
        $('#toddlerRoomFee').css('display', 'none');
        $('#preSchoolRoomFee').css('display', 'block');
      });
    });
  }

}
