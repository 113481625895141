import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { isThisMinute } from 'date-fns';
import { Subject } from 'rxjs';
import { OtherService } from 'src/app/modules/nursery/models/child.model';

@Component({
  selector: 'app-basic-info-form',
  templateUrl: './basic-info-form.component.html',
})
export class BasicInfoFormComponent implements OnChanges {
  @Output() formChange = new EventEmitter<FormGroup>();
  customFormGroup: FormGroup;
  @Input() serviceData: OtherService;
  destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder) {}


  ngOnChanges(): void {
    this.initForm();
    if (this.serviceData) {
      this.customFormGroup.patchValue(this.serviceData);
      this.updateService();
    }
    this.customFormGroup.valueChanges.subscribe(() => {
      this.updateService();
    });
  }

  updateService() {
    this.formChange.emit(this.customFormGroup);
  }

  initForm(): void {
    this.customFormGroup = this.formBuilder.group({
      id: new FormControl(''),
      serviceName: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      contactInfo: new FormControl('', Validators.required),
    });
  }
}
