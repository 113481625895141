<ng-container [formGroup]="checkEligibilityFormThree">
    <!-- Form Field -->
    <div class="form-group pl-4" [hidden]="stage!=='Step 3'">
        <h5 class="mb-3">Are you both working?</h5>
        <span class="mb-4 d-block">You can usually get 30 hours free childcare if you and your partner are working.</span>
        
        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" value="yes" id="workingRadBtn1" name="working" formControlName="working">
            <label class="form-check-label font-weight-bold text-uppercase" for="workingRadBtn1">Yes</label>
        </div>
        
        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" value="no" id="workingRadBtn2" name="working" formControlName="working">
            <label class="form-check-label font-weight-bold text-uppercase" for="workingRadBtn2">No</label>
        </div>

        <!-- <div class="alert-text">
            <mdb-error *ngIf="submitted && form1.working.errors">Please Select Value</mdb-error>
        </div> -->

    </div>

    <!-- Send button -->
    <button mdbBtn type="button" color="danger" rounded="true" class="z-depth-0 my-4 waves-effect" [hidden]="stage=='Step 1'" (click)="goBack(step)" mdbWavesEffect>PREVIOUS</button>
    <button mdbBtn type="button" color="info" rounded="true" class="z-depth-0 my-4 waves-effect float-right" [innerHtml]="stage!=='Step 4' ? 'NEXT': 'SUBMIT' " (click)="goForward();" mdbWavesEffect>NEXT</button>

</ng-container>