<!-- Section: Associated Member -->
<section id="associated-member">

    <!-- Section heading -->
    <h2 class="h1-responsive font-weight-bold text-center">Happy To Be Associated</h2>
    <!-- Section description -->
    <p class="grey-text text-center w-responsive mx-auto mb-5">We are happy to be a member of such Professional Associations who is 
        dedicated to supporting everyone working in childcare and early years to provide high quality care and early learning for 
        children and families.
    </p>
  
    <!-- Grid row-->
    <div class="row text-center text-md-left">
  
        <!-- Grid column -->
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="img-box-wrap d-flex justify-content-center">
                <a href="https://www.ndna.org.uk/" target="_blank">
                    <img src="../../../../../assets/img/icons/ndna.png" alt="NDNA" width="220" />
                </a>
            </div>
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="img-box-wrap d-flex justify-content-center">
                <a href="https://www.pacey.org.uk/" target="_blank">
                    <img src="../../../../../assets/img/icons/pacey.png" alt="PACEY" width="220" />
                </a>
            </div>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="img-box-wrap d-flex justify-content-center">
                <a href="https://www.jigsawearlyyearsconsultancy.com/" target="_blank">
                    <img src="../../../../../assets/img/icons/jigsaw.png" alt="JIGSAW" width="220" />
                </a>
            </div>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="img-box-wrap d-flex justify-content-center">
                <a href="https://www.eyalliance.org.uk/" target="_blank">
                    <img src="../../../../../assets/img/icons/eya.png" alt="EYA" width="220" />
                </a>
            </div>
        </div>
        <!-- Grid column -->
  
    </div>
    <!-- Grid row-->  

    <!-- Grid row-->
    <div class="row text-center text-md-left mt-4">
  
        <!-- Grid column -->
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="img-box-wrap d-flex justify-content-center">
                <a href="https://reports.ofsted.gov.uk/" target="_blank">
                    <img src="../../../../../assets/img/icons/ofsted_registered.png" alt="Ofsted Registered" width="220" />
                </a>
            </div>
        </div>
        <!-- Grid column -->
  
    </div>
    <!-- Grid row--> 
  
  </section>
  <!-- Section: Associated Member -->
  