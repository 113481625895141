<div [formGroup]="form" class="d-flex align-item-baseline justify-content-between">
    <select formControlName="isd" class="form-control isd-code">
        <option [ngValue]="item" *ngFor="let item of optionsSelect">
            +{{item}}
        </option>
    </select>
    <div class="md-form">
        <label>{{controlName}}</label>
        <input formControlName="phone" appNumbersOnly autocomplete="new-landlineNumber" maxlength="10" name="phone" mdbInput
            mdbValidate type="text" class="form-control complete-number">
        <mdb-error
            *ngIf="form.get('phone').touched &&  form.get('phone').errors">
            Please enter {{controlName}}.
        </mdb-error>
    </div>
</div>