export interface ChildSessionDTO {
  id?: number;
  childId: string;
  friday: SessionType;
  monday: SessionType;
  saturday: SessionType;
  sunday: SessionType;
  thursday: SessionType;
  tuesday: SessionType;
  wednesday: SessionType;
}

export interface Discount {
  discountId: number;
  discountCode: string;
  discountType: string;
  discountPercentage: number;
}

export interface FeeDiscount {
  id: number;
  child: Child;
  discount: Discount;
}

export interface ChildSessionAndFeeDiscountDTO {
  childSessionDTO: ChildSessionDTO;
  discountCodes: string[];
}

export interface ParentChildren {}

export interface Child {}

export interface ChildObject {
  anyOtherLanguageSpoken: string[];
  birthPlace: string;
  dob: string;
  ethinicity: string;
  medicalNeeds: string;
  childSessionDTO: ChildSessionDTO; // todo remove this
  firstLanguage: string;
  firstName: string;
  gender: string;
  dietaryRequirements: string;
  dislike: string;
  routine: string;
  liveWith: string;
  needs: string;
  id: number;
  lastName: string;
  livesWith: string;
  nationality: string;
  parentChildren: ParentChildren;
  parentalResponsibility: string;
  religion: string;
  room: Room;
}

export interface Room {
  id: number;
  nurseryId: number;
  roomName: string;
  description: string;
}
export interface HomeAddress {
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  country: string;
  id: number;
  postcode: string;
  state: string;
}

export interface HealthVisitorDetails extends DoctorDetails {}

export enum RelationType {
  child = 1,
  ParentOne = 2,
  ParentTwo = 3,
  EmergencyContactOne = 4,
  EmergencyContactTwo = 5,
  ChildSession = 6,
  Doctor = 7,
  HealthVisitor = 8,
  Consent,
  GuardianOne = 10,
  GuardianTwo = 11,
  GuardianThree = 12,
  GuardianFour = 13
}

export enum SessionType {
  Full = 0,
  AM = 1,
  PM = 2,
}

export enum FundingSessionMonths {
  Jan = 'Jan',
  April = 'April',
  September = 'September'
}

export enum RoomType {
  Baby = 'Baby',
  Toddler = 'Toddler',
  PreSchool = 'Pre-School',
  NoRoom = 'No Room'
}

export enum DiscountType {
  TwoYears15Hours = '2Yrs15Hrs',
  ThreeYears15Hours = '3Yrs15Hrs',
  ThreeYears30Hours = '3Yrs30Hrs'
}

export interface RelationShipObject {
  id: number;
  childId: number;
  title: string;
  type: RelationType;
  firstName: string;
  lastName: string;
  relationshipToChild: string;
  email: string;
  mobileNumber: string;
  landlineNumber: string;
  jobTitle: string;
  department: string;
  homeAddress: HomeAddress;
  officeAddress: HomeAddress;
  parentalResponsibility: string | boolean;
  ableToCollectChild: string | boolean;
  officePhoneNumber: string;
}

export interface MedicalDetail {
  id: number;
  childId: number;
  doctorDetails: DoctorDetails;
  healthVisitorDetails: DoctorDetails;
  hasRedBook: boolean;
  services: OtherService[];
}

export interface DoctorDetails {
  id: number;
  childId: number;
  type: number;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  officeAddress?: HomeAddress;
}

export interface OtherService {
  id: number;
  name: string;
  serviceName: string;
  date: string;
  contactInfo: string;
}

export interface ImmuneDataObject {
  id: number;
  vaccines: string[];
  allergies: string;
}

export interface IndividualData {
  id?: number;
  questionId: number;
  questionText: string;
  status?: boolean;
  description?: string;
}

export interface ConsentData {
  id?: number;
  groupLabel: string;
  groupDescription: string;
  label?: string;
  groupSeqOrder: number;
  seqOrder: number;
}

export interface ConsetMasterData {
  1: ConsentData[];
  2: ConsentData[];
  3: ConsentData[];
  4: ConsentData[];
}

export class ConsenViewModel {
  label: string;
  consentText: string;
  date: Date;
  consents = [new Consents()];
  ip?: string;
  consentMasterId: number;

  constructor(
    consentText = null,
    label = null,
    consents = [],
    date = new Date(),
    ip = null
  ) {
    this.label = label;
    this.consentText = consentText;
    this.date = date;
    this.consents = consents;
    this.ip = ip;
  }
}

export class Consents {
  isAllowed: boolean;
  label: string;
  consentMasterId: number;

  constructor(isAllowed = false, label = null) {
    this.isAllowed = isAllowed;
    this.label = label;
  }
}

export interface ConsentPostData {
  date: string;
  ip: string;
  value: boolean;
  consentMasterId: string;
}

export interface FormStatus {
  childId: string;
  child: boolean;
  parentOne: boolean;
  parentTwo: boolean;
  emergencyContactOne: boolean;
  emergencyContactTwo: boolean;
  doctor: boolean;
  visitor: boolean;
  immune: boolean;
  childSession: boolean;
  individual: boolean;
  consent: boolean;
  guardian: number[];
}

export interface AllAddresses {
    type: number;
    homeAddress: HomeAddress;
    officeAddress: HomeAddress;
}

export interface CopyAddress extends HomeAddress {
  addressType: number;
  relationType: number;
}
