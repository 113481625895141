import { Component } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderHideShowService } from '../services/header-hide-show.service';


@Component({
  selector: 'app-nursery-form',
  templateUrl: './nursery-form.component.html',
})
export class NurseryFormComponent {
  private breadCrumbObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  destroySubject: Subject<any> = new Subject<any>();

  showBreadCrumb = false;

  constructor(private headerHideShowService: HeaderHideShowService) {
    console.log('Main layout constructor called');

  }

  ngOnInit() {
    this.headerHideShowService.change
      .pipe(takeUntil(this.destroySubject)).subscribe((isHeaderShown: boolean) => {
      console.log(' ----------- ' + this.showBreadCrumb);
      this.showBreadCrumb = !isHeaderShown;
    });
  }
}
