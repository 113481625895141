import {createAction, props} from '@ngrx/store';
import {ContinousProvisionPlanning} from '../../../models/continous-provision-planning.model';

export const addContinousProvisionPlanningAction = createAction(
  '[Add Continous Provision Planning] Add Continous Provision Planning',
  props<{continousProvisionPlanning: ContinousProvisionPlanning[]}>()
);

export const saveContinousProvisionPlanningAction = createAction(
  '[Save Continous Provision Planning] Saving Continous Provision Planning to database',
  props<{continousProvisionPlanning: ContinousProvisionPlanning[]}>()
);


