<div id="address-info" [formGroup]="otherDetailsForm">
  <mdb-checkbox class="d-block pt-3" formControlName="checkBox" (change)="changeChkValue($event)">
    {{questionLabel}}
  </mdb-checkbox>
  <div class="md-form mb-5 mt-3" *ngIf="otherDetailsForm?.get('checkBox')?.value">
    <div class="other-details col-lg-6 col-md-12 col-sm-12 p-0 mt-3" >
      <input type="text" class="form-control" formControlName="description" mdbInput mdbValidate id="other" >
      <label for="other">If Yes Please Give Details</label>
      <mdb-error *ngIf="otherDetailsForm?.get('description').touched && otherDetailsForm?.get('description')?.errors">
        Please enter the details
      </mdb-error>
    </div>
  </div>
</div>
