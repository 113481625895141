<div class="admin-page-wrapper">
  <div class="header-admin">
    <div class="d-flex align-items-center justify-content-center">
      <div class="humburger mr-5" (click)="showMenu = !showMenu">
        <i class="fa fa-bars"></i>
      </div>
      <mdb-navbar-brand>
        <a class="navbar-brand" [routerLink]="['/']">
          <img src="../../../../assets/img/logo/rise-n-shine-logo.png" height="60"
            alt="Rise N Shine Nursery, Isle of Dogs Canary, UK">
        </a>
      </mdb-navbar-brand>
    </div>
    <div>
      <li class="nav-item mega-dropdown ml-3 d-lg-block d-none">
        <div class="btn-group" mdbDropdown>
          <a mdbDropdownToggle mdbWavesEffect type="button" class="dropdown-toggle waves-light" mdbWavesEffect
            style="height: 40px; width: 40px">
            <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-1.jpg" alt=""
              class="img-fluid rounded-circle z-depth-0"></a>
          <div class="dropdown-menu dropdown-primary login-dropdown">
            <a (click)="logout()" class="dropdown-item" mdbWavesEffect>
              Logout
            </a>
          </div>
        </div>
      </li>
    </div>
  </div>
  <div class="row p-0 m-0 d-flex align-items-center justify-content-center">
    <div [style.left]="showMenu ? '0px !important' : ''" class="side-bar">
      <div (click)="goToRoute(item.router)" *ngFor="let item of menuItems" class="burger-items">
        <div class="items" >
          <img class="icon-menu" [class.position-relative]="showMenu" [src]="'../../../../assets/img/icons/'+item.icon">
          <span class="menu-label" [class.d-block]="showMenu"> {{item.label}}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-11 col-sm-12">
      <main class="main-wrapper">
        <breadcrumb #parent>
          <ol class="breadcrumb">

            <li class="breadcrumb-item ">
              <a href="#" [routerLink]="['/pages']">
                <mdb-badge info="true">Admin Dashboard</mdb-badge>
              </a>
            </li>

            <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
              <li *ngIf="!route.terminal" class="breadcrumb-item">
                <a href="#" [routerLink]="[route.url]">
                  <mdb-badge default="true">{{ route.displayName }}</mdb-badge>
                </a>
              </li>
              <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">
                <mdb-badge warning="true">{{ route.displayName }}</mdb-badge>
              </li>
            </ng-template>

          </ol>
        </breadcrumb>
        <!-- End Breadcrumb Section -->
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>

</div>
