<body yahoo style="min-width: 100% !important; margin: 0; padding: 0;">
<table width="100%" bgcolor="#f7ffff" border="0" cellpadding="0" cellspacing="0"
       [style.background-color]="email.colors.fifth">
  <tr>
    <td>
      <!--[if (gte mso 9)|(IE)]>
      <table width="600" align="center" cellpadding="0" cellspacing="0" border="0">
        <tr>
          <td>
      <![endif]-->
      <table class="content" align="center" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff"
             style="width: 600px !important; max-width: 600px;"
             [style.background-color]="email.colors.third">
        <tr>
          <td class="header" bgcolor="#6DBBEF" style="padding: 20px 30px;"
              [style.background-color]="email.colors.fourth">

            <!--[if (gte mso 9)|(IE)]>
            <table width="425" align="left" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <td>
            <![endif]-->
            <table class="col275" align="left" border="0" cellpadding="0" cellspacing="0"
                   style="width: 275px !important; max-width: 275px;">
              <tr>
                <td height="70">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td class="subhead"
                          style="font-size: 15px; color: #ffffff; font-family: sans-serif; letter-spacing: 10px; padding: 0 0 0 3px;"
                          [style.color]="email.colors.secondary">
                        {{email.general.subheading}}
                      </td>
                    </tr>
                    <tr>
                      <td class="h1"
                          style="font-size: 33px; line-height: 38px; font-weight: bold; color: #12569E; font-family: sans-serif; padding: 5px 0 0;"
                          [style.color]="email.colors.primary">
                        {{email.general.heading}}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
            <table width="220" align="right" border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td height="70" style="padding: 0 0px 20px 0;">
                  <img [src]="email.general.logo" width="210" height="auto" border="0" alt="bild"
                       style="height: auto;"/>
                </td>
              </tr>
            </table>

          </td>
        </tr>

        <tr>
          <td class="innerpadding borderbottom"
              style="border-bottom-width: 1px; border-bottom-color: #f2eeed; border-bottom-style: solid; padding: 30px;">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td class="h2"
                    style="color: #12569E; font-family: sans-serif; font-size: 24px; line-height: 28px; font-weight: bold; padding: 0 0 15px;"
                    [style.color]="email.colors.primary">
                  {{email.introduction.heading}}
                </td>
              </tr>
              <tr>
                <td class="bodycopy" style="font-family: sans-serif; font-size: 16px; line-height: 22px;"
                    [style.color]="email.colors.ninth">
                  {{email.introduction.text}}
                </td>
              </tr>
            </table>
          </td>
        </tr>


        <tr *ngFor="let section of email.sections">
          <td class="innerpadding borderbottom"
              style="border-bottom-width: 1px; border-bottom-color: #f2eeed; border-bottom-style: solid; padding: 30px;">
            <table width="100%" align="left" border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td class="h3"
                    style="color: #12569E; font-family: sans-serif; font-size: 20px; line-height: 24px; font-weight: bold; padding: 0 0 5px;"
                    [style.color]="email.colors.primary">
                  {{section.heading}}
                </td>
              </tr>
            </table>
            <table width="115" align="left" border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td height="115" style="padding: 0 20px 20px 0;">
                  <img *ngIf="section.img != ''" [src]="section.img" width="115" height="auto" border="0" alt="article"
                       style="height: auto;"/>
                </td>
              </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
            <table width="380" align="left" cellpadding="0" cellspacing="0" border="0">
              <tr>
                <td>
            <![endif]-->
            <table class="col380" align="left" border="0" width="380" cellpadding="0" cellspacing="0"
                   style="width: 380px !important; max-width: 380px;">
              <tr>
                <td>
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td class="bodycopy" style="font-family: sans-serif; font-size: 16px; line-height: 22px;"
                          [style.color]="email.colors.ninth">
                        {{section.text}}
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 20px 0 0 0;">
                        <table class="buttonwrapper" align="right" bgcolor="#ADACB2" border="0" cellspacing="0"
                               cellpadding="0" style="background-color: transparent !important;"
                               [style.background-color]="email.colors.sixth">
                          <tr>
                            <td *ngIf="section.button != ''" class="button" height="35" align="center"
                                style="text-align: center; font-size: 14px; font-family: sans-serif; font-weight: bold; padding: 0 30px;">
                              <a [href]="section.link"
                                 style="color: #ffffff; text-decoration: none; display: block !important; padding: 15px 15px 13px;">{{section.button}}</a>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
          </td>
        </tr>

        <tr>
          <td class="innerpadding borderbottom bodycopy"
              style="font-family: sans-serif; border-bottom-width: 1px; border-bottom-color: #f2eeed; border-bottom-style: solid; font-size: 16px; line-height: 22px; padding: 30px;"
              [style.color]="email.colors.ninth">
            {{email.bottom.text}}
          </td>
        </tr>

        <tr>
          <td class="footer" bgcolor="#44525f" style="padding: 20px 30px 15px;"
              [style.background-color]="email.colors.seventh">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td align="center" class="footercopy" style="font-family: sans-serif; font-size: 14px; color: #ffffff;">
                  <a [href]="email.bottom.link" class="unsubscribe"
                     style="color: #ffffff; text-decoration: none !important; display: block; margin-top: 20px; border-radius: 5px; font-weight: bold; width: 70px; background-color: #2f3942; padding: 10px 50px;"
                     [style.background-color]="email.colors.eighth">
                    <font color="#ffffff">{{email.bottom.unsubscribe}}</font>
                    <span class="hide" style="display: none !important;">from this newsletter instantly</span>
                  </a>
                  <p><b>{{email.imprint.company}}</b></p>
                  <p>{{email.imprint.street}}</p>
                  <p>{{email.imprint.city}}</p>
                  <p><a [href]="email.imprint.website" style="color: #ffffff; text-decoration: underline;">{{email.imprint.website}}</a>
                  </p>
                  <p>{{email.imprint.responsibilities}}</p>

                </td>
              </tr>
            </table>
          </td>
        </tr>


      </table>
      <!--[if (gte mso 9)|(IE)]>
      </td>
      </tr>
      </table>
      <![endif]-->
    </td>
  </tr>
</table>
</body>

<div fxLayout="column" class="mt-5">
  <h3 class="text-center">Send Newsletter</h3>
  <div fxLayout="row">
    <button mat-raised-button (click)="getDownloadHtmlLink()" class="mx-auto btn-info mt-3 w-25">Send Now</button>
  </div>
  <!-- <div fxLayout="column">
    <p>Click one of the buttons to copy HTML code or JSON config to clipboard.</p>
    <div fxLayout="row">
      <button mat-raised-button ngxClipboard [cbContent]="getHtmlEmailContent()" class="margin-right--sm">GET HTML
      </button>
      <button mat-raised-button ngxClipboard [cbContent]="getJson()">GET CONFIG (JSON)</button>
    </div>
  </div> -->
  <!-- <h3>Set config</h3>
  <div fxLayout="column" class="preview-action-buttons">
    <p>Paste your config json into the textarea and click the button.</p>
    <mat-form-field>
      <textarea matInput [(ngModel)]="config" style="resize: none;">Enter config JSON...</textarea>
    </mat-form-field>
    <div fxLayout="row">
      <button mat-raised-button (click)="setJson()">SET CONFIG (JSON)</button>
    </div>
  </div> -->
</div>
