  <i class="fa fa-trash position-absolute" (click)="remove(question)" aria-hidden="true"></i>
  <div>
    <div class="question-box">
      <div class="card mb-3 card-body " [class.bg-warning]="question.flag === 'warning'"
        [class.bg-danger]="question.flag === 'danger'">
        <div class="question d-flex align-items-center justify-content-between">
          <div [@enterAnimation] class="question-text">
            {{currentLength+1}}. {{question.label}}
          </div>
          <div class="question-count">
            Question {{currentLength+1}} of {{totalCount}}
          </div>
        </div>
        <div class="progress">
          <div class="progress-bar bg-info" role="progressbar" aria-valuenow="0" aria-valuemin="0"
            aria-valuemax="100" style="width: 100%;">
          </div>
        </div>
        <div class="selected-value d-flex align-items-center justify-content-between position-relative"
          [@enterAnimation]>
          <label class="container">Yes
            <input type="radio" checked="checked" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="question.value" [value]="'1'" name="radio_{{currentLength}}">
            <span class="checkmark"></span>
          </label>
          <label class="container">No
            <input type="radio" checked="checked" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="question.value" [value]="'0'" name="radio_{{currentLength}}">
            <span class="checkmark"></span>
          </label>
          <label class="container">Partially
            <input type="radio" checked="checked" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="question.value" [value]="'2'" name="radio_{{currentLength}}">
            <span class="checkmark"></span>
          </label>
          <div class="btn-group" dropdown placement="left">
            <i dropdownToggle class="fa fa-flag" [ngClass]="question.flag" aria-hidden="true"></i>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
              <li role="menuitem">
                <a (click)="question.flag = 'danger'" class="dropdown-item d-flex align-items-center">
                  <i dropdownToggle class="fa fa-flag danger mr-2" aria-hidden="true"></i>
                  <span>Attention Required</span>
                </a></li>
              <li role="menuitem"><a (click)="question.flag = 'warning'"
                  class="dropdown-item d-flex align-items-center">
                  <i dropdownToggle class="fa fa-flag warning mr-2" aria-hidden="true"></i>
                  <span>Warning Required</span>
                </a></li>
              <li role="menuitem"><a (click)="question.flag = ''"
                  class="dropdown-item d-flex align-items-center">
                  <i dropdownToggle class="fa fa-flag mr-2" aria-hidden="true"></i>
                  <span>Reset</span>
                </a></li>
            </ul>
          </div>
        </div>
        <div class="desc-box position-relative">
          <span class="mb-2">Reason:</span>
          <textarea [class.has-error]="(description.errors?.required && description?.touched)"
            #description="ngModel" required class="reason" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="question.description"></textarea>
          <span class="text-error position-absolute"
            *ngIf="description.errors?.required && description?.touched">
            Reason is required.
          </span>
        </div>
        <div class="footer-section d-flex justify-content-end">
          <button type="button" [disabled]="!description.valid" mdbBtn (click)="save(question)"
            class="rs-btn-info btn btn-rounded waves-effect waves-light" data-target="#basicExample"
            mdbWavesEffect>
            <span *ngIf="question.loader">
              <div class="text-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </span>
            <span *ngIf="!question.loader">
              Save
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
