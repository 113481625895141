import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MdbStepperComponent, ToastService} from 'ng-uikit-pro-standard';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, FormGroupName, FormBuilder, Validator, Validators, AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-eligibility-step-three',
  templateUrl: './eligibility-step-three.component.html',
  //styleUrls: ['./eligibility-step-three.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EligibilityStepThreeComponent),
      multi: true
    },
      {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EligibilityStepThreeComponent),
      multi: true
    }
  ]
})
export class EligibilityStepThreeComponent implements OnInit {

  submitted = false;

  working: '';

  @Input() stage: string;

  @Input() step: MdbStepperComponent;

  checkEligibilityFormThree: FormGroup;

  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastService) { }

  ngOnInit() {
    this.checkEligibilityFormThree = this.formBuilder.group({
      working: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get form1() { return this.checkEligibilityFormThree.controls; }

  goBack(stepper: MdbStepperComponent) {
    stepper.previous();
  }

  goForward() {
    this.submitted = true;
    if (this.checkEligibilityFormThree.invalid) {
      this.showWarning();
      return;
    }
    else {
      if(this.stage!=='Step 4') {
        console.log(' Data For ' + this.stage + ':' + JSON.stringify(this.checkEligibilityFormThree.value));
        this.step.next();
      }
      else {
        console.log(' Data For ' + this.stage + ':' + JSON.stringify(this.checkEligibilityFormThree.value));
        this.showSuccess();
      }
    }
  }

  showSuccess() {
    this.toastrService.info('You are eligible for 30 hours. For more details visit government website.');
    setTimeout(function(){ location.reload(); }, 3000);
  }

  showWarning() {
    this.toastrService.warning('Please Select Value!!');
  }

  onReset() {
    this.submitted = false;
    this.checkEligibilityFormThree.reset();
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.checkEligibilityFormThree.setValue(val, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    //console.log("on change");
    this.checkEligibilityFormThree.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    //console.log("on blur");
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.checkEligibilityFormThree.disable() : this.checkEligibilityFormThree.enable();
  }
  validate(c: AbstractControl): ValidationErrors | null{
    console.log("Eligibility Check Form 3 validation", c);
    return this.checkEligibilityFormThree.valid ? null : { invalidForm: {valid: false, message: "Eligibilty Check Form 3 Invalid"}};
  }

}
