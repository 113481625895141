import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserContactUsEffects } from './effects/user-contact-us.effect';
import * as fromStore from './reducers/user-contact-us.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.userContactStateFeatureKey, fromStore.userContactUsReducers, { metaReducers: fromStore.userMetaReducers }),
    EffectsModule.forFeature([UserContactUsEffects]),
  ],
  declarations: []
})
export class UserContactUsStoreModule { }

