import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RegistrationEffect } from './effects/registration.effects';
import * as fromStore from './reducers/registration.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.registrationStateFeatureKey,
      fromStore.registrationReducers,
      { metaReducers: fromStore.registrationMetaReducers }),
    EffectsModule.forFeature([RegistrationEffect]),
  ],
  declarations: []
})
export class CustomStoreModule { }

