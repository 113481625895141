<div class="calendar-container">
  <mdb-card-rotating #card>
    <div class="face front tp-box_side tp-box_front">
      <!--      <a class="btn btn-link m-0 p-0" (click)="chooseManual = true;card.toggle();clearSelectedAppointment()">-->
      <!--        Choose Date Time Manually-->
      <!--      </a>-->
      <!--      <div class="mt-4 mb-4 ml-0 mr-0 rounded custom-border-line">-->
      <!--        <span class="other-content">or</span>-->
      <!--      </div>-->
      <div class="position-relative">
        <i (click)="calendarMoveAllowed(true)" [class.disabled]="!isNextAllowed"
          class="fas fa-chevron-right position-absolute"></i>
        <i (click)="calendarMoveAllowed(false)" [class.disabled]="!isPreviousAllowed"
          class="fas fa-chevron-left position-absolute"></i>
        <h3 class="calendar-heading">{{ dateHeading }}</h3>
      </div>

      <div class="divider-line"></div>
      <div class="calendar-body">
        <div class="calendar-dateslot" *ngFor="let appointment of groupedAppointments">
          <div>{{ appointment.dayOfWeek }}</div>
          <h4 class="calendar-dateslot__day">{{ appointment.day }}</h4>
          <div class="slot-selection-card-wrapper" [title]="isBookingDisabled ? 'Please fill the contact form' : ''">
            <div class="slot-selection-card" [ngClass]="{
                'slot-selection-card-selected': optionSelected[slot.index],
                'slot-option-unavailable': !slot.timeSlot.available
              }" *ngFor="let slot of appointment.appointments; let i = index">
              <div class="Body-Small time-slot-text" [ngClass]="{ 'slot-unavailable__time': !slot.timeSlot.available }">
                {{ slot.timeSlot.timeRange }}
              </div>
              <div *ngIf="slot.timeSlot.available && !slot.timeSlot.booked" class="Body-X-Small selected-option-unavailable">
                <p class="Body-X-Small-Book">Available</p>
              </div>
              <div *ngIf="!slot.timeSlot.available && !slot.timeSlot.booked" style="opacity: 0.4;"
                class="Body-X-Small selected-option-unavailable">
                <p class="Body-X-Small-Book">Unavailable</p>
              </div>
              <div *ngIf="slot.timeSlot.booked" style="opacity: 0.4;" class="Body-X-Small selected-option-unavailable">
                <p class="Body-X-Small-Book">Your Booking</p>
              </div>
              <div class="slot-selection-container">
                <div class="selection-option-indicator" *ngIf="slot.timeSlot.available && !slot.timeSlot.booked">
                  <input value="{{ slot }}" name="option-{{ slot.index }}" type="radio" id="option-{{ slot.index }}"
                    (ngModel)="(slot)" (change)="slotSelected(slot)" (click)="checkIfDisabled($event)"
                    [checked]="optionSelected[slot.index]" />
                </div>
                <div *ngIf="optionSelected[slot.index] && slot.timeSlot.available" class="selected-option-indicator">
                  <i class="fa fa-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vm-row calendar-summary pl-3 pr-3">
        <div class="selected-date">
          <h5 class="calendar-summary__title m-2">Selected date:</h5>
          <h5 class="calendar-summary__detail m-2">{{ selectedDateString }}</h5>
        </div>
        <div class="selected-date">
          <h5 class="calendar-summary__title m-2">Selected time slot:</h5>
          <h5 class="calendar-summary__detail m-2">{{ selectedTimeSlot }}</h5>
        </div>
      </div>
      <div class="text-center clearfix mt-4" *ngIf="selectedDateString && selectedTimeSlot">
        <button (click)="onSubmit()" type="submit" mdbBtn class="rs-btn-info btn btn-rounded waves-effect waves-light"
          data-toggle="modal" data-target="#basicExample" mdbWavesEffect>Submit
        </button>
      </div>
    </div>
    <div class="back tp-box_side tp-box_back p-0">
      <a class="btn btn-link m-0 p-0" (click)="chooseManual = false;card.toggle(); clearSelectedAppointment() ">
        Choose From Slots
      </a>
      <div class="mt-4 mb-4 ml-0 mr-0 rounded custom-border-line">
        <span class="other-content">or</span>
      </div>
      <div class="date-picker mt-3 mb-3">
        <div class="col-lg-6 col-md-6 col-sm-12 no-gutter">
          <mdb-date-picker [(ngModel)]="selectedAppointment.appointmentDate" [options]="myDatePickerOptions"
            (closeButtonClicked)="updateSlot(selectedAppointment)" [inline]="true" name="mydate"
            [placeholder]="'Select starting date'" required>
          </mdb-date-picker>
        </div>
      </div>
      <input type="time" (change)="updateSlot(selectedAppointment)" id="input" class="form-control"
        [(ngModel)]="selectedAppointment.timeSlot.startTime" mdbInput />
      <div class="vm-row calendar-summary">
        <div>
          <h5 class="calendar-summary__title m-2">Selected date:</h5>
          <h5 class="calendar-summary__detail m-2">{{ selectedDateString }}</h5>
        </div>
        <div>
          <h5 class="calendar-summary__title m-2">Selected time slot:</h5>
          <h5 class="calendar-summary__detail m-2">{{ selectedTimeSlot }}</h5>
        </div>
      </div>
      <div class="text-center clearfix mt-4" *ngIf="selectedDateString && selectedTimeSlot">
        <button (click)="onSubmit()" type="submit" mdbBtn class="rs-btn-info btn btn-rounded waves-effect waves-light"
          data-toggle="modal" data-target="#basicExample" mdbWavesEffect>Submit
        </button>
      </div>
    </div>


  </mdb-card-rotating>
</div>


<!--<app-modal-toaster>{{modalText}}</app-modal-toaster>-->
<!-- End Modal Popup -->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-full-width">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <p class="pt-3 mx-4">
            <strong>{{modalText}}</strong>
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
            (click)="autoShownModal.hide()">Close</a>
        </div>
      </div>
    </div>
  </div>
</div>

