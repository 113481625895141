import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HeaderHideShowService} from '../../nursery/services/header-hide-show.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  private breadCrumbObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  destroySubject: Subject<any> = new Subject<any>();

  showBreadCrumb = false;

  constructor(private headerHideShowService: HeaderHideShowService) {
    console.log('Main layout constructor called');

  }

  ngOnInit() {

    // this.breadCrumbObs$.subscribe(parameter => {
    //   console.log(parameter);
    //   this.showBreadCrumb  = parameter == 'show' ? true : false;
    // });

    this.headerHideShowService.change
      .pipe(takeUntil(this.destroySubject)).subscribe((isHeaderShown: boolean) => {
      console.log(' ----------- ' + this.showBreadCrumb);
      this.showBreadCrumb = !isHeaderShown;
    });
  }

}
