import { saveUserContactInfoError404, saveUserContactInfoError, saveUserContactInfoSuccess, saveUserContactUs, initUserContactInfoStore } from './../actions/user-contact-us.action';
import { createReducer, MetaReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { ContactUs } from 'src/app/modules/nursery/models/contact-us.model';


export const userContactStateFeatureKey = 'userContactUs';


export interface UserContactUsState {
  userContactUs: ContactUs;
}

export const initialState: UserContactUsState = {
  userContactUs: {
    id: null,
    title: null,
    firstName: null,
    lastName: null,
    fullName: null,
    email: null,
    phoneNumber: null,
    ageGroup: null,
    message: null,
    comment: null,
    subject: null,
    creationDateTime: null,
    estimatedStartDate: null,
    showaroundDate: null,
    showaroundTime: null,
    showaroundDateTime: null,
    status: null,
    closedStatus: null,
    nursery: null,
    tempComment: null,
    user: null,
    contactUsHistoryList: null,
  } as ContactUs,
};

export const _userContactUsReducers = createReducer(initialState,

  on(saveUserContactUs, (state: UserContactUsState = initialState, action) => (
    {
      ...state,
      userContactUs: {
        ...action.contactInfo,
      }
    })),

  on(saveUserContactInfoSuccess, (state: UserContactUsState = initialState, action) => (
    {
      ...state,
      userContactUs: {
        ...action.contactInfo,
      }
    })),

  on(saveUserContactInfoError, (state: UserContactUsState = initialState, action) => (
    {
      ...state,
      userContactUs: {
        ...action.contactInfo,
      }
    })),

  on(initUserContactInfoStore, (state: UserContactUsState = initialState, action) => (
    {
      ...state,
      userContactUs: {
        ...action.contactInfo,
      }
    })),

  on(saveUserContactInfoError404, (state: UserContactUsState = initialState, action) => (
    {
      ...state,
      userContactUs: {
        ...action.contactInfo,
      }
    })),
);

export function userContactUsReducers(state, action) {
  return _userContactUsReducers(state, action);
}

export const userMetaReducers: MetaReducer<UserContactUsState>[] = !environment.production ? [] : [];
