import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderEnabled } from 'src/app/shared/components/loader/loader.service';
import { environment } from 'src/environments/environment';

import { ChildRegistrationForm } from '../models/registration.model';
import { BaseServiceService } from './base-service.service';

@Injectable({providedIn: 'root'})
export class RegistrationService extends BaseServiceService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    'responseType': 'text' as 'json'
  };

  constructor(private readonly _http: HttpClient) {
    super();
  }

  @LoaderEnabled()
  getRregistration(registrationId: number): Observable<any> {
    return this._http.get(this._apiUrl + `/registration/get-registration/${registrationId}`)
      .pipe(
        catchError(this.handleError('error while creating child registration', registrationId))
      );
  }

  @LoaderEnabled()
  saveChild(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(environment.apiUrl + '/child', registration.child, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating child registration', registration))
      );
  }

  @LoaderEnabled()
  saveParentOne(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-parent-one-reg', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating parent1 registration', registration))
      );
  }

  @LoaderEnabled()
  saveParentTwo(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-parent-two-reg', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while creating parent2 registration', registration))
      );
  }

  @LoaderEnabled()
  saveEmergencyContactOne(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-emergency-contact-one', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving emergency contact two registration', registration))
      );
  }

  @LoaderEnabled()
  saveEmergencyContactTwo(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-emergency-contact-two', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving emergency contact one registration', registration))
      );
  }

  @LoaderEnabled()
  saveChildSessions(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-child-session', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving child session', registration))
      );
  }

  @LoaderEnabled()
  saveMedicalDetails(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-medical-details', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving Medical details', registration))
      );
  }

  @LoaderEnabled()
  saveImmuneDetails(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-immune-details', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving immune details', registration))
      );
  }

  @LoaderEnabled()
  saveIndividualDetails(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-individual-details', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving individual details', registration))
      );
  }

  @LoaderEnabled()
  saveConsentDetails(registration: ChildRegistrationForm): Observable<any> {
    return this._http.post(this._apiUrl + '/registration/save-consent-details', registration, this.httpOptions)
      .pipe(
        catchError(this.handleError('error while saving consent details', registration))
      );
  }

}
