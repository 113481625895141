import {Component, HostListener, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FocussedActivityService} from '../../services/focussed-activity.service';
import {FocussedActivity} from '../../models/focussed-activity.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MDBModalRef} from 'ng-uikit-pro-standard';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';

import {saveFocussedActivity} from '../../home/registration/store/actions/registration.actions';
import {FocussedActivityState} from "../store/reducers/focussed-activity.reducer";


@Component({
  selector: 'app-focussed-activity',
  templateUrl: './focussed-activity.component.html',
  // styleUrls: ['./focussed-activity.component.scss']
  host: {'class': 'w-100'}
})
export class FocussedActivityComponent implements OnInit {

  public nestedForm: FormGroup = new FormGroup({
    common: new FormControl(''),
    focussedActivity: new FormControl('')
  });

  focussedActivityForm: FormGroup;

  submitted = false;

  modalRef: MDBModalRef;

  // convenience getter for easy access to form fields
  retval: Observable<boolean>;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<FocussedActivityState>,
    private focussedActivityService: FocussedActivityService) { }

  isVertical = false;

  @HostListener('window:resize') onWindowResize() {

    if (window.innerWidth <= 1199) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }

  ngOnInit() {
  }

  // convenience getter for easy access to form fields
  get form1() { return this.nestedForm.controls; }

  saveFocussedActivity() {
  let focussedActivity: FocussedActivity;
  this.store.pipe(select(saveFocussedActivity)).subscribe((focussedActivityState: FocussedActivityState) => {
    focussedActivity = focussedActivityState.focussedActivity;
    }
  );
  this.store.dispatch(saveFocussedActivity({focussedActivity : focussedActivity}));
  }

  public onSubmit() {

    if (this.nestedForm.invalid) {
      console.log('Invalid Nested Form');
      return;
    } else {

      this.submitted = true;

      // Make sure to create a deep copy of the form-model
      const result: FocussedActivity = Object.assign({}, this.nestedForm.value);

      this.retval = this.focussedActivityService.saveFocussedActivity(result);

      this.retval.subscribe(data => {
        console.log(' Data Submitted Successfully: ' + JSON.stringify(this.nestedForm.value));
      });

    }
  }
}
