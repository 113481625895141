<div class="invoice-summary">
  <div class="row">
    <div class="col-lg-12">
      <div class="card mt-4 mb-4">
        <div class="children-count-group p-4 pt-0">
          <app-children-group-question [isInReview]="true" [isEditAble]="false"></app-children-group-question>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card mt-4 mb-4">
        <div class="children-count-group p-4 pt-0">
          <app-optional-services [isInReview]="true" [isEditAble]="false"></app-optional-services>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card mt-4 mb-4">
        <div class="children-count-group p-4 pt-0">
          <app-user-info [isInReview]="true" [isEditAble]="false"></app-user-info>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card mt-4 mb-4">
        <div class="children-count-group p-4 pt-0">
          <app-booking-form [isInReview]="true" [isEditAble]="false"></app-booking-form>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card mt-4 mb-4">
        <div class="children-count-group p-4 pt-0">
          <h3 class="main-heading">
            Invoice Total
          </h3>
          <div class="invoice-section">
            <div class="amount">
              Total Payable Amount: £ {{nannyData?.slots?.totalCost || 0}}
            </div>
            <div class="send-payment">
              <div class="pay-details">
                <span class="banc-details">Bank Name:</span>
                <span class="banc-details">ICICI</span>
              </div>
              <div class="pay-details">
                <span class="banc-details">IFSC:</span>
                <span class="banc-details">0000000</span>
              </div>
              <div class="pay-details">
                <span class="banc-details">Account Holder: </span>
                <span class="banc-details">Rise'N'Shine</span>
              </div>
              <div class="pay-details">
                <span class="banc-details">Account Number: </span>
                <span class="banc-details">123986981398</span>
              </div>
            </div>
          </div>
          <div class="footer-section m-4">
            <button mdbBtn   type="button" (click)="goBack()" class="btn btn-link waves-light" mdbWavesEffect>
              Go Back
            </button>
            <button mdbBtn (click)="submit()" type="submit"  class="rs-btn-info btn btn-rounded waves-light"
              mdbWavesEffect>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal-toaster>
   Your request has been submitted. Please check your email for more information. Our Team will contact you ASAP.
</app-modal-toaster>
