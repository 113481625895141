import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BookingComponent } from './nanny-service/booking/booking.component';
import { NannyServiceComponent } from './nanny-service/nanny-service.component';
import { NurseryFormComponent } from './nursery-form.component';

const nurseryFormsRoutes: Routes = [
  {
    path: '',
    component: NurseryFormComponent,
    children: [
      {
        path: 'nanny-service',
        redirectTo: 'nanny-service/',
        pathMatch: 'full',
      },
      {
        path: 'nanny-service/:id',
        component: NannyServiceComponent,
        data: {
          breadcrumb: 'Nanny Service'
        }
      },
      {
        path: 'booking-nanny',
        component: BookingComponent,
        // canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Book Slot'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(nurseryFormsRoutes)],
  exports: [RouterModule]
})

export class NurseryFormsRoutingModule { }
