<div class="check-list-wrapper">
  <div class="filter-box overflow-auto col-12 d-flex align-items-center justify-content-between">
    <h3 class="m-0">Monthly Check List</h3>
    <div class="display-week">
     For the month of - {{currentDate | date: 'MMM'}}
    </div>
  </div>
  <div class="list-wrapper">
    <div class="row d-flex align-items-center justify-content-center">
      <div class="wrapper-week w-100 pl-3 pr-3">
        <div class="add-new-section">
          <a (click)="openModal(template)">+ Add Question</a>
        </div>
        <div class="row d-flex align-items-center justify-content-center mt-4">
          <div class="col-lg-6 position-relative" *ngFor="let question of arrayCheckList.questions;let i=index">
            <app-question-card (saveEmit)="save($event)" (deleteEmit)="remove($event)" [question]="question"
              [currentLength]="i" [totalCount]="arrayCheckList.questions?.length"></app-question-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Question</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="modal-form">
      <div class="row">
        <div class="col-lg-12 md-form p-0 pr-2">
          <input type="text" id="title" #question="ngModel" required [ngModelOptions]="{standalone: true}"
            [(ngModel)]="newQuestion" class="form-control" mdbInput mdbValidate>
          <label for="title">Question</label>
          <span class="text-error position-absolute" *ngIf="question.errors?.required && question?.touched">
            Question is required.
          </span>
        </div>
      </div>
    </div>
    <div class="footer-modal-section">
      <button type="button" mdbBtn [disabled]="!question?.valid" [class.disabled]="!question?.valid"
        class="rs-btn-info btn btn-rounded waves-effect waves-light" (click)="saveNewQuestion()"
        data-target="#basicExample" mdbWavesEffect>Save
      </button>
    </div>
  </div>
</ng-template>
