import { Subject } from 'rxjs';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { NannyServiceModel } from 'src/app/shared/models/nanny-service.model';

import { NannyServiceState } from '../../../store/reducers/nanny-service.reducer';
import { selectNannyFromStore } from '../../../store/selectors/nanny-service.selector';
import {
  addUserCredentials,
  saveUserCredentials,
  saveUserCredentialsError,
} from './../../../store/actions/nanny-service.action';

@Component({
  selector: 'app-user-credentials',
  templateUrl: './user-credentials.component.html',
  styleUrls: ['./user-credentials.component.scss']
})
export class UserCredentialsComponent implements OnInit {

  @Output() successEmit = new EventEmitter();
  @Output() goBack = new EventEmitter();
  form: FormGroup;
  destroy$ = new Subject();

  constructor(private fb: FormBuilder, private updates$: Actions,
    private store: Store<NannyServiceModel>) { }

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.subscribeForm();
    let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
    if (data?.nannyService?.userCredentials) {
      this.form.patchValue(data?.nannyService?.userCredentials)
    }
    this.updates$.pipe(ofType(saveUserCredentialsError))
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.successEmit.next();
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.required)
    });
  }

  subscribeForm(): void {
    this.form.valueChanges.subscribe(() => {
      this.store.dispatch(addUserCredentials({ userCredentials: this.form.value }));
    });
  }

  async submitForm(): Promise<void> {
    if (this.form.valid) {
      let data: NannyServiceState = await this.store.pipe(select(selectNannyFromStore), take(1)).toPromise();
      this.store.dispatch(saveUserCredentials({ nannyService: data.nannyService }));
    }
  }

  back(): void {
    this.goBack.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
